import {
  Box,
  Button,
  Checkbox,
  Stack,
  Modal,
  MenuItem,
  OutlinedInput,
  Pagination,
  PaginationItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  Menu,
  Dialog,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  PrimaryTypography,
  SecondaryTypography,
  StyledOutlinedInput,
  StyledSecondaryTypography,
  StyledTableCell,
  StyledTableRow,
  TypographyDefault,
  StyledSecondaryDefaultTypography,
} from "../../Theme";
import { ReactComponent as SearchIcon } from "../../Icons/Search.svg";
import { ReactComponent as DeleteDisable } from "../../Icons/DeleteDisable.svg";
import { ReactComponent as Close } from "../../Icons/BorderAlertClose.svg";
import { ReactComponent as FilterIcon } from "../../Icons/Filter.svg";
import { ReactComponent as AlertCloseIcon } from "../../Icons/AlertClose.svg";
import { ReactComponent as CheckboxIcon } from "../../Icons/Checkbox.svg";
import { ReactComponent as SortIcon } from "../../Icons/Sort.svg";
import { ReactComponent as NumberInputIcon } from "../../Icons/NumberInputIcon.svg";
import { ReactComponent as CheckBoxMinusIcon } from "../../Icons/CheckBoxMinus.svg";
import { ReactComponent as DeleteIcon } from "../../Icons/DeleteTable.svg";
import { ReactComponent as PreviousIcon } from "../../Icons/Previous.svg";
import { ReactComponent as NextIcon } from "../../Icons/Next.svg";
import { ReactComponent as ExclamatoryIcon } from "../../Icons/Exclamatory.svg";
import { ReactComponent as ContactBookToasterIcon } from "../../Icons/ContactBookToaster.svg";
import { contactListColumns, UsercontactListColumns } from "../../Data";
import { useNavigate } from "react-router-dom";
import {
  getContactDetails,
  deleteContactDetails,
  setContactBookSliceToNull,
  deleteMultipleContactDetails,
} from "../../Redux/Feature/ContactBook/ContactBookSlice";
import { useDispatch, useSelector } from "react-redux";
import AlertModal, { PostLoaderModal } from "../../CommonComponents/modal";
import axiosInstance from "../../Redux/AxiosInstance";
import moment from "moment";
import { Toaster } from "../../CommonComponents/modal";
import { getChip } from "../../Utils";
import { showToast } from "../../CommonComponents/Toaster";

export default function ContactBook() {
  const { LanguageData } = useSelector((state) => state.Language);
  const tabList = [
    LanguageData?.all_filter || "All",
    LanguageData?.Contact_Property_Filter || "Property",
    LanguageData?.Contact_Property_Emergency || "Emergency",
    LanguageData?.Contact_Property_Other || "Other",
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("All");
  const [paginationData, setPaginationData] = useState({});
  const [initialRender, setInitialRender] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [currentPage, setCurrentPage] = useState(1);
  const [TabId, setTabId] = useState("");
  const [contactList, setContactList] = useState([]);
  const {
    deleteContactDetailsStatus,
    editContactDetailsStatus,
    addContactDetailsStatus,
  } = useSelector((state) => state.ContactBook);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { UserManage } = useSelector((state) => state.Login);

  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [showMultipleDeleteModal, setshowMultipleDeleteModal] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [SelectedDatas, setSelectedDatas] = useState([]);
  const [MultiDelId, setMultiDelId] = useState([]);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const sortOpen = Boolean(sortAnchorEl);
  const [sortData, setSortData] = useState(null);
  // const [allCheckedBox, setAllCheckedBox] = useState({})
  const [maincheck, setMainCheck] = useState(false);
  const [toaster, settoaster] = useState({ message: "", delStatus: false });
  const [showAnchorEl, setshowAnchorEl] = useState(null);
  const showOpen = Boolean(showAnchorEl);
  const [Tableloading, setTableloading] = useState(true);
  const [postLoader, SetPostLoader] = useState(false);

  // const [Tableloader, setTableLoader] = useState(true)
  // useEffect(() => {
  //   for (const key in initialState) {
  //     if (initialState[key] != null) {
  //       if (initialState[key].success == true) {
  //         if (key == "deleteContactDetailsStatus") {
  //           setshowDeleteModal(false);
  //           setshowMultipleDeleteModal(false)
  //           setSelectedDatas([])
  //           getContactList();
  //         }
  //         settoaster({
  //           message: initialState[key].message,
  //           delStatus: key == "deleteContactDetailsStatus" ? true : false,
  //         });
  //         dispatch(setContactBookSliceToNull());
  //       }
  //     }
  //   }
  // }, [initialState]);

  useEffect(() => {
    if (addContactDetailsStatus != null) {
      if (addContactDetailsStatus.success == true) {
        // showToast(addContactDetailsStatus.message)
        // settoaster({ message: addContactDetailsStatus.message })
        dispatch(setContactBookSliceToNull());
      }
    } else if (editContactDetailsStatus != null) {
      if (editContactDetailsStatus.success == true) {
        dispatch(setContactBookSliceToNull());
        // showToast(editContactDetailsStatus.message)
        // settoaster({ message: editContactDetailsStatus.message })
      }
    } else if (deleteContactDetailsStatus != null) {
      if (deleteContactDetailsStatus.success == true) {
        setshowDeleteModal(false);
        setshowMultipleDeleteModal(false);
        setSelectedDatas([]);
        getContactList();
        showToast(deleteContactDetailsStatus.toast, "delete");
        // settoaster({ message: deleteContactDetailsStatus.message })
        dispatch(setContactBookSliceToNull());
        SetPostLoader(false);
      }
    }
  }, [
    addContactDetailsStatus,
    editContactDetailsStatus,
    deleteContactDetailsStatus,
  ]);

  const getContactList = () => {
    setTableloading(true);
    setContactList([]);
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/contactbook?page=${currentPage}&sort=${sortData}&category_id=${TabId}&record_count=${rowsPerPage}`
      )
      .then((data) => {
        setContactList(data.data.data.contact_book);
        setPaginationData(data.data.data.pagination_details);
        dispatch(setContactBookSliceToNull());
        setTableloading(false);
        // setTableLoader(false)
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.error == "No common area found") {
          setContactList([]);
        }
        setTableloading(false);
      });
  };
  useEffect(() => {
    getContactList();
    // setTableLoader(true)
  }, [currentPage]);

  useEffect(() => {
    if (!initialRender) {
      if (currentPage !== 1) {
        setCurrentPage(1);
      } else {
        // setTableLoader(true)
        getContactList();
      }
    } else {
      setInitialRender(false);
    }
  }, [sortData, TabId, rowsPerPage]);

  const handleNavigate = (id) => {
    navigate(`reviewContact/${id}`);
  };
  const deleteContactDetail = () => {
    dispatch(deleteContactDetails({ propertyId, id }));
    SetPostLoader(true);
  };

  const handleShowDeleteModal = () => {
    setshowDeleteModal(!showDeleteModal);
  };

  let deleteModalDatas = {
    label: LanguageData?.Delete_Contact || "Delete Contact book",
    descriptionOne: LanguageData?.Delete_description
      ? `${name} ${LanguageData?.Delete_description} `
      : `${name} will be removed from contact book.`,
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: LanguageData?.Announcement_Cancel || "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#B42318",
      text: LanguageData?.Announcement_DeleteNow || "Delete Now",
    },
  };

  //multipledeletemodal
  const handleMultipleCheck = (e, datas) => {
    const { checked } = e.target;

    const find = SelectedDatas.find((itm) => itm?.id === datas?.id);
    if (Boolean(find)) {
      const data = SelectedDatas.filter((itm) => itm?.id != datas?.id);
      setSelectedDatas(data);
    } else if (!Boolean(find)) {
      setSelectedDatas((prev) => {
        return [...prev, datas];
      });
    }
  };

  const deleteMultipleContactDetail = async () => {
    const arr = [];
    const data = SelectedDatas.map((itm) => arr.push(itm?.id));
    const ids = { contact_book_ids: arr };
    if (ids?.contact_book_ids?.length >= 1) {
      dispatch(deleteMultipleContactDetails({ propertyId, ids }));
      SetPostLoader(true);
      // setshowDeleteModal(false);
    }
    // try {
    //   const response = await axiosInstance.delete(`${process.env.REACT_APP_API_URL}property/${propertyId}/contactbook/multiple_delete`,
    //     {
    //       data: {
    //         contact_book_ids: ["51"],
    //       },
    //     })
    //   console.log(response);
    // } catch (error) {
    //   console.log(error);
    // }
    // }
  };

  const handleShowMultipleDeleteModal = () => {
    setshowMultipleDeleteModal(!showMultipleDeleteModal);
  };

  useEffect(() => {
    if (maincheck) {
      contactList.map((iitm) => {
        const find = SelectedDatas.find((itm) => itm?.id === iitm?.id);
        if (Boolean(find)) {
          const data = SelectedDatas.filter((itm) => itm?.id != iitm?.id);
          setSelectedDatas(data);
        } else if (!Boolean(find)) {
          setSelectedDatas((prev) => {
            return [...prev, iitm];
          });
        }
      });
    }
  }, [maincheck]);

  useEffect(() => {
    if (SelectedDatas.length == 0) {
      setMainCheck(false);
    }
  }, [SelectedDatas]);

  const handleMainCheck = (e) => {
    const { checked } = e.target;
    setMainCheck(checked);
  };

  console.log(LanguageData?.Contact_Book);

  return (
    <>
      {/* <Dialog open={true}>
        <Box sx={{ minWidth: '400px', minHeight: '200px', p: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <PrimaryTypography sx={{ fontSize: '20px !important' }}>Check your connection and Try again</PrimaryTypography>
          <Button onClick={() => { window.location.reload() }} sx={{ marginTop: 3 }} variant="contained">Retry</Button>
        </Box>
      </Dialog> */}
      {postLoader && <PostLoaderModal />}
      {showDeleteModal && (
        <AlertModal
          modalDatas={deleteModalDatas}
          confirmFunction={deleteContactDetail}
          name={name}
          closeFunction={handleShowDeleteModal}
        />
      )}

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <PrimaryTypography>
            {" "}
            {LanguageData?.Contact_Book || "Contact Book"}
          </PrimaryTypography>
          <StyledSecondaryTypography>
            {LanguageData?.Contact_subHeader ||
              " Manage and view all contact informations listed on the LOBY system."}
          </StyledSecondaryTypography>
        </Box>
        {UserManage?.["contact-book"] && (
          <Button
            onClick={() => navigate("createContact")}
            sx={{
              padding: "10px 16px",
              borderRadius: "8px",
              backgroundColor: "#0E5E84",
              color: "#FFF",
              fontSize: "14px",
              fontWeight: 600,
              "&:hover": {
                backgroundColor: "#0E4965",
              }
            }}
          >
            {LanguageData?.Announcement_create || "Create"}
          </Button>
        )}
      </Box>

      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #E5E7EB",
          p: 3,
          mt: 4,
          borderRadius: " 8px 8px 0px 0px",
          display: "flex",
          justifyContent: "space-between",
          overflowX: "scroll",
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        {SelectedDatas.length >= 1 ? (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              height="40px"
            >
              <Box display="flex" alignItems="center">
                <SecondaryTypography mr={2}>
                  {SelectedDatas.length} {LanguageData?.Selected || "Selected"}
                </SecondaryTypography>
                <StyledSecondaryTypography
                  onClick={() => {
                    setSelectedDatas([]);
                    setMainCheck(false);
                  }}
                  sx={{ borderBottomWidth: "2px", borderBottomStyle: "solid" }}
                >
                  {LanguageData?.Unselect_All || "Unselect All"}
                </StyledSecondaryTypography>
              </Box>
              <Box
                onClick={() => setshowMultipleDeleteModal(true)}
                sx={{
                  display: "flex",
                  height: "40px",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #E5E7EB",
                  borderRadius: "6px",
                  px: 2,
                  ml: 2,
                }}
              >
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.Delete_Selected || "Delete Selected"}
                </SecondaryTypography>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                border: "1px solid #D2D6DB",
                display: "flex",
                borderRadius: "10px",
              }}
            >
              {tabList.map((value, index) => (
                <Box
                  onClick={() => {
                    setSelectedTab(value);
                    setTabId(index == 0 ? "" : index);
                  }}
                  key={index}
                  sx={{
                    borderRadius:
                      index === 3
                        ? "0px 8px 8px 0px"
                        : index === 0
                        ? "8px 0px 0px 8px"
                        : "initial",
                    borderLeft: index !== 0 ? "1px solid #E5E7EB" : "initial",
                    backgroundColor:
                      value == selectedTab ? "#F3F4F6" : "initial",
                    padding: "10px 16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  <Typography
                    sx={{
                      color: value === selectedTab ? "#111927" : "#4D5761",
                      fontSize: "14px",
                      fontWeight: 550,
                    }}
                    textAlign={"center"}
                  >
                    {value}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box sx={{ display: "flex", height: "40px" }}>
              {/* <StyledOutlinedInput placeholder='Search' startAdornment={<SearchIcon style={{ paddingRight: '5px' }} />} /> */}
              <Box
                onClick={(e) => setSortAnchorEl(e.currentTarget)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #D2D6DB",
                  borderRadius: "6px",
                  px: 2,
                  ml: 2,
                  "&:hover": { cursor: "pointer", backgroundColor: "#F9FAFB" },
                }}
              >
                <SortIcon style={{ marginRight: "10px" }} />
                <SecondaryTypography>
                  {LanguageData?.sort_filter || "Sort"}
                </SecondaryTypography>
              </Box>
            </Box>
          </>
        )}
      </Box>

      <Box sx={{ border: "1px solid #E5E7EB" , borderRadius:"0px 0px 8px 8px" }}>
        {/* {Tableloader ? <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "center", minHeight: '300px' }}> < CircularProgress sx={{ color: "#0E5E84" }} /> </Box> : */}
        {contactList.length > 0 ? (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <StyledTableRow>
                    {(UserManage?.["contact-book"]
                      ? contactListColumns
                      : UsercontactListColumns
                    )?.map((value, index) => {
                      return (
                        <StyledTableCell key={index}>
                          {value === "Checkbox" ? (
                            SelectedDatas.length >= 1 ? (
                              <Checkbox
                                sx={{ height: "40px", minWidth: "43px" }}
                                onClick={() => {
                                  setSelectedDatas([]);
                                  setMainCheck(false);
                                }}
                                size="small"
                                icon={<CheckBoxMinusIcon />}
                              />
                            ) : (
                              <Checkbox
                                sx={{ height: "40px", minWidth: "43px" }}
                                onChange={(e) => {
                                  handleMainCheck(e);
                                }}
                                checked={maincheck}
                                size="small"
                                icon={<CheckboxIcon />}
                              />
                            )
                          ) : value === "Delete" ? (
                            ""
                          ) : (
                            value
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {contactList?.map((value, index) => (
                    <StyledTableRow height="72px" key={index}>
                      {UserManage?.["contact-book"] && (
                        <StyledTableCell>
                          {" "}
                          <Checkbox
                            sx={{ height: "40px", minWidth: "43px" }}
                            onChange={(e) => {
                              handleMultipleCheck(e, value);
                            }}
                            checked={Boolean(
                              SelectedDatas.find((itm) => itm?.id == value?.id)
                            )}
                            size="small"
                            icon={<CheckboxIcon />}
                          />
                        </StyledTableCell>
                      )}
                      <StyledTableCell onClick={() => handleNavigate(value.id)}>
                        {value.title}
                      </StyledTableCell>
                      {/* <StyledTableCell onClick={()=>handleNavigate(value.id)}>{getChip(value.status[0]?.key)}</StyledTableCell> */}
                      <StyledTableCell onClick={() => handleNavigate(value.id)}>
                        {value?.category[0]?.name}
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleNavigate(value.id)}>
                        {value?.users[0]?.name}
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleNavigate(value.id)}>
                        {moment
                          .utc(value.updated_at)
                          .local()
                          .format("DD MMM YYYY, HH:mm")}
                      </StyledTableCell>
                      {UserManage?.["contact-book"] &&
                        (SelectedDatas?.length >= 1 ? (
                          <StyledTableCell>
                            {" "}
                            <DeleteDisable width={20} />
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell
                            onClick={() => {
                              handleShowDeleteModal();
                              setId(value.id);
                              setName(value.title);
                            }}
                          >
                            <DeleteIcon />
                          </StyledTableCell>
                        ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderTopStyle: "none !important",
                // border: "1px solid #E5E7EB",
                borderRadius: "0px 0px 8px 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2.5,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ color: "#4D5761", fontSize: "14px", fontWeight: 500 }}
                >
                  {LanguageData?.Common_Show || "Show :"}
                </Typography>
                {/* <OutlinedInput
                  inputProps={{ min: 5, max: 30, step: 5 }}
                  value={rowsPerPage}
                  onChange={(e) => setRowsPerPage(e.target.value)}
                  type="number"
                  sx={{
                    height: "40px",
                    width: "100px",
                    ml: 2,
                    borderRadius: "8px",
                  }}
                /> */}
                <Box
                  onClick={(e) => setshowAnchorEl(e.currentTarget)}
                  sx={{
                    width: "95px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #E5E7EB",
                    borderRadius: "6px",
                    ml: 2,
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "15px" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {rowsPerPage} rows{" "}
                    <NumberInputIcon style={{ paddingLeft: "5px" }} />
                  </Typography>
                </Box>
              </Box>
              <Pagination
                size="medium"
                page={currentPage}
                count={paginationData?.last_page}
                onChange={(e, value) => setCurrentPage(value)}
                shape="rounded"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: PreviousIcon, next: NextIcon }}
                    {...item}
                  />
                )}
              />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "50vh",
              justifyContent: "center",
            }}
          >
            {Tableloading ? (
              <CircularProgress />
            ) : (
              <>
                <ExclamatoryIcon />
                <Typography
                  sx={{
                    color: "#9DA4AE",
                    fontSize: "16px",
                    fontWeight: 600,
                    my: 2,
                  }}
                >
                  {LanguageData?.Contact_NoData || "No Contacts Here"}
                </Typography>
                <StyledSecondaryTypography>
                  {LanguageData?.Contact_SubHead_NoData ||
                    "There are no active contacts to display at the moment."}{" "}
                </StyledSecondaryTypography>
                <StyledSecondaryTypography>
                  {LanguageData?.Contact_subhead_noData ||
                    "You may create contact by clicking the top right Create button."}
                </StyledSecondaryTypography>
              </>
            )}
          </Box>
        )}
      </Box>

      <Modal open={showMultipleDeleteModal}>
        <Box
          sx={{
            position: "absolute",
            border: "none",
            width: "100%",
            height: "90%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: "40%",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: "2px solid #F3F4F6",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PrimaryTypography>
                {LanguageData?.Contact_Single_Delete ||
                  "Confirm to delete contact permanently"}
              </PrimaryTypography>
              <Close onClick={() => handleShowMultipleDeleteModal()} />
            </Box>
            <Box sx={{ px: 2, py: 3, borderBottom: "2px solid #F3F4F6" }}>
              <StyledSecondaryDefaultTypography mb={1}>
                {LanguageData?.Contact_Single_DeleteSub ||
                  "The selected contacts will be removed from contact book."}
              </StyledSecondaryDefaultTypography>
              {SelectedDatas.map((itm, index) => {
                return (
                  <StyledSecondaryDefaultTypography
                    sx={{ fontSize: "16px", fontWeight: 400, color: "#1F2A37" }}
                  >
                    {index + 1 + ". " + itm?.title}
                  </StyledSecondaryDefaultTypography>
                );
              })}
            </Box>
            <Box
              sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={() => handleShowMultipleDeleteModal()}
                sx={{ background: "#FFFFFF", color: "#0E5E84" }}
                variant="outlined"
              >
                {LanguageData?.Announcement_Cancel || "Cancel"}
              </Button>
              <Button
                onClick={() => {
                  deleteMultipleContactDetail();
                }}
                sx={{
                  background: "#B42318",
                  color: "#FFFFFF",
                  "&:hover": { background: "#B42318" },
                }}
                variant="contained"
              >
                {LanguageData?.Announcement_DeleteNow || "Delete now"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* <Toaster Toaster={toaster} setToaster={settoaster} /> */}

      <Menu
        sx={{ m: 0.5 }}
        open={sortOpen}
        anchorEl={sortAnchorEl}
        onClose={() => setSortAnchorEl(null)}
      >
        <MenuItem
          sx={{ backgroundColor: sortData == "recent" ? "#eff3f5" : "" }}
          onClick={() => {
            setSortData("recent");
            setSortAnchorEl(null);
          }}
        >
          <Typography> {LanguageData?.Most_filter || "Most Recent"}</Typography>
        </MenuItem>
        <MenuItem
          sx={{ backgroundColor: sortData == "old" ? "#eff3f5" : "" }}
          onClick={() => {
            setSortData("old");
            setSortAnchorEl(null);
          }}
        >
          <Typography> {LanguageData?.Oldest_Filter || "Oldest"}</Typography>
        </MenuItem>
      </Menu>

      <Menu
        sx={{ m: 0.5 }}
        open={showOpen}
        anchorEl={showAnchorEl}
        onClose={() => setshowAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            setRowsPerPage(10);
            setshowAnchorEl(null);
          }}
        >
          <Typography>10 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(20);
            setshowAnchorEl(null);
          }}
        >
          <Typography>20 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(30);
            setshowAnchorEl(null);
          }}
        >
          <Typography>30 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(40);
            setshowAnchorEl(null);
          }}
        >
          <Typography>40 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(50);
            setshowAnchorEl(null);
          }}
        >
          <Typography>50 rows</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
