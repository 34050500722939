import {
  Alert,
  Box,
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Modal,
  Grid,
  OutlinedInput,
  Pagination,
  PaginationItem,
  Select,
  Snackbar,
  SnackbarContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  CircularProgress,
} from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import {
  DatePickerStyle,
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledDefaultTypography,
  StyledOutlinedInput,
  StyledSwitch,
  StyledSecondaryDefaultTypography,
  StyledSecondaryTypography,
  StyledTableCell,
  StyledTableRow,
  StyledTypography,
  TypographyDefault,
} from "../../../Theme";
import { ReactComponent as SearchIcon } from "../../../Icons/Search.svg";
import { ReactComponent as FilterIcon } from "../../../Icons/Filter.svg";
import { ReactComponent as SortIcon } from "../../../Icons/Sort.svg";
import { ReactComponent as CheckboxIcon } from "../../../Icons/Checkbox.svg";
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteTable.svg";
import { ReactComponent as PreviousIcon } from "../../../Icons/Previous.svg";
import { ReactComponent as NextIcon } from "../../../Icons/Next.svg";
import { ReactComponent as ExclamatoryIcon } from "../../../Icons/Exclamatory.svg";
import { ReactComponent as MoreIcon } from "../../../Icons/ThreeDot.svg";
import { ReactComponent as NumberInputIcon } from "../../../Icons/NumberInputIcon.svg";
import { ReactComponent as Close } from "../../../Icons/BorderAlertClose.svg";
import { ReactComponent as SnackbarClose } from "../../../Icons/AlertClose.svg";
import { ReactComponent as SnackbarSuccess } from "../../../Icons/SnackbarSuccess.svg";
import { ReactComponent as DatePickerIcon } from "../../../Icons/Datepicker.svg";
import { ReactComponent as DownloadIcon } from "../../../Icons/DownloadIcon.svg";
import { ReactComponent as CheckBoxMinusIcon } from "../../../Icons/CheckBoxMinus.svg";
import { ReactComponent as CsvIcon } from "../../../Icons/CsvIcon.svg";
import { ReactComponent as PdfIcon } from "../../../Icons/PdfIcon.svg";
import {
  StaffActiveTabColumn,
  StaffAllTabColumn,
  StaffInactiveTabColumn,
  StaffOffboardTabColumn,
  UserStaffAllTabColumn,
} from "../../../Data";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../Redux/AxiosInstance";
import moment from "moment";
import { getChip } from "../../../Utils";
import TypesInformationModal from "../../../CommonComponents/TypeInformationModal";
import { DatePicker } from "@mui/x-date-pickers";
import AlertModal, { PostLoaderModal } from "../../../CommonComponents/modal";

import { ReactComponent as DisabledDownloadIcon } from "../../../Icons/DisableDownload.svg";
// import DateRangePicker from '@wojtekmaj/react-daterange-picker';
// import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
// import 'react-calendar/dist/Calendar.css';

import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { makeStyles } from "@mui/styles";
const CustomDateRangePicker = React.lazy(() =>
  import("../../../CommonComponents/CustomDateRange")
);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white ",
    "& .MuiDateRangeCalendar-root ": { color: "red !important" },
    // "& .css-968uz8-MuiStack-root-MuiMultiInputDateRangeField-root": {
    //   border: "2px solid red"
    // },
    "&.MuiDateRangeCalendar-root > div ": {
      height: "470px !important",
    },
    "&.MuiDateRangeCalendar-root > div:nth-child(1)": {
      color: "white !important",
      zIndex: "-100000 !important",
    },
    // "& .css-jef1b6-MuiDateRangeCalendar-container": { color: "red !important" },
  },
}));
const ModalData = [
  {
    title: "Management",
    description:
      "Management staff are the highest authority within the staff departments, they will have both dashboard (website) and mobile app access Management department might include senior employees and managers and team leads who needs to add facilities, download monthly, yearly reports and people who do not have to handle day to day matters.",
  },
  {
    title: "Operations",
    description:
      "Operations staff are the middle authority within the staff departments, they will have both dashboard (website) and mobile app access. Operations staff might include juristics, administrators who are handling and replying to tenants on a daily basis, and assigning ground staffs to cases.",
  },
  {
    title: "Ground Staff",
    description:
      "Ground staffs are the most junior within the staff departments, with only mobile app access. Ground staff will include staff who needs to be assigned to cases in order to rectify and resolve the problems, these will include technicians, security personnel and cleaners.",
  },
];

// let cancelOffboardData = {
//     label: 'Confirm Cancel Offboard ',
//     descriptionOne: 'Please confirm that you wish to cancel the offboard request.',
//     buttonOne: {
//         variant: 'outlined',
//         color: '#0E5E84',
//         backgroundColor: '#FFFFFF',
//         text: 'Cancel'
//     },
//     buttonTwo: {
//         variant: 'contained',
//         color: '#FFFFFF',
//         backgroundColor: '#0E5E84',
//         text: 'Confirm'
//     },
// }

// let cancelScheduledInactiveData = {
//     label: 'Confirm Cancel Scheduled Inactive ',
//     descriptionOne: 'Please confirm that you wish to cancel the Scheduled Inactive.',
//     buttonOne: {
//         variant: 'outlined',
//         color: '#0E5E84',
//         backgroundColor: '#FFFFFF',
//         text: 'Cancel'
//     },
//     buttonTwo: {
//         variant: 'contained',
//         color: '#FFFFFF',
//         backgroundColor: '#0E5E84',
//         text: 'Confirm'
//     },
// }

// let offboardModalData = {
//     label: 'Offboard User Now',
//     descriptionOne: 'Please confirm that you wish to offboard user now. After offboarded, the information cannot be recovered.',
//     buttonOne: {
//         variant: 'outlined',
//         color: '#0E5E84',
//         backgroundColor: '#FFFFFF',
//         text: 'Cancel'
//     },
//     buttonTwo: {
//         variant: 'contained',
//         color: '#FFFFFF',
//         backgroundColor: '#B42318',
//         text: 'Confirm Offboard'
//     },
// }

// let InactiveData = {
//     label: 'Confirm Inactive ',
//     descriptionOne: 'Please confirm that you wish to Inactive the staff',
//     buttonOne: {
//         variant: 'outlined',
//         color: '#0E5E84',
//         backgroundColor: '#FFFFFF',
//         text: 'Cancel'
//     },
//     buttonTwo: {
//         variant: 'contained',
//         color: '#FFFFFF',
//         backgroundColor: '#0E5E84',
//         text: 'Confirm'
//     },
// }
// let activeModalData = {
//     label: 'Confirm active ',
//     descriptionOne: 'Please confirm that you wish to active the staff',
//     buttonOne: {
//         variant: 'outlined',
//         color: '#0E5E84',
//         backgroundColor: '#FFFFFF',
//         text: 'Cancel'
//     },
//     buttonTwo: {
//         variant: 'contained',
//         color: '#FFFFFF',
//         backgroundColor: '#0E5E84',
//         text: 'Confirm'
//     },
// }

export default function Staff() {
  const { LanguageData } = useSelector((state) => state.Language);
  const { Lang_Management } = LanguageData;

  const [initialRender, setInitialRender] = useState(true);
  const initialState = { offboard: false, inActive: false, active: false };
  const tabList = ["All", "Active", "Inactive", "Offboarding"];
  const [TabId, setTabId] = useState("");
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("All");
  const [staffList, setStaffList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [showModal, setShowModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [selectedMultipleData, setselectedMultipleData] = useState([]);
  const [changeStatusMultiple, setChangeStatusMultiple] = useState(false);
  const [mainCheck, setMainCheck] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [modalCheckboxStatus, setModalCheckboxStatus] = useState({
    offboard: false,
    inActive: false,
    active: false,
    changeInactive: false,
  });
  const [offboardDate, setOffboardDate] = useState(null);
  // const [datetimerangedata, setdatetimerangedata] = useState([])
  const [modalError, setModalError] = useState({
    offboard: "",
    inActive: "",
    active: "",
    selected: "",
  });
  const [showAlert, setShowAlert] = useState({
    cancel: false,
    inactive: false,
    offboard: false,
    active: false,
    cancel_Scheduled_inactive: false,
    changeInactive: false,
  });
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const sortOpen = Boolean(sortAnchorEl);
  const [sortData, setSortData] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const filterOpen = Boolean(filterAnchorEl);
  const [filterData, setFilterData] = useState([]);
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);
  const MoreOpen = Boolean(moreAnchorEl);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const downloadOpen = Boolean(downloadAnchorEl);
  const [showAnchorEl, setshowAnchorEl] = useState(null);
  const showOpen = Boolean(showAnchorEl);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { UserManage } = useSelector((state) => state.Login);
  const [openDateRangePicker, setopenDateRangePicker] = useState(false);
  const [datetimerangedata, setdatetimerangedata] = useState([]);
  console.log(datetimerangedata);
  const [isStatus, setIsStatus] = useState(true);
  const [DepartmentData, setDepartmentData] = useState([]);
  const [SearchData, setSearchData] = useState("");
  const [dateRangeSubmit, setdateRangeSubmit] = useState(false);
  const [Tableloading, setTableloading] = useState(true);

  //changeInactiveDate
  const [openChangeDateRangePicker, setopenChangeDateRangePicker] =
    useState(false);
  const [Changedatetimerangedata, setChangedatetimerangedata] = useState([
    null,
    null,
  ]);
  const [postLoader, SetPostLoader] = useState(false);

  const getstafflist = async () => {
    setTableloading(true);
    setStaffList([]);
    const filterId = filterData.join(",");

    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/staff_onboard?page=${currentPage}&record_count=${rowsPerPage}&status_id=${TabId}&sort=${sortData}&department_id=${filterId}&search=${SearchData}`
      );
      setStaffList(response.data.data.staff_onboard);
      setPaginationData(response.data.data.pagination_details);
      setTableloading(false);
      // setRowsPerPage(response?.data?.data?.pagination_details?.per_page)
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.error == "No data found") {
        setStaffList([]);
      }
      setTableloading(false);
    }
  };

  useEffect(() => {
    getstafflist();
  }, [currentPage]);

  useEffect(() => {
    if (!initialRender) {
      if (currentPage != 1) {
        setCurrentPage(1);
      } else {
        getstafflist();
      }
    } else {
      setInitialRender(false);
    }
  }, [TabId, sortData, filterData, rowsPerPage, SearchData]);

  const handleSearch = async (data) => {
    setSearchData(data);

    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/staff_onboard?search=${data}`
      );
      setStaffList(response.data.data.staff_onboard);
      setPaginationData(response.data.data.pagination_details);
      setRowsPerPage(response?.data?.data?.pagination_details?.per_page);
    } catch (error) {
      console.log(error);
    }
  };

  const getDepartmentData = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}getDropDownDatas?type=department`
      );
      setDepartmentData(response?.data?.data?.drop_down);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDepartmentData();
  }, []);

  const handleSelectedFilter = (e, id) => {
    // setCurrentPage('1')
    const Data = filterData?.find((itm) => itm == id);
    if (Data) {
      const Data = filterData?.filter((itm) => itm !== id);
      setFilterData(Data);
    } else {
      setFilterData((itm) => [...itm, id]);
    }
  };

  const DownloadReport = async (type) => {
    const filterId = filterData.join(",");
    try {
      setLoading(true);
      setDownloadAnchorEl(null);
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/staff_onboard/download?download_type=${type}&page=${currentPage}&record_count=${rowsPerPage}&status_id=${TabId}&sort=${sortData}&department_id=${filterId}&search=${SearchData}`,
        { responseType: "blob" }
      );
      saveAs(response.data, `Staffs_report.${type}`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getColumnType = () => {
    switch (selectedTab) {
      case "All":
        return StaffAllTabColumn;
      case "Active":
        return StaffActiveTabColumn;
      case "Inactive":
        return StaffInactiveTabColumn;
      case "Offboarding":
        return StaffOffboardTabColumn;
      default:
        return [];
    }
  };

  const handleMoreOptions = (e, value) => {
    setMoreAnchorEl(e.currentTarget);
    setSelectedData(value);
  };

  const handleModalCheckboxStatus = (key) => {
    if (key !== "inActive") {
      setdatetimerangedata([]);
      setOffboardDate(null);
    }
    setModalCheckboxStatus({
      ...initialState,
      [key]: !modalCheckboxStatus[key],
    });
  };

  const closeModal = () => {
    setIsStatus(true);
    setChangeStatusModal(false);
    setSelectedData({});
    setdatetimerangedata([]);
    setOffboardDate(null);
    setModalCheckboxStatus({
      offboard: false,
      inActive: false,
      active: false,
      changeInactive: false,
    });
    SetPostLoader(false);
  };

  const closeAlert = () => {
    setShowAlert({
      cancel: false,
      inactive: false,
      offboard: false,
      active: false,
      changeInactive: false,
      cancel_Scheduled_inactive: false,
    });
  };

  const validate = () => {
    let hasError = [];

    if (
      modalCheckboxStatus.inActive == false &&
      modalCheckboxStatus.offboard == false &&
      modalCheckboxStatus.active == false &&
      modalCheckboxStatus.changeInactive == false
    ) {
      setModalError({
        ...modalError,
        selected: "Please select one option",
      });
      hasError.push(true);
      return hasError.includes(true);
    } else {
      setModalError({
        ...modalError,
        selected: "",
      });
    }

    if (modalCheckboxStatus.offboard == true && offboardDate === null) {
      setModalError({
        ...modalError,
        offboard: "Please select offboard date",
      });
      hasError.push(true);
      return hasError.includes(true);
    } else {
      setModalError({
        ...modalError,
        offboard: "",
      });
    }

    if (
      (modalCheckboxStatus.inActive == true &&
        (datetimerangedata[0] == null || datetimerangedata[1] == null)) ||
      (modalCheckboxStatus.changeInactive == true &&
        Changedatetimerangedata.length < 2)
    ) {
      setModalError({
        ...modalError,
        inActive: "Please select date range",
      });
      hasError.push(true);
      return hasError.includes(true);
    } else {
      setModalError({
        ...modalError,
        inActive: "",
      });
    }
  };

  useEffect(() => {
    if (!validate()) {
      setIsStatus(false);
    } else {
      setIsStatus(true);
    }
  }, [
    datetimerangedata,
    offboardDate,
    selectedData,
    modalCheckboxStatus,
    Changedatetimerangedata,
  ]);

  const handleModalConfirm = () => {
    let hasError = validate();

    if (!hasError) {
      if (modalCheckboxStatus.inActive) {
        setShowAlert({
          ...showAlert,
          inactive: true,
        });
      } else if (modalCheckboxStatus.offboard) {
        setShowAlert({
          ...showAlert,
          offboard: true,
        });
      } else if (modalCheckboxStatus.active) {
        setShowAlert({
          ...showAlert,
          active: true,
        });
      } else if (modalCheckboxStatus.changeInactive) {
        setShowAlert({
          ...showAlert,
          changeInactive: true,
        });
      }

      // closeModal()
    }
  };

  const offboardStaff = () => {
    const AccessEnd = offboardDate;
    const finalEnd = moment(AccessEnd).hour(0).minute(0).second(0);
    const final1End = moment.utc(finalEnd).format("YYYY-MM-DD HH:mm:ss");
    SetPostLoader(true);
    const data = {
      user_ids: changeStatusMultiple
        ? selectedMultipleData
        : [selectedData.user_id],
      status: "offboard",
      access_end: final1End,
    };
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/property_user_status_change`,
        data
      )
      .then((data) => {
        getstafflist();
        closeAlert();
        closeModal();
        undo();
        setCurrentPage(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const inactiveStaff = () => {
    const checkstartDate =
      moment(datetimerangedata[0].$d).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const startFormatToday = moment(new Date(), "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const checkendDate =
      moment(datetimerangedata[1].$d).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const endFormatToday = moment(new Date(), "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const startFormat = moment(datetimerangedata[0].$d)
      .hour(0)
      .minute(0)
      .second(0);
    const finalStart = moment.utc(startFormat).format("YYYY-MM-DD HH:mm:ss");
    const endFormat = moment(datetimerangedata[1].$d)
      .hour(0)
      .minute(0)
      .second(0);
    const finalEnd = moment.utc(endFormat).format("YYYY-MM-DD HH:mm:ss");
    SetPostLoader(true);
    const data = {
      user_ids: changeStatusMultiple
        ? selectedMultipleData
        : [selectedData.user_id],
      status: "inactive",
      start_date: checkstartDate ? startFormatToday : finalStart,
      end_date: checkendDate ? endFormatToday : finalEnd,
    };
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/property_user_status_change`,
        data
      )
      .then((data) => {
        getstafflist();
        closeAlert();
        closeModal();
        undo();
        setCurrentPage(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const activeStaff = () => {
    SetPostLoader(true);
    const data = {
      user_ids: changeStatusMultiple
        ? selectedMultipleData
        : [selectedData.user_id],
      status: "active",
    };
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/property_user_status_change`,
        data
      )
      .then((data) => {
        getstafflist();
        closeAlert();
        closeModal();
        undo();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ChangeinactiveStaffdate = () => {
    const checkstartDate =
      moment(Changedatetimerangedata[0].$d).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const startFormatToday = moment(
      Changedatetimerangedata[0].$d,
      "YYYY-MM-DD HH:mm:ss"
    )
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const checkendDate =
      moment(Changedatetimerangedata[1].$d).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const endFormatToday = moment(
      Changedatetimerangedata[1].$d,
      "YYYY-MM-DD HH:mm:ss"
    )
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const startFormat = moment(Changedatetimerangedata[0].$d)
      .hour(0)
      .minute(0)
      .second(0);
    const finalStart = moment.utc(startFormat).format("YYYY-MM-DD HH:mm:ss");
    const endFormat = moment(Changedatetimerangedata[1].$d)
      .hour(0)
      .minute(0)
      .second(0);
    const finalEnd = moment.utc(endFormat).format("YYYY-MM-DD HH:mm:ss");
    SetPostLoader(true);
    const data = {
      user_ids: [selectedData.user_id],
      status: "inactive",
      start_date: checkstartDate ? startFormatToday : finalStart,
      end_date: checkendDate ? endFormatToday : finalEnd,
    };
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/property_user_status_change`,
        data
      )
      .then((data) => {
        getstafflist();
        closeAlert();
        closeModal();
        undo();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (valid()) {
      if (selectedMultipleData?.length > 1) {
        setChangeStatusMultiple(true);
        const data = staffList.find(
          (itm) => itm?.user_id == selectedMultipleData[0]
        );
        setSelectedData(data);
      } else {
        setChangeStatusMultiple(false);
      }
    } else {
      setChangeStatusMultiple(false);
    }
  }, [selectedMultipleData]);

  const valid = () => {
    let finded_date;
    var isValid = true;
    const id = selectedMultipleData[0];
    if (Boolean(id)) {
      let data = staffList.find((itm) => itm?.user_id == id);
      if (id === data?.user_id) {
        if (data?.inactive == null) {
          finded_date = {
            key: data?.status?.key,
            access_end: data?.access_end,
            inactive: data?.inactive,
          };
        } else if (data?.inactive != null) {
          finded_date = {
            key: data?.status?.key,
            access_end: data?.access_end,
            start: data?.inactive?.start_date,
            end: data?.inactive?.end_date,
            inactive: data?.inactive,
          };
        }
      }
    }

    for (let i = 1; i < selectedMultipleData.length; i++) {
      for (let j = 0; j < staffList.length; j++) {
        if (selectedMultipleData[i] == staffList[j]?.user_id) {
          if (finded_date?.inactive === null) {
            if (
              staffList[j]?.status?.key != finded_date.key ||
              staffList[j]?.access_end != finded_date.access_end ||
              finded_date?.inactive != staffList[j]?.inactive
            ) {
              isValid = false;
            }
          } else if (finded_date?.inactive != null) {
            if (
              staffList[j]?.status?.key != finded_date.key ||
              finded_date?.start != staffList[j]?.inactive?.start_date ||
              finded_date?.end != staffList[j]?.inactive?.end_date ||
              staffList[j]?.access_end != finded_date.access_end
            ) {
              isValid = false;
            }
          }
        }
      }
    }
    return isValid;
  };

  const handleMultipleCheck = (id) => {
    const find = selectedMultipleData.find((itm) => itm === id);
    if (Boolean(find)) {
      const data = selectedMultipleData.filter((itm) => itm != id);
      setselectedMultipleData(data);
    } else if (!Boolean(find)) {
      setselectedMultipleData((prev) => {
        return [...prev, id];
      });
    }
  };

  useEffect(() => {
    if (mainCheck) {
      setselectedMultipleData([]);
      staffList.map((itm) =>
        setselectedMultipleData((prev) => [...prev, itm?.user_id])
      );
    }
  }, [mainCheck]);

  useEffect(() => {
    if (selectedMultipleData.length <= 1) {
      setMainCheck(false);
    }
  }, [selectedMultipleData]);

  const handleMainCheck = (e) => {
    const { checked } = e.target;
    if (staffList?.length > 1) {
      setMainCheck(checked);
    }
  };

  const undo = () => {
    setMainCheck(false);
    setselectedMultipleData([]);
    setChangeStatusMultiple(false);
  };

  console.log(selectedTab);
  const renderData = (value, index) => {
    // switch (selectedTab) {
    //     case 'All':
    if (selectedTab == "Offboarding") {
      return (
        <StyledTableRow key={index}>
          <StyledTableCell
            onClick={() => navigate(`reviewStaff/${value.user_id}`)}
          >
            {value?.user_details?.personal_details?.first_name}
            <StyledSecondaryTypography>
              {" "}
              {value?.department_details?.department_name}{" "}
            </StyledSecondaryTypography>
          </StyledTableCell>
          {/* <StyledTableCell onClick={() => navigate(`reviewStaff/${value.user_id}`)} > {moment(value?.join_date).format("DD MMM YYYY")} </StyledTableCell> */}
          <StyledTableCell
            onClick={() => navigate(`reviewStaff/${value.user_id}`)}
          >
            {moment.utc(value?.access_start).local().format("DD MMM YYYY")} -{" "}
            {moment.utc(value?.access_end).local().format("DD MMM YYYY")}{" "}
          </StyledTableCell>
          <StyledTableCell
            onClick={() => navigate(`reviewStaff/${value.user_id}`)}
          >
            {" "}
            {moment.utc(value?.access_end).local().format("DD MMM YYYY")}
          </StyledTableCell>
        </StyledTableRow>
      );
    } else {
      return (
        <StyledTableRow key={index}>
          {UserManage?.staffs && (
            <StyledTableCell>
              <Box sx={{ maxWidth: "40px" }}>
                {" "}
                <Checkbox
                  checked={Boolean(
                    selectedMultipleData.find((itm) => itm == value?.user_id)
                  )}
                  onChange={() => {
                    handleMultipleCheck(value.user_id);
                  }}
                  size="small"
                  icon={<CheckboxIcon />}
                />
              </Box>
            </StyledTableCell>
          )}
          <StyledTableCell
            onClick={() => navigate(`reviewStaff/${value.user_id}`)}
          >
            {value?.user_details?.personal_details?.first_name}
            <StyledSecondaryTypography>
              {value?.department_details?.department_name}
            </StyledSecondaryTypography>
          </StyledTableCell>
          <StyledTableCell
            onClick={() => navigate(`reviewStaff/${value.user_id}`)}
          >
            {moment.utc(value?.access_start).local().format("DD MMM YYYY")}
          </StyledTableCell>
          {selectedTab == "All" && (
            <StyledTableCell
              onClick={() => navigate(`reviewStaff/${value.user_id}`)}
            >
              {" "}
              {getChip(value?.status?.key)}
            </StyledTableCell>
          )}

          {value?.status?.key === "inactive" && (
            <StyledTableCell
              onClick={() => navigate(`reviewStaff/${value.user_id}`)}
            >
              {moment
                .utc(value?.inactive?.start_date)
                .local()
                .format("DD MMM YYYY")}{" "}
              -{" "}
              {moment
                .utc(value?.inactive?.end_date)
                .local()
                .format("DD MMM YYYY")}{" "}
            </StyledTableCell>
          )}

          {value?.status?.key === "active" && value?.access_end === null && (
            <StyledTableCell
              onClick={() => navigate(`reviewStaff/${value.user_id}`)}
            >
              {" "}
              -{" "}
            </StyledTableCell>
          )}

          {value?.status?.key === "active" && value?.access_end !== null && (
            <StyledTableCell
              onClick={() => navigate(`reviewStaff/${value.user_id}`)}
            >
              {" "}
              Offboard on{" "}
              {moment.utc(value?.access_end).local().format("DD MMM YYYY")}{" "}
            </StyledTableCell>
          )}

          <StyledTableCell
            onClick={() => navigate(`reviewStaff/${value.user_id}`)}
          >
            {" "}
            {value?.user_details?.email}
            <StyledSecondaryTypography>
              {" "}
              {value?.user_details?.personal_details?.country_code}{" "}
              {value?.user_details?.personal_details?.phone}
            </StyledSecondaryTypography>
          </StyledTableCell>

          {UserManage?.staffs && (
            <StyledTableCell
              onClick={(e) => {
                handleMoreOptions(e, value);
                undo();
              }}
            >
              {" "}
              <MoreIcon />{" "}
            </StyledTableCell>
          )}
        </StyledTableRow>
      );
    }
  };

  //Modal Datas

  let cancelOffboardData = {
    label: "Confirm Cancel Offboard ",
    descriptionOne: `Please confirm that you wish to confirm the scheduled offboard date of ${moment
      .utc(selectedData?.access_end)
      .local()
      .format("DD MMM YYYY")}`,
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#0E5E84",
      text: "Confirm",
    },
  };

  let cancelScheduledInactiveData = {
    label: "Confirm Cancel Scheduled Inactive ",
    descriptionOne:
      "Please confirm that you wish to cancel the Scheduled Inactive.",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#0E5E84",
      text: "Confirm",
    },
  };

  let offboardModalData = {
    label: "Offboard User Now",
    descriptionOne:
      "Please confirm that you wish to offboard user now. After offboarded, the information cannot be recovered.",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#0E5E84",
      text: "Confirm Offboard",
    },
  };

  let InactiveData = {
    label: "Confirm Inactive ",
    descriptionOne: "Please confirm that you wish to Inactive the staff",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#0E5E84",
      text: "Confirm",
    },
  };
  let activeModalData = {
    label: "Confirm active ",
    descriptionOne: "Please confirm that you wish to active the staff",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#0E5E84",
      text: "Confirm",
    },
  };

  let changeInactiveData = {
    label: "Confirm Change of Inactive End Date",
    descriptionOne: `Please confirm that you wish to change inactive end date to ${moment
      .utc(new Date(Changedatetimerangedata[1]))
      .local()
      .format("DD MMM YYYY")}.`,
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#0E5E84",
      text: "Confirm",
    },
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      {showAlert.cancel_Scheduled_inactive && (
        <AlertModal
          modalDatas={cancelScheduledInactiveData}
          confirmFunction={activeStaff}
          closeFunction={closeAlert}
        />
      )}
      {showModal && (
        <TypesInformationModal
          data={ModalData}
          label={"Explanation of Staff Departments"}
          setFunction={setShowModal}
        />
      )}
      {showAlert.cancel && (
        <AlertModal
          modalDatas={cancelOffboardData}
          confirmFunction={activeStaff}
          closeFunction={closeAlert}
        />
      )}
      {showAlert.inactive && (
        <AlertModal
          modalDatas={InactiveData}
          confirmFunction={inactiveStaff}
          closeFunction={closeAlert}
        />
      )}
      {showAlert.offboard && (
        <AlertModal
          modalDatas={offboardModalData}
          confirmFunction={offboardStaff}
          closeFunction={closeAlert}
        />
      )}
      {showAlert.active && (
        <AlertModal
          modalDatas={activeModalData}
          confirmFunction={activeStaff}
          closeFunction={closeAlert}
        />
      )}
      {showAlert.changeInactive && (
        <AlertModal
          modalDatas={changeInactiveData}
          confirmFunction={ChangeinactiveStaffdate}
          closeFunction={closeAlert}
        />
      )}

      {/* <Box sx={{ position: 'absolute', bottom: '5%', display: 'flex', justifyContent: 'center', width: "80%" }}>
                <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#FFFFFF', borderRadius: '8px', borderColor: '#E5E7EB #E5E7EB #16A763 #E5E7EB', borderStyle: 'solid', borderWidth: '1px 1px 3px 1px', p: 1.5, boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.20)' }}>
                    <SnackbarSuccess style={{ marginRight: '10px' }} />
                    <Typography sx={{ color: '#384250', fontSize: '15px', fontWeight: 500 }}>You have successfully updated the active period (30 Aug 2023 - 14 Sept 2025) for staff, Germaine  Undo</Typography>
                    <SnackbarClose style={{ marginLeft: '10px' }} />
                </Box>
            </Box> */}

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <PrimaryTypography>
            {Lang_Management?.Staff_Management || "Staff Management"}
          </PrimaryTypography>
          <StyledSecondaryTypography>
            {Lang_Management?.Staff_Management_Des ||
              "Manage your team members and their account permissions here."}{" "}
            <span
              onClick={() => setShowModal(true)}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              {Lang_Management?.Department_Details || "Department Details"}
            </span>
          </StyledSecondaryTypography>
        </Box>
        <Box>
          {UserManage?.staffs && (
            <>
              <Button
                onClick={(e) => setDownloadAnchorEl(e.currentTarget)}
                sx={{
                  fontWeight: "600",
                  height: "40px",
                  width: "fit-content",
                  color: "#0E5E84",
                  backgroundColor: "#E7EFF3",
                  padding: "10px 16px 10px 16px",
                  mr: 3,
                  "&:hover": {
                    backgroundColor: "#CFDFE6",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#F9FAFB",
                    color: "#D2D6DB",
                  },
                }}
                disabled={staffList.length === 0 || loading}
              >
                {loading ? (
                  <Box sx={{ width: "40px", marginTop: "5px" }}>
                    <CircularProgress size={15} />
                  </Box>
                ) : (
                  <>
                    {staffList.length > 0 ? (
                      <DownloadIcon
                        style={{ marginRight: "8px", padding: "3px" }}
                      />
                    ) : (
                      <DisabledDownloadIcon
                        style={{ marginRight: "8px", padding: "3px" }}
                      />
                    )}
                  </>
                )}
                {LanguageData?.home_download_report || "Download Report"}
              </Button>
              <Button
                onClick={(e) => setAnchorEl(e.currentTarget)}
                // variant="contained"
                // height="40px"
                sx={{
                  height: "40px",
                  padding: "10px 16px",
                  borderRadius: "8px",
                  backgroundColor: "#0E5E84",
                  color: "#FFF",
                  fontSize: "14px",
                  fontWeight: 600,
                  "&:hover": {
                    backgroundColor: "#0E4965",
                  },
                }}
              >
                {LanguageData?.management_staff_add_button || "Add"}
              </Button>
            </>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #E5E7EB",
          p: 3,
          mt: 4,
          borderRadius: " 8px 8px 0px 0px",
          display: "flex",
          justifyContent: "space-between",
          overflowX: "scroll",
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        {changeStatusMultiple ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            height="40px"
          >
            <Box display="flex" alignItems="center">
              <SecondaryTypography mr={2}>
                {selectedMultipleData.length}{" "}
                {LanguageData?.Selected || "Selected "}
              </SecondaryTypography>
              <StyledSecondaryTypography
                sx={{ borderBottomWidth: "2px", borderBottomStyle: "solid" }}
                onClick={() => {
                  setselectedMultipleData([]);
                }}
              >
                {LanguageData?.Unselect_All || "Unselect_All "}
              </StyledSecondaryTypography>
            </Box>
            <Box
              sx={{
                display: "flex",
                height: "40px",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #E5E7EB",
                borderRadius: "6px",
                px: 2,
                ml: 2,
              }}
            >
              <SecondaryTypography onClick={() => setChangeStatusModal(true)}>
                {LanguageData?.Change_Status || "Change Status "}
              </SecondaryTypography>
            </Box>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                border: "1px solid #D2D6DB",
                display: "flex",
                borderRadius: "10px",
              }}
            >
              {tabList.map((value, index) => (
                <Box
                  key={index}
                  onClick={() => {
                    setSelectedTab(value);
                    setTabId(index == 0 ? "" : index);
                  }}
                  sx={{
                    borderLeft: "1px solid #E5E7EB",
                    padding: "10px 16px",
                    backgroundColor:
                      value == selectedTab ? "#F3F4F6" : "initial",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius:
                      index === 3
                        ? "0px 8px 8px 0px"
                        : index === 0
                        ? "8px 0px 0px 8px"
                        : "initial",
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  <Typography
                    sx={{
                      color: value === selectedTab ? "#111927" : "#4D5761",
                      fontSize: "14px",
                      fontWeight: 550,
                    }}
                    textAlign={"center"}
                  >
                    {value}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box sx={{ display: "flex", height: "40px" }}>
              <StyledOutlinedInput
                placeholder="Search"
                onChange={(e) => {
                  setSearchData(e.target.value);
                }}
                startAdornment={
                  <SearchIcon
                    style={{
                      paddingRight: "5px",
                      "&:hover": { cursor: "pointer" },
                    }}
                  />
                }
              />
              <Box
                onClick={(e) => setSortAnchorEl(e.currentTarget)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #D2D6DB",
                  borderRadius: "6px",
                  px: 2,
                  ml: 2,
                  "&:hover": {
                    cursor: "pointer",
                    backgroundColor: "#F9FAFB",
                  },
                }}
              >
                <SortIcon style={{ marginRight: "10px" }} />
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.Sort || "Sort "}
                </SecondaryTypography>
              </Box>
              <Box
                onClick={(e) => setFilterAnchorEl(e.currentTarget)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #D2D6DB",
                  borderRadius: "6px",
                  px: 2,
                  ml: 2,
                  "&:hover": { cursor: "pointer", backgroundColor: "#F9FAFB" },
                }}
              >
                <FilterIcon style={{ marginRight: "10px" }} />
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.Filters || "Filters "}
                </SecondaryTypography>
              </Box>
            </Box>
          </>
        )}
      </Box>

      <Box
        sx={{ border: "1px solid #E5E7EB", borderRadius: "0px 0px 8px 8px" }}
      >
        {staffList.length > 0 ? (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <StyledTableRow>
                    {(UserManage?.staffs
                      ? getColumnType()
                      : UserStaffAllTabColumn
                    ).map((value, index) => {
                      return (
                        <StyledTableCell key={index}>
                          {value === "Checkbox" ? (
                            selectedMultipleData.length > 1 ? (
                              <Checkbox
                                sx={{ height: "40px", minWidth: "43px" }}
                                onClick={() => {
                                  setselectedMultipleData([]);
                                }}
                                size="small"
                                icon={<CheckBoxMinusIcon />}
                              />
                            ) : (
                              <Checkbox
                                sx={{ height: "40px", minWidth: "43px" }}
                                size="small"
                                onChange={handleMainCheck}
                                checked={mainCheck}
                                icon={<CheckboxIcon />}
                              />
                            )
                          ) : value === "More" ? (
                            ""
                          ) : (
                            value
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {staffList?.map((value, index) => {
                    return renderData(value, index);
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderTopStyle: "none !important",
                borderRadius: "0px 0px 8px 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2.5,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ color: "#4D5761", fontSize: "14px", fontWeight: 500 }}
                >
                  {LanguageData?.Common_Show || "Show :"}
                </Typography>
                {/* <OutlinedInput inputProps={{ min: 5, max: 30, step: 5 }} value={rowsPerPage} onChange={(e) => setRowsPerPage(e.target.value)} type='number' sx={{ height: '40px', width: '100px', ml: 2, borderRadius: '8px' }} /> */}
                <Box
                  onClick={(e) => setshowAnchorEl(e.currentTarget)}
                  sx={{
                    width: "95px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #E5E7EB",
                    borderRadius: "6px",
                    ml: 2,
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "15px" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {rowsPerPage} {LanguageData?.Common_rows || "rows "}{" "}
                    <NumberInputIcon style={{ paddingLeft: "5px" }} />
                  </Typography>
                </Box>
              </Box>
              <Pagination
                size="medium"
                page={currentPage}
                count={paginationData?.last_page}
                onChange={(e, value) => setCurrentPage(value)}
                shape="rounded"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: PreviousIcon, next: NextIcon }}
                    {...item}
                  />
                )}
              />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "50vh",
              justifyContent: "center",
            }}
          >
            {Tableloading ? (
              <CircularProgress />
            ) : (
              <>
                <ExclamatoryIcon />
                <Typography
                  sx={{
                    color: "#9DA4AE",
                    fontSize: "16px",
                    fontWeight: 600,
                    my: 2,
                  }}
                >
                  {Lang_Management?.No_Staff_1 || " No Staff Here "}
                </Typography>
                <StyledSecondaryTypography>
                  {Lang_Management?.No_Staff_2 ||
                    "There are no staffs in the system yet. "}
                </StyledSecondaryTypography>
                <StyledSecondaryTypography>
                  {Lang_Management?.No_Staff_3 ||
                    " If you are authorised, you will see an add button and you add new staff by clicking the top right add button. "}
                </StyledSecondaryTypography>
              </>
            )}
          </Box>
        )}
      </Box>
      <Menu
        sx={{ m: 0.5 }}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => navigate("createSingleStaff")}>
          <Typography>
            {" "}
            {Lang_Management?.Single_Onboarding || "Single Onboarding"}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => navigate("createBatchStaff")}>
          <Typography>
            {Lang_Management?.Batch_Onboarding || "Batch Onboarding"}
          </Typography>
        </MenuItem>
      </Menu>
      <Menu
        sx={{ m: 0.5 }}
        open={sortOpen}
        anchorEl={sortAnchorEl}
        onClose={() => setSortAnchorEl(null)}
      >
        <MenuItem
          sx={{ backgroundColor: sortData == "recent" ? "#eff3f5" : "" }}
          onClick={() => {
            setSortData("recent");
            setSortAnchorEl(null);
          }}
        >
          <Typography>
            {LanguageData?.Newest_to_Oldest || "Newest to Oldest"}
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{ backgroundColor: sortData == "old" ? "#eff3f5" : "" }}
          onClick={() => {
            setSortData("old");
            setSortAnchorEl(null);
          }}
        >
          <Typography>
            {LanguageData?.Oldest_to_Newest || "Oldest to Newest"}
          </Typography>
        </MenuItem>
      </Menu>
      <Menu
        sx={{ m: 0.5 }}
        open={filterOpen}
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
      >
        {DepartmentData?.map((itm) => {
          return (
            <MenuItem onClick={(e) => handleSelectedFilter(e, itm?.id)}>
              <Checkbox
                id="checkBox-column"
                checked={Boolean(filterData.find((item) => itm?.id == item))}
                icon={<CheckboxIcon />}
              />
              <Typography sx={{ mr: 3 }}>{itm?.department_name}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
      <Menu
        sx={{ m: 0.5 }}
        open={showOpen}
        anchorEl={showAnchorEl}
        onClose={() => setshowAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            setRowsPerPage(10);
            setshowAnchorEl(null);
          }}
        >
          <Typography>10 {LanguageData?.rows || "rows"}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(20);
            setshowAnchorEl(null);
          }}
        >
          <Typography>20 {LanguageData?.rows || "rows"}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(30);
            setshowAnchorEl(null);
          }}
        >
          <Typography>30 {LanguageData?.rows || "rows"}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(40);
            setshowAnchorEl(null);
          }}
        >
          <Typography>40 {LanguageData?.rows || "rows"}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(50);
            setshowAnchorEl(null);
          }}
        >
          <Typography>50 {LanguageData?.rows || "rows"}</Typography>
        </MenuItem>
      </Menu>
      <Menu
        sx={{
          m: 0.5,
          "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper": {
            marginTop: "-30px !important",
          },
        }}
        open={MoreOpen}
        anchorEl={moreAnchorEl}
        onClose={() => setMoreAnchorEl(null)}
      >
        <MenuItem
          onClick={() => navigate(`reviewStaff/${selectedData.user_id}`)}
        >
          <Typography>
            {LanguageData?.View_Profile || "View Profile"}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setChangeStatusModal(true);
            setMoreAnchorEl(null);
          }}
        >
          <Typography>
            {LanguageData?.Change_Status || "Change Status"}
          </Typography>
        </MenuItem>
      </Menu>
      <Menu
        sx={{ m: 0.5 }}
        open={downloadOpen}
        anchorEl={downloadAnchorEl}
        onClose={() => setDownloadAnchorEl(null)}
      >
        <MenuItem>
          <TypographyDefault
            onClick={() => DownloadReport("csv")}
            py={0.5}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
          >
            <CsvIcon width={40} />{" "}
            {LanguageData?.Download_csv || "Download as CSV file"}
          </TypographyDefault>
        </MenuItem>
        <MenuItem>
          <TypographyDefault
            onClick={() => DownloadReport("pdf")}
            py={0.5}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
          >
            <PdfIcon width={40} />
            {LanguageData?.Download_pdf || "Download as PDF file"}
          </TypographyDefault>
        </MenuItem>
      </Menu>
      <Modal open={changeStatusModal} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "40%",
              height: "fit-content",
              maxHeight: "90%",
              overflowY: "scroll",
              "::-webkit-scrollbar": { display: "none" },
              backgroundColor: "#FFF",
              boxShadow:
                "0px 4px 20px 0px rgba(0, 0, 0, 0.15), 0px 0px 3px 0px rgba(0, 0, 0, 0.10)",
              border: "1px solid #F3F4F6",
              borderRadius: "6px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #F3F4F6",
                p: 2,
              }}
            >
              <PrimaryTypography>
                {Lang_Management?.sts_Modal_title ||
                  "Change status to the account"}
              </PrimaryTypography>
              <Close onClick={closeModal} />
            </Box>
            <Stack rowGap={3} sx={{ p: 2 }} direction={"column"}>
              <Typography sx={{ fontSize: "15px", fontWeight: 400 }}>
                {Lang_Management?.sts_Modal_Des ||
                  "You are able to change the status of the user’s account here. Please select one of the options below."}
              </Typography>
              <Stack direction="row" alignItems="center">
                <span>{getChip(selectedData?.status?.key)}</span>
                <Stack width="82%" direction="column">
                  {(selectedData?.status?.key === "inactive" ||
                    selectedData?.inactive !== null) && (
                    <Stack
                      ml={2}
                      width="100%"
                      direction="row"
                      justifyContent="space-between"
                    >
                      <TypographyDefault>
                        {moment
                          .utc(selectedData?.inactive?.start_date)
                          .local()
                          .format("DD MMM YYYY")}{" "}
                        -{" "}
                        {moment
                          .utc(selectedData?.inactive?.end_date)
                          .local()
                          .format("DD MMM YYYY")}
                      </TypographyDefault>
                      {/* <TypographyDefault onClick={() => { handleModalCheckboxStatus('inActive') }} sx={{ textDecoration: "underline", '&:hover': { cursor: 'pointer' } }}>Change Date</TypographyDefault> */}
                      <TypographyDefault
                        onClick={() => {
                          handleModalCheckboxStatus("changeInactive");
                          setChangedatetimerangedata([
                            dayjs(selectedData?.inactive?.start_date),
                            dayjs(selectedData?.inactive?.end_date),
                          ]);
                        }}
                        sx={{
                          textDecoration: "underline",
                          "&:hover": { cursor: "pointer" },
                        }}
                      >
                        {Lang_Management?.Change_Inactive_Date ||
                          "Change Inactive Date"}
                      </TypographyDefault>
                    </Stack>
                  )}
                  {(selectedData?.status?.key === "active" ||
                    selectedData?.status?.key === "inactive") &&
                    selectedData?.access_end !== null && (
                      <Stack
                        ml={2}
                        width="100%"
                        direction="row"
                        justifyContent="space-between"
                      >
                        <TypographyDefault>
                          {Lang_Management?.Offboard_on || " Offboard on"}{" "}
                          {moment
                            .utc(selectedData?.access_end)
                            .local()

                            .format("DD MMM YYYY")}{" "}
                        </TypographyDefault>
                        <TypographyDefault
                          onClick={() => {
                            setShowAlert({
                              cancel: true,
                              inactive: false,
                              offboard: false,
                              active: false,
                              cancel_Scheduled_inactive: false,
                              changeInactive: false,
                            });
                          }}
                          sx={{
                            textDecoration: "underline",
                            "&:hover": { cursor: "pointer" },
                          }}
                        >
                          {Lang_Management?.Cancel_Offboard ||
                            " Cancel Offboard"}
                        </TypographyDefault>
                      </Stack>
                    )}
                  {selectedData?.status?.key === "active" &&
                    selectedData?.inactive !== null && (
                      <Stack
                        width="103%"
                        mt={1}
                        direction="row"
                        alignItems="end"
                        justifyContent="end"
                      >
                        {/* <TypographyDefault>Offboard on {moment(selectedData?.access_end).format("DD MMM YYYY")} </TypographyDefault> */}
                        {/* <TypographyDefault>{moment(WorkAccessData?.inactive?.start_date).format("DD MMM YYYY")} - {moment(WorkAccessData?.inactive?.end_date).format("DD MMM YYYY")}</TypographyDefault> */}
                        <TypographyDefault
                          onClick={() => {
                            setShowAlert({
                              cancel: false,
                              inactive: false,
                              offboard: false,
                              active: false,
                              cancel_Scheduled_inactive: true,
                              changeInactive: false,
                            });
                          }}
                          sx={{
                            textDecoration: "underline",
                            "&:hover": { cursor: "pointer" },
                          }}
                        >
                          {Lang_Management?.Cancel_Scheduled_Inactive ||
                            "Cancel Scheduled Inactive"}
                        </TypographyDefault>
                      </Stack>
                    )}
                </Stack>
              </Stack>
              <StyledDefaultTypography my={1.5}>
                {Lang_Management?.Status_Options || " Status Options"}
              </StyledDefaultTypography>
              {selectedData?.status?.key !== "inactive" &&
                selectedData?.inactive == null && (
                  <Stack
                    className={modalCheckboxStatus.inActive && "selectedStyle"}
                    rowGap={1}
                    sx={{
                      backgroundColor: "#F9FAFB",
                      p: 1,
                      "&.selectedStyle": {
                        backgroundColor: "#EDF6FA",
                        border: "1px solid #0E4965",
                        borderRadius: "8px",
                      },
                    }}
                    direction={"column"}
                  >
                    <StyledDefaultTypography>
                      {" "}
                      {Lang_Management?.home_inactive || "Inactive"}
                    </StyledDefaultTypography>
                    <Stack
                      direction={"row"}
                      width={"100%"}
                      justifyContent={"space-between"}
                    >
                      <StyledSecondaryTypography>
                        {Lang_Management?.Modal_Inactive_Des ||
                          "Temporarily offboard account in the event staff is away for a long period of time. During inactive period, he/she will not be able to perform tasks other than logging in."}{" "}
                      </StyledSecondaryTypography>
                      <Checkbox
                        onChange={() => handleModalCheckboxStatus("inActive")}
                        checked={modalCheckboxStatus.inActive}
                        size="small"
                        icon={<CheckboxIcon />}
                      />
                    </Stack>
                  </Stack>
                )}
              {selectedData?.access_end === null && (
                <Stack
                  className={modalCheckboxStatus.offboard && "selectedStyle"}
                  rowGap={1}
                  sx={{
                    backgroundColor: "#F9FAFB",
                    p: 1,
                    "&.selectedStyle": {
                      backgroundColor: "#EDF6FA",
                      border: "1px solid #0E4965",
                      borderRadius: "8px",
                    },
                  }}
                  direction={"column"}
                >
                  <StyledDefaultTypography>
                    {Lang_Management?.Offboard_User || "Offboard User"}
                  </StyledDefaultTypography>
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    justifyContent={"space-between"}
                  >
                    <StyledSecondaryTypography>
                      {Lang_Management?.Modal_Offboard_Des ||
                        "User will not be able to access this account after the stated date, and his/ her active cases will be automatically assigned back to sender."}
                    </StyledSecondaryTypography>
                    <Checkbox
                      onChange={() => handleModalCheckboxStatus("offboard")}
                      checked={modalCheckboxStatus.offboard}
                      size="small"
                      icon={<CheckboxIcon />}
                    />
                  </Stack>
                </Stack>
              )}
              {selectedData?.status?.key !== "active" && (
                <Stack
                  className={modalCheckboxStatus.active && "selectedStyle"}
                  rowGap={1}
                  sx={{
                    backgroundColor: "#F9FAFB",
                    p: 1,
                    "&.selectedStyle": {
                      backgroundColor: "#EDF6FA",
                      border: "1px solid #0E4965",
                      borderRadius: "8px",
                    },
                  }}
                  direction={"column"}
                >
                  <StyledDefaultTypography>
                    {Lang_Management?.Change_to_Active || "Change to Active"}
                  </StyledDefaultTypography>
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    justifyContent={"space-between"}
                  >
                    <StyledSecondaryTypography>
                      {Lang_Management?.Change_to_Active_Des ||
                        "User will be able to be assign or be assigned to cases, post or book facilties and make announcements effective immediate."}
                    </StyledSecondaryTypography>
                    <Checkbox
                      onChange={() => handleModalCheckboxStatus("active")}
                      checked={modalCheckboxStatus.active}
                      size="small"
                      icon={<CheckboxIcon />}
                    />
                  </Stack>
                </Stack>
              )}
              {modalCheckboxStatus.offboard && (
                <DatePicker
                  minDate={dayjs(selectedData?.inactive?.end_date).add(
                    1,
                    "day"
                  )}
                  onChange={(e) => setOffboardDate(e.$d)}
                  format="DD/MM/YYYY"
                  slots={{ openPickerIcon: () => <DatePickerIcon /> }}
                  sx={DatePickerStyle}
                />
              )}

              {modalCheckboxStatus.inActive && (
                <Stack
                  onClick={() => setopenDateRangePicker(true)}
                  sx={{
                    height: "47px",
                    padding: "2px 12px 2px 12px",
                    borderRadius: "8px",
                    backgroundColor: "#F9FAFB",
                  }}
                  direction="row"
                  height="40px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {Boolean(datetimerangedata[0]) ? (
                    <StyledSecondaryDefaultTypography>
                      {moment
                        .utc(new Date(datetimerangedata[0]?.$d))
                        .local()
                        .format("MMM D YYYY")}{" "}
                      -{" "}
                      {moment
                        .utc(new Date(datetimerangedata[1]?.$d))
                        .local()
                        .format("MMM D YYYY")}{" "}
                    </StyledSecondaryDefaultTypography>
                  ) : (
                    <StyledSecondaryDefaultTypography
                      sx={{ color: "#9DA4AE !important" }}
                    >
                      {Lang_Management?.Modal_Inactive__Placeholder ||
                        "Please select a inactive date"}
                    </StyledSecondaryDefaultTypography>
                  )}
                  <DatePickerIcon />
                </Stack>
              )}
              {modalCheckboxStatus.changeInactive && (
                <>
                  <Stack
                    onClick={() => setopenChangeDateRangePicker(true)}
                    sx={{
                      height: "47px",
                      padding: "2px 12px 2px 12px",
                      borderRadius: "8px",
                      backgroundColor: "#F9FAFB",
                    }}
                    direction="row"
                    height="40px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {Boolean(Changedatetimerangedata[0]) ? (
                      <StyledSecondaryDefaultTypography>
                        {moment
                          .utc(Changedatetimerangedata[0]?.$d)
                          .local()
                          .format("MMM D YYYY")}{" "}
                        -{" "}
                        {moment
                          .utc(Changedatetimerangedata[1]?.$d)
                          .local()
                          .format("MMM D YYYY")}{" "}
                      </StyledSecondaryDefaultTypography>
                    ) : (
                      <StyledSecondaryDefaultTypography
                        sx={{ color: "#9DA4AE !important" }}
                      >
                        {Lang_Management?.Modal_Inactive__Placeholder ||
                          "Please select a inactive date"}
                      </StyledSecondaryDefaultTypography>
                    )}
                    <DatePickerIcon />
                    {/* <DatePicker disablePast format="DD/MM/YYYY" slots={{ openPickerIcon: () => <DatePickerIcon /> }} sx={DatePickerStyle} /> */}
                  </Stack>
                  <DateRangePicker
                    minDate={dayjs(selectedData?.inactive?.start_date).add(
                      1,
                      "day"
                    )}
                    calendars={1}
                    open={openChangeDateRangePicker}
                    value={[
                      dayjs(selectedData?.inactive?.start_date),
                      Changedatetimerangedata[1],
                    ]}
                    onChange={(e) => {
                      setChangedatetimerangedata([
                        dayjs(selectedData?.inactive?.start_date),
                        e[1],
                      ]);
                    }}
                    onClose={() => setopenChangeDateRangePicker(false)}
                    sx={{
                      "&>*": {
                        visibility: "hidden",
                        width: "0px",
                        height: "0px !important",
                      },
                      height: "0px",
                      width: "0px",
                      marginLeft: "50%",
                    }}
                  />
                </>
              )}
              {/* {modalError.offboard && <ErrorTypography>{modalError.offboard}</ErrorTypography>}
                            {modalError.selected && <ErrorTypography>{modalError.selected}</ErrorTypography>}
                            {modalError.inActive && <ErrorTypography>{modalError.inActive}</ErrorTypography>} */}
            </Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderTop: "1px solid #F3F4F6",
                p: 2,
              }}
            >
              <Button onClick={() => closeModal()} variant="outlined">
                {LanguageData?.Cancel || "Cancel"}
              </Button>
              <Button
                onClick={() => handleModalConfirm()}
                disabled={
                  (isStatus || openDateRangePicker) == true ? true : false
                }
                style={{
                  padding: "10px 16px",
                  borderRadius: "8px",
                  backgroundColor: "#0E5E84",
                  color: "#FFF",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                {LanguageData?.Confirm || "Confirm"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* DateRange picker for Inactive */}
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        }
      >
        {openDateRangePicker && (
          <CustomDateRangePicker
            openDateRangePicker={openDateRangePicker}
            setopenDateRangePicker={setopenDateRangePicker}
            datetimerangedata={datetimerangedata}
            setdatetimerangedata={setdatetimerangedata}
            setdateSubmit={setdateRangeSubmit}
            maxDate={selectedData?.access_end}
          />
        )}
      </Suspense>
    </>
  );
}
