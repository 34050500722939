import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Dayjs from "dayjs";
import PhoneInput from "react-phone-input-2";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import { ReactComponent as ContactDetailsIcon } from "../../../Icons/ContactDetails.svg";
import { ReactComponent as DatePickerIcon } from "../../../Icons/Datepicker.svg";
import { ReactComponent as EditIcon } from "../../../Icons/Edit.svg";
import {
  DatePickerStyle,
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../../Theme";
import {
  Switch,
  Stack,
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  IconButton,
  Divider,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { emailRegex, NameRegex, No_special_regex } from "../../../Utils/index";
import {
  SnackbarSucces,
  PostLoaderModal,
} from "../../../CommonComponents/modal";
import axiosInstance from "../../../Redux/AxiosInstance";
import { showToast } from "../../../CommonComponents/Toaster";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const reviewstyle = {
  minHeight: "61px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "10px 0",
};

const snakbardata = {
  main: "The resident’s details has been successfully updated.",
  sub: "You’re successfully Update the resident information.",
};

const EditResidentDetails = () => {
  const navigate = useNavigate();
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { id } = useParams();
  const [getres, setgetres] = useState(true);
  const [blocks, setblocks] = useState({ editblock: true, reviewblock: false });
  const [PersonalDetailsData, setPersonalDetailsData] = useState({
    firstname: "",
    lastname: "",
    middlename: "",
    dob: "",
    gender: "",
    nationality: "",
    inorpn: "",
    contact: "",
    phone: "",
    countrycode: "",
    email: "",
    address: "",
    country: "",
    city: "",
    postalcode: "",
  });
  const [emergencyContactData, setemergencyContactData] = useState({
    firstname: "",
    lastname: "",
    // gender: '',
    // nationality: '',
    phone: "",
    email: "",
    relationship: "",
  });
  const [WorkAccessData, setWorkAccessData] = useState({
    residenttype: "",
    building: "",
    level: "",
    unit: "",
    accessstartdate: "",
    accessenddate: "",
    updated_at: "",
  });

  const [nationalityData, setnationalityData] = useState("");
  const [genderData, setgenderData] = useState([]);
  const [ResidentTypeData, setResidentTypeData] = useState([]);
  const [buildingData, setBuildingData] = useState([]);

  const [onboardedby, setonboardedby] = useState({
    name: "",
    email: "",
    department: "",
    jobrole: "",
  });
  const [errtext, seterrtext] = useState({
    PersonalDetailsData: {},
    emergencyContactData: {},
    WorkAccessData: {},
  });
  const [CountryList, setCountryList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [postLoader, SetPostLoader] = useState(false);

  // {
  //   Boolean(WorkAccessData.accessenddate?.$d) ?
  //     (residentData.access_details.access_end = moment(
  //       WorkAccessData.accessenddate?.$d
  //     ).format("YYYY-MM-DD")) : null
  // }

  //edit api
  const editResidentData = async () => {
    const AccessStart = WorkAccessData.accessstartdate?.$d;
    const final = moment(AccessStart).hour(0).minute(0).second(0);
    const final1 = moment.utc(final).format("YYYY-MM-DD HH:mm:ss");
    const AccessEnd = WorkAccessData.accessenddate?.$d;
    const finalEnd = moment(AccessEnd).hour(0).minute(0).second(0);
    const final1End = moment.utc(finalEnd).format("YYYY-MM-DD HH:mm:ss");
    const residentData = {
      user_id: id,
      first_name: PersonalDetailsData.firstname, //required|max:20|min:1
      last_name: PersonalDetailsData.lastname, //required|max:20|min:1
      // middle_name: PersonalDetailsData.middlename, //max:20|min:1
      dob: moment(PersonalDetailsData.dob?.$d).format("YYYY-MM-DD"), //required|date|before_or_equal:today
      gender: PersonalDetailsData.gender, //required|int|in:1,2,3
      nationality: PersonalDetailsData.nationality, //required|max:20|min:1
      identity_number: PersonalDetailsData.inorpn, //required|max:25|min:1
      phone: PersonalDetailsData.phone, //required|min:10|max:20
      country_code: PersonalDetailsData.countrycode, //required
      address: PersonalDetailsData.address, //required|min:3|max:50
      country: PersonalDetailsData.country, //required|min:3|max:20
      city: PersonalDetailsData.city, //required|min:3|max:20
      zipcode: PersonalDetailsData.postalcode, //required|max:7|min:6
      email: PersonalDetailsData.email,
      emergency_details: {
        first_name: emergencyContactData.firstname, //required|max:20|min:1
        last_name: emergencyContactData.lastname, //max:20|min:1
        email: emergencyContactData.email, //email
        phone: emergencyContactData.phone, //required|min:10|max:20
        country_code: emergencyContactData.countrycode, //required
        gender: "1", //required|int|in:1,2,3
        nationality: "dgdj", //required|max:20|min:1
        relationship: emergencyContactData.relationship, //required|max:20|min:1
      },

      access_details: {
        residency_type_id: WorkAccessData.residenttype, //required|exists:residency_type,id
        building_id: WorkAccessData.building,
        level_id: WorkAccessData.level, //exists:building_level,id
        unit_id: WorkAccessData.unit, //exists:building_units,id
        join_date: moment.utc(new Date()).format("YYYY-MM-DD"), //date
        access_start: final1,
        access_end: WorkAccessData.accessenddate?.$d ? final1End : null,
      },
    };

    {
      Boolean(PersonalDetailsData.middlename) &&
        Object.assign(residentData, {
          middle_name: PersonalDetailsData.middlename,
        });
    }
    SetPostLoader(true);
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/resident_onboard_single`,
        residentData
      );
      const id = response?.data?.data?.resident?.id;
      showToast(response.data?.toast);
      navigate(`/residents/reviewResident/${id}`);
      SetPostLoader(false);
    } catch (error) {
      showToast(
        error?.response?.data?.error ||
          "Something went wrong! Please try again.",
        "error"
      );
      SetPostLoader(false);
    }
  };

  console.log(WorkAccessData.accessenddate);

  useEffect(() => {
    if (selectedCountry) {
      const selectedCountryObject = CountryList.find(
        (city) => city.country === selectedCountry
      );
      if (selectedCountryObject?.city) {
        setCityList(selectedCountryObject?.city);
      }
    }
  }, [selectedCountry, CountryList]);

  useEffect(() => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}getDropDownDatas?type=country`)
      .then((res) => {
        setCountryList(res.data.data?.drop_down);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const getResidentData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}user_details?user_id=${id}`
        );
        // setstaffdata(response.data.data.user_details)
        const data = response.data.data.user_details;
        setPersonalDetailsData({
          firstname: data.first_name,
          lastname: data.last_name,
          middlename: data.middle_name,
          dob: Dayjs(data.dob),
          gender: data.gender,
          nationality: data.nationality,
          inorpn: data.identity_number,
          phone: data.phone,
          countrycode: data.country_code,
          contact: data.country_code + data.phone,
          email: data.email,
          address: data.address,
          country: data.country,
          city: data.city,
          postalcode: data.zipcode,
          fullName: data.full_name,
        });
        setSelectedCountry(data.country);
        setemergencyContactData({
          firstname: data.emergency_contacts.first_name,
          lastname: data.emergency_contacts.last_name,
          gender: data.emergency_contacts.gender,
          nationality: data.emergency_contacts.nationality,
          countrycode: data.emergency_contacts.country_code,
          contact:
            data.emergency_contacts.country_code +
            data.emergency_contacts.phone,
          phone: data.emergency_contacts.phone,
          email: data.emergency_contacts.email,
          relationship: data.emergency_contacts.relationship,
        });
        setWorkAccessData({
          residenttype: data.access_details.residency_type.id,
          building: data.access_details.building_id,
          level: data.access_details.level_id,
          unit: data.access_details.unit_id,
          accessstartdate:
            data.access_details.access_start != null
              ? Dayjs(data.access_details.access_start)
              : null,
          accessenddate:
            data.access_details.access_end != null
              ? Dayjs(data.access_details.access_end)
              : null,
          updated_at: data.access_details.updated_at,
        });
        setonboardedby({
          name: data?.access_details?.onboarded_user_details?.name,
          email: data?.access_details?.onboarded_user_details?.email,
          department:
            data?.access_details?.onboarded_user_details?.access_details
              ?.department_details?.department_name,
          jobrole:
            data?.access_details?.onboarded_user_details?.access_details
              ?.role_details?.title,
        });
        setgetres(false);
      } catch (err) {
        console.log(err);
      }
    };
    getResidentData();
  }, []);
  useEffect(() => {
    const getnationality = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}getDropDownDatas?type=nationality`
        );
        const nationalityData = response.data.data.drop_down;
        var nationalitymenuItems = [];
        for (const key in nationalityData) {
          nationalitymenuItems.push(
            <MenuItem key={key} value={key}>
              {nationalityData[key]}
            </MenuItem>
          );
        }
        setnationalityData(nationalitymenuItems);
      } catch (error) {
        console.log(error.message);
      }
    };
    getnationality();

    const getGenderData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}getDropDownDatas?type=gender`
        );
        const genderData = response.data.data.drop_down;
        setgenderData(genderData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getGenderData();
    const getResidentTypeData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}getDropDownDatas?type=residency_type_tenant`
        );
        const ResidentTypeData = response.data.data.drop_down;
        setResidentTypeData(ResidentTypeData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getResidentTypeData();

    const getBuildingData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/building`
        );
        const BuildingData = response.data.data.property_buildings;
        setBuildingData(BuildingData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getBuildingData();
  }, []);

  const isValid = (curval) => {
    let isError = false;
    // Personaldetails
    for (const key in PersonalDetailsData) {
      if (key === "email" && PersonalDetailsData[key] !== "") {
        if (!emailRegex.test(PersonalDetailsData[key])) {
          seterrtext((prev) => {
            return {
              ...prev,
              PersonalDetailsData: {
                ...prev.PersonalDetailsData,
                [key]: "Error! Invalid Email Format ",
              },
            };
          });
          isError = true;
        }
      }
      // else if (key === "phone" && PersonalDetailsData[key].length < 10) {
      //   seterrtext((prev) => {
      //     return {
      //       ...prev,
      //       PersonalDetailsData: {
      //         ...prev.PersonalDetailsData,
      //         [key]: "Error! Contact number must be above 10 number ",
      //       },
      //     };
      //   });
      //   isError = true;
      // }
      else if (key === "phone") {
        if (
          PersonalDetailsData[key] === "" ||
          PersonalDetailsData[key] === null ||
          PersonalDetailsData[key].length < 6
        ) {
          seterrtext((prev) => {
            return {
              ...prev,
              PersonalDetailsData: {
                ...prev.PersonalDetailsData,
                [key]:
                  PersonalDetailsData[key] === "" ||
                  PersonalDetailsData[key] === null
                    ? "Error! No Inputs detected"
                    : "Error! Phone should not below 6 numbers ",
              },
            };
          });
          isError = true;
        }
      } else if (key === "postalcode") {
        if (
          PersonalDetailsData[key] === "" ||
          PersonalDetailsData[key] === null
        ) {
          seterrtext((prev) => {
            return {
              ...prev,
              PersonalDetailsData: {
                ...prev.PersonalDetailsData,
                [key]: "Error! No Inputs detected",
              },
            };
          });
          isError = true;
        } else if (PersonalDetailsData[key].length < 5) {
          seterrtext((prev) => {
            return {
              ...prev,
              PersonalDetailsData: {
                ...prev.PersonalDetailsData,
                [key]: "Error! Postal Code must be above 5 numbers ",
              },
            };
          });
          isError = true;
        }
      } else if (
        (PersonalDetailsData[key] === "" ||
          PersonalDetailsData[key] === null) &&
        key !== "middlename"
      ) {
        seterrtext((prev) => {
          return {
            ...prev,
            PersonalDetailsData: {
              ...prev.PersonalDetailsData,
              [key]: "Error! No Inputs detected",
            },
          };
        });
        isError = true;
      }
    }
    //emergencycontact
    for (const key in emergencyContactData) {
      if (key === "email" && emergencyContactData[key] !== "") {
        if (!emailRegex.test(emergencyContactData[key])) {
          seterrtext((prev) => {
            return {
              ...prev,
              emergencyContactData: {
                ...prev.emergencyContactData,
                [key]: "Error! Invalid Email Format",
              },
            };
          });
          isError = true;
        }
      }
      // else if (key === "phone" && emergencyContactData[key].length < 10) {
      //   seterrtext((prev) => {
      //     return {
      //       ...prev,
      //       emergencyContactData: {
      //         ...prev.emergencyContactData,
      //         [key]: "Error! Contact number must be above 10 number ",
      //       },
      //     };
      //   });
      //   isError = true;
      // }
      else if (key === "phone") {
        if (
          emergencyContactData[key] === "" ||
          emergencyContactData[key] === null ||
          emergencyContactData[key].length < 6
        ) {
          seterrtext((prev) => {
            return {
              ...prev,
              emergencyContactData: {
                ...prev.emergencyContactData,
                [key]:
                  emergencyContactData[key] === "" ||
                  emergencyContactData[key] === null
                    ? "Error! No Inputs detected"
                    : "Error! Phone should not below 6 numbers ",
              },
            };
          });
          isError = true;
        }
      } else if (emergencyContactData[key] === "" && key !== "lastname") {
        seterrtext((prev) => {
          return {
            ...prev,
            emergencyContactData: {
              ...prev.emergencyContactData,
              [key]: "Error! No Inputs detected",
            },
          };
        });
        isError = true;
      }
    }

    for (const key in WorkAccessData) {
      if (
        key === "accessenddate" &&
        WorkAccessData.residenttype != "3" &&
        WorkAccessData.residenttype != "4"
      ) {
        if (WorkAccessData[key] === "" || WorkAccessData[key] === null) {
          seterrtext((prev) => {
            return {
              ...prev,
              WorkAccessData: {
                ...prev.WorkAccessData,
                [key]: "Error! No Inputs detected",
              },
            };
          });
          isError = true;
        } else if (
          !(
            WorkAccessData.accessstartdate.$d <= WorkAccessData.accessenddate.$d
          )
        ) {
          seterrtext((prev) => {
            return {
              ...prev,
              WorkAccessData: {
                ...prev.WorkAccessData,
                [key]: "Error! End date should be greater than start date",
              },
            };
          });
          isError = true;
        }
      } else if (
        (WorkAccessData[key] === "" || WorkAccessData[key] === null) &&
        key !== "accessenddate"
      ) {
        seterrtext((prev) => {
          return {
            ...prev,
            WorkAccessData: {
              ...prev.WorkAccessData,
              [key]: "Error! No Inputs detected",
            },
          };
        });
        isError = true;
      }
    }
    return isError;
  };

  //personalDetails
  const Continue = (val) => {
    const Valid = isValid();
    if (!Valid) {
      editResidentData();
    }
  };

  const handlePersonalDetails = (e, name) => {
    if (name) {
      setPersonalDetailsData((prev) => {
        return {
          ...prev,
          [name]: e,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          PersonalDetailsData: {
            ...prev.PersonalDetailsData,
            [name]: "",
          },
        };
      });
    } else {
      const { name, value } = e.target;
      if (name === "country" && value) {
        setCityList([]);
        const selectedCountry = value;
        const selectedCountryObject = CountryList.find(
          (city) => city.country === selectedCountry
        );
        setCityList(selectedCountryObject?.city);
        setPersonalDetailsData((prevData) => ({
          ...prevData,
          city: "",
        }));
      }
      setPersonalDetailsData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          PersonalDetailsData: {
            ...prev.PersonalDetailsData,
            [name]: "",
          },
        };
      });
    }
  };

  const handleMobileInput = (
    value,
    country,
    functionName,
    state,
    statename
  ) => {
    functionName({
      ...state,
      countrycode: `+${country.dialCode}`,
      phone: value.slice(country.dialCode.length),
    });

    seterrtext((prev) => {
      return {
        ...prev,
        [statename]: {
          ...prev[statename],
          countrycode: "",
          phone: "",
        },
      };
    });
  };

  const handleEmergencyContact = (e) => {
    const { name, value } = e.target;
    setemergencyContactData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    seterrtext((prev) => {
      return {
        ...prev,
        emergencyContactData: {
          ...prev.emergencyContactData,
          [name]: "",
        },
      };
    });
  };

  const handleWorkAccess = (e, name) => {
    if (name) {
      setWorkAccessData((prev) => {
        return {
          ...prev,
          [name]: e,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          WorkAccessData: {
            ...prev.WorkAccessData,
            [name]: "",
          },
        };
      });
    } else {
      const { name, value } = e.target;
      setWorkAccessData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          WorkAccessData: {
            ...prev.WorkAccessData,
            [name]: "",
          },
        };
      });
      if (name == "residenttype" && (value == "3" || value == "4")) {
        seterrtext((prev) => {
          return {
            ...prev,
            WorkAccessData: {
              ...prev.WorkAccessData,
              accessenddate: "",
            },
          };
        });
      }
    }
  };
  const DropdownEmpty = (val) => {
    if (val === "building") {
      setWorkAccessData((prev) => {
        return {
          ...prev,
          level: "",
          unit: "",
        };
      });
    } else if (val === "level") {
      setWorkAccessData((prev) => {
        return {
          ...prev,
          unit: "",
        };
      });
    }
  };
  const Editblock = () => {
    return (
      <>
        {" "}
        <BackArrow
          onClick={() => navigate("/residents")}
          style={{ marginBottom: "26px", cursor: "pointer" }}
        />
        <Box mb={4}>
          <PrimaryTypography mb={1}>
            {PersonalDetailsData.fullName}{" "}
          </PrimaryTypography>
          <StyledSecondaryTypography>
            Last Updated:{" "}
            {moment(WorkAccessData?.updated_at)
              .format("DD MMM YYYY , HH:mm")
              .toString()}{" "}
          </StyledSecondaryTypography>
        </Box>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>Personal Details</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px  ",
            }}
          >
            <Grid xs={12} sm={5.8}>
              <StyledTypography>First Name*</StyledTypography>
              <TextField
                name="firstname"
                value={PersonalDetailsData.firstname}
                onChange={handlePersonalDetails}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.firstname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.firstname
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.firstname}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8}>
              <StyledTypography>Last Name*</StyledTypography>
              <TextField
                name="lastname"
                value={PersonalDetailsData.lastname}
                onChange={handlePersonalDetails}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.lastname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.lastname
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.lastname}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Middle Name</StyledTypography>
              <TextField
                name="middlename"
                value={PersonalDetailsData.middlename}
                onChange={handlePersonalDetails}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.middlename
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.middlename
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.middlename}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Date of Birth*</StyledTypography>
              <DatePicker
                format="DD/MM/YYYY"
                slots={{ openPickerIcon: () => <DatePickerIcon /> }}
                name="dob"
                value={PersonalDetailsData.dob || null}
                onChange={(value) => handlePersonalDetails(value, "dob")}
                sx={{
                  ...DatePickerStyle,
                  backgroundColor: Boolean(errtext.PersonalDetailsData.dob)
                    ? "#FEE4E2"
                    : "",

                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.dob
                      ? "#912018"
                      : "#111927",
                  },
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.dob}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Gender*</StyledTypography>
              <Select
                name="gender"
                value={PersonalDetailsData.gender}
                IconComponent={KeyboardArrowDownIcon}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.gender
                      ? "#912018"
                      : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:"none !important"
                  },
                  backgroundColor: errtext.PersonalDetailsData.gender
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {genderData.map((itm) => {
                  return <MenuItem value={itm.id}>{itm.name}</MenuItem>;
                })}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.gender}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Nationality*</StyledTypography>
              <Select
                name="nationality"
                IconComponent={KeyboardArrowDownIcon}
                value={PersonalDetailsData.nationality}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.nationality
                      ? "#912018"
                      : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:"none !important"
                  },
                  backgroundColor: errtext.PersonalDetailsData.nationality
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {Boolean(nationalityData) && nationalityData}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.nationality}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>
                Identity Number / Passport Number*
              </StyledTypography>
              <TextField
                name="inorpn"
                value={PersonalDetailsData.inorpn}
                onChange={handlePersonalDetails}
                onKeyDown={(evt) => {
                  !No_special_regex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 25 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.inorpn
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.inorpn
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.inorpn}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography sx={{ marginBottom: "10px" }}>
                Contact Number*
              </StyledTypography>
              <PhoneInput
                value={PersonalDetailsData.contact}
                onChange={(value, country, e) =>
                  handleMobileInput(
                    value,
                    country,
                    setPersonalDetailsData,
                    PersonalDetailsData,
                    "PersonalDetailsData"
                  )
                }
                inputProps={{ name: "phone", maxLength: 20 }}
                country={"th"}
                buttonStyle={{ border: "none" }}
                inputStyle={{
                  backgroundColor: errtext.PersonalDetailsData.phone
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  border: "none",
                  height: "46px",
                  width: "100%",
                  borderRadius: "8px",
                  color: errtext.PersonalDetailsData.phone
                    ? "#912018"
                    : "#111927",
                }}
                countryCodeEditable={false}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.phone}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={12} mt={2}>
              <StyledTypography>Email Address*</StyledTypography>
              <TextField
                name="email"
                value={PersonalDetailsData.email}
                onChange={handlePersonalDetails}
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 125 }}
                fullWidth
                disabled
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.email}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={12} mt={2}>
              <StyledTypography>Address*</StyledTypography>
              <TextField
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.address
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.address
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  height: "88px",
                }}
                name="address"
                placeholder="Enter the full address here"
                value={PersonalDetailsData.address}
                inputProps={{ maxLength: 50 }}
                onChange={handlePersonalDetails}
                InputProps={{ disableUnderline: true }}
                fullWidth
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.address}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={3.77} mt={2}>
              <StyledTypography>Country*</StyledTypography>
              <Select
                name="country"
                IconComponent={KeyboardArrowDownIcon}
                value={PersonalDetailsData.country}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.country
                      ? "#912018"
                      : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:"none !important"
                  },
                  backgroundColor: errtext.PersonalDetailsData.country
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {CountryList.length > 0 &&
                  CountryList.map((value) => (
                    <MenuItem key={value?.key} value={value?.country}>
                      {value?.country}
                    </MenuItem>
                  ))}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.country}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={3.77} mt={2}>
              <StyledTypography>City*</StyledTypography>
              <Select
                name="city"
                value={PersonalDetailsData.city}
                IconComponent={KeyboardArrowDownIcon}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.city
                      ? "#912018"
                      : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:"none !important"
                  },
                  backgroundColor: errtext.PersonalDetailsData.city
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {CityList.length > 0 &&
                  CityList.map((city) => (
                    <MenuItem value={city}>{city}</MenuItem>
                  ))}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.city}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={3.77} mt={2}>
              <StyledTypography>Postal Code*</StyledTypography>
              <TextField
                type="number"
                name="postalcode"
                value={PersonalDetailsData.postalcode}
                onChange={(e) => {
                  e.target.value.length <= 20 && handlePersonalDetails(e);
                }}
                // inputProps={{ maxLength: 6 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.postalcode
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.postalcode
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.postalcode}
              </ErrorTypography>
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>Emergency Contact</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px",
            }}
          >
            <Grid xs={12} sm={5.8}>
              <StyledTypography>First Name*</StyledTypography>
              <TextField
                name="firstname"
                value={emergencyContactData.firstname}
                onChange={handleEmergencyContact}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.firstname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.emergencyContactData.firstname
                    ? "#FEE4E2"
                    : "#F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.firstname}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8}>
              <StyledTypography>Last Name</StyledTypography>
              <TextField
                name="lastname"
                value={emergencyContactData.lastname}
                onChange={handleEmergencyContact}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.lastname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.emergencyContactData.lastname
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.lastname}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Email Address*</StyledTypography>
              <TextField
                name="email"
                value={emergencyContactData.email}
                onChange={handleEmergencyContact}
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 125 }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.email
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.emergencyContactData.email
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.email}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Contact Number*</StyledTypography>
              <PhoneInput
                value={emergencyContactData.contact}
                onChange={(value, country, e) =>
                  handleMobileInput(
                    value,
                    country,
                    setemergencyContactData,
                    emergencyContactData,
                    "emergencyContactData"
                  )
                }
                inputProps={{ name: "phone", maxLength: 20 }}
                country={"th"}
                buttonStyle={{ border: "none" }}
                inputStyle={{
                  backgroundColor: errtext.emergencyContactData.phone
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  border: "none",
                  height: "46px",
                  width: "100%",
                  borderRadius: "8px",
                  color: errtext.emergencyContactData.phone
                    ? "#912018"
                    : "#111927",
                }}
                countryCodeEditable={false}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.phone}
              </ErrorTypography>
            </Grid>
            {/* <Grid xs={12} sm={3.77} mt={2}>
                            <StyledTypography>Nationality*</StyledTypography>
                            <Select name="nationality" value={emergencyContactData.nationality}
                                onChange={(e) => handleEmergencyContact(e)}
                            >
                                {Boolean(nationalityData) && nationalityData}
                            </Select>
                            <ErrorTypography>{errtext.emergencyContactData.nationality}</ErrorTypography>
                        </Grid>
                        <Grid xs={12} sm={3.77} mt={2}>
                            <StyledTypography>Gender*</StyledTypography>
                            <Select name="gender" value={emergencyContactData.gender}
                                onChange={(e) => handleEmergencyContact(e)}
                            >
                                {genderData.map((itm) => {
                                    return <MenuItem value={itm.id}>{itm.name}</MenuItem>
                                })}
                            </Select>
                            <ErrorTypography>{errtext.emergencyContactData.gender}</ErrorTypography>
                        </Grid> */}
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Relationship*</StyledTypography>
              <TextField
                name="relationship"
                value={emergencyContactData.relationship}
                onChange={handleEmergencyContact}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.relationship
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.emergencyContactData.relationship
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.relationship}
              </ErrorTypography>
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>Access Details</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px  ",
            }}
          >
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Resident Type*</StyledTypography>
              <Select
                name="residenttype"
                IconComponent={KeyboardArrowDownIcon}
                value={WorkAccessData.residenttype}
                onChange={(e) => handleWorkAccess(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.residenttype
                      ? "#912018"
                      : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:"none !important"
                  },
                  backgroundColor: Boolean(errtext.WorkAccessData.residenttype)
                    ? "#FEE4E2"
                    : "",
                }}
              >
                {ResidentTypeData.map((itm) => {
                  return (
                    <MenuItem key={itm.key} value={itm.id}>
                      {itm.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <ErrorTypography>
                {errtext.WorkAccessData.residenttype}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Building</StyledTypography>
              <Select
                name="building"
                IconComponent={KeyboardArrowDownIcon}
                value={WorkAccessData.building}
                onChange={(e) => handleWorkAccess(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.building
                      ? "#912018"
                      : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:"none !important"
                  },
                  backgroundColor: Boolean(errtext.WorkAccessData.building)
                    ? "#FEE4E2"
                    : "",
                }}
              >
                {buildingData.map((itm, index) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        DropdownEmpty("building");
                      }}
                      key={index}
                      value={itm.id}
                    >
                      {itm.building_name}
                    </MenuItem>
                  );
                })}
              </Select>
              <ErrorTypography>
                {errtext.WorkAccessData.building}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>level*</StyledTypography>
              <Select
                name="level"
                IconComponent={KeyboardArrowDownIcon}
                value={WorkAccessData.level}
                onChange={(e) => handleWorkAccess(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.level ? "#912018" : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:"none !important"
                  },
                  backgroundColor: Boolean(errtext.WorkAccessData.level)
                    ? "#FEE4E2"
                    : "",
                }}
              >
                {buildingData.map((itm, index) => {
                  if (WorkAccessData.building == itm.id) {
                    return itm.level.map((sitm) => {
                      return (
                        <MenuItem
                          onClick={() => {
                            DropdownEmpty("level");
                          }}
                          value={sitm.id}
                        >
                          {sitm.level}
                        </MenuItem>
                      );
                    });
                  }
                })}
              </Select>
              <ErrorTypography>{errtext.WorkAccessData.level}</ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Unit*</StyledTypography>
              <Select
                name="unit"
                IconComponent={KeyboardArrowDownIcon}
                value={WorkAccessData.unit}
                onChange={(e) => handleWorkAccess(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.unit ? "#912018" : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:"none !important"
                  },
                  backgroundColor: Boolean(errtext.WorkAccessData.unit)
                    ? "#FEE4E2"
                    : "",
                }}
              >
                {buildingData.map((itm, index) => {
                  if (WorkAccessData.building == itm.id) {
                    return itm.level.map((sitm) => {
                      if (WorkAccessData.level == sitm.id) {
                        return sitm.building_unit.map((ssitm) => {
                          return (
                            <MenuItem value={ssitm.id}>
                              {ssitm.unit_no}
                            </MenuItem>
                          );
                        });
                      }
                    });
                  }
                })}
              </Select>
              <ErrorTypography>{errtext.WorkAccessData.unit}</ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Access Start Date*</StyledTypography>
              <DatePicker
                format="DD/MM/YYYY"
                slots={{ openPickerIcon: () => <DatePickerIcon /> }}
                name="accessstartdate"
                value={WorkAccessData.accessstartdate || null}
                onChange={(value) => handleWorkAccess(value, "accessstartdate")}
                sx={{
                  ...DatePickerStyle,
                  backgroundColor: Boolean(
                    errtext.WorkAccessData.accessstartdate
                  )
                    ? "#FEE4E2"
                    : "",

                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.accessstartdate
                      ? "#912018"
                      : "#111927",
                  },
                  
                }}
              />
              <ErrorTypography>
                {errtext.WorkAccessData.accessstartdate}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Access End Date*</StyledTypography>
              <DatePicker
                format="DD/MM/YYYY"
                slots={{ openPickerIcon: () => <DatePickerIcon /> }}
                name="accessenddate"
                value={WorkAccessData.accessenddate ||  null}
                onChange={(value) => handleWorkAccess(value, "accessenddate")}
                sx={{
                  ...DatePickerStyle,
                  backgroundColor: Boolean(errtext.WorkAccessData.accessenddate)
                    ? "#FEE4E2"
                    : "",

                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.accessenddate
                      ? "#912018"
                      : "#111927",
                  },
                }}
              />
              <ErrorTypography>
                {errtext.WorkAccessData.accessenddate}
              </ErrorTypography>
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={3}
          bgcolor="#FFFFFF"
          sx={{
            border: "1px solid  #E5E7EB",
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "8px 8px 8px 8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="space-between"
            alignItems="center"
            height="60px"
          >
            <Stack direction="row">
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                Last Updated at{" "}
                {moment(new Date(WorkAccessData?.updated_at)).format("HH:mm A")}
              </StyledSecondaryTypography>
            </Stack>
            <Stack direction="column" justifyContent="end" alignItems="end">
              <Button
                type="button"
                onClick={() => {
                  Continue("update");
                }}
                variant="Standard"
                disabled={getres}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  };

  const Reviewblock = () => {
    return (
      <>
        <SnackbarSucces data={snakbardata} />
        <BackArrow
          onClick={() => navigate("/residents")}
          style={{ marginBottom: "16px" }}
        />
        <Stack
          mb={5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <PrimaryTypography mb={1}>Kraisee Anurak </PrimaryTypography>
            <StyledSecondaryTypography>
              Last Updated:{" "}
              {moment(new Date()).format("DD MMM YYYY , HH:mm").toString()}{" "}
            </StyledSecondaryTypography>
          </Box>
          <Button
            onClick={() => {
              Continue("edit");
            }}
            sx={{
              backgroundColor: "#E7EFF3 !important",
              width: "134px",
              height: "36px",
              padding: "8px 6px 8px 2px",
            }}
          >
            {" "}
            <IconButton sx={{ paddingLeft: "10px" }}>
              {" "}
              <EditIcon width="14px" />
            </IconButton>{" "}
            Edit Details
          </Button>
        </Stack>
        {/* Personal Details */}
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px 8px 0 0"
        >
          <Stack
            direction="row"
            alignItems="center"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <SecondaryTypography>
              {" "}
              Resident Basic Information
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
            }}
          >
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>First Name</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.firstname}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Last Name</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.lastname}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Middle Name</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.middlename}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Date of Birth
              </StyledSecondaryTypography>
              <TypographyDefault>
                {moment(PersonalDetailsData.dob.$d)
                  .format("DD MMM YYYY ")
                  .toString()}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Gender</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.gender == 1
                  ? "Male"
                  : PersonalDetailsData.gender == 2
                  ? "Female"
                  : "Other"}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Nationality</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.nationality}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Identity Number / Passport Number
              </StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.inorpn}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography sx={{ marginBottom: "10px" }}>
                Contact Number
              </StyledSecondaryTypography>
              <TypographyDefault>{PersonalDetailsData.phone}</TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Email Address
              </StyledSecondaryTypography>
              <TypographyDefault>{PersonalDetailsData.email}</TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Address</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.address}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Country</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.country}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>City</StyledSecondaryTypography>
              <TypographyDefault>{PersonalDetailsData.city}</TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Postal Code</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.postalcode}
              </TypographyDefault>
            </Grid>
          </Grid>
        </Box>
        {/* Emergency Contact */}
        <Box
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="0px"
        >
          <Stack
            direction="row"
            alignItems="center"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
            }}
          >
            <SecondaryTypography>Emergency Contact</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
            }}
          >
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>First Name</StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.firstname}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Last Name</StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.lastname}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Email Address
              </StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.email}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Contact Number
              </StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.phone}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Nationality</StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.nationality}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Gender</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.gender == 1
                  ? "Male"
                  : PersonalDetailsData.gender == 2
                  ? "Female"
                  : "Other"}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Relationship
              </StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.relationship}
              </TypographyDefault>
            </Grid>
          </Grid>
        </Box>
        {/* Work & Access Details */}
        <Box component="div" boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)">
          <Stack
            direction="row"
            alignItems="center"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
            }}
          >
            <SecondaryTypography>Work & Access Details</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
            }}
          >
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Employment Type
              </StyledSecondaryTypography>
              <TypographyDefault>
                {WorkAccessData.residenttype == 3 && "Owner"}
                {WorkAccessData.residenttype == 4 && "Sub Owner"}
                {WorkAccessData.residenttype == 5 && "Tenant"}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Department</StyledSecondaryTypography>
              <TypographyDefault>{WorkAccessData.level}</TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Job Role</StyledSecondaryTypography>
              <TypographyDefault>{WorkAccessData.unit}</TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Access Start Date
              </StyledSecondaryTypography>
              <TypographyDefault>
                {moment(WorkAccessData.accessstartdate.$d)
                  .format("DD MMM YYYY ")
                  .toString()}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Access End Date
              </StyledSecondaryTypography>
              <TypographyDefault>
                {Boolean(WorkAccessData.accessenddate) &&
                  moment(WorkAccessData.accessenddate.$d)
                    .format("DD MMM YYYY ")
                    .toString()}
              </TypographyDefault>
            </Grid>
          </Grid>
        </Box>
        {/* Onboarded by */}
        <Box
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="0 0 8px 8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
            }}
          >
            <SecondaryTypography>Onboarded by</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px  ",
            }}
          >
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Name</StyledSecondaryTypography>
              <TypographyDefault>{onboardedby.name}</TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Email</StyledSecondaryTypography>
              <TypographyDefault>{onboardedby.email}</TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Department</StyledSecondaryTypography>
              <TypographyDefault>{onboardedby.department}</TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Job Role</StyledSecondaryTypography>
              <TypographyDefault>{onboardedby.jobrole}</TypographyDefault>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      <Box p={5} sx={{ backgroundColor: "#F3F4F6" }}>
        {blocks.editblock && Editblock()}
        {/* {blocks.reviewblock && Reviewblock()} */}
      </Box>
    </>
  );
};

export default EditResidentDetails;
