import { Chip } from "@mui/material";
import { ReactComponent as DownArrow } from "../Icons/DownArrow.svg";
import { ReactComponent as UpArrow } from "../Icons/UpArrow.svg";

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NameRegex = /^[a-zA-Z,', ]+$/;

export const No_special_regex = /^[A-Za-z0-9 ]+$/;

export const getChip = (value, id) => {
  if (value === "active") {
    return (
      <Chip
        label={value}
        sx={{
          backgroundColor: "#ECFDF3",
          color: "#067647",
          border: "1px solid #ABEFC6",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "14px",
          padding: "2px 8px 2px 8px",
          height: "24px",
          fontWeight: 500,
          "& .MuiChip-label": {
            padding: 0,
          },
        }}
      />
    );
  } else if (value === "inactive") {
    return (
      <Chip
        label={value}
        sx={{
          backgroundColor: "#FFFAEB",
          color: "#B54708",
          border: "1px solid #FEDF89",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "14px",
          padding: "2px 8px 2px 8px",
          height: "24px",
          fontWeight: 500,
          "& .MuiChip-label": {
            padding: 0,
          },
        }}
      />
    );
  } else if (value === "submitted") {
    return (
      <Chip
        label={value}
        sx={{
          backgroundColor: "#EFF8FF",
          color: "#175CD3",
          border: "1px solid #B2DDFF",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: 500,
          padding: "2px 8px 2px 8px",
          height: "24px",
          "& .MuiChip-label": {
            padding: 0,
          },
        }}
      />
    );
  } else if (value === "investigating") {
    return (
      <Chip
        label={value}
        sx={{
          backgroundColor: "#FFFAEB",
          color: "#B54708",
          border: "1px solid #FEDF89",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: 500,
          padding: "2px 8px 2px 8px",
          height: "24px",
          "& .MuiChip-label": {
            padding: 0,
          },
        }}
      />
    );
  } else if (value === "cancelled") {
    return (
      <Chip
        label={value}
        sx={{
          backgroundColor: "#FEF3F2",
          color: "#B42318",
          border: "1px solid #FECDCA",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: 500,
          padding: "2px 8px 2px 8px",
          height: "24px",
          "& .MuiChip-label": {
            padding: 0,
          },
        }}
      />
    );
  } else if (value === "completed" && !id) {
    return (
      <Chip
        label={value}
        sx={{
          backgroundColor: "#ECFDF3",
          color: "#067647",
          border: "1px solid #ABEFC6",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: 500,
          padding: "2px 8px 2px 8px",
          height: "24px",
          "& .MuiChip-label": {
            padding: 0,
          },
        }}
      />
    );
  } else if (value === "completed" && id === 5) {
    return (
      <Chip
        label={value}
        sx={{
          backgroundColor: "#F3F4F6",
          color: "#344054",
          border: "1px solid #F3F4F6",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: 500,
          padding: "2px 8px 2px 8px",
          height: "24px",
          "& .MuiChip-label": {
            padding: 0,
          },
        }}
      />
    );
  } else if (value === "approved") {
    return (
      <Chip
        label={value}
        sx={{
          backgroundColor: "#ECFDF3",
          color: "#067647",
          border: "1px solid #ABEFC6",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: 500,
          padding: "2px 8px 2px 8px",
          height: "24px",
          "& .MuiChip-label": {
            padding: 0,
          },
        }}
      />
    );
  } else if (value === "Uncollected") {
    return (
      <Chip
        label={value}
        sx={{
          backgroundColor: "#FFFAEB",
          color: "#B54708",
          border: "1px solid #FEDF89",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: 500,
          padding: "2px 8px 2px 8px",
          height: "24px",
          "& .MuiChip-label": {
            padding: 0,
          },
        }}
      />
    );
  } else if (value === "Collected") {
    return (
      <Chip
        label={value}
        sx={{
          backgroundColor: "#ECFDF3",
          color: "#067647",
          border: "1px solid #ABEFC6",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: 500,
          padding: "2px 8px 2px 8px",
          height: "24px",
          "& .MuiChip-label": {
            padding: 0,
          },
        }}
      />
    );
  } else if (value === "pending") {
    return (
      <Chip
        label={value}
        sx={{
          backgroundColor: "#EFF8FF",
          color: "#175CD3",
          border: "1px solid #B2DDFF",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: 500,
          padding: "2px 8px 2px 8px",
          height: "24px",
          "& .MuiChip-label": {
            padding: 0,
          },
        }}
      />
    );
  } else if (value === "draft") {
    return (
      <Chip
        label={value}
        sx={{
          backgroundColor: "#FEE4E2",
          color: "#912018",
          border: "1px solid #FEE4E2",
          borderRadius: "6px",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: 500,
          padding: "2px 8px 2px 8px",
          height: "24px",
          "& .MuiChip-label": {
            padding: 0,
          },
        }}
      />
    );
  }
};

export const timeAgo = (timestamp) => {
  const currentDate = new Date();
  const previousDate = new Date(timestamp);
  const timeDifferenceInSeconds = Math.floor(
    (currentDate - previousDate) / 1000
  );
  const intervals = {
    year: 31536000,
    month: 2592000,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  let interval;
  let value;

  for (const key in intervals) {
    interval = Math.floor(timeDifferenceInSeconds / intervals[key]);
    if (interval >= 1) {
      value = interval;
      return value === 1 ? `${value} ${key} ago` : `${value} ${key}s ago`;
    }
  }

  return "just now";
};

export const userData = (value) => {
  const data = JSON.parse(localStorage.getItem("propertyStatus"));
  if (data) {
    return data[value];
  }
};

export const changeUserData = (value, action) => {
  let isvalid = false;
  let data = JSON.parse(localStorage.getItem("propertyStatus"));
  if (data) {
    if (value > data.completed_step) {
      isvalid = true;
    }
    if (action === "change") {
      data.completed_step = value;
      localStorage.setItem("propertyStatus", JSON.stringify(data));
    }
    if (action == "chgstatus") {
      data.status = "draft";
      localStorage.setItem("propertyStatus", JSON.stringify(data));
    }
    if (action == "propertycompleted") {
      data.status = "published";
      localStorage.setItem("propertyStatus", JSON.stringify(data));
    }

    return isvalid;
  }
};
// changeUserData(5, 'chgstatus')
// changeUserData(5, 'change')

export const getLocalStorageData = () => {
  try {
    const data = localStorage.getItem("userDetails");
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error("Error accessing localStorage:", error);
    return null;
  }
};
export const getPropertyStatus = () => {
  try {
    const data = localStorage.getItem("propertyStatus");
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error("Error accessing localStorage:", error);
    return null;
  }
};

// export const getAccess = () => {
//     const data = JSON.parse(localStorage.getItem('Allowed_access'))
//     return data?.['access'] ? data?.['access'] : false
// }

// export const getManage = () => {
//     const data = JSON.parse(localStorage.getItem('Allowed_access'))
//     return data?.['manage'] ? data?.['manage'] : false
// }

export const getGraphChip = (data) => {
  const commonStyles = {
    borderRadius: "6px",
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: 500,
    height: "24px",
    padding: "2px 6px 2px 4px",
    ".MuiChip-icon": {
      marginRight: "6px",
      marginLeft: "0px",
    },
    ".MuiChip-label": {
      padding: 0,
    },
  };

  if (data?.includes("-")) {
    const newData = data?.replace("-", "");
    return (
      <Chip
        label={newData}
        icon={<DownArrow />}
        sx={{
          backgroundColor: "#FEF3F2",
          color: "#B42318",
          border: "1px solid #FECDCA",
          ...commonStyles,
        }}
      />
    );
  } else {
    return (
      <Chip
        label={data}
        icon={<UpArrow />}
        sx={{
          backgroundColor: "#ECFDF3",
          color: "#067647",
          border: "1px solid #ABEFC6",
          ...commonStyles,
        }}
      />
    );
  }
};
