import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import parse from "html-react-parser";
import dayjs from "dayjs";
import { CustomDateRangePicker } from "../../../CommonComponents/CustomDateRange";
import CustomTextEditor from "../../../CommonComponents/CustomTextEditor";
import CustomDateTimeRange from "../../../CommonComponents/CustomDateTimeRange";
import {
  DatePickerStyle,
  ErrorTypography,
  StyledPrimaryDefaultTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryServiceTypography,
  StyledOutlinedInput,
  StyledSecondaryDefaultTypography,
  StyledSecondaryTypography,
  StyledTableCell,
  StyledTableRow,
  StyledTypography,
  TypographyDefault,
} from "../../../Theme";
import {
  Alert,
  Box,
  Divider,
  Tabs,
  Tab,
  Button,
  Checkbox,
  Menu,
  TextField,
  MenuItem,
  Modal,
  Grid,
  InputAdornment,
  OutlinedInput,
  Pagination,
  PaginationItem,
  Select,
  Snackbar,
  SnackbarContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import ImageCourosel from "../../../CommonComponents/ImageCourosel";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as JPGIcon } from "../../../Icons/Jpg.svg";
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteNB.svg";
import { ReactComponent as UploadIcon } from "../../../Icons/UploadIcon.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import { ReactComponent as SideArrow } from "../../../Icons/SideDropDownArrow.svg";
import { ReactComponent as ContactDetailsIcon } from "../../../Icons/ContactDetails.svg";
import { ReactComponent as DatePickerIcon } from "../../../Icons/Datepicker.svg";
import { ReactComponent as BackArrowMobileIcon } from "../../../Icons/BackArrowMobileIcon.svg";
import AlertModal, { PostLoaderModal } from "../../../CommonComponents/modal";
import axiosInstance from "../../../Redux/AxiosInstance";
import { NestedMenuItem } from "mui-nested-menu";
import { DatePicker } from "@mui/x-date-pickers";
import { showToast } from "../../../CommonComponents/Toaster";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const endAdornmentStyles = {
  position: "absolute",
  right: 0,
  top: "-20px",
  fontSize: "12px !important",
  "&>*": { fontSize: "12px !important", fontWeight: "400", color: "#9DA4AE" },
};

export default function CreateEvent() {
  const { LanguageData } = useSelector((state) => state.Language);
  const { Marketing } = LanguageData;
  console.log(Marketing);
  const navigate = useNavigate();
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const [addblocks, setaddblocks] = useState({
    Event_Details: true,
    Review_Information: false,
  });
  const blocks = [
    {
      status: addblocks.Event_Details,
      tle: Marketing?.Event_Details || "Event Details",
    },
    {
      status: addblocks.Review_Information,
      tle: Marketing?.Review_Information || "Review Information",
    },
  ];
  const ref = useRef();
  const [tabValue, settabValue] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setlocation] = useState("");
  const [image, setImage] = useState([]);
  const [terms_conditions, setTerms_conditions] = useState("");
  const [date, setDate] = useState({ startDate: null, endDate: null });
  const [errText, setErrtext] = useState({
    title: false,
    description: false,
    duration: false,
    location: false,
    image: false,
    terms_conditions: false,
    startDate: false,
    endDate: false,
  });

  const [buildingData, setBuildingData] = useState([]);
  const [CommonAreaData, setCommonAreaData] = useState([]);
  const [CommonAreaFilterData, setCommonAreaFilterData] = useState([]);
  const [commonAreaAnchor, setcommonAreaAnchor] = useState(null);
  const [commonAreaOpen, setCommonAreaOpen] = useState(false);
  const [commonAreaId, setcommonAreaId] = useState(null);

  const [checkdescription, setcheckdescription] = useState("");
  const [checkterms_conditions, setcheckterms_conditions] = useState("");
  const [DateTimeRangeStatus, setDateTimeRangeStatus] = useState(false);
  // DateRange

  const [openDateRangePicker, setopenDateRangePicker] = useState(false);
  const [datetimerangedata, setdatetimerangedata] = useState([null, null]);

  const [time, setTime] = useState({ start: "", end: "" });
  const [isDatafilled, setIsDataFilled] = useState(false);
  // const [datetimerangedata, setdatetimerangedata] = useState([])
  const [postLoader, SetPostLoader] = useState(false);

  useEffect(() => {
    // setcheckdescription(parse(description)?.props?.children?.length)
    const descriptionLength = description?.replace(/<[^>]+>/g, "");
    setcheckdescription((descriptionLength?.trim()).length);
    setErrtext((prev) => ({ ...prev, description: false }));
  }, [description]);

  useEffect(() => {
    const terms_conditionLength = terms_conditions?.replace(/<[^>]+>/g, "");
    setcheckterms_conditions((terms_conditionLength?.trim()).length);
    // setcheckterms_conditions(parse(terms_conditions)?.props?.children?.length)
    setErrtext((prev) => ({ ...prev, terms_conditions: false }));
  }, [terms_conditions]);

  const inputImg = useRef();
  const handleInputImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage([{ file: file, src: URL.createObjectURL(file) }]);
      setErrtext((prev) => ({ ...prev, image: false }));
      e.target.value = null;
    }
  };

  const handleImage = () => {
    inputImg.current.click();
  };

  const handleDropImage = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      if (image?.length < 5) {
        setImage([{ file: file, src: URL.createObjectURL(file) }]);
        setErrtext((prev) => ({ ...prev, image: false }));
        // seterrtextFeatureImages((prev) => ({ ...prev, FacilityImages: false }))
      }
    }
  };
  const handleDragImage = (e) => {
    e.preventDefault();
  };

  const valid = () => {
    let isError = false;
    if (title == "") {
      setErrtext((prev) => ({ ...prev, title: true }));
      isError = true;
    }
    if (checkdescription == undefined) {
      setErrtext((prev) => ({ ...prev, description: true }));
      isError = true;
    }
    if (!DateTimeRangeStatus) {
      setErrtext((prev) => ({ ...prev, duration: true }));
      isError = true;
    }
    if (image?.length == 0) {
      setErrtext((prev) => ({ ...prev, image: true }));
      isError = true;
    }
    if (location == "") {
      setErrtext((prev) => ({ ...prev, location: true }));
      isError = true;
    }
    if (checkterms_conditions == 0) {
      setErrtext((prev) => ({ ...prev, terms_conditions: true }));
      isError = true;
    }
    if (date?.startDate == null) {
      setErrtext((prev) => ({ ...prev, startDate: true }));
      isError = true;
    }
    if (date?.endDate == null) {
      setErrtext((prev) => ({ ...prev, endDate: true }));
      isError = true;
    }
    return isError;
  };

  const Continue = (nxtval) => {
    if (!valid()) {
      if (nxtval == (Marketing?.Event_Details || "Event Details")) {
        setaddblocks({ Event_Details: true, Review_Information: false });
        setIsDataFilled(true);
      } else if (
        nxtval == (Marketing?.Review_Information || "Review Information")
      ) {
        setaddblocks({ Event_Details: false, Review_Information: true });
        setIsDataFilled(true);
        // setdescription_HtmlContent(description);
        // setterms_conditions_HtmlContent(terms_conditions)
      }
    } else {
      setIsDataFilled(false);
    }
  };

  const handleTabValue = (e, value) => {
    settabValue(value);
  };
  const CustomTabPanel = ({ value, index, children }) => {
    return <>{index == value && <Box>{children}</Box>}</>;
  };

  const handleConfirm = async () => {
    const checkStartDate =
      moment(date?.startDate?.$d).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const StartDateToday = moment(new Date(), "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const checkEndDate =
      moment(date?.endDate?.$d).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const EndDateToday = moment(new Date(), "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const AnnouncemntStart = date?.startDate?.$d;
    const final = moment(AnnouncemntStart).hour(0).minute(0).second(0);
    const final1 = moment.utc(final).format("YYYY-MM-DD HH:mm:ss");
    const AnnouncemntEnd = date?.endDate?.$d;
    const finalEnd = moment(AnnouncemntEnd).hour(0).minute(0).second(0);
    const finalEnd1 = moment.utc(finalEnd).format("YYYY-MM-DD HH:mm:ss");
    const dateStartRange = moment(datetimerangedata[0]?.$d).format(
      "YYYY-MM-DD"
    );
    const StartTime = time?.start;
    const combinedDateTime = new Date(
      new Date(dateStartRange).setHours(
        StartTime.slice(0, 2),
        StartTime.slice(3, 5),
        StartTime.slice(6, 8)
      )
    );
    const combinedDateTimeUTC = moment.utc(combinedDateTime);
    const dateEndRange = moment(datetimerangedata[1]?.$d).format("YYYY-MM-DD");
    const EndTime = time?.end;
    const combinedDateTimeEnd = new Date(
      new Date(dateEndRange).setHours(
        EndTime.slice(0, 2),
        EndTime.slice(3, 5),
        EndTime.slice(6, 8)
      )
    );
    const combinedDateTimeUTCEnd = moment.utc(combinedDateTimeEnd);
    const data = new FormData();
    data.append("marketing_type_id", 1);
    data.append("common_area_id", location);
    data.append("title", title);
    data.append("description", description);
    data.append("terms_condition", terms_conditions);
    data.append("image_path[]", image[0].file);
    if (datetimerangedata[0] && datetimerangedata[1]) {
      data.append(
        "duration_start_date",
        moment(combinedDateTimeUTC).format("YYYY-MM-DD")
      );
      data.append(
        "duration_end_date",
        moment(combinedDateTimeUTCEnd).format("YYYY-MM-DD")
      );
      data.append(
        "duration_from_time",
        moment(combinedDateTimeUTC).format("HH:mm:ss")
      );
      data.append(
        "duration_end_time",
        moment(combinedDateTimeUTCEnd).format("HH:mm:ss")
      );
    }
    data.append("start_date", checkStartDate ? StartDateToday : final1);
    data.append("end_date", checkEndDate ? EndDateToday : finalEnd1);

    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/marketing`,
        data
      );
      navigate("/Marketing");
      SetPostLoader(false);
      showToast(response?.data?.toast);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDraft = async () => {
    const checkStartDate =
      moment(date?.startDate?.$d).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const StartDateToday = moment(new Date(), "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const checkEndDate =
      moment(date?.endDate?.$d).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const EndDateToday = moment(new Date(), "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const AnnouncemntStart = date?.startDate?.$d;
    const final = moment(AnnouncemntStart).hour(0).minute(0).second(0);
    const final1 = moment.utc(final).format("YYYY-MM-DD HH:mm:ss");
    const AnnouncemntEnd = date?.endDate?.$d;
    const finalEnd = moment(AnnouncemntEnd).hour(0).minute(0).second(0);
    const finalEnd1 = moment.utc(finalEnd).format("YYYY-MM-DD HH:mm:ss");

    const dateStartRange = moment(datetimerangedata[0]?.$d).format(
      "YYYY-MM-DD"
    );
    const StartTime = time?.start;
    const combinedDateTime = new Date(
      new Date(dateStartRange).setHours(
        StartTime.slice(0, 2),
        StartTime.slice(3, 5),
        StartTime.slice(6, 8)
      )
    );
    const combinedDateTimeUTC = moment.utc(combinedDateTime);
    const dateEndRange = moment(datetimerangedata[1]?.$d).format("YYYY-MM-DD");
    const EndTime = time?.end;
    const combinedDateTimeEnd = new Date(
      new Date(dateEndRange).setHours(
        EndTime.slice(0, 2),
        EndTime.slice(3, 5),
        EndTime.slice(6, 8)
      )
    );
    const combinedDateTimeUTCEnd = moment.utc(combinedDateTimeEnd);
    const data = new FormData();
    data.append("marketing_type_id", 1);
    data.append("is_draft", 1);
    {
      Boolean(location) && data.append("common_area_id", location);
    }
    {
      Boolean(title) && data.append("title", title);
    }
    {
      !(checkdescription?.length <= 1) &&
        data.append("description", description);
    }
    {
      !(checkterms_conditions?.length <= 1) &&
        data.append("terms_condition", terms_conditions);
    }
    {
      !(image?.length == 0) && data.append("image_path[]", image[0].file);
    }
    {
      DateTimeRangeStatus &&
        data.append(
          "duration_start_date",
          moment(combinedDateTimeUTC).format("YYYY-MM-DD")
        );
      data.append(
        "duration_end_date",
        moment(combinedDateTimeUTCEnd).format("YYYY-MM-DD")
      );
      data.append(
        "duration_from_time",
        moment(combinedDateTimeUTC).format("HH:mm:ss")
      );
      data.append(
        "duration_end_time",
        moment(combinedDateTimeUTCEnd).format("HH:mm:ss")
      );
    }

    {
      date?.startDate !== null &&
        data.append("start_date", checkStartDate ? StartDateToday : final1);
    }
    {
      date?.endDate !== null &&
        data.append("end_date", checkEndDate ? EndDateToday : finalEnd1);
    }
    // { DateTimeRangeStatus && data.append('duration_from_time', time?.start) }
    // { DateTimeRangeStatus && data.append('duration_end_time', time?.end) }

    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/marketing`,
        data
      );
      navigate("/Marketing");
      SetPostLoader(false);
      showToast(response?.data?.toast);
    } catch (error) {
      console.log(error);
    }
  };

  function CommonAreaEnter(e) {
    setcommonAreaAnchor(e.currentTarget);
    setCommonAreaOpen(true);
  }
  function CommonAreaLeave() {
    setcommonAreaAnchor(null);
    setCommonAreaOpen(false);
  }

  useEffect(() => {
    const getBuildingData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/building`
        );
        const BuildingData = response.data.data.property_buildings;
        setBuildingData(BuildingData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getBuildingData();

    const getCommonAreaData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/commonarea`
        );
        const CommonAreaData = response.data.data.common_areas;
        setCommonAreaData(CommonAreaData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getCommonAreaData();

    const getCommonAreaFilterData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/commonarea?is_part_of_building=0`
        );
        const CommonAreaData = response.data.data.common_areas;
        setCommonAreaFilterData(CommonAreaData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getCommonAreaFilterData();
  }, []);

  console.log(date);

  const Event_Details = () => {
    return (
      <>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px 8px 0 0"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {Marketing?.Event_Details || "Event Details"}{" "}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12}>
              <StyledTypography>
                {LanguageData?.Title || "Title"}
              </StyledTypography>
              <TextField
                sx={{
                  "& .MuiInputBase-root": {
                    color: errText?.title ? "#912018" : "#111927",
                  },
                  backgroundColor: errText?.title ? "#FEE4E2" : " #F9FAFB",
                }}
                name="title"
                placeholder={LanguageData?.Title_Placeholder || "Enter title"}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setErrtext((prev) => ({ ...prev, title: false }));
                }}
                inputProps={{ maxLength: 50 }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment sx={endAdornmentStyles}>
                      {title?.length}/50
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

            <Grid xs={12} mt={3}>
              <StyledTypography>
                {LanguageData?.Description || "Description"}
              </StyledTypography>
              <Box mt={2} sx={{ position: "relative" }}>
                <CustomTextEditor
                  textEditorvalue={description}
                  settextEditorvalue={setDescription}
                  label={"Write description"}
                  textEditorErr={errText?.description}
                />
              </Box>
              {/* <StyledSecondaryServiceTypography mt={1.5} sx={{ display: 'flex', justifyContent: 'end', fontSize: '12px !important', color: "#9DA4AE !important" }}></StyledSecondaryServiceTypography> */}
            </Grid>
          </Grid>

          {/* Duration */}

          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {LanguageData?.Duration || "Duration"}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12}>
              <StyledTypography mb={1.4}>
                {LanguageData?.Date_Time_Range || "Date & Time Range"}
              </StyledTypography>
              <CustomDateTimeRange
                datetimerangedata={datetimerangedata}
                setdatetimerangedata={setdatetimerangedata}
                time={time}
                setTime={setTime}
                isStatus={DateTimeRangeStatus}
                setIsStatus={setDateTimeRangeStatus}
                errDateRange={errText?.duration}
                seterrDateRange={setErrtext}
              />
            </Grid>
          </Grid>

          {/* Location */}

          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {LanguageData?.Location || "Location"}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12} sm={5.9}>
              <StyledTypography>
                {LanguageData?.Location || "Location"}
              </StyledTypography>
              <Select
                name="location"
                IconComponent={KeyboardArrowDownIcon}
                value={location}
                onChange={(e) => {
                  setlocation(e.target.value);
                  setErrtext((prev) => ({ ...prev, location: false }));
                }}
                sx={{
                  color: Boolean(location)
                    ? ""
                    : errText?.location
                    ? "#912018"
                    : "#9DA4AE",
                  backgroundColor: errText?.location ? "#FEE4E2" : " #F9FAFB",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:"none !important"
                  },
                }}
                displayEmpty
                renderValue={
                  location !== ""
                    ? undefined
                    : () =>
                        LanguageData?.Location_Placeholder || "Select Location"
                }
                open={commonAreaOpen}
                onOpen={(e) => CommonAreaEnter(e)}
                onClose={() => CommonAreaLeave()}
              >
                {/* <MenuItem value='1'>1</MenuItem> */}

                {/* {buildingData?.map((itm, index) => {
                                    return <MenuItem onClick={() => setcommonAreaId(null)} key={index} value={itm.id}>{itm.building_name}</MenuItem>
                                })} */}

                {CommonAreaData?.map((itm) => (
                  <MenuItem value={itm?.id} sx={{ display: "none" }}>
                    {itm?.common_area_name}
                  </MenuItem>
                ))}
                {buildingData?.map(
                  (item) =>
                    item?.common_area?.length > 0 && (
                      <NestedMenuItem
                        label={item?.building_name}
                        parentMenuOpen={commonAreaOpen}
                        disabled
                      >
                        {item?.common_area?.map((itm) => (
                          <MenuItem
                            data-value={itm?.id}
                            onClick={(e) => {
                              setlocation(e.currentTarget.dataset.value);
                              setErrtext((prev) => ({
                                ...prev,
                                location: false,
                              }));
                              setcommonAreaId(itm?.id);
                              CommonAreaLeave();
                            }}
                          >
                            {itm?.common_area_name}
                          </MenuItem>
                        ))}
                      </NestedMenuItem>
                    )
                )}

                {CommonAreaFilterData?.length >= 1 && (
                  <NestedMenuItem
                    label={"Common Area"}
                    parentMenuOpen={commonAreaOpen}
                    disabled
                  >
                    {CommonAreaFilterData?.map((itm) => (
                      <MenuItem
                        data-value={itm?.id}
                        onClick={(e) => {
                          setlocation(e.currentTarget.dataset.value);
                          setErrtext((prev) => ({ ...prev, location: false }));
                          setcommonAreaId(itm?.id);
                          CommonAreaLeave();
                        }}
                      >
                        {itm?.common_area_name}
                      </MenuItem>
                    ))}
                  </NestedMenuItem>
                )}
              </Select>
            </Grid>
          </Grid>

          {/* Image */}

          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {LanguageData?.Image || "Image"}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12}>
              {image?.length < 1 && (
                <Stack
                  onDragOver={handleDragImage}
                  onDrop={handleDropImage}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    padding: "24px 16px 24px 16px",
                    border: errText.image
                      ? "1px solid #912018"
                      : "1px solid #EAECF0",
                    borderRadius: "12px",
                    backgroundColor: errText.image ? "#FEE4E2" : "",
                    cursor: "pointer",
                  }}
                >
                  <Stack
                    flex={1}
                    direction="column"
                    alignItems="center"
                    textAlign="center"
                  >
                    {errText.image && (
                      <ErrorTypography mb={2}>
                        {Marketing?.Event_Image_ErrText ||
                          "Please upload event image"}
                      </ErrorTypography>
                    )}

                    <UploadIcon onClick={handleImage} />
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png,.svg,.gif"
                      ref={inputImg}
                      onChange={(e) => handleInputImage(e)}
                      hidden
                    />
                    <Stack onClick={handleImage} my="5px" direction="row">
                      <Typography
                        marginRight="5px"
                        sx={{
                          fontSize: "14px !important",
                          fontWeight: "600 !important",
                          color: "#0E4965 !important",
                        }}
                      >
                        {LanguageData?.Click || " Click to upload"}
                      </Typography>
                      <StyledSecondaryTypography sx={{ color: "#4D5761" }}>
                        {LanguageData?.Drag || "or drag and drop"}
                      </StyledSecondaryTypography>
                    </Stack>
                    <StyledSecondaryTypography sx={{ color: "#4D5761" }}>
                      {Marketing?.Image_Types ||
                        "Types: JEPG, PNG (1MB max file size) (Max 1 file)"}
                    </StyledSecondaryTypography>
                    <StyledSecondaryServiceTypography
                      mt={1.5}
                      sx={{ fontSize: "12px !important" }}
                    >
                      {" "}
                      {Marketing?.Image_Dimension ||
                        "Dimension: 375px (w) x 211px (h)"}
                    </StyledSecondaryServiceTypography>
                  </Stack>
                </Stack>
              )}
              {image?.map((itm, index) => {
                const { name, size } = itm.file;
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px solid #EAECF0",
                      borderRadius: "12px",
                      p: 2,
                      mt: 2,
                    }}
                  >
                    <Stack direction="row">
                      <JPGIcon style={{ marginRight: "12px" }} />
                      <Stack direction="column">
                        <StyledSecondaryTypography>
                          {name}
                        </StyledSecondaryTypography>
                        <StyledSecondaryTypography>
                          {(size / 1048576).toFixed(2)} MB
                        </StyledSecondaryTypography>
                      </Stack>
                    </Stack>
                    <DeleteIcon
                      onClick={() => {
                        setImage([]);
                      }}
                      width={40}
                      style={{ cursor: "pointer" }}
                    />
                  </Box>
                );
              })}
            </Grid>
          </Grid>

          {/* Terms & Conditions */}

          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {LanguageData?.Terms_Conditions || "Terms & Conditions"}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12} mt={1.5}>
              <Box sx={{ position: "relative" }}>
                <CustomTextEditor
                  textEditorvalue={terms_conditions}
                  settextEditorvalue={setTerms_conditions}
                  label={
                    LanguageData?.Marketing
                      ?.Event_Terms_Conditions_Placeholder ||
                    "Enter the Terms and Conditions for the event"
                  }
                  textEditorErr={errText?.terms_conditions}
                />
              </Box>
            </Grid>
          </Grid>

          {/* Publish Date */}

          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {LanguageData?.Publish_Date || "Publish Date"}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12} sm={5.8}>
              <StyledTypography>
                {LanguageData?.Start_Date || "Start Date"}
              </StyledTypography>
              <DatePicker
                disablePast
                value={date?.startDate|| null}
                slotProps={{
                  textField: {
                    placeholder:
                      LanguageData?.Start_date_Placeholder ||
                      "Select start date",
                  },
                }}
                onChange={(e) => {
                  setDate((prev) => ({ endDate: null, startDate: e }));
                  setErrtext((prev) => ({ ...prev, startDate: false }));
                }}
                format="DD/MM/YYYY"
                slots={{
                  openPickerIcon: () => (
                    <DatePickerIcon
                      stroke={errText?.startDate ? "#ea6c6c" : ""}
                    />
                  ),
                }}
                sx={{
                  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
                    color: errText?.startDate ? "#912018 !important" : "",
                  },
                  backgroundColor: errText?.startDate ? "#FEE4E2" : "",
                  paddingRight: "20px",
                  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:before": {
                    borderBottom: "none",
                  },
                  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:after": {
                    borderBottom: "none",
                  },
                  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:hover": {
                    borderBottom: "none",
                  },
                  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before":
                    { borderBottom: "none" },
                }}
              />
            </Grid>
            <Grid xs={12} sm={5.8}>
              <StyledTypography>
                {LanguageData?.End_date || "End Date"}
              </StyledTypography>
              <DatePicker
                minDate={date?.startDate || null}
                value={date?.endDate || null}
                slotProps={{
                  textField: {
                    placeholder:
                      LanguageData?.End_date_Placeholder || "Select end date",
                  },
                }}
                onChange={(e) => {
                  setDate((prev) => ({ ...prev, endDate: e }));
                  setErrtext((prev) => ({ ...prev, endDate: false }));
                }}
                format="DD/MM/YYYY"
                slots={{
                  openPickerIcon: () => (
                    <DatePickerIcon
                      stroke={errText?.endDate ? "#ea6c6c" : ""}
                    />
                  ),
                }}
                sx={{
                  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
                    color: errText?.endDate ? "#912018 !important" : "",
                  },
                  backgroundColor: errText?.endDate ? "#FEE4E2" : "",
                  paddingRight: "20px",
                  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:before": {
                    borderBottom: "none",
                  },
                  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:after": {
                    borderBottom: "none",
                  },
                  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:hover": {
                    borderBottom: "none",
                  },
                  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before":
                    { borderBottom: "none" },
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box
          bgcolor="#FFFFFF"
          sx={{
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="space-between"
            alignItems="center"
            height="60px"
          >
            <Stack direction="row" alignItems="center">
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                {LanguageData?.Common_last_Update || " Last Updated at"}{" "}
                {moment(new Date()).format("HH:mm A")}
              </StyledSecondaryTypography>
            </Stack>
            <Button
              onClick={() =>
                Continue(Marketing?.Review_Information || "Review Information")
              }
              variant="Standard"
            >
              {LanguageData?.Common_Continue || "Continue"}
            </Button>
          </Stack>
        </Box>
      </>
    );
  };

  const Review_Information = () => {
    return (
      <>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px 8px 0 0"
          width="100%"
        >
          <Box
            sx={{
              borderBottom: "1px solid #E5E7EB",
              backgroundColor: "#FFFFFF",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <Tabs
              sx={{ width: "100%" }}
              value={tabValue}
              onChange={handleTabValue}
            >
              <Tab
                sx={{ width: "50%", maxWidth: "none" }}
                label={LanguageData?.Details || "Details"}
              />
              <Tab
                sx={{ width: "50%", maxWidth: "none" }}
                label={LanguageData?.Preview || "Preview"}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {LanguageData?.Publish_Date || "Publish Date"}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
            >
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.To_Post_On || "To Post On"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={0.5}>
                  {moment(date?.startDate?.$d).format("DD MMM YYYY")}
                </TypographyDefault>
              </Grid>
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.End_Post || "End Post"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={0.5}>
                  {moment(date?.endDate?.$d).format("DD MMM YYYY")}
                </TypographyDefault>
              </Grid>
            </Grid>

            {/* Event Details  */}

            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {Marketing?.Event_Details || "Event Details"}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
            >
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Title || "Title"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={0.5}>{title}</TypographyDefault>
              </Grid>
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Event_Duration || "Event Duration"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={0.5}>
                  {moment(datetimerangedata[0]?.$d).format("DD MMM YYYY")},{" "}
                  {time?.start} -{" "}
                  {moment(datetimerangedata[1]?.$d).format("DD MMM YYYY")},{" "}
                  {time?.end}
                </TypographyDefault>
              </Grid>
              <Divider
                sx={{
                  borderTop: "1px solid #F3F4F6",
                  width: "100%",
                  marginTop: "10px",
                }}
              />
              <Grid xs={12} my={2}>
                <StyledSecondaryTypography mb={0.5}>
                  {LanguageData?.Description || "Description"}
                </StyledSecondaryTypography>
                <TypographyDefault
                  mt={0.5}
                  sx={{
                    wordWrap: "break-word",
                    "&>p": {
                      margin: "0px !important",
                      padding: "0px !important",
                    },
                  }}
                >
                  {parse(description)}
                </TypographyDefault>
              </Grid>
            </Grid>

            {/* Location */}

            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {LanguageData?.Location || "Location"}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
            >
              <Grid xs={12} sm={5.8}>
                <StyledSecondaryTypography>
                  {LanguageData?.Location || "Location"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={0.5}>
                  {Boolean(commonAreaId)
                    ? CommonAreaData.find((itm) => itm?.id == location)
                        .common_area_name
                    : buildingData.find((itm) => itm?.id == location)
                        .building_name}
                </TypographyDefault>
              </Grid>
            </Grid>

            {/* IMages */}

            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {LanguageData?.Image || "Image"}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
            >
              <Grid xs={12}>
                <Stack padding="20px 16px 20px 16px">
                  <img
                    src={image[0]?.src}
                    width="120px"
                    height="120px"
                    style={{ borderRadius: "10px", objectFit: "cover" }}
                  />
                </Stack>
              </Grid>
            </Grid>

            {/* Terms & Condition */}

            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {LanguageData?.Terms_Conditions || "Terms Conditions"}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "20px 24px 30px 24px" }}
            >
              <Grid xs={12}>
                <Stack
                  direction="column"
                  sx={{
                    wordWrap: "break-word",
                    "&>p": {
                      margin: "0px !important",
                      padding: "0px !important",
                    },
                    "& li": { padding: "5px !important" },
                  }}
                >
                  <TypographyDefault>
                    {parse(terms_conditions)}
                  </TypographyDefault>
                </Stack>
              </Grid>
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <Stack
              py={1}
              direction="column"
              alignItems="center"
              justifyContent="center"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#F9FAFB",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <StyledPrimaryDefaultTypography>
                {LanguageData?.Mobile_Preview || "Mobile App Preview"}{" "}
              </StyledPrimaryDefaultTypography>
              <StyledSecondaryTypography>
                {LanguageData?.Scroll_mobile ||
                  "Scroll on the mobile phone to review details"}
              </StyledSecondaryTypography>
            </Stack>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                my={6.5}
                sx={{
                  width: "375px",
                  height: "750px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "8px",
                  overflowY: "scroll",
                  "::-webkit-scrollbar": { display: "none" },
                }}
              >
                <Stack sx={{ padding: "32px 16px 32px 16px" }}>
                  <BackArrowMobileIcon />
                  <Typography mt={5} sx={{ fontSize: "24px !important" }}>
                    {title}
                  </Typography>
                  <StyledPrimaryDefaultTypography mt={2}>
                    {Boolean(commonAreaId)
                      ? CommonAreaData.find((itm) => itm?.id == location)
                          .common_area_name
                      : buildingData.find((itm) => itm?.id == location)
                          .building_name}
                  </StyledPrimaryDefaultTypography>
                </Stack>
                <img
                  src={image[0]?.src}
                  width="100%"
                  height="211px"
                  style={{ objectFit: "cover" }}
                />
                <Box padding="24px 16px 24px 16px">
                  {/* <ImageCourosel images={FacilityImages} /> */}
                  {/* <img src="https://images.pexels.com/photos/380768/pexels-photo-380768.jpeg?auto=compress&cs=tinysrgb&w=600" width="100%" /> */}
                  <SecondaryTypography>
                    {moment(datetimerangedata[0]?.$d).format("DD MMM YYYY")} -{" "}
                    {moment(datetimerangedata[1]?.$d).format("DD MMM YYYY")}{" "}
                  </SecondaryTypography>
                  <StyledSecondaryDefaultTypography>
                    {time?.start} - {time?.end}
                  </StyledSecondaryDefaultTypography>
                </Box>
                <Box height="10px" backgroundColor="#F9FAFB">
                  {" "}
                </Box>
                <Stack gap={2.5} sx={{ padding: "32px 16px 32px 16px" }}>
                  <Stack gap="4px">
                    <SecondaryTypography>
                      {LanguageData?.Details || "Details"}
                    </SecondaryTypography>
                    <TypographyDefault
                      sx={{
                        "&>p": {
                          margin: "0px !important",
                          padding: "0px !important",
                        },
                        "& li": { padding: "5px !important" },
                      }}
                    >
                      {parse(description)}{" "}
                    </TypographyDefault>
                  </Stack>
                </Stack>
              </Box>
            </Box>
          </CustomTabPanel>
        </Box>

        <Box
          bgcolor="#FFFFFF"
          sx={{
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="space-between"
            alignItems="center"
            height="60px"
          >
            <Stack direction="row">
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                {LanguageData?.Common_last_Update || "Last Updated at"}{" "}
                {moment(new Date()).format("HH:mm A")}
              </StyledSecondaryTypography>
            </Stack>
            <Button
              onClick={() => {
                handleConfirm();
                SetPostLoader(true);
              }}
              variant="Standard"
            >
              {LanguageData?.Common_CreateNow || "Create Now"}
            </Button>
          </Stack>
        </Box>
      </>
    );
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      <Box p={5} sx={{ backgroundColor: "#F3F4F6" }}>
        <Stack direction="row" justifyContent="space-between">
          <BackArrow
            onClick={() => {
              navigate("/Marketing");
            }}
            style={{ marginBottom: "30px", cursor: "pointer" }}
          />
          {/* <BorderAlertCloseIcon width='40' height='40' /> */}
        </Stack>
        <Stack
          mb={5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <PrimaryTypography mb={1}>
              {Marketing?.Create_Event_Title || "Create Event"}
            </PrimaryTypography>
            <StyledSecondaryTypography>
              {" "}
              {Marketing?.Create_Event_Subtitle ||
                "Enter the details of the event."}
            </StyledSecondaryTypography>
          </Box>
          <Button
            onClick={() => {
              handleDraft();
              SetPostLoader(true);
            }}
            disabled={title == "" ? true : false}
            variant="Standard"
          >
            {LanguageData?.Save_Draft || "Save Draft"}
          </Button>
        </Stack>
        <Grid container gap="1.5%">
          {blocks.map((itm) => {
            return (
              <Grid
                xs={5.91}
                onClick={() => Continue(itm.tle, "")}
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                <Box
                  component="div"
                  sx={{
                    borderTop: itm.status
                      ? "3px solid #0E4965"
                      : "3px solid #D2D6DB",
                    width: "100%",
                    color: itm.status ? "#0E4965" : "#9DA4AE",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "12px",
                      padding: "10px 0 0 0",
                    }}
                  >
                    {itm.tle}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        {addblocks.Event_Details && Event_Details()}
        {addblocks.Review_Information && Review_Information()}
      </Box>
    </>
  );
}
