import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  SvgIcon,
  Tabs,
  Tab,
  Menu,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  PrimaryTypography,
  StyledSecondaryServiceTypography,
  TypoGraph,
  TypographyDefault,
} from "../../Theme";
import { ReactComponent as DownloadIcon } from "../../Icons/DownloadIcon.svg";
import { ReactComponent as InfoIcon } from "../../Icons/OverViewInfo.svg";
import { ReactComponent as DropArrowIcon } from "../../Icons/SelectDropdownArrow.svg";
import { ReactComponent as HeatMapIconFirst } from "../../Icons/HeatMapOne.svg";
import { ReactComponent as HeatMapIconTwo } from "../../Icons/HeatMapTwo.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import { ReactComponent as HeatMapIconThree } from "../../Icons/HeatMapThree.svg";
import { ReactComponent as HeatMapIconFour } from "../../Icons/HeatMapFour.svg";
import { ReactComponent as HeatMapIconFive } from "../../Icons/HeatMapFive.svg";
import { ReactComponent as HeatMapIconsix } from "../../Icons/HeatMapSix.svg";
import { ReactComponent as HeatMapIconSeven } from "../../Icons/HeatMapSeven.svg";
import { ReactComponent as ExclamatoryIcon } from "../../Icons/GraphExclamatoryIcon.svg";
import { ReactComponent as DownArrow } from "../../Icons/DownArrow.svg";
import { ReactComponent as UpArrow } from "../../Icons/UpArrow.svg";
import "./Overview.css";
import ManagementGraph from "./ManagementGraph";
import axiosInstance from "../../Redux/AxiosInstance";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import FaultReportGraph from "./FaultReportGraph";
import ServiceRequestGraph from "./ServiceRequestGraph";
import FacilityBookingsGraph from "./FacilityBookingGraph";
import ParcelCollectionGraph from "./ParcelCollectionGraph";
import { getGraphChip } from "../../Utils";
import Chart from "react-apexcharts";
import { getOverviewDatas } from "../../Redux/Feature/OverView/OverviewSlice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { showToast } from "../../CommonComponents/Toaster";
import {
  setpropertySetupLoader,
  getGeneralDetailsByProperty,
} from "../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";

const Overview = () => {
  const [tabValue, setTabValue] = useState(0);
  const { OverviewStatus } = useSelector((state) => state.OverView);
  const { LanguageData } = useSelector((state) => state.Language);
  const [userProfileDetails, setUserProfileDetails] = useState([]);
  let { generalDetailsList } = useSelector((state) => state.PropertyDetails);
  const [countDeatils, setCountDetails] = useState({
    Residents: "",
    invitedVisitors: "",
    PropertyStaff: "",
    FaultsReported: "",
    ServiceRequested: "",
    FacilitiesBooked: "",
  });
  const dispatch = useDispatch();
  const [yearSorting, setYearSorting] = useState("");
  const [yearArray, setYearArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const [facilityList, setFacilityList] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState({
    id: 0,
    title: "",
  });
  const [heatMapData, setHeatMapData] = useState([]);
  const [selectedHeatMapData, setSelectedHeatMapData] = useState({});
  const [dateSorting, setDateSorting] = useState("Current Week");
  const [heatMapLoading, setHeatMapLoading] = useState(false);
  const [percentageChange, setPercentageChange] = useState({
    faultReportChange: "",
    serviceRequestChange: "",
    facilitiesChange: "",
    inviteVisitorChange: "",
  });
  const [loading, setLoading] = useState(false);
  const [buildingName, setBuildingName] = useState("");
  console.log(buildingName);

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#262D33",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#262D33",
      fontSize: "12px",
      color: "#F3F4F6",
      fontWeight: 400,
      borderRadius: "8px 8px 5px 8px",
      padding: "12px",
    },
  }));

  useEffect(() => {
    dispatch(setpropertySetupLoader(false));
  }, []);

  useEffect(() => {
    if (generalDetailsList.success) {
      let data = generalDetailsList.data.property;
      setBuildingName(data?.title);
    }
  }, [generalDetailsList]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const previousYears = [currentYear - 2, currentYear - 1];
    const yearsArray = [...previousYears, currentYear];

    setYearSorting(currentYear);
    setYearArray(yearsArray);

    const apiPromises = [];

    const userDetailsPromise = axiosInstance
      .get(`${process.env.REACT_APP_API_URL}user_details`)
      .then((res) => {
        setUserProfileDetails(res.data.data?.user_details);
      })
      .catch((err) => console.error(err));

    apiPromises.push(userDetailsPromise);

    const dashboardPromise = axiosInstance
      .get(`${process.env.REACT_APP_API_URL}property/${propertyId}/dashboard`)
      .then((res) => {
        setCountDetails({
          Residents: res.data?.data?.dashboard?.resident_count,
          invitedVisitors: res.data?.data?.dashboard?.invite_visitor_count,
          PropertyStaff: res.data?.data?.dashboard?.staff_count,
          FaultsReported: res.data?.data?.dashboard?.fault_report_count,
          ServiceRequested: res.data?.data?.dashboard?.service_request_count,
          FacilitiesBooked: res.data?.data?.dashboard?.facility_booking_count,
        });
        setPercentageChange({
          facilitiesChange:
            res.data?.data?.dashboard?.percentage_change
              ?.facility_booking_count,
          faultReportChange:
            res.data?.data?.dashboard?.percentage_change?.fault_report_count,
          inviteVisitorChange:
            res.data?.data?.dashboard?.percentage_change?.invite_visitor_count,
          serviceRequestChange:
            res.data?.data?.dashboard?.percentage_change?.service_request_count,
        });
      })
      .catch((err) => console.error(err));

    apiPromises.push(dashboardPromise);

    Promise.all(apiPromises)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (OverviewStatus) {
      showToast("Something went wrong! Please try again later.", "error");
    }
  }, [OverviewStatus]);

  useEffect(() => {
    if (yearSorting) {
      dispatch(getOverviewDatas({ propertyId, yearSorting }));
    }
  }, [yearSorting]);

  useEffect(() => {
    dispatch(getGeneralDetailsByProperty(propertyId));
  }, []);

  useEffect(() => {
    setHeatMapLoading(true);
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}facility/property/${propertyId}`)
      .then(async (res) => {
        const result = await res.data?.data?.facility;
        if (result && result.length > 0) {
          const SelectedFacility = result[0];
          setSelectedFacility({
            id: SelectedFacility?.id,
            title: SelectedFacility?.title,
          });
          setFacilityList(result);
        }
        let apiUrl = `${process.env.REACT_APP_API_URL}property/${propertyId}/dashboard_facility_overview`;

        if (dateSorting === "Current Week") {
          apiUrl = `${process.env.REACT_APP_API_URL}property/${propertyId}/dashboard_facility_overview`;
        } else if (dateSorting === "Last Week") {
          apiUrl += "?week=last";
        } else if (dateSorting === "Last Month") {
          apiUrl += "?months=1";
        } else if (dateSorting === "Last Year") {
          const previousYear = new Date().getFullYear() - 1;
          apiUrl += `?year=${previousYear}`;
        } else if (dateSorting === "Last 3 Months") {
          apiUrl += "?months=3";
        } else if (dateSorting === "Last 6 Months") {
          apiUrl += "?months=6";
        }

        axiosInstance
          .get(apiUrl)
          .then((res) => {
            setHeatMapData(res?.data?.data?.facility_overview);
            setHeatMapLoading(false);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  }, [dateSorting]);

  useEffect(() => {
    const selectedHeatMapData = heatMapData.find(
      (value) => selectedFacility.id === value.id
    );

    if (selectedHeatMapData) {
      setSelectedHeatMapData(selectedHeatMapData);
    }
  }, [heatMapData, selectedFacility]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const data = {
    mon: selectedHeatMapData?.schedule?.Mon || {},
    tue: selectedHeatMapData?.schedule?.Tue || {},
    wed: selectedHeatMapData?.schedule?.Wed || {},
    thu: selectedHeatMapData?.schedule?.Thu || {},
    fri: selectedHeatMapData?.schedule?.Fri || {},
    sat: selectedHeatMapData?.schedule?.Sat || {},
    sun: selectedHeatMapData?.schedule?.Sun || {},
  };
  const isSingleBookingType = selectedHeatMapData?.booking_type?.id === 1;
  const daysOfWeek = Object.keys(data);
  const timeSlots = Object.keys(data[daysOfWeek[0]]);

  timeSlots.reverse();

  const series = timeSlots.map((slot) => ({
    name: slot.trim(),

    data: daysOfWeek.map((day) => data[day][slot]),
  }));
  timeSlots.map((slot) => {
    console.log("slot", slot);
  });

  const options = {
    chart: {
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: isSingleBookingType
            ? [
                {
                  from: 0,
                  to: 0,
                  color: "#F3F4F6",
                },
                {
                  from: 1,
                  to: 5,
                  color: "#CEE6F2",
                },
                {
                  from: 5,
                  to: 10,
                  color: "#A0CEE5",
                },
                {
                  from: 10,
                  to: 15,
                  color: "#72B7D7",
                },
                {
                  from: 20,
                  to: 30,
                  color: "#1687BD",
                },
                {
                  from: 30,
                  to: 50,
                  color: "#126C97",
                },
                {
                  from: 50,
                  color: "#0D5171",
                },
              ]
            : [
                {
                  from: 0,
                  to: 0,
                  color: "#F3F4F6",
                },
                {
                  from: 1,
                  to: 50,
                  color: "#CEE6F2",
                },
                {
                  from: 50,
                  to: 100,
                  color: "#A0CEE5",
                },
                {
                  from: 100,
                  to: 150,
                  color: "#72B7D7",
                },
                {
                  from: 150,
                  to: 300,
                  color: "#1687BD",
                },
                {
                  from: 300,
                  to: 500,
                  color: "#126C97",
                },
                {
                  from: 500,
                  color: "#0D5171",
                },
              ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "category",
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      position: "top",
    },
    // yaxis: {
    //   type: "category",
    //   categories: timeSlots,
    // },
    legend: {
      show: false,
    },
    tooltip: {
      theme: "dark",
      style: {
        background: "#262D33",
      },
      marker: {
        show: false,
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const day = daysOfWeek[dataPointIndex];
        const time = timeSlots;
        const timeSlot = time[seriesIndex];

        const total = series[seriesIndex][dataPointIndex] || "0";

        return `
          <div class="apexcharts-tooltip-custom">
            <div class="total-count-tip">
              <div>
                Booking
              </div>
              <div>
                ${total}
              </div>
            </div>
            <div class="month-change-top">${day}, ${timeSlot}</div>
          </div>
        `;
      },
    },
  };

  const convertPDF = () => {
    const input = document.getElementById("print");

    html2canvas(input, {
      scrollX: 0,
      scrollY: 0,
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("Overview_Download.pdf");

        setLoading(false);
      })
      .catch((error) => {
        showToast("Download Failed.Please Try Again!", "error");
        console.error("Error generating PDF:", error);
        setLoading(false);
      });
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        // padding="20px"
        width="100%"
        id="print"
        sx={{ position: "absolute", zIndex: -1 }}
      >
        <Box
          sx={{
            backgroundColor: "#0E4965",
            color: "#FFFFFF",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography
            style={{ fontSize: "18px", fontWeight: 400, padding: "20px" }}
          >
            {buildingName}
          </Typography>
          <Typography
            style={{ fontSize: "22px", fontWeight: 600, padding: "20px" }}
          >
            loby
          </Typography>
        </Box>
        <Box
          sx={{
            color: "#0E4965",
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography
            style={{ fontSize: "20px", fontWeight: 600, padding: "20px" }}
          >
            Overview (Statistics)
          </Typography>
          <Typography
            style={{ fontSize: "16px", fontWeight: 400, padding: "20px" }}
          >
            {moment(new Date()).format("DD MMM YYYY , HH:mm")}
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            marginTop: "35px",
            padding: "20px",
          }}
        >
          <Typography
            sx={{ color: "#111927", fontWeight: 600, fontSize: "20px" }}
          >
            This Month
          </Typography>
        </Box>
        <Box sx={{ padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              // padding: "0px 20px 0px 20px",
            }}
          >
            <Box
              sx={{
                borderRadius: "8px 0px 0px 0px",
                border: "0.5px solid #E5E7EB",
                padding: "20px",
                backgroundColor: "#FFFFFF",
                width: "33.3%",
              }}
            >
              <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
                Residents
              </StyledSecondaryServiceTypography>
              <Typography
                sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
              >
                {countDeatils?.Residents || "-"}
              </Typography>
            </Box>
            <Box
              sx={{
                border: "0.5px solid #E5E7EB",
                padding: "20px",
                backgroundColor: "#FFFFFF",
                width: "33.3%",
              }}
            >
              <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
                Invited Visitors
              </StyledSecondaryServiceTypography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
                >
                  {countDeatils?.invitedVisitors || "-"}
                </Typography>
                {percentageChange?.inviteVisitorChange?.includes("-") ? (
                  <Box
                    sx={{
                      backgroundColor: "#FEF3F2",
                      color: "#B42318",
                      border: "1px solid #FECDCA",
                      borderRadius: "6px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100px",
                    }}
                  >
                    <DownArrow />
                    <Typography
                      style={{
                        textTransform: "capitalize",
                        fontSize: "14px",
                        fontWeight: 500,
                        padding: "10px",
                      }}
                    >
                      {percentageChange?.inviteVisitorChange?.replace("-", "")}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      backgroundColor: "#ECFDF3",
                      color: "#067647",
                      border: "1px solid #ABEFC6",
                      borderRadius: "6px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100px",
                    }}
                  >
                    <UpArrow />
                    <Typography
                      style={{
                        textTransform: "capitalize",
                        fontSize: "14px",
                        fontWeight: 500,
                        padding: "10px",
                      }}
                    >
                      {percentageChange?.inviteVisitorChange?.replace("-", "")}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                borderRadius: "0px 8px 0px 0px",
                border: "0.5px solid #E5E7EB",
                padding: "20px",
                backgroundColor: "#FFFFFF",
                width: "33.3%",
              }}
            >
              <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
                Property Staff
              </StyledSecondaryServiceTypography>
              <Typography
                sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
              >
                {countDeatils?.PropertyStaff || "-"}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              // padding: "0px 20px 0px 20px",
            }}
          >
            <Box
              sx={{
                borderRadius: "0px 0px 0px 8px",
                border: "0.5px solid #E5E7EB",
                padding: "20px",
                backgroundColor: "#FFFFFF",
                width: "33.3%",
              }}
            >
              <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
                Faults Reported
              </StyledSecondaryServiceTypography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
                >
                  {countDeatils?.FaultsReported || "-"}
                </Typography>
                {percentageChange?.faultReportChange?.includes("-") ? (
                  <Box
                    sx={{
                      backgroundColor: "#FEF3F2",
                      color: "#B42318",
                      border: "1px solid #FECDCA",
                      borderRadius: "6px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100px",
                    }}
                  >
                    <DownArrow />
                    <Typography
                      style={{
                        textTransform: "capitalize",
                        fontSize: "14px",
                        fontWeight: 500,
                        padding: "10px",
                      }}
                    >
                      {percentageChange?.faultReportChange?.replace("-", "")}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      backgroundColor: "#ECFDF3",
                      color: "#067647",
                      border: "1px solid #ABEFC6",
                      borderRadius: "6px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100px",
                    }}
                  >
                    <UpArrow />
                    <Typography
                      style={{
                        textTransform: "capitalize",
                        fontSize: "14px",
                        fontWeight: 500,
                        padding: "10px",
                      }}
                    >
                      {percentageChange?.faultReportChange?.replace("-", "")}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                border: "0.5px solid #E5E7EB",
                padding: "20px",
                backgroundColor: "#FFFFFF",
                width: "33.3%",
              }}
            >
              <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
                Service Requested
              </StyledSecondaryServiceTypography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
                >
                  {countDeatils?.ServiceRequested || "-"}
                </Typography>
                {percentageChange?.serviceRequestChange?.includes("-") ? (
                  <Box
                    sx={{
                      backgroundColor: "#FEF3F2",
                      color: "#B42318",
                      border: "1px solid #FECDCA",
                      borderRadius: "6px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100px",
                    }}
                  >
                    <DownArrow />
                    <Typography
                      style={{
                        textTransform: "capitalize",
                        fontSize: "14px",
                        fontWeight: 500,
                        padding: "10px",
                      }}
                    >
                      {percentageChange?.serviceRequestChange?.replace("-", "")}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      backgroundColor: "#ECFDF3",
                      color: "#067647",
                      border: "1px solid #ABEFC6",
                      borderRadius: "6px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100px",
                    }}
                  >
                    <UpArrow />
                    <Typography
                      style={{
                        textTransform: "capitalize",
                        fontSize: "14px",
                        fontWeight: 500,
                        padding: "10px",
                      }}
                    >
                      {percentageChange?.serviceRequestChange?.replace("-", "")}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                borderRadius: "0px 0px 8px 0px",
                border: "0.5px solid #E5E7EB",
                padding: "20px",
                backgroundColor: "#FFFFFF",
                width: "33.3%",
              }}
            >
              <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
                Facilities Booked
              </StyledSecondaryServiceTypography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
                >
                  {countDeatils?.FacilitiesBooked || "-"}
                </Typography>
                {percentageChange?.facilitiesChange?.includes("-") ? (
                  <Box
                    sx={{
                      backgroundColor: "#FEF3F2",
                      color: "#B42318",
                      border: "1px solid #FECDCA",
                      borderRadius: "6px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100px",
                    }}
                  >
                    <DownArrow />
                    <Typography
                      style={{
                        textTransform: "capitalize",
                        fontSize: "14px",
                        fontWeight: 500,
                        padding: "10px",
                      }}
                    >
                      {percentageChange?.facilitiesChange?.replace("-", "")}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      backgroundColor: "#ECFDF3",
                      color: "#067647",
                      border: "1px solid #ABEFC6",
                      borderRadius: "6px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100px",
                    }}
                  >
                    <UpArrow />
                    <Typography
                      style={{
                        textTransform: "capitalize",
                        fontSize: "14px",
                        fontWeight: 500,
                        padding: "10px",
                      }}
                    >
                      {percentageChange?.facilitiesChange?.replace("-", "")}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            style={{
              color: "#111927",
              fontSize: "20px",
              fontWeight: 600,
              padding: "20px",
            }}
          >
            Overview -{" "}
            {tabValue === 0
              ? "Management"
              : tabValue === 1
              ? "Fault Report"
              : tabValue === 2
              ? "Service Request"
              : tabValue === 3
              ? "Facility Booking"
              : tabValue === 4
              ? "Parcel Collection"
              : "--"}
          </Typography>
          <Typography
            style={{
              color: "#0E5E84",
              fontSize: "20px",
              fontWeight: 600,
              padding: "20px",
            }}
          >
            {yearSorting}
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          {tabValue === 0 && <ManagementGraph />}
          {tabValue === 1 && <FaultReportGraph />}
          {tabValue === 2 && <ServiceRequestGraph />}
          {tabValue === 3 && <FacilityBookingsGraph />}
          {tabValue === 4 && <ParcelCollectionGraph />}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            style={{
              color: "#111927",
              fontSize: "20px",
              fontWeight: 600,
              padding: "20px",
            }}
          >
            Facilities - {selectedFacility?.title}
          </Typography>
          <Typography
            style={{
              color: "#0E5E84",
              fontSize: "20px",
              fontWeight: 600,
              padding: "20px",
            }}
          >
            {dateSorting}
          </Typography>
        </Box>
        <>
          {selectedHeatMapData?.booking_type?.id === 2 ? (
            <Box
              sx={{
                borderRadius: "8px 8px 0px 0px",
                borderBottom: "1px solid #E5E7EB",
                backgroundColor: "#FCFCFD",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ padding: "10px" }}>
                {selectedFacility.title !== "" && selectedFacility.id !== 0 && (
                  <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
                    Multi Booking Type
                  </StyledSecondaryServiceTypography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                    padding: "16px 0px 16px 0px",
                  }}
                >
                  <HeatMapIconFirst style={{ marginRight: "10px" }} />
                  <TypoGraph>0</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconTwo style={{ marginRight: "10px" }} />
                  <TypoGraph>1 - 50</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconThree style={{ marginRight: "10px" }} />
                  <TypoGraph>50-100</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconFour style={{ marginRight: "10px" }} />
                  <TypoGraph>100-150</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconFive style={{ marginRight: "10px" }} />
                  <TypoGraph>150-300</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconsix style={{ marginRight: "10px" }} />
                  <TypoGraph>300-500</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconSeven style={{ marginRight: "10px" }} />
                  <TypoGraph>500+</TypoGraph>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                borderRadius: "8px 8px 0px 0px",
                borderBottom: "1px solid #E5E7EB",
                backgroundColor: "#FCFCFD",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ padding: "10px" }}>
                {selectedFacility.title !== "" && selectedFacility.id !== 0 && (
                  <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
                    Single Booking Type
                  </StyledSecondaryServiceTypography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                    padding: "16px 0px 16px 0px",
                  }}
                >
                  <HeatMapIconFirst style={{ marginRight: "10px" }} />
                  <TypoGraph>0</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconTwo style={{ marginRight: "10px" }} />
                  <TypoGraph>1 - 5</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconThree style={{ marginRight: "10px" }} />
                  <TypoGraph>5-10</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconFour style={{ marginRight: "10px" }} />
                  <TypoGraph>10-15</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconFive style={{ marginRight: "10px" }} />
                  <TypoGraph>20-30</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconsix style={{ marginRight: "10px" }} />
                  <TypoGraph>30-50</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconSeven style={{ marginRight: "10px" }} />
                  <TypoGraph>50+</TypoGraph>
                </Box>
              </Box>
            </Box>
          )}
          {selectedFacility.title !== "" && selectedFacility.id !== 0 ? (
            <Box
              sx={{
                borderRadius: "0px 0px 8px 8px",
                backgroundColor: "#FFFFFF",
              }}
            >
              {heatMapLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "600px",
                  }}
                >
                  {" "}
                  <CircularProgress />
                </Box>
              ) : (
                <Chart options={options} series={series} type="heatmap" />
              )}
            </Box>
          ) : (
            <Box
              sx={{
                borderRadius: "0px, 0px, 8px, 8px",
                padding: "16px",
                backgroundColor: "#F9FAFB",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ExclamatoryIcon
                style={{ marginBottom: "5px", marginTop: "60px" }}
              />
              <Typography
                sx={{
                  color: "#9DA4AE",
                  fontSize: "16px",
                  fontWeight: 600,
                  width: "470px",
                  my: 2,
                  textAlign: "center",
                  marginTop: "5px",
                  marginBottom: "100px",
                }}
                style={{ color: "#4D5761" }}
              >
                No statistics available for display
              </Typography>
            </Box>
          )}{" "}
        </>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <PrimaryTypography>
            {" "}
            {isLoading ? (
              <Skeleton width={200} height={50} />
            ) : (
              `${LanguageData?.home_welcome_back || "Welcome Back"},${
                userProfileDetails?.full_name || "--"
              }`
            )}
          </PrimaryTypography>
          <StyledSecondaryServiceTypography>
            {isLoading ? (
              <Skeleton width={200} height={50} />
            ) : (
              `${userProfileDetails?.address || "--"}, ${
                userProfileDetails?.city || "--"
              } ${userProfileDetails?.zipcode || "--"}`
            )}
          </StyledSecondaryServiceTypography>
        </Box>
        {isLoading ? (
          <Skeleton width={200} height={50} />
        ) : (
          <Box>
            <Button
              sx={{
                fontWeight: "600",
                width: "fit-content",
                color: "#0E5E84",
                backgroundColor: "#E7EFF3",
                padding: "10px 16px 10px 16px",
                "&:hover": {
                  backgroundColor: "#CFDFE6",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#F9FAFB",
                  color: "#D2D6DB",
                },
              }}
              onClick={() => {
                convertPDF();
                setLoading(true);
              }}
            >
              {loading ? (
                <Box sx={{ width: "40px", marginTop: "5px" }}>
                  <CircularProgress size={15} />
                </Box>
              ) : (
                <DownloadIcon style={{ marginRight: "8px", padding: "3px" }} />
              )}
              {LanguageData?.home_download_report || "Download Report"}
            </Button>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "50px",
        }}
      >
        {isLoading ? (
          <Skeleton width={200} height={50} />
        ) : (
          <Typography
            sx={{ color: "#111927", fontWeight: 600, fontSize: "20px" }}
          >
            {LanguageData?.home_this_month || "This Month"}
          </Typography>
        )}

        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          {isLoading ? (
            <Skeleton width={200} height={50} />
          ) : (
            <>
              <StyledTooltip
                title="The up/down trend indicator illustrates the comparison of data between the current month and the prior month."
                arrow
                placement="top-start"
              >
                <InfoIcon style={{ marginRight: "10px", cursor: "pointer" }} />
              </StyledTooltip>

              <StyledSecondaryServiceTypography>
                {LanguageData?.home_this_month || "Updated as of"}{" "}
                {moment(new Date()).format("DD MMMM YYYY")}
              </StyledSecondaryServiceTypography>
            </>
          )}
        </Box>
      </Box>
      {isLoading ? (
        <Skeleton variant="rounded" width="100%" height={80} />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              borderRadius: "8px 0px 0px 0px",
              border: "0.5px solid #E5E7EB",
              padding: "20px",
              backgroundColor: "#FFFFFF",
              width: "33.3%",
            }}
          >
            <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
              {LanguageData?.home_resident || "Residents"}
            </StyledSecondaryServiceTypography>
            <Typography
              sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
            >
              {countDeatils?.Residents || "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              border: "0.5px solid #E5E7EB",
              padding: "20px",
              backgroundColor: "#FFFFFF",
              width: "33.3%",
            }}
          >
            <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
              {LanguageData?.home_invited_visitors || " Invited Visitors"}
            </StyledSecondaryServiceTypography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
              >
                {countDeatils?.invitedVisitors || "-"}
              </Typography>
              <TypographyDefault>
                {getGraphChip(percentageChange?.inviteVisitorChange)}
              </TypographyDefault>
            </Box>
          </Box>
          <Box
            sx={{
              borderRadius: "0px 8px 0px 0px",
              border: "0.5px solid #E5E7EB",
              padding: "20px",
              backgroundColor: "#FFFFFF",
              width: "33.3%",
            }}
          >
            <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
              {LanguageData?.home_property_staff || "Property Staff"}
            </StyledSecondaryServiceTypography>
            <Typography
              sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
            >
              {countDeatils?.PropertyStaff || "-"}
            </Typography>
          </Box>
        </Box>
      )}
      {isLoading ? (
        <Skeleton variant="rounded" width="100%" height={80} />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Box
            sx={{
              borderRadius: "0px 0px 0px 8px",
              border: "0.5px solid #E5E7EB",
              padding: "20px",
              backgroundColor: "#FFFFFF",
              width: "33.3%",
            }}
          >
            <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
              {LanguageData?.home_falut_report || "Faults Reported"}
            </StyledSecondaryServiceTypography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
              >
                {countDeatils?.FaultsReported || "-"}
              </Typography>
              <TypographyDefault>
                {getGraphChip(percentageChange?.faultReportChange)}
              </TypographyDefault>
            </Box>
          </Box>
          <Box
            sx={{
              border: "0.5px solid #E5E7EB",
              padding: "20px",
              backgroundColor: "#FFFFFF",
              width: "33.3%",
            }}
          >
            <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
              {LanguageData?.home_service_request || "Service Requested"}
            </StyledSecondaryServiceTypography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
              >
                {countDeatils?.ServiceRequested || "-"}
              </Typography>
              <TypographyDefault>
                {getGraphChip(percentageChange?.serviceRequestChange)}
              </TypographyDefault>
            </Box>
          </Box>
          <Box
            sx={{
              borderRadius: "0px 0px 8px 0px",
              border: "0.5px solid #E5E7EB",
              padding: "20px",
              backgroundColor: "#FFFFFF",
              width: "33.3%",
            }}
          >
            <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
              {LanguageData?.home_facility_book || "Facilities Booked"}
            </StyledSecondaryServiceTypography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: "20px", fontWeight: 600, color: "#111927" }}
              >
                {countDeatils?.FacilitiesBooked || "-"}
              </Typography>
              <TypographyDefault>
                {getGraphChip(percentageChange?.facilitiesChange)}
              </TypographyDefault>
            </Box>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "50px",
          alignItems: "center",
        }}
      >
        {isLoading ? (
          <Skeleton width={200} height={50} />
        ) : (
          <Typography
            sx={{ color: "#111927", fontWeight: 600, fontSize: "20px" }}
          >
            {LanguageData?.home_overview || " Overview"}
          </Typography>
        )}
        {isLoading ? (
          <Skeleton width={200} height={50} />
        ) : (
          <Box sx={{ cursor: "pointer" }}>
            <Select
              value={yearSorting}
              style={{
                borderRadius: "8px",
                border: "1px solid #D2D6DB",
                backgroundColor: "#FFFFFF",
                padding: "8px 14px 8px 14px",
                color: "#0E5E84",
                fontWeight: 600,
                fontSize: "14px",
                height: "36px",
                minWidth: "92px",
              }}
              IconComponent={KeyboardArrowDownIcon}
              onChange={(e) => setYearSorting(e.target.value)}
            >
              {yearArray.map((data, index) => (
                <MenuItem key={index} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </Box>
      {isLoading ? (
        <Skeleton variant="rounded" width="100%" height={500} />
      ) : (
        <>
          <Box
            sx={{
              borderRadius: "8px 8px 0px 0px",
              borderBottom: "1px solid #E5E7EB",
              backgroundColor: "#FFFFFF",
              marginTop: "10px",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              style={{ justifyContent: "space-between" }}
            >
              <Tab
                label={LanguageData?.home_tab_management || "Management"}
                sx={{ flexGrow: 1 }}
              />
              <Tab
                label={LanguageData?.home_tab_fault || "Fault Report"}
                sx={{ flexGrow: 1 }}
              />
              <Tab
                label={LanguageData?.home_tab_service || "Service Request"}
                sx={{ flexGrow: 1 }}
              />
              <Tab
                label={LanguageData?.home_tab_facility || "Facilities Booked"}
                sx={{ flexGrow: 1 }}
              />
              <Tab
                label={LanguageData?.home_tab_parcel || "Parcel"}
                sx={{ flexGrow: 1 }}
              />
            </Tabs>
          </Box>
          {tabValue === 0 && <ManagementGraph />}
          {tabValue === 1 && <FaultReportGraph />}
          {tabValue === 2 && <ServiceRequestGraph />}
          {tabValue === 3 && <FacilityBookingsGraph />}
          {tabValue === 4 && <ParcelCollectionGraph />}
        </>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "50px",
        }}
      >
        {isLoading ? (
          <Skeleton width={200} height={50} />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                sx={{ color: "#111927", fontWeight: 600, fontSize: "20px" }}
              >
                {LanguageData?.home_header_facility || "Facilities"}
              </Typography>
            </Box>
            {selectedFacility.title !== "" && selectedFacility.id !== 0 ? (
              <Box
                sx={{
                  cursor: "pointer",
                  marginLeft: "30px",
                  maxWidth: "150px",
                  minWidth: "92px",
                }}
              >
                <Select
                  name="title"
                  onChange={(e) => {
                    const selectedTitle = e.target.value;
                    const selectedFacilityObject = facilityList.find(
                      (facility) => facility.title === selectedTitle
                    );

                    setSelectedFacility({
                      id: selectedFacilityObject?.id || 0,
                      title: selectedTitle,
                    });
                  }}
                  value={selectedFacility?.title}
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #D2D6DB",
                    backgroundColor: "#FFFFFF",
                    padding: "8px 14px 8px 14px",
                    color: "#0E5E84",
                    fontWeight: 600,
                    fontSize: "14px",
                    height: "36px",
                  }}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {facilityList?.map((result) => (
                    <MenuItem key={result?.id} value={result?.title}>
                      {result?.title}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        )}

        {selectedFacility.title !== "" && selectedFacility.id !== 0 ? (
          isLoading ? (
            <Skeleton width={200} height={50} />
          ) : (
            <Box sx={{ cursor: "pointer" }}>
              <Select
                value={dateSorting}
                sx={{
                  borderRadius: "8px",
                  border: "1px solid #D2D6DB",
                  backgroundColor: "#FFFFFF",
                  padding: "8px 14px 8px 14px",
                  color: "#0E5E84",
                  fontWeight: 600,
                  fontSize: "14px",
                  height: "36px",
                }}
                IconComponent={KeyboardArrowDownIcon}
                onChange={(e) => setDateSorting(e.target.value)}
              >
                <MenuItem value="Current Week">Current Week</MenuItem>
                <MenuItem value="Last Week">Last Week</MenuItem>
                <MenuItem value="Last Month">Last Month</MenuItem>
                <MenuItem value="Last 3 Months">Last 3 Months</MenuItem>
                <MenuItem value="Last 6 Months"> Last 6 Months</MenuItem>
                <MenuItem value="Last Year"> Last Year</MenuItem>
              </Select>
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
      {isLoading ? (
        <Skeleton variant="rounded" width="100%" height={500} />
      ) : (
        <>
          {selectedHeatMapData?.booking_type?.id === 2 ? (
            <Box
              sx={{
                borderRadius: "8px 8px 0px 0px",
                borderBottom: "1px solid #E5E7EB",
                backgroundColor: "#FCFCFD",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ padding: "10px" }}>
                {selectedFacility.title !== "" && selectedFacility.id !== 0 && (
                  <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
                    {LanguageData?.home_booking_multi || "Multi Booking Type"}
                  </StyledSecondaryServiceTypography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                    padding: "16px 0px 16px 0px",
                  }}
                >
                  <HeatMapIconFirst style={{ marginRight: "10px" }} />
                  <TypoGraph>0</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconTwo style={{ marginRight: "10px" }} />
                  <TypoGraph>1 - 50</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconThree style={{ marginRight: "10px" }} />
                  <TypoGraph>50-100</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconFour style={{ marginRight: "10px" }} />
                  <TypoGraph>100-150</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconFive style={{ marginRight: "10px" }} />
                  <TypoGraph>150-300</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconsix style={{ marginRight: "10px" }} />
                  <TypoGraph>300-500</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconSeven style={{ marginRight: "10px" }} />
                  <TypoGraph>500+</TypoGraph>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                borderRadius: "8px 8px 0px 0px",
                borderBottom: "1px solid #E5E7EB",
                backgroundColor: "#FCFCFD",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ padding: "10px" }}>
                {selectedFacility.title !== "" && selectedFacility.id !== 0 && (
                  <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
                    {LanguageData?.home_booking_single || "Single Booking Type"}
                  </StyledSecondaryServiceTypography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                    padding: "16px 0px 16px 0px",
                  }}
                >
                  <HeatMapIconFirst style={{ marginRight: "10px" }} />
                  <TypoGraph>0</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconTwo style={{ marginRight: "10px" }} />
                  <TypoGraph>1 - 5</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconThree style={{ marginRight: "10px" }} />
                  <TypoGraph>5-10</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconFour style={{ marginRight: "10px" }} />
                  <TypoGraph>10-15</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconFive style={{ marginRight: "10px" }} />
                  <TypoGraph>20-30</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconsix style={{ marginRight: "10px" }} />
                  <TypoGraph>30-50</TypoGraph>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <HeatMapIconSeven style={{ marginRight: "10px" }} />
                  <TypoGraph>50+</TypoGraph>
                </Box>
              </Box>
            </Box>
          )}
          {selectedFacility.title !== "" && selectedFacility.id !== 0 ? (
            <Box
              sx={{
                borderRadius: "0px 0px 8px 8px",
                backgroundColor: "#FFFFFF",
              }}
            >
              {heatMapLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "600px",
                  }}
                >
                  {" "}
                  <CircularProgress />
                </Box>
              ) : (
                <Chart
                  height={1500}
                  options={options}
                  series={series}
                  type="heatmap"
                />
              )}
            </Box>
          ) : (
            <Box
              sx={{
                borderRadius: "0px, 0px, 8px, 8px",
                padding: "16px",
                backgroundColor: "#F9FAFB",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ExclamatoryIcon
                style={{ marginBottom: "5px", marginTop: "60px" }}
              />
              <Typography
                sx={{
                  color: "#9DA4AE",
                  fontSize: "16px",
                  fontWeight: 600,
                  width: "470px",
                  my: 2,
                  textAlign: "center",
                  marginTop: "5px",
                  marginBottom: "100px",
                }}
                style={{ color: "#4D5761" }}
              >
                No statistics available for display
              </Typography>
            </Box>
          )}{" "}
        </>
      )}
    </Box>
  );
};

export default Overview;
