import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { ReactComponent as BackArrow } from "../../Icons/BorderBackArrow.svg";
import { ReactComponent as RefreshIcon } from "../../Icons/Refresh.svg";
import { ReactComponent as ContactDetailsIcon } from "../../Icons/ContactDetails.svg";
import {
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../Theme";
import {
  Stack,
  Box,
  Button,
  Grid,
  MenuItem,
  Divider,
  Select,
  TextField,
  Typography,
  FormControl,
  IconButton,
} from "@mui/material";
import { editContactDetails } from "../../Redux/Feature/ContactBook/ContactBookSlice";
import { emailRegex } from "../../Utils";
import axiosInstance from "../../Redux/AxiosInstance";
import { PostLoaderModal } from "../../CommonComponents/modal";
import { showToast } from "../../CommonComponents/Toaster";

const EditContactDetails = () => {
  const { LanguageData } = useSelector((state) => state.Language);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [create, setcreate] = useState(true);
  const [preview, setPreview] = useState(false);
  let { contactDetailsList } = useSelector((state) => state.ContactBook);
  const [contactdata, setcontactdata] = useState({
    category: "",
    title: "",
    contact_number: "",
    email: "",
  });
  const [errtext, seterrtext] = useState({
    category: "",
    title: "",
    contact_number: "",
    email: "",
  });
  const { editContactDetailsStatus } = useSelector(
    (state) => state.ContactBook
  );
  const [response, setresponse] = useState("");
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const [categoryData, setCategoryData] = useState([]);
  const [InvalidErr, setInvalidErr] = useState({
    contact_number: "",
    email: "",
  });
  const [updatedAt, setUpdatedAt] = useState(null);
  const [postLoader, SetPostLoader] = useState(false);
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if (editContactDetailsStatus !== null) {
      if (editContactDetailsStatus.success) {
        navigate("/contact-book");
        SetPostLoader(true);
        showToast(editContactDetailsStatus.toast);
      } else {
        if (editContactDetailsStatus.error === "Validation Error") {
          let obj = editContactDetailsStatus.data.validation_error;
          let str = "";
          for (let key in obj) {
            str = str.concat(obj[key]);
          }
          setresponse(str);
        } else {
          setresponse(editContactDetailsStatus.error);
        }
      }
    }
  }, [editContactDetailsStatus]);

  useEffect(() => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/contactbook/${id}`
      )
      .then((response) => {
        const { category_id, title, contact_number, email, updated_at } =
          response.data.data.contact_book;
        setcontactdata({
          category: category_id,
          title: title,
          contact_number: contact_number,
          email: email,
        });
        setUpdatedAt(updated_at);
        setDisable(false);
      })
      .catch((err) => {
        console.log(err);
      });

    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/contactbookcategory`
      )
      .then((data) => {
        setCategoryData(data.data.data.contactbook_category);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handlechange = (e) => {
    const { name, value } = e.target;
    setcontactdata((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    seterrtext((prev) => {
      return {
        ...prev,
        [name]: "",
      };
    });
    setInvalidErr((prev) => {
      return {
        ...prev,
        [name]: "",
      };
    });
  };

  const validate = () => {
    let isError = false;
    for (const key in contactdata) {
      if (contactdata[key] === "" && key !== "email") {
        seterrtext((prev) => {
          return {
            ...prev,
            [key]: "Error! No Inputs detected",
          };
        });
        // }
        isError = true;
      } else if (
        key == "contact_number" &&
        (contactdata[key].length < 6 || contactdata[key].length > 20)
      ) {
        seterrtext((prev) => {
          return {
            ...prev,
            [key]: "Error! min and max value should between 6 - 20",
          };
        });
        setInvalidErr((prev) => ({
          ...prev,
          contact_number: "Error! min and max value should between 6 - 20",
        }));
        isError = true;
      } else if (key == "email" && contactdata[key] !== "") {
        if (!emailRegex.test(contactdata[key])) {
          seterrtext((prev) => {
            return {
              ...prev,
              [key]: "Error! Invalid email",
            };
          });
          setInvalidErr((prev) => ({ ...prev, email: "Error! Invalid email" }));
          isError = true;
        }
      }
    }
    return isError;
  };

  const Update = () => {
    const isError = validate();
    if (!isError) {
      const datas = {
        category_id: contactdata.category,
        contact_number: contactdata.contact_number,
        email: contactdata.email,
        title: contactdata.title,
        status: 1,
      };
      SetPostLoader(true);
      dispatch(editContactDetails({ datas, propertyId, id }));
    }
  };

  const Continue = (e) => {
    // e.preventDefault()
    const isError = validate();
    if (!isError) {
      setcreate(false);
      setPreview(true);
    }
  };
  const gobacktocreate = () => {
    setcreate(true);
    setPreview(false);
  };

  const createf = () => {
    return (
      // <>
      //     <Grid container gap='1.5%' >
      //         <Grid xs={5.9}>
      //             <Box component='div' sx={{ borderTop: '3px solid #0E4965', width: '100%', color: '#0E4965' }}>
      //                 <Typography sx={{ fontWeight: '500', fontSize: "12px", padding: '10px 0 0 0' }}>Contact Details </Typography>
      //             </Box>
      //         </Grid>
      //         <Grid xs={5.9} onClick={Continue}>
      //             <Box component='div' sx={{ borderTop: '3px solid #D2D6DB', width: '100%', color: "#D2D6DB" }}>
      //                 <Typography sx={{ fontWeight: '500', fontSize: "12px", padding: '10px 0 0 0' }}>Review Information</Typography>
      //             </Box>
      //         </Grid>
      //     </Grid>
      //     <Box mt={3} component='div' boxShadow={1} borderRadius='8px'>
      //         <Stack direction='row' alignItems='center' gap='10px' px='16px' sx={{ height: '66px', backgroundColor: '#FCFCFD', border: '1px solid #E5E7EB', borderRadius: '8px 8px 0 0 ' }}>
      //             <ContactDetailsIcon />
      //             <SecondaryTypography>Contact Details</SecondaryTypography>
      //         </Stack>
      //         <Box component='form' onSubmit={Continue} >
      //             <Grid container xs={12} gap='20px' sx={{ backgroundColor: 'white', padding: '30px 16px 30px 16px', border: '1px solid #E5E7EB', borderBottom: '0' }}>
      //                 <Grid xs={12} md={5.8}>
      //                     <StyledTypography>Category</StyledTypography>
      //                     <FormControl fullWidth>
      //                         {/* <InputLabel id="emer">Acge</InputLabel> */}
      //                         <Select
      //                             name='category'
      //                             displayEmpty
      //                             // inputProps={{ 'aria-label': 'Without label' }}
      //                             value={contactdata.category.id}
      //                             sx={{ color: Boolean(errtext.category) ? '#912018' : '', backgroundColor: Boolean(errtext.category) ? '#FEE4E2' : '' }}
      //                             onChange={handlechange}
      //                             renderValue={contactdata.category.id !== undefined ? undefined : () => "Select category"}
      //                         // fullWidth
      //                         >
      //                             {categoryData?.map((value) => (
      //                                 <MenuItem value={value}>{value.name}</MenuItem>
      //                             ))}
      //                         </Select>
      //                     </FormControl>
      //                     {/* <ErrorTypography>{errtext.category}</ErrorTypography> */}
      //                 </Grid>
      //                 <Grid xs={12} md={5.8}>
      //                     <StyledTypography>Name</StyledTypography>
      //                     <TextField
      //                         name="title"
      //                         placeholder="Add title"
      //                         value={contactdata.title}
      //                         sx={{ "& .MuiInputBase-root": { color: Boolean(errtext.title) ? '#912018' : '' }, backgroundColor: Boolean(errtext.title) ? '#FEE4E2' : '' }}
      //                         onChange={handlechange}
      //                         InputProps={{ disableUnderline: true }}
      //                         fullWidth
      //                     />
      //                     {/* <ErrorTypography>{errtext.title}</ErrorTypography> */}
      //                 </Grid>
      //                 <Grid xs={12} md={5.8}>

      //                     <StyledTypography>Contact Number</StyledTypography>
      //                     <TextField
      //                         type='number'
      //                         name="contact_number"
      //                         value={contactdata.contact_number}
      //                         placeholder="Add Contact number"
      //                         onChange={handlechange}
      //                         sx={{ "& .MuiInputBase-root": { color: Boolean(errtext.contact_number) ? '#912018' : '' }, backgroundColor: Boolean(errtext.contact_number) ? '#FEE4E2' : '' }}
      //                         InputProps={{ disableUnderline: true }}
      //                         fullWidth

      //                     />
      //                     {/* <ErrorTypography>{errtext.contact_number}</ErrorTypography> */}

      //                 </Grid>
      //                 <Grid xs={12} md={5.8}>
      //                     <StyledTypography>Email Address</StyledTypography>
      //                     <TextField
      //                         name="email"
      //                         value={contactdata.email}
      //                         placeholder="Add email address"
      //                         onChange={handlechange}
      //                         sx={{ "& .MuiInputBase-root": { color: Boolean(errtext.email) ? '#912018' : '' }, backgroundColor: Boolean(errtext.email) ? '#FEE4E2' : '' }}
      //                         InputProps={{ disableUnderline: true }}
      //                         inputProps={{ maxLength: 125 }}
      //                         fullWidth
      //                     />
      //                     <ErrorTypography>{errtext.email}</ErrorTypography>
      //                 </Grid>
      //             </Grid>
      //             <Grid xs={12} >
      //                 <Box bgcolor="#FFFFFF" sx={{ border: '1px solid  #E5E7EB', borderTop: ' 1px solid #F3F4F6', borderRadius: '0 0 8px 8px' }}>
      //                     <Stack direction="row" py={2} px={2} justifyContent="space-between" alignItems="center" height='60px'>
      //                         <Stack direction='row'>
      //                             <RefreshIcon style={{ marginRight: '5px' }} />
      //                             <StyledSecondaryTypography>Last Updated at  {moment(new Date()).format('hh:mm A')}</StyledSecondaryTypography>
      //                         </Stack>
      //                         <Stack direction="column" justifyContent='end' alignItems='end'>
      //                             <Button type='submit' sx={{ width: "fit-content" }} variant='contained'>Continue</Button>
      //                         </Stack>
      //                     </Stack>
      //                 </Box>
      //             </Grid>
      //         </Box>
      //     </Box>
      // </>

      <>
        <Grid container gap="1.5%" sx={{ "&:hover": { cursor: "pointer" } }}>
          <Grid xs={5.9}>
            <Box
              component="div"
              sx={{
                borderTop: "3px solid #0E4965",
                width: "100%",
                color: "#0E4965",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  padding: "10px 0 0 0",
                }}
              >
                {LanguageData?.Contact_Details || "Contact Details"}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={5.9} onClick={Continue}>
            <Box
              component="div"
              sx={{
                borderTop: "3px solid #D2D6DB",
                width: "100%",
                color: "#9DA4AE",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  padding: "10px 0 0 0",
                }}
              >
                {LanguageData?.Review_Information || "Review Information"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box mt={3} component="div" boxShadow={1} borderRadius="8px">
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {LanguageData?.Contact_Details || "Contact Details"}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="20px"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 20px",
              border: "1px solid #E5E7EB",
              borderBottom: "0",
            }}
          >
            <Grid xs={12} md={5.77}>
              <StyledTypography>
                {LanguageData?.Category || "Category"}
              </StyledTypography>
              <FormControl fullWidth>
                <Select
                  value={contactdata.category}
                  
                  name="category"
                  defaultValue={1}
                  onChange={handlechange}
                  sx={{
                    color: Boolean(errtext.category) ? "#912018" : "",
                    backgroundColor: Boolean(errtext.category) ? "#FEE4E2" : "",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border:"none !important"
                    },
                  }}
                  renderValue={
                    contactdata.category !== undefined
                      ? undefined
                      : () => "Select category"
                  }
                  fullWidth
                >
                  {categoryData?.map((value) => (
                    <MenuItem value={value?.id}>{value.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <ErrorTypography>{errtext.category}</ErrorTypography> */}
            </Grid>
            <Grid xs={12} md={5.77}>
              <StyledTypography>
                {LanguageData?.Name || "Name"}
              </StyledTypography>
              <TextField
                name="title"
                placeholder="Add title"
                value={contactdata.title}
                onChange={handlechange}
                sx={{
                  color: Boolean(errtext.title) ? "#912018" : "",
                  backgroundColor: Boolean(errtext.title) ? "#FEE4E2" : "",
                }}
                InputProps={{ disableUnderline: true }}
                fullWidth
              />
              {/* <ErrorTypography>{errtext.title}</ErrorTypography> */}
            </Grid>
            <Grid xs={12} md={5.77}>
              <StyledTypography>
                {" "}
                {LanguageData?.Contact_Number || "Contact Number"}
              </StyledTypography>
              <TextField
                type="number"
                name="contact_number"
                value={contactdata.contact_number}
                placeholder="Add Contact number"
                sx={{
                  color: Boolean(errtext.contact_number) ? "#912018" : "",
                  backgroundColor: Boolean(errtext.contact_number)
                    ? "#FEE4E2"
                    : "",
                }}
                onChange={handlechange}
                InputProps={{ disableUnderline: true }}
                fullWidth
              />
              <ErrorTypography>{InvalidErr.contact_number}</ErrorTypography>
            </Grid>
            <Grid xs={12} md={5.77}>
              <StyledTypography>
                {" "}
                {LanguageData?.Email_Address || "Email Address"}
              </StyledTypography>
              <TextField
                name="email"
                value={contactdata.email}
                placeholder="Add email address"
                onChange={handlechange}
                sx={{
                  color: Boolean(errtext.email) ? "#912018" : "",
                  backgroundColor: Boolean(errtext.email) ? "#FEE4E2" : "",
                }}
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 125 }}
                fullWidth
              />
              <ErrorTypography>{InvalidErr.email}</ErrorTypography>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Box
              bgcolor="#FFFFFF"
              sx={{
                border: "1px solid  #E5E7EB",
                borderTop: " 1px solid #F3F4F6",
                borderRadius: "0 0 8px 8px",
              }}
            >
              <Stack
                direction="row"
                py={2}
                px={2}
                justifyContent="space-between"
                alignItems="center"
                height="50px"
              >
                <Stack direction="row">
                  <RefreshIcon style={{ marginRight: "5px" }} />
                  <StyledSecondaryTypography>
                    {LanguageData?.Common_last_Update || "Last Updated at"}{" "}
                    {moment.utc(updatedAt).local().format("hh:mm A")}
                  </StyledSecondaryTypography>
                </Stack>
                <Stack direction="column" justifyContent="end" alignItems="end">
                  <Button
                    variant="Standard"
                    onClick={Continue}
                    disabled={disable}
                  >
                    {LanguageData?.Common_Continue || "Continue"}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Box>
      </>
    );
  };

  const reviewf = () => {
    return (
      <>
        <Grid container gap="1.5%" sx={{ "&:hover": { cursor: "pointer" } }}>
          <Grid xs={5.9} onClick={gobacktocreate}>
            <Box
              component="div"
              sx={{
                borderTop: "3px solid #9DA4AE",
                width: "100%",
                color: "#9DA4AE",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  padding: "10px 0 0 0",
                }}
              >
                {LanguageData?.Contact_Details || "Contact Details"}{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={5.9}>
            <Box
              component="div"
              sx={{
                borderTop: "3px solid #0E4965",
                width: "100%",
                color: "#0E4965",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  padding: "10px 0 0 0",
                }}
              >
                {LanguageData?.Review_Information || "Review Information"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box mt={3} component="div" boxShadow={1} borderRadius="8px">
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {" "}
              {LanguageData?.Contact_Details || "Contact Details"}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 20px 16px",
              border: "1px solid #E5E7EB",
              borderBottom: "0",
            }}
          >
            <Grid xs={12} md={5.8}>
              <StyledSecondaryTypography>
                {LanguageData?.Category || "Category"}
              </StyledSecondaryTypography>
              {categoryData?.map((value) => {
                if (value.id == contactdata.category) {
                  return <TypographyDefault>{value?.name} </TypographyDefault>;
                }
              })}
            </Grid>
            <Grid xs={12} md={5.8}>
              <StyledSecondaryTypography>
                {" "}
                {LanguageData?.Name || "Name"}
              </StyledSecondaryTypography>
              <TypographyDefault>{contactdata.title}</TypographyDefault>
            </Grid>
            <Divider />
            <Grid xs={12} md={5.8} mt={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Contact_Number || "Contact Number"}
              </StyledSecondaryTypography>
              <TypographyDefault>
                {contactdata.contact_number}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} md={5.8} mt={2}>
              <StyledSecondaryTypography>
                {LanguageData?.Email_Address || "Email Address"}
              </StyledSecondaryTypography>
              <TypographyDefault>{contactdata.email || "-"} </TypographyDefault>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Box
              bgcolor="#FFFFFF"
              sx={{
                border: "1px solid  #E5E7EB",
                borderTop: " 1px solid #F3F4F6",
                borderRadius: "0 0 8px 8px",
              }}
            >
              <Stack
                direction="row"
                py={2}
                px={2}
                justifyContent="space-between"
                alignItems="center"
                height="50px"
              >
                <Stack direction="row">
                  <RefreshIcon style={{ marginRight: "5px" }} />
                  <StyledSecondaryTypography>
                    {LanguageData?.Common_last_Update || "Last Updated at"}{" "}
                    {moment.utc(updatedAt).local().format("hh:mm A")}
                  </StyledSecondaryTypography>
                </Stack>
                <Stack direction="column" justifyContent="end" alignItems="end">
                  <Button variant="Standard" onClick={Update}>
                    {LanguageData?.Common_Confirm || "Confirm"}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Box>
      </>
    );
  };

  return (
    <>
      {/* <Box p={5} sx={{ backgroundColor: '#F3F4F6' }}>
                <BackArrow onClick={() => navigate('/contact-book')} style={{ marginBottom: '16px' }} />
                <Box mb={5} >
                    <PrimaryTypography mb={1}>Create Contact</PrimaryTypography>
                    <StyledSecondaryTypography >
                        Create a contact detail that will be added and displayed on the LOBY system.
                    </StyledSecondaryTypography>
                </Box> */}
      {postLoader && <PostLoaderModal />}
      <Box p={5} sx={{ backgroundColor: "#F3F4F6" }}>
        <BackArrow
          onClick={() => navigate("/contact-book")}
          style={{ marginBottom: "26px", cursor: "pointer" }}
        />
        <Box mb={5}>
          <PrimaryTypography mb={0.3}>
            {LanguageData?.Edit_Contact || "Edit Contact Details"}{" "}
          </PrimaryTypography>
          <StyledSecondaryTypography>
            {LanguageData?.Edit_Contact_Sub ||
              "Edit an existing contact detail. Once updated, this will be be visible to all users on LOBY system."}
          </StyledSecondaryTypography>
        </Box>
        {create && createf()}
        {preview && reviewf()}
      </Box>
    </>
  );
};

export default EditContactDetails;
