import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../AxiosInstance";
import { getLocalStorageData } from "../../../Utils"
import axios from "axios";

const initialState = {
    loading: false,
    loginStatus: null,
    verifyEmailStatus: null,
    verifyOtpStatus: null,
    updatePasswordStatus: null,
    savePropertyDetailsAsDraftStatus: false,
    emailExistStatus: null,
    userDetails: JSON.parse(localStorage.getItem('userDetails')) != null ? JSON.parse(localStorage.getItem('userDetails')) : null,
    UserAccess: JSON.parse(localStorage.getItem('Allowed_access')) != null ? JSON.parse(localStorage.getItem('Allowed_access'))?.access : null,
    UserManage: JSON.parse(localStorage.getItem('Allowed_access')) != null ? JSON.parse(localStorage.getItem('Allowed_access'))?.manage : null,
}

export const login = createAsyncThunk('login', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}login`, data);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const verifyEmail = createAsyncThunk('verifyEmail', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}email_verification`, data);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const verifyOtp = createAsyncThunk('verifyOtp', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}verify_otp`, data);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const updatePassword = createAsyncThunk('updatePassword', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}update_password`, data);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})
export const emailExist = createAsyncThunk('emailExist', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}email_exist`, data);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})


export const loadLocalStorageData = createAsyncThunk('loadLocalStorageData', async (_, { dispatch }) => {
    try {
        const data = getLocalStorageData();
        if (Boolean(data)) {
            dispatch(setUserDetails(data));
        }
    } catch (error) {
        console.error('Error loading data from localStorage:', error);
    }
});


const Login = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setLoginSliceToNull: (state) => {
            state.verifyEmailStatus = null
            state.loginStatus = null
            state.emailExistStatus = null
            state.verifyOtpStatus = null
            state.loginStatus = null
            state.updatePasswordStatus = null
        },
        setUserDetails: (state, action) => {
            console.log(action.payload);
            state.userDetails = action.payload
        },
        setAccessData: (state, action) => {
            state.UserAccess = action.payload
        },
        setManageData: (state, action) => {
            state.UserManage = action.payload
        },
        savePropertyDetailsAsDraft: (state) => {
            state.savePropertyDetailsAsDraftStatus = true
        },
        savePropertyDetailsAsDraftToNull: (state) => {
            state.savePropertyDetailsAsDraftStatus = false
        },

    },
    extraReducers: (builders) => {
        //login
        builders.addCase(login.fulfilled, (state, action) => {
            state.loginStatus = action.payload.data
        })
        builders.addCase(login.rejected, (state, action) => {
            state.loginStatus = action?.payload?.response?.data
        })
        //verify_email
        builders.addCase(verifyEmail.pending, (state, action) => {
            state.verifyEmailStatus = null
        })
        builders.addCase(verifyEmail.fulfilled, (state, action) => {
            state.verifyEmailStatus = action.payload.data
        })
        builders.addCase(verifyEmail.rejected, (state, action) => {
            state.verifyEmailStatus = action?.payload?.response?.data
        })
        //verify otp
        builders.addCase(verifyOtp.fulfilled, (state, action) => {
            state.verifyOtpStatus = action.payload.data
        })
        builders.addCase(verifyOtp.rejected, (state, action) => {
            state.verifyOtpStatus = action.payload.response.data
        })
        //update password
        builders.addCase(updatePassword.fulfilled, (state, action) => {
            state.updatePasswordStatus = action.payload.data
        })
        builders.addCase(updatePassword.rejected, (state, action) => {
            state.updatePasswordStatus = action.payload.response.data
        })
        builders.addCase(emailExist.fulfilled, (state, action) => {
            state.emailExistStatus = action.payload.data
        })
        builders.addCase(emailExist.rejected, (state, action) => {
            state.emailExistStatus = action.payload.response.data
        })
    }
})

export const { setLoginSliceToNull, savePropertyDetailsAsDraft, savePropertyDetailsAsDraftToNull, setUserDetails, setManageData, setAccessData, setLoading, setPostLoader } = Login.actions
export default Login.reducer