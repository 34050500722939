import axios from "axios";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const isTokenExpired = () => {
  const expiryTime = localStorage.getItem("expireTime");
  const bufferTime = 30 * 1000; // 30 seconds in milliseconds
  return expiryTime ? Date.now() > parseInt(expiryTime) - bufferTime : true;
};

axiosInstance.interceptors.request.use(
 async (request) => {
    const token =
      localStorage.getItem("accessToken") != null
        ? localStorage.getItem("accessToken")
        : null;
    if (isTokenExpired()) {
      try {
        const refreshToken = localStorage.getItem("refreshToken");

        if (!refreshToken) {
          // Handle refresh token failure (e.g., redirect to login)
          localStorage.clear();
          window.location.href = "/"; // Redirect to login page
          return request;
        }

        // Create FormData and append the refresh token
        let formData = new FormData();
        formData.append("refresh_token", refreshToken);

        // Send FormData directly as the request body
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}access_token`,
          formData
        );

        if (response.data?.data?.token?.access_token && response.data?.success) {
          const newAccessToken = response.data.data.token.access_token;
          const newRefreshToken = response.data.data.token.refresh_token;
          const expiresIn = response.data.data.token.expires_in;
          const expiresAt = Date.now() + expiresIn * 1000; // Calculate expiry timestamp

          // Store tokens and user details
          localStorage.setItem("accessToken", newAccessToken);
          localStorage.setItem("refreshToken", newRefreshToken);
          localStorage.setItem("expireTime", expiresAt.toString());
          // Update the token variable with the new access token
          token = newAccessToken;
        } else {
          // Handle case where token refresh fails unexpectedly
          localStorage.clear();
          window.location.href = "/";
          return request;
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
        // Handle refresh token failure (e.g., redirect to login)
        localStorage.clear();
        window.location.href = "/";
        return Promise.reject(error);
      }
      

    }
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }  
    request.headers.device_type = 1;
    request.headers.device_token =
      localStorage.getItem("Notify_token") != null
        ? JSON.parse(localStorage.getItem("Notify_token"))
        : null;
    request.headers.timezone = timeZone;

    return request;
  },

  (err) => {
    Promise.reject(err);
  }
);

export default axiosInstance;
