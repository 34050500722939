import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  StyledPrimaryDefaultTypography,
  StyledSwitch,
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryDefaultTypography,
  StyledSecondaryTypography,
  TypographyDefault,
  ErrorTypography,
} from "../../Theme";
import {
  Box,
  Divider,
  Tabs,
  Tab,
  Button,
  IconButton,
  Modal,
  Grid,
  Stack,
  Typography,
  TextField,
  Skeleton,
} from "@mui/material";
import { ReactComponent as Close } from "../../Icons/BorderAlertClose.svg";
import { ReactComponent as BackArrow } from "../../Icons/BorderBackArrow.svg";
import { ReactComponent as DeleteBIcon } from "../../Icons/DeleteBIcon.svg";
import { ReactComponent as BackArrowMobileIcon } from "../../Icons/BackArrowMobileIcon.svg";
import { ReactComponent as EditIcon } from "../../Icons/Edit.svg";
import AlertModal, { PostLoaderModal } from "../../CommonComponents/modal";
import axiosInstance from "../../Redux/AxiosInstance";
import { getChip } from "../../Utils/index";
import parse from "html-react-parser";
import { showToast } from "../../CommonComponents/Toaster";

export default function ReviewAnnouncement() {
  const { LanguageData } = useSelector((state) => state.Language);
  const { id } = useParams();
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { UserManage } = useSelector((state) => state.Login);
  const navigate = useNavigate();
  const [image, setImage] = useState([]);
  const [tabValue, settabValue] = useState(0);
  const [ConfirmchangeStatus, setConfirmChangeStatus] = useState(false);
  const [duplicateDraftModal, setDuplicateDraftModal] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [reviewAnnouncementData, setReviewAnnouncementData] = useState([]);
  const [checkStatus, setCheckStatus] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [errText, setErrorText] = useState("");
  const [duplicateTitle, setDuplicateTitle] = useState("");
  const [loading, setloading] = useState(true);
  const [postLoader, SetPostLoader] = useState(false);
  const [durationStart, setDurationStart] = useState(null);
  const [durationEnd, setDurationEnd] = useState(null);

  let deleteModalDatas = {
    label:
      LanguageData?.Announcement_Single_DeleteHeader ||
      "Confirm to delete the Announcement",
    descriptionOne:
      LanguageData?.Announcement_Review_Delete_Header ||
      "Please confirm that you wish to delete the announcement. Upon deletion, the announcement will disappear from both dashboard and mobile app.",
    descriptionTwo:
      LanguageData?.Announcement_Review_Delete_subHeader ||
      "If your intention is to hide the post temporarily to users, please turn the toggle to inactive instead.",

    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: LanguageData?.Announcement_Cancel || "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#B42318",
      text: LanguageData?.Common_Confirm || "Confirm",
    },
  };

  const handleTabValue = (e, value) => {
    settabValue(value);
  };
  const CustomTabPanel = ({ value, index, children }) => {
    return <>{index == value && <Box>{children}</Box>}</>;
  };

  const handleShowDeleteModal = () => {
    setshowDeleteModal(!showDeleteModal);
  };
  const deleteContactDetail = () => {
    if (id) {
      SetPostLoader(true);

      axiosInstance
        .delete(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/announcement/${id}`
        )
        .then((res) => {
          if (res.data?.success) {
            showToast(res?.data?.toast, "delete");
            setshowDeleteModal(false);
            navigate("/announcement");
            SetPostLoader(false);
          }
        })
        .catch((err) => {
          console.error(err);
          showToast(err.response?.data?.toast, "error");
        });
    }
  };

  useEffect(() => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/announcement/${id}`
      )
      .then((res) => {
        const startDate = moment.utc(
          res.data.data?.announcement?.duration_start_date
        );
        const startTime = res.data.data?.announcement?.duration_from_time;
        const combinedDateTime =
          startDate && startTime
            ? moment(startDate).add({
                hours: startTime.slice(0, 2),
                minutes: startTime.slice(3, 5),
                seconds: startTime.slice(6, 8),
              })
            : null;
        const local = moment
          .utc(combinedDateTime)
          .local()
          .format("DD MMM YYYY,HH:mm");
        setDurationStart(local);
        const EndDate = moment.utc(
          res.data.data?.announcement?.duration_end_date
        );
        const EndTime = res.data.data?.announcement?.duration_to_time;
        const combinedDateTimeEnd =
          EndDate && EndTime
            ? moment(EndDate).add({
                hours: EndTime.slice(0, 2),
                minutes: EndTime.slice(3, 5),
                seconds: EndTime.slice(6, 8),
              })
            : null;
        const local1 = moment
          .utc(combinedDateTimeEnd)
          .local()
          .format("DD MMM YYYY,HH:mm");
        setDurationEnd(local1);

        setReviewAnnouncementData(res.data.data?.announcement);
        setDuplicateTitle(res.data.data?.announcement?.title);
        setImage(res.data?.data.announcement?.images);
        if (res.data?.data.announcement?.status?.id === 1) {
          setCheckStatus(true);
        }
        setloading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [trigger]);

  const handleDuplicateDrafts = () => {
    if (duplicateTitle.trim() !== "") {
      SetPostLoader(true);
      const formData = new FormData();
      formData.append("title", duplicateTitle);

      axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/announcement_duplicate_draft/${id}`,
          formData
        )
        .then((res) => {
          if (res.data?.success) {
            showToast(res?.data?.toast);
            navigate("/announcement");
            SetPostLoader(false);
          }
        })
        .catch((err) => {
          if (err.response.data.data?.validation_error?.title) {
            setErrorText("This announcement already exist in draft.");
          }
        });
    } else if (duplicateTitle.trim() === "") {
      setErrorText("Error! No Inputs detected");
    }
  };

  const handleChangeStatus = () => {
    SetPostLoader(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/announcement_status_update/${id}`
      )
      .then((res) => {
        console.log(res);
        if (res.data?.success) {
          setCheckStatus(!checkStatus);
          setTrigger(!trigger);
          setConfirmChangeStatus(false);
          SetPostLoader(false);
          setloading(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      {showDeleteModal && (
        <AlertModal
          modalDatas={deleteModalDatas}
          confirmFunction={deleteContactDetail}
          closeFunction={handleShowDeleteModal}
        />
      )}

      <Box p={5} sx={{ backgroundColor: "#F3F4F6" }}>
        <Stack direction="row" justifyContent="space-between">
          <BackArrow
            onClick={() => {
              navigate("/Announcement");
            }}
            style={{ marginBottom: "30px", cursor: "pointer" }}
          />
        </Stack>

        <Stack mb={5} direction="row" justifyContent="space-between">
          <Box>
            <PrimaryTypography mb={1}>
              {loading ? (
                <Skeleton />
              ) : (
                LanguageData?.Announcement_Details || "Announcement Details"
              )}
            </PrimaryTypography>
            <StyledSecondaryTypography>
              {loading ? (
                <Skeleton width={200} />
              ) : (
                "Created on: " +
                moment
                  .utc(reviewAnnouncementData?.created_at)
                  .local()
                  .format("DD MMM YYYY, HH:mm")
              )}
            </StyledSecondaryTypography>
          </Box>
          {UserManage?.announcement &&
            !loading &&
            (reviewAnnouncementData?.status?.id === 5 ? (
              <Button
                sx={{
                  fontWeight: "600",
                  height: "40px",
                  width: "fit-content",
                  color: "#0E5E84",
                  backgroundColor: "#E7EFF3",
                  padding: "10px 16px 10px 16px",
                }}
                onClick={() => setDuplicateDraftModal(true)}
              >
                {LanguageData?.Announcement_Duplicate || "Duplicate as draft"}
              </Button>
            ) : (
              <DeleteBIcon
                onClick={() => {
                  setshowDeleteModal(true);
                }}
                style={{ cursor: "pointer" }}
              />
            ))}
        </Stack>
        {loading ? (
          <Skeleton variant="rounded" width="100%" height={400} />
        ) : (
          <Box
            mt={3}
            component="div"
            boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
            borderRadius="8px 8px 8px 8px"
            width="100%"
          >
            <Box
              sx={{
                borderBottom: "1px solid #E5E7EB",
                backgroundColor: "#FFFFFF",
                borderRadius: "8px 8px 0 0 ",
              }}
            >
              <Tabs
                sx={{ width: "100%" }}
                value={tabValue}
                onChange={handleTabValue}
              >
                <Tab
                  sx={{ width: "50%", maxWidth: "none" }}
                  label={
                    LanguageData?.Announcement_Switchtab_Details || "Details"
                  }
                />
                <Tab
                  sx={{ width: "50%", maxWidth: "none" }}
                  label={
                    LanguageData?.Announcement_Switchtab_Preview || "Preview"
                  }
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              <Box
                backgroundColor="white"
                alignItems="center"
                padding="24px 16px 24px 16px"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center" mb={1}>
                    <SecondaryTypography marginRight={2}>
                      {LanguageData?.Announcement_is || "Announcement is"}
                    </SecondaryTypography>
                    {getChip(
                      reviewAnnouncementData.status?.key,
                      reviewAnnouncementData.status?.id
                    )}
                  </Stack>
                  {UserManage?.announcement &&
                    reviewAnnouncementData?.status?.id !== 3 &&
                    reviewAnnouncementData?.status?.id !== 5 &&
                    reviewAnnouncementData?.status?.id !== 4 && (
                      <StyledSwitch
                        checked={checkStatus}
                        onClick={() => {
                          setConfirmChangeStatus(true);
                        }}
                        name="loading"
                        color="primary"
                      />
                    )}
                </Stack>
                {UserManage?.announcement &&
                  reviewAnnouncementData?.status?.id !== 3 &&
                  reviewAnnouncementData?.status?.id !== 5 &&
                  reviewAnnouncementData?.status?.id !== 4 && (
                    <TypographyDefault>
                      {LanguageData?.Announcement_StatusText ||
                        "Please click on the toggle button on the right to change the status."}
                    </TypographyDefault>
                  )}
              </Box>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.Publish_Date || "Publish Date"}
                </SecondaryTypography>
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                }}
              >
                <Grid xs={12} sm={5.8} my={2}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Start_Date || "Start Date"}
                  </StyledSecondaryTypography>
                  <TypographyDefault mt={0.5}>
                    {reviewAnnouncementData?.start_date
                      ? moment
                          .utc(reviewAnnouncementData.start_date)
                          .local()
                          .format("DD MMM YYYY")
                      : "-"}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} my={2}>
                  <StyledSecondaryTypography>
                    {LanguageData?.End_date || "End Date"}
                  </StyledSecondaryTypography>
                  <TypographyDefault mt={0.5}>
                    {reviewAnnouncementData?.end_date
                      ? moment
                          .utc(reviewAnnouncementData.end_date)
                          .local()
                          .format("DD MMM YYYY")
                      : "-"}
                  </TypographyDefault>
                </Grid>
              </Grid>

              {/* Announcement Type */}

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.Announcement_type || "Announcement Type"}{" "}
                </SecondaryTypography>
                {UserManage?.announcement &&
                  reviewAnnouncementData?.status?.id !== 5 && (
                    <Button
                      onClick={() => {
                        navigate(`/Announcement/editAnnouncement/${id}`);
                      }}
                      sx={{
                        width: "83px",
                        height: "36px",
                        padding: "8px 2px 8px 6px",
                        border: "1px solid #D2D6DB",
                        borderRadius: "8px",
                      }}
                    >
                      {" "}
                      {LanguageData?.Common_Edit || "Edit"}{" "}
                      <IconButton sx={{ paddingLeft: "10px" }}>
                        {" "}
                        <EditIcon width="14px" />
                      </IconButton>
                    </Button>
                  )}
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                }}
              >
                <Grid xs={12} sm={5.8}>
                  <TypographyDefault>
                    {reviewAnnouncementData?.announcement_type === 1
                      ? LanguageData?.General_Filter || "General "
                      : LanguageData?.Building_Filter || "Building Alert"}
                  </TypographyDefault>
                </Grid>
              </Grid>

              {/* Basic Information  */}

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.Basic_Information || "Basic Information"}
                </SecondaryTypography>
                {UserManage?.announcement &&
                  reviewAnnouncementData?.status?.id !== 5 && (
                    <Button
                      onClick={() => {
                        navigate(`/Announcement/editAnnouncement/${id}`);
                      }}
                      sx={{
                        width: "83px",
                        height: "36px",
                        padding: "8px 2px 8px 6px",
                        border: "1px solid #D2D6DB",
                        borderRadius: "8px",
                      }}
                    >
                      {" "}
                      {LanguageData?.Common_Edit || "Edit"}
                      <IconButton sx={{ paddingLeft: "10px" }}>
                        {" "}
                        <EditIcon width="14px" />
                      </IconButton>
                    </Button>
                  )}
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                }}
              >
                <Grid xs={12} sm={5.8} my={2}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Announcement_tab_title || "Title"}
                  </StyledSecondaryTypography>
                  <TypographyDefault mt={0.5}>
                    {reviewAnnouncementData?.title || "-"}
                  </TypographyDefault>
                </Grid>
                <Grid xs={12} sm={5.8} my={2}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Announcement_Duration ||
                      "Announcement Duration"}
                  </StyledSecondaryTypography>
                  <TypographyDefault mt={0.5}>
                    {durationStart && durationEnd
                      ? durationStart + " " + "-" + " " + durationEnd
                      : "-"}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "10px",
                  }}
                />
                <Grid xs={12} my={2}>
                  <StyledSecondaryTypography mb={0.5}>
                    {LanguageData?.Description || "Description"}
                  </StyledSecondaryTypography>
                  <TypographyDefault
                    mt={0.5}
                    sx={{
                      wordWrap: "break-word",
                      "&>p": {
                        margin: "0px !important",
                        padding: "0px !important",
                      },
                    }}
                  >
                    {Boolean(reviewAnnouncementData?.description)
                      ? parse(reviewAnnouncementData?.description)
                      : "-"}
                  </TypographyDefault>
                </Grid>
              </Grid>

              {/* IMages */}

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.Image || "Image"}
                </SecondaryTypography>
                {UserManage?.announcement &&
                  reviewAnnouncementData?.status?.id !== 5 && (
                    <Button
                      onClick={() => {
                        navigate(`/Announcement/editAnnouncement/${id}`);
                      }}
                      sx={{
                        width: "83px",
                        height: "36px",
                        padding: "8px 2px 8px 6px",
                        border: "1px solid #D2D6DB",
                        borderRadius: "8px",
                      }}
                    >
                      {" "}
                      {LanguageData?.Common_Edit || "Edit"}
                      <IconButton sx={{ paddingLeft: "10px" }}>
                        {" "}
                        <EditIcon width="14px" />
                      </IconButton>
                    </Button>
                  )}
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Grid xs={12}>
                  <Stack
                    padding="20px 16px 20px 16px"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {image?.path ? (
                      <img
                        key={image.id}
                        src={`${process.env.REACT_APP_FILE_PATH}${image.path}`}
                        alt={image.name}
                        width="120px"
                        height="120px"
                        style={{
                          borderRadius: "10px",
                          marginRight: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    ) : (
                      <TypographyDefault>
                        {" "}
                        {LanguageData?.Common_Noimage || "No images available"}
                      </TypographyDefault>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </CustomTabPanel>

            <CustomTabPanel value={tabValue} index={1}>
              <Stack
                py={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#F9FAFB",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <StyledPrimaryDefaultTypography>
                  {LanguageData?.Mobile_Preview || "Mobile App Preview"}
                </StyledPrimaryDefaultTypography>
                <StyledSecondaryTypography>
                  {LanguageData?.Scroll_mobile ||
                    "Scroll on the mobile phone to review details"}
                </StyledSecondaryTypography>
              </Stack>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  my={6.5}
                  sx={{
                    width: "375px",
                    height: "750px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "8px",
                    overflowY: "scroll",
                    // "::-webkit-scrollbar": { display: "initial" },
                  }}
                >
                  <Stack sx={{ padding: "32px 16px 32px 16px" }}>
                    <BackArrowMobileIcon />
                    <Typography
                      mt={5}
                      sx={{
                        fontSize: "24px !important",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {reviewAnnouncementData?.title || "--"}
                    </Typography>
                  </Stack>
                  {image?.path ? (
                    <img
                      key={image.id}
                      src={`${process.env.REACT_APP_FILE_PATH}${image.path}`}
                      alt={image.name}
                      width="100%"
                      height="211px"
                      style={{ objectFit: "cover" }}
                    />
                  ) : (
                    <TypographyDefault pl={2}>
                      {" "}
                      {LanguageData?.Common_Noimage || "No images available"}
                    </TypographyDefault>
                  )}
                  {reviewAnnouncementData && (
                    <>
                      <Box padding="24px 16px 24px 16px">
                        {" "}
                        <SecondaryTypography>
                          {reviewAnnouncementData?.start_date
                            ? moment
                                .utc(reviewAnnouncementData.start_date)
                                .local()
                                .format("DD MMM YYYY")
                            : "-"}{" "}
                          -{" "}
                          {reviewAnnouncementData?.end_date
                            ? moment
                                .utc(reviewAnnouncementData.end_date)
                                .local()
                                .format("DD MMM YYYY")
                            : "-"}
                        </SecondaryTypography>
                      </Box>

                      <Box height="10px" backgroundColor="#F9FAFB">
                        {" "}
                      </Box>
                    </>
                  )}
                  <Stack
                    gap={2.5}
                    height="100%"
                    sx={{ padding: "32px 16px 32px 16px" }}
                  >
                    <Stack gap="4px">
                      <SecondaryTypography mb={2}>
                        {LanguageData?.Announcement_Switchtab_Details ||
                          "Details"}
                      </SecondaryTypography>
                      <TypographyDefault
                        sx={{
                          "&>p": {
                            margin: "0px !important",
                            padding: "0px !important",
                            breakWord: "break-all !important",
                            whiteSpace: "pre-wrap !important",
                            overflowWrap: "break-word !important",
                          },
                          "& li": {
                            padding: "5px !important",
                            whiteSpace: "pre-wrap !important",
                            overflowWrap: "break-word !important",
                          },
                        }}
                      >
                        {Boolean(reviewAnnouncementData?.description)
                          ? parse(reviewAnnouncementData?.description)
                          : "-"}{" "}
                      </TypographyDefault>
                      <StyledSecondaryTypography
                        mt={5}
                        fontSize="12px !important"
                      >
                        {LanguageData?.Published_on || " Published on"}{" "}
                        {reviewAnnouncementData?.start_date
                          ? moment
                              .utc(reviewAnnouncementData.start_date)
                              .local()
                              .format("DD MMM YYYY")
                          : "-"}
                      </StyledSecondaryTypography>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </CustomTabPanel>
          </Box>
        )}
      </Box>

      <Modal open={ConfirmchangeStatus}>
        <Box
          sx={{
            position: "absolute",
            border: "none",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: "40%",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: "2px solid #F3F4F6",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PrimaryTypography>
                {LanguageData?.Confirm_Inactive ||
                  "Confirm Inactive Immediately"}
              </PrimaryTypography>
              <Close onClick={() => setConfirmChangeStatus(false)} />
            </Box>
            <Box sx={{ px: 2, py: 3, borderBottom: "2px solid #F3F4F6" }}>
              {checkStatus ? (
                <StyledSecondaryDefaultTypography
                  sx={{ color: "#1F2A37 !important" }}
                >
                  {LanguageData?.Confirm_Inactive_header ||
                    " Please confirm if you wish to inactive the announcement immediately.  "}
                </StyledSecondaryDefaultTypography>
              ) : (
                <StyledSecondaryDefaultTypography
                  sx={{ color: "#1F2A37 !important" }}
                >
                  {LanguageData?.Active_header ||
                    "  Please confirm if you wish to active the announcement immediately."}
                </StyledSecondaryDefaultTypography>
              )}
              {checkStatus ? (
                <StyledSecondaryDefaultTypography
                  sx={{ mt: 3, color: "#1F2A37 !important" }}
                >
                  {" "}
                  {LanguageData?.Active_to_InActive ||
                    "The status will change from Active to Inactive."}
                </StyledSecondaryDefaultTypography>
              ) : (
                <StyledSecondaryDefaultTypography
                  sx={{ mt: 3, color: "#1F2A37 !important" }}
                >
                  {LanguageData?.Inactive_to_Active ||
                    "The status will change from Inactive to Active."}
                </StyledSecondaryDefaultTypography>
              )}

              <Box sx={{ mt: 3 }} display="flex" alignItems="center">
                {checkStatus ? getChip("active") : getChip("inactive")}
                <Box display="flex" alignItems="center">
                  <div
                    style={{
                      marginLeft: "6px",
                      borderBottom: "1px solid black",
                      width: "50px",
                      height: "1px",
                    }}
                  ></div>
                  <span style={{ marginRight: "6px" }}>{">"}</span>
                </Box>
                {!checkStatus ? getChip("active") : getChip("inactive")}
              </Box>
            </Box>
            <Box
              sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={() => setConfirmChangeStatus(false)}
                sx={{ background: "#FFFFFF", color: "#0E5E84" }}
                variant="outlined"
              >
                {LanguageData?.Announcement_Cancel || "Cancel"}
              </Button>
              <Button
                sx={{
                  background: "#B42318",
                  color: "#FFFFFF",
                  "&:hover": { background: "#B42318" },
                }}
                variant="contained"
                onClick={handleChangeStatus}
              >
                {LanguageData?.Common_Confirm || "Confirm"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal open={duplicateDraftModal}>
        <Box
          sx={{
            position: "absolute",
            border: "none",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: "40%",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: "2px solid #F3F4F6",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PrimaryTypography>
                {" "}
                {LanguageData?.Duplicate_Draft || "Duplicate as Draft"}
              </PrimaryTypography>
              <Close
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDuplicateDraftModal(false);
                  setErrorText("");
                }}
              />
            </Box>
            <Box sx={{ px: 2, py: 3, borderBottom: "2px solid #F3F4F6" }}>
              <StyledSecondaryDefaultTypography
                sx={{ color: "#1F2A37 !important" }}
              >
                {LanguageData?.Duplicate_Description ||
                  "Please confirm if you wish to duplicate as draft for “Power Outage Announcement“. Once you confirm, the announcement will be saved in a draft screen."}
              </StyledSecondaryDefaultTypography>
              <SecondaryTypography sx={{ mt: 3, color: "#1F2A37 !important" }}>
                {" "}
                {LanguageData?.Event_title || "Event Title "}
              </SecondaryTypography>

              <Box sx={{}} display="flex" alignItems="center">
                <TextField
                  sx={{
                    "& .MuiInputBase-root": {
                      color: errText ? "#912018" : "#111927",
                    },
                    backgroundColor: errText ? "#FEE4E2" : " #F9FAFB",
                  }}
                  name="title"
                  placeholder="Enter Title"
                  inputProps={{ maxLength: 50 }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  value={duplicateTitle}
                  onChange={(e) => {
                    setDuplicateTitle(e.target.value);
                    setErrorText("");
                  }}
                />
              </Box>
              {errText && <ErrorTypography>{errText}</ErrorTypography>}
            </Box>
            <Box
              sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={() => {
                  setDuplicateDraftModal(false);
                  setErrorText("");
                }}
                sx={{ background: "#FFFFFF", color: "#0E5E84" }}
                variant="outlined"
              >
                {LanguageData?.Announcement_Cancel || "Cancel"}
              </Button>
              <Button
                sx={{
                  padding: "10px 16px",
                  borderRadius: "8px",
                  backgroundColor: "#0E5E84",
                  color: "#FFF",
                  fontSize: "14px",
                  fontWeight: 600,
                  '&:hover':{
                    backgroundColor:"#0E4965"
                  }
                }}
                onClick={handleDuplicateDrafts}
              >
                {LanguageData?.Common_Confirm || "Confirm"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
