import React, { useState } from "react";
import { Box, Select, MenuItem,SvgIcon } from "@mui/material";
import { ReactComponent as DropArrowIcon } from "../../Icons/SelectDropdownArrow.svg";
import { TypoGraph, StyledSecondaryServiceTypography } from "../../Theme";
import { ReactComponent as ActiveIcon } from "../../Icons/ActiveGraph.svg";
import { ReactComponent as InactiveIcon } from "../../Icons/InactiveIcon.svg";
import { ReactComponent as OffBoardIcon } from "../../Icons/OffBoardIcon.svg";
import { useSelector } from "react-redux";
import VisitorGraph from "./VisitorGraph";
import StaffGraph from "./StaffGraph";
import ResidentGraph from "./ResidentGraph";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ManagementGraph = () => {
  const { ResidentData, VisitorData, StaffData } = useSelector(
    (state) => state.OverView
  );
  const { LanguageData } = useSelector((state) => state.Language);
  const [managementType, setManagementType] = useState("Residents");

  return (
    <>
      {
        <>
          <Box
            sx={{
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
              padding: "16px",
              backgroundColor: "#FCFCFD",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box data-html2canvas-ignore="true" sx={{ cursor: "pointer" }}>
                <Select
                  value={managementType}
                  name="2023"
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #D2D6DB",
                    backgroundColor: "#FFFFFF",
                    padding: "8px 14px 8px 14px",
                    color: "#0E5E84",
                    fontWeight: 600,
                    fontSize: "14px",
                    height: "36px",
                  }}
                  IconComponent={KeyboardArrowDownIcon}
                 
                  onChange={(e) => setManagementType(e.target.value)}
                >
                  <MenuItem value="Residents">
                    {LanguageData?.home_select_resident || "Residents"}
                  </MenuItem>
                  <MenuItem value="Staff">
                    {" "}
                    {LanguageData?.home_select_staff || "Staff"}
                  </MenuItem>
                  <MenuItem value="Visitors">
                    {" "}
                    {LanguageData?.home_select_visitor || "Visitors"}
                  </MenuItem>
                </Select>
              </Box>
              <Box sx={{ marginLeft: 2 }}>
                {managementType === "Residents" ? (
                  <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
                    {` ${
                      LanguageData?.home_total_resident || "Total Residents"
                    }${" "}${ResidentData?.total_count || 0} `}
                  </StyledSecondaryServiceTypography>
                ) : managementType === "Visitors" ? (
                  <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
                    {` ${
                      LanguageData?.home_total_visitor || "Total Visitors"
                    }${" "}${VisitorData?.total_count || 0} `}
                  </StyledSecondaryServiceTypography>
                ) : (
                  <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
                    {` ${
                      LanguageData?.home_total_staff || "Total Staffs"
                    }${" "}${StaffData?.total_count || 0} `}
                  </StyledSecondaryServiceTypography>
                )}
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {managementType === "Visitors" ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginRight: "20px",
                      alignItems: "center",
                    }}
                  >
                    <ActiveIcon style={{ marginRight: "10px" }} />
                    <TypoGraph>
                      {" "}
                      {LanguageData?.home_select_visitor || "Visitors"}
                    </TypoGraph>
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "20px",
                        alignItems: "center",
                      }}
                    >
                      <ActiveIcon style={{ marginRight: "10px" }} />
                      <TypoGraph>
                        {" "}
                        {LanguageData?.home_active || "Active"}
                      </TypoGraph>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginRight: "20px",
                      }}
                    >
                      <InactiveIcon style={{ marginRight: "10px" }} />
                      <TypoGraph>
                        {LanguageData?.home_inactive || "Inactive"}
                      </TypoGraph>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <OffBoardIcon style={{ marginRight: "10px" }} />
                      <TypoGraph>
                        {LanguageData?.home_offboarc || "Offboard"}
                      </TypoGraph>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>

          {managementType === "Visitors" && (
            <Box
              sx={{
                borderRadius: "0px 0px 8px 8px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <VisitorGraph />
            </Box>
          )}

          {managementType === "Staff" && (
            <Box
              sx={{
                borderRadius: "0px 0px 8px 8px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <StaffGraph />
            </Box>
          )}

          {managementType === "Residents" && (
            <Box
              sx={{
                borderRadius: "0px 0px 8px 8px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <ResidentGraph />
            </Box>
          )}
        </>
      }
    </>
  );
};

export default ManagementGraph;
