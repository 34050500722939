import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import dayjs from "dayjs";
import CustomDateTimeRange from "../../CommonComponents/CustomDateTimeRange";
import CustomTextEditor from "../../CommonComponents/CustomTextEditor";
import {
  ErrorTypography,
  StyledPrimaryDefaultTypography,
  PrimaryTypography,
  SecondaryTypography,
  SecondaryTypographyDefault,
  StyledSecondaryServiceTypography,
  StyledSecondaryDefaultTypography,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../Theme";
import {
  Box,
  Divider,
  Tabs,
  Tab,
  Button,
  Checkbox,
  TextField,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";

import { ReactComponent as BackArrow } from "../../Icons/BorderBackArrow.svg";
import { ReactComponent as CheckboxIcon } from "../../Icons/Checkbox.svg";
import { ReactComponent as JPGIcon } from "../../Icons/Jpg.svg";
import { ReactComponent as DeleteIcon } from "../../Icons/DeleteNB.svg";
import { ReactComponent as UploadIcon } from "../../Icons/UploadIcon.svg";
import { ReactComponent as RefreshIcon } from "../../Icons/Refresh.svg";
import { ReactComponent as ContactDetailsIcon } from "../../Icons/ContactDetails.svg";
import { ReactComponent as DatePickerIcon } from "../../Icons/Datepicker.svg";
import { ReactComponent as BackArrowMobileIcon } from "../../Icons/BackArrowMobileIcon.svg";
import axiosInstance from "../../Redux/AxiosInstance";
import { DatePicker } from "@mui/x-date-pickers";
import AlertModal, { PostLoaderModal } from "../../CommonComponents/modal";
import { showToast } from "../../CommonComponents/Toaster";

const endAdornmentStyles = {
  position: "absolute",
  right: 0,
  top: "-20px",
  fontSize: "12px !important",
  "&>*": { fontSize: "12px !important", fontWeight: "400", color: "#9DA4AE" },
};

export default function EditAnnouncement() {
  const { LanguageData } = useSelector((state) => state.Language);
  const navigate = useNavigate();
  const { id } = useParams();
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const [addblocks, setaddblocks] = useState({
    Announcement_Details: true,
    Review_Information: false,
  });
  const blocks = [
    { status: addblocks.Announcement_Details, tle: "Announcement Details" },
    { status: addblocks.Review_Information, tle: "Review Information" },
  ];
  const ref = useRef();
  const [tabValue, settabValue] = useState(0);
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState({});
  const [changedImage, setChangedImage] = useState([]);
  console.log(changedImage);
  const [date, setDate] = useState({ startDate: null, endDate: null });
  const [errText, setErrtext] = useState({
    type: false,
    title: false,
    description: false,
    location: false,
    image: false,
    terms_conditions: false,
    startDate: false,
    endDate: false,
  });

  const [titleErr, setTitleErr] = useState("");
  const [htmlContent, setHtmlContent] = useState("");

  const [checkdescription, setcheckdescription] = useState(0);
  const [DateTimeRangeStatus, setDateTimeRangeStatus] = useState(false);
  const [datetimerangedata, setdatetimerangedata] = useState([null, null]);
  const [time, setTime] = useState({ start: "", end: "" });
  const [isDatafilled, setIsDataFilled] = useState(false);
  const [editData, setEditData] = useState([]);
  const [postLoader, SetPostLoader] = useState(false);

  useEffect(() => {
    const descriptionLength = description?.replace(/<[^>]+>/g, "");
    setcheckdescription(descriptionLength.trim().length);
    setErrtext((prev) => ({ ...prev, description: false }));
  }, [description]);

  const handleDeleteImage = (imageId) => {
    if (imageId) {
      axiosInstance
        .delete(`${process.env.REACT_APP_API_URL}delete_image/${imageId}`)
        .then((res) => {
          if (res.data?.success) {
            setImage({});
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/announcement/${id}`
      )
      .then((res) => {
        setEditData(res.data?.data.announcement);
        setType(res.data?.data.announcement?.announcement_type);
        setTitle(res.data?.data.announcement?.title);
        setDescription(
          Boolean(res.data?.data.announcement?.description)
            ? res.data?.data.announcement?.description
            : ""
        );

        setImage(res.data?.data.announcement?.images);

        if (
          Boolean(res.data?.data.announcement?.duration_start_date) &&
          Boolean(res.data?.data.announcement?.duration_end_date)
        ) {
          setDateTimeRangeStatus(true);
          const startDate = moment.utc(
            res.data.data?.announcement?.duration_start_date
          );
          const startTime = res.data.data?.announcement?.duration_from_time;
          const combinedDateTime =
            startDate && startTime
              ? moment(startDate).add({
                  hours: startTime.slice(0, 2),
                  minutes: startTime.slice(3, 5),
                  seconds: startTime.slice(6, 8),
                })
              : null;
          const local = moment
            .utc(combinedDateTime)
            .local()
            .format("DD MMM YYYY");
          const StartTime = moment
            .utc(combinedDateTime)
            .local()
            .format("HH:mm:ss");
          const EndDate = moment.utc(
            res.data.data?.announcement?.duration_end_date
          );
          const EndTime = res.data.data?.announcement?.duration_to_time;
          const combinedDateTimeEnd =
            EndDate && EndTime
              ? moment(EndDate).add({
                  hours: EndTime.slice(0, 2),
                  minutes: EndTime.slice(3, 5),
                  seconds: EndTime.slice(6, 8),
                })
              : null;
          const local1 = moment
            .utc(combinedDateTimeEnd)
            .local()
            .format("DD MMM YYYY");
          const EndTimeDuration = moment
            .utc(combinedDateTimeEnd)
            .local()
            .format("HH:mm:ss");
          setdatetimerangedata([dayjs(local), dayjs(local1)]);
          setTime({
            start: StartTime,
            end: EndTimeDuration,
          });
        }
        const AnnouncementStart = moment
          .utc(res.data?.data.announcement?.start_date)
          .local();
        const AnnouncementEnd = moment
          .utc(res.data?.data.announcement?.end_date)
          .local();
        setDate({
          startDate: dayjs(AnnouncementStart?._d),
          endDate: dayjs(AnnouncementEnd?._d),
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handeChangeEvent = (e) => {};

  const inputImg = useRef();
  const handleInputImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setChangedImage([{ file: file, src: URL.createObjectURL(file) }]);
      setErrtext((prev) => ({ ...prev, image: false }));
    }
  };

  const handleImage = () => {
    inputImg.current.click();
  };

  const handleDropImage = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setChangedImage([{ file: file, src: URL.createObjectURL(file) }]);
      setErrtext((prev) => ({ ...prev, image: false }));
      e.target.value = null;
      // seterrtextFeatureImages((prev) => ({ ...prev, FacilityImages: false }))
    }
  };
  const handleDragImage = (e) => {
    e.preventDefault();
  };

  const valid = () => {
    let isError = false;
    if (type == "") {
      setErrtext((prev) => ({ ...prev, type: true }));
      isError = true;
    }
    if (title == "") {
      setErrtext((prev) => ({ ...prev, title: true }));
      isError = true;
    }
    if (checkdescription == 0) {
      setErrtext((prev) => ({ ...prev, description: true }));
      isError = true;
    }

    if (!changedImage[0] && !image?.path) {
      setErrtext((prev) => ({ ...prev, image: true }));
      isError = true;
    }
    if (date?.startDate == null) {
      setErrtext((prev) => ({ ...prev, startDate: true }));
      isError = true;
    }
    if (date?.endDate == null) {
      setErrtext((prev) => ({ ...prev, endDate: true }));
      isError = true;
    }
    return isError;
  };

  const Continue = (nxtval) => {
    if (!valid()) {
      if (nxtval == "Announcement Details") {
        setaddblocks({ Announcement_Details: true, Review_Information: false });
        setIsDataFilled(true);
      } else if (nxtval == "Review Information") {
        setaddblocks({ Announcement_Details: false, Review_Information: true });
        setIsDataFilled(true);
        // setHtmlContent(description);
      }
    } else {
      setIsDataFilled(false);
    }
  };

  const handleTabValue = (e, value) => {
    settabValue(value);
  };
  const CustomTabPanel = ({ value, index, children }) => {
    return <>{index == value && <Box>{children}</Box>}</>;
  };
  const sanitizeHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const handleEditAnnouncement = () => {
    const checkStartDate =
      moment(date?.startDate?.$d).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const StartDateToday = moment(new Date(), "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const checkEndDate =
      moment(date?.endDate?.$d).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const EndDateToday = moment(new Date(), "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const AnnouncemntStart = date?.startDate?.$d;
    const final = moment(AnnouncemntStart).hour(0).minute(0).second(0);
    const final1 = moment.utc(final).format("YYYY-MM-DD HH:mm:ss");
    const AnnouncemntEnd = date?.endDate?.$d;
    const finalEnd = moment(AnnouncemntEnd).hour(0).minute(0).second(0);
    const finalEnd1 = moment.utc(finalEnd).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
    formData.append("title", title);
    console.log(editData.status?.id);
    if (editData.status?.id !== 1 && editData.status?.id !== 2) {
      formData.append("start_date", checkStartDate ? StartDateToday : final1);
      formData.append("end_date", checkEndDate ? EndDateToday : finalEnd1);
    }
    if (datetimerangedata[0] && datetimerangedata[1]) {
      const dateStartRange = moment(datetimerangedata[0]?.$d).format(
        "YYYY-MM-DD"
      );
      const StartTime = time?.start;
      const combinedDateTime = new Date(
        new Date(dateStartRange).setHours(
          StartTime.slice(0, 2),
          StartTime.slice(3, 5),
          StartTime.slice(6, 8)
        )
      );
      const combinedDateTimeUTC = moment.utc(combinedDateTime);
      const dateEndRange = moment(datetimerangedata[1]?.$d).format(
        "YYYY-MM-DD"
      );
      const EndTime = time?.end;
      const combinedDateTimeEnd = new Date(
        new Date(dateEndRange).setHours(
          EndTime.slice(0, 2),
          EndTime.slice(3, 5),
          EndTime.slice(6, 8)
        )
      );
      const combinedDateTimeUTCEnd = moment.utc(combinedDateTimeEnd);
      formData.append(
        "duration_start_date",
        moment(combinedDateTimeUTC).format("YYYY-MM-DD")
      );
      formData.append(
        "duration_end_date",
        moment(combinedDateTimeUTCEnd).format("YYYY-MM-DD")
      );
      formData.append(
        "duration_from_time",
        moment(combinedDateTimeUTC).format("HH:mm:ss")
      );
      formData.append(
        "duration_to_time",
        moment(combinedDateTimeUTCEnd).format("HH:mm:ss")
      );
    }
    // else if (
    //   !datetimerangedata[0] &&
    //   !datetimerangedata[1] &&
    //   editData.duration_start_date &&
    //   editData.duration_end_date
    // ) {
    //   formData.append(
    //     "duration_start_date",
    //     moment(editData.duration_start_date).format("YYYY-MM-DD")
    //   );
    //   formData.append(
    //     "duration_end_date",
    //     moment(editData.duration_end_date).format("YYYY-MM-DD")
    //   );
    // }

    formData.append("description", description);
    formData.append("announcement_type", type);
    if (editData.status?.id === 4) {
      formData.append("is_published", "1");
    }

    if (changedImage[0]) {
      formData.append("image_path[]", changedImage[0].file);
    }

    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/announcement/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        if (res?.data) {
          if (res.data?.success) {
            navigate("/announcement");
            showToast(res?.data?.toast);
          }
        }
      })
      .catch((err) => {
        showToast(err.response?.data?.toast, "error");
        if (
          !err.response.data.success &&
          err.response.data?.data === "The title has already been taken."
        ) {
          setaddblocks({
            Announcement_Details: true,
            Review_Information: false,
          });
          setErrtext({ title: true });
          setTitleErr("The title has already been taken.");
        }
      });
  };

  const Announcement_Details = () => {
    return (
      <>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px 8px 0 0"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {" "}
              {LanguageData?.Select_Type || "Select Type"}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            {errText?.type && (
              <Grid
                xs={12}
                mb={1.5}
                borderRadius="8px"
                padding="9px 0px 9px 0px"
                textAlign="center"
                backgroundColor="#FEE4E2"
              >
                <ErrorTypography marginTop="0 !important">
                  {LanguageData?.Announcement_type_select ||
                    "Please select announcement type"}
                </ErrorTypography>
              </Grid>
            )}
            <Grid xs={5.8}>
              <Stack
                onClick={() => {
                  setType("1");
                  setErrtext((prev) => ({ ...prev, type: false }));
                }}
                direction="row"
                alignItems="start"
                justifyContent="start"
                p={1.5}
                borderRadius="8px"
                sx={{
                  backgroundColor: type == 1 ? "#EDF6FA" : "#F9FAFB",
                  border: type == 1 ? "1px solid #0E4965" : "1px solid white",
                }}
              >
                <Stack direction="column">
                  <SecondaryTypographyDefault color="#0E4965 !important">
                    {LanguageData?.General_Filter || "General"}
                  </SecondaryTypographyDefault>
                  <StyledSecondaryTypography>
                    {LanguageData?.Announcement_type_description ||
                      "Notify all residents of general information, including updates, news, and developments."}
                  </StyledSecondaryTypography>
                </Stack>
                <Checkbox checked={type == "1"} icon={<CheckboxIcon />} />
              </Stack>
            </Grid>

            <Grid xs={5.8}>
              <Stack
                onClick={() => {
                  setType("2");
                  setErrtext((prev) => ({ ...prev, type: false }));
                }}
                direction="row"
                alignItems="start"
                justifyContent="start"
                p={1.5}
                borderRadius="8px"
                sx={{
                  backgroundColor: type == 2 ? "#EDF6FA" : "#F9FAFB",
                  border: type == 2 ? "1px solid #0E4965" : "1px solid white",
                }}
              >
                <Stack direction="column">
                  <SecondaryTypographyDefault color="#0E4965 !important">
                    {LanguageData?.Building_Filter || "Building Alert"}
                  </SecondaryTypographyDefault>
                  <StyledSecondaryTypography>
                    {LanguageData?.Announcement_type_description_building ||
                      "Inform all residents of critical issues, such as technical failures and safety-related delays."}
                  </StyledSecondaryTypography>
                </Stack>
                <Checkbox checked={type == "2"} icon={<CheckboxIcon />} />
              </Stack>
            </Grid>
          </Grid>

          {/* Announcement Details  */}

          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {" "}
              {LanguageData?.Announcement_Details || "Announcement Details"}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12}>
              <StyledTypography>
                {LanguageData?.Announcement_tab_title || "Title"}
              </StyledTypography>
              <TextField
                sx={{
                  "& .MuiInputBase-root": {
                    color: errText?.title ? "#912018" : "#111927",
                  },
                  backgroundColor: errText?.title ? "#FEE4E2" : " #F9FAFB",
                }}
                name="title"
                placeholder="Enter title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setErrtext((prev) => ({ ...prev, title: false }));
                  setTitleErr("");
                }}
                inputProps={{ maxLength: 50 }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment sx={endAdornmentStyles}>
                      {title?.length}/50
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
              {titleErr && <ErrorTypography>{titleErr}</ErrorTypography>}
            </Grid>

            <Grid xs={12} mt={3}>
              <StyledTypography>
                {" "}
                {LanguageData?.Description || "Description"}
              </StyledTypography>
              <Box mt={2} sx={{ position: "relative" }}>
                <CustomTextEditor
                  textEditorvalue={description}
                  settextEditorvalue={setDescription}
                  setcheckdescription={setcheckdescription}
                  label={"Write description"}
                  textEditorErr={errText?.description}
                />
              </Box>
              <StyledSecondaryServiceTypography
                mt={1.5}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  fontSize: "12px !important",
                  color: "#9DA4AE !important",
                }}
              >
                {checkdescription}/3000
              </StyledSecondaryServiceTypography>
            </Grid>
          </Grid>

          {/* Duration */}

          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {" "}
              {LanguageData?.Duration || "Duration"}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12}>
              <StyledTypography mb={1.4}>
                {" "}
                {LanguageData?.Date_and_Range || "Date & Time Range"}
              </StyledTypography>
              <CustomDateTimeRange
                datetimerangedata={datetimerangedata}
                setdatetimerangedata={setdatetimerangedata}
                time={time}
                setTime={setTime}
                isStatus={DateTimeRangeStatus}
                setIsStatus={setDateTimeRangeStatus}
                errDateRange={errText?.duration}
                seterrDateRange={setErrtext}
              />
            </Grid>
          </Grid>

          {/* Image */}

          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>
              {" "}
              {LanguageData?.Image || "Image"}{" "}
            </SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
          >
            <Grid xs={12}>
              {!image?.path && changedImage.length < 1 && (
                <Stack
                  onDragOver={handleDragImage}
                  onDrop={handleDropImage}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    padding: "24px 16px 24px 16px",
                    border: errText.image
                      ? "1px solid #912018"
                      : "1px solid #EAECF0",
                    borderRadius: "12px",
                    backgroundColor: errText.image ? "#FEE4E2" : "",
                    cursor: "pointer",
                  }}
                >
                  <Stack
                    flex={1}
                    direction="column"
                    alignItems="center"
                    textAlign="center"
                  >
                    {errText.image && (
                      <ErrorTypography mb={2}>
                        {LanguageData?.Upload_image ||
                          "Please upload announcement image"}
                      </ErrorTypography>
                    )}

                    <UploadIcon onClick={!image?.path ? handleImage : null} />
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png,.svg,.gif"
                      ref={inputImg}
                      onChange={handleInputImage}
                      hidden
                    />
                    <Stack
                      onClick={!image?.path ? handleImage : null}
                      my="5px"
                      direction="row"
                    >
                      <Typography
                        marginRight="5px"
                        sx={{
                          fontSize: "14px !important",
                          fontWeight: "600 !important",
                          color: "#0E4965 !important",
                        }}
                      >
                        {LanguageData?.Click || "Click to upload"}
                      </Typography>
                      <StyledSecondaryTypography sx={{ color: "#4D5761" }}>
                        {LanguageData?.Drag || "or drag and drop"}
                      </StyledSecondaryTypography>
                    </Stack>
                    <StyledSecondaryTypography
                      sx={{ color: "#4D5761", fontSize: "12px !important" }}
                    >
                      {LanguageData?.File_type ||
                        "JPG, PNG (max. 25 megabytes)"}
                    </StyledSecondaryTypography>
                    {/* <StyledSecondaryServiceTypography
                    mt={0.5}
                    sx={{ fontSize: "12px !important" }}
                  >
                    {changedImage.length === 0 && "1 image"}
                  </StyledSecondaryServiceTypography> */}
                  </Stack>
                </Stack>
              )}

              {image?.path && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #EAECF0",
                    borderRadius: "12px",
                    p: 2,
                    mt: 2,
                  }}
                >
                  <Stack direction="row">
                    <JPGIcon style={{ marginRight: "12px" }} />
                    <Stack direction="column">
                      <StyledSecondaryTypography>
                        {image?.name}
                      </StyledSecondaryTypography>
                      <StyledSecondaryTypography>
                        {image.file_size.toFixed(2) + "MB"}
                      </StyledSecondaryTypography>
                    </Stack>
                  </Stack>
                  <DeleteIcon
                    onClick={() => handleDeleteImage(image?.id)}
                    width={40}
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              )}
              {changedImage.length > 0 &&
                changedImage?.map((itm) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        border: "1px solid #EAECF0",
                        borderRadius: "12px",
                        p: 2,
                        mt: 2,
                      }}
                    >
                      <Stack direction="row">
                        <JPGIcon style={{ marginRight: "12px" }} />
                        <Stack direction="column">
                          <StyledSecondaryTypography>
                            {itm.file?.name}
                          </StyledSecondaryTypography>
                          <StyledSecondaryTypography>
                            {(itm.file?.size / 1048576).toFixed(2) + "MB"}{" "}
                          </StyledSecondaryTypography>
                        </Stack>
                      </Stack>
                      <DeleteIcon
                        onClick={() => setChangedImage([])}
                        width={40}
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                  );
                })}
            </Grid>
          </Grid>

          {/* Publish Date */}

          {editData.status?.id !== 1 && editData.status?.id !== 2 && (
            <>
              <Stack
                direction="row"
                alignItems="center"
                gap="10px"
                px="16px"
                sx={{
                  height: "66px",
                  backgroundColor: "#FCFCFD",
                  borderBottom: "1px solid #E5E7EB",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <ContactDetailsIcon />
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.Publish_Date || "Publish Date"}{" "}
                </SecondaryTypography>
              </Stack>
              <Grid
                container
                xs={12}
                gap="2.8%"
                sx={{
                  backgroundColor: "white",
                  padding: "30px 16px 30px 16px",
                }}
              >
                <Grid xs={12} sm={5.8}>
                  <StyledTypography>
                    {" "}
                    {LanguageData?.Start_Date || "Start Date"}{" "}
                  </StyledTypography>
                  <DatePicker
                    disablePast
                    value={date?.startDate || null}
                    slotProps={{
                      textField: { placeholder: "Select start date" },
                    }}
                    onChange={(e) => {
                      setDate((prev) => ({ endDate: null, startDate: e }));
                      setErrtext((prev) => ({ ...prev, startDate: false }));
                    }}
                    format="DD/MM/YYYY"
                    slots={{
                      openPickerIcon: () => (
                        <DatePickerIcon
                          stroke={errText?.startDate ? "#912018" : ""}
                        />
                      ),
                    }}
                    sx={{
                      ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
                        color: errText?.startDate ? "#912018 !important" : "",
                      },
                      backgroundColor: errText?.startDate ? "#FEE4E2" : "",
                      paddingRight: "20px",
                      ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:before": {
                        borderBottom: "none",
                      },
                      ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:after": {
                        borderBottom: "none",
                      },
                      ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:hover": {
                        borderBottom: "none",
                      },
                      ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before":
                        { borderBottom: "none" },
                    }}
                  />
                </Grid>
                <Grid xs={12} sm={5.8}>
                  <StyledTypography>
                    {" "}
                    {LanguageData?.End_date || "End Date"}
                  </StyledTypography>
                  <DatePicker
                    minDate={date?.startDate || null}
                    value={date?.endDate || null}
                    slotProps={{
                      textField: { placeholder: "Select end date" },
                    }}
                    onChange={(e) => {
                      setDate((prev) => ({ ...prev, endDate: e }));
                      setErrtext((prev) => ({ ...prev, endDate: false }));
                    }}
                    format="DD/MM/YYYY"
                    slots={{
                      openPickerIcon: () => (
                        <DatePickerIcon
                          stroke={errText?.endDate ? "#912018" : ""}
                        />
                      ),
                    }}
                    sx={{
                      ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
                        color: errText?.endDate ? "#912018 !important" : "",
                      },
                      backgroundColor: errText?.endDate ? "#FEE4E2" : "",
                      paddingRight: "20px",
                      ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:before": {
                        borderBottom: "none",
                      },
                      ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:after": {
                        borderBottom: "none",
                      },
                      ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:hover": {
                        borderBottom: "none",
                      },
                      ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before":
                        { borderBottom: "none" },
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Box>

        <Box
          bgcolor="#FFFFFF"
          sx={{
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="space-between"
            alignItems="center"
            height="60px"
          >
            <Stack direction="row">
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                {LanguageData?.Common_last_Update || "Last Updated at"}{" "}
                {moment.utc(editData?.updated_at).local().format("HH:mm A")}
              </StyledSecondaryTypography>
            </Stack>
            <Stack direction="column" justifyContent="end" alignItems="end">
              <Button
                onClick={() => Continue("Review Information")}
                variant="Standard"
              >
                {LanguageData?.Common_Continue || "Continue"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  };

  const Review_Information = () => {
    return (
      <>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px 8px 0 0"
          width="100%"
        >
          <Box
            sx={{
              borderBottom: "1px solid #E5E7EB",
              backgroundColor: "#FFFFFF",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <Tabs
              sx={{ width: "100%" }}
              value={tabValue}
              onChange={handleTabValue}
            >
              <Tab
                sx={{ width: "50%", maxWidth: "none" }}
                label={
                  LanguageData?.Announcement_Switchtab_Details || "Details"
                }
              />
              <Tab
                sx={{ width: "50%", maxWidth: "none" }}
                label={
                  LanguageData?.Announcement_Switchtab_Preview || "Preview"
                }
              />
            </Tabs>
          </Box>

          <CustomTabPanel value={tabValue} index={0}>
            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {" "}
                {LanguageData?.Publish_Date || "Publish Date"}{" "}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
            >
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Start_Date || "Start Date"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={0.5}>
                  {moment(date?.startDate?.$d).format("DD MMM YYYY")}
                </TypographyDefault>
              </Grid>
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {" "}
                  {LanguageData?.End_date || "End Date"}{" "}
                </StyledSecondaryTypography>
                <TypographyDefault mt={0.5}>
                  {moment(date?.endDate?.$d).format("DD MMM YYYY")}
                </TypographyDefault>
              </Grid>
            </Grid>

            {/* Announcement Type  */}

            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {" "}
                {LanguageData?.Announcement_type || "Announcement Type "}{" "}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
            >
              <Grid xs={12}>
                <TypographyDefault>
                  {type == 1
                    ? LanguageData?.General_Filter || "General "
                    : LanguageData?.Building_Filter || "Building Alert"}
                </TypographyDefault>
              </Grid>
            </Grid>

            {/* Announcement Details  */}

            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {" "}
                {LanguageData?.Announcement_Details ||
                  "Announcement Details"}{" "}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
            >
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {" "}
                  {LanguageData?.Announcement_tab_title || "Title"}
                </StyledSecondaryTypography>
                <TypographyDefault mt={0.5}>{title}</TypographyDefault>
              </Grid>
              <Grid xs={12} sm={5.8} my={2}>
                <StyledSecondaryTypography>
                  {LanguageData?.Event_Duration || "Event Duration"}
                </StyledSecondaryTypography>

                <TypographyDefault mt={0.5}>
                  {datetimerangedata[0]
                    ? datetimerangedata[0].format("DD MMM YYYY") + ","
                    : "-"}
                  {time?.start || "-"} -{" "}
                  {datetimerangedata[1]
                    ? datetimerangedata[1].format("DD MMM YYYY") + ","
                    : "-"}
                  {time?.end || "-"}
                  {/* {datetimerangedata[0]?.format("DD MMM YYYY") || "-"},{" "}
                    {time?.start || '-'} -{" "}
                    {datetimerangedata[1]?.format("DD MMM YYYY") || "-"},{" "}
                    {time?.end ||'-'} */}
                </TypographyDefault>
              </Grid>
              <Divider
                sx={{
                  borderTop: "1px solid #F3F4F6",
                  width: "100%",
                  marginTop: "10px",
                }}
              />
              <Grid xs={12} my={2}>
                <StyledSecondaryTypography mb={0.5}>
                  {LanguageData?.Description || "Description"}
                </StyledSecondaryTypography>
                <TypographyDefault
                  mt={0.5}
                  sx={{
                    wordWrap: "break-word",
                    "&>p": {
                      margin: "0px !important",
                      padding: "0px !important",
                    },
                  }}
                >
                  {parse(description)}
                </TypographyDefault>
              </Grid>
            </Grid>

            {/* IMages */}

            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#FCFCFD",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <SecondaryTypography>
                {" "}
                {LanguageData?.Image || "Image"}{" "}
              </SecondaryTypography>
            </Stack>
            <Grid
              container
              xs={12}
              gap="2.8%"
              sx={{ backgroundColor: "white", padding: "30px 16px 30px 16px" }}
            >
              <Grid xs={12}>
                <Stack padding="20px 16px 20px 16px">
                  {/* <img
                    src={image[0]?.src}
                    width="120px"
                    height="120px"
                    style={{ borderRadius: "10px", objectFit: "cover" }}
                  /> */}
                  {image?.path ? (
                    <img
                      key={image?.id}
                      src={
                        image?.path
                          ? `${process.env.REACT_APP_FILE_PATH}${image.path}`
                          : image?.src
                      }
                      alt={image?.name}
                      width="120px"
                      height="120px"
                      style={{ borderRadius: "10px", objectFit: "cover" }}
                    />
                  ) : (
                    <img
                      src={
                        changedImage[0]?.path
                          ? `${process.env.REACT_APP_FILE_PATH}${changedImage[0]?.path}`
                          : changedImage[0]?.src
                      }
                      width="120px"
                      height="120px"
                      style={{ borderRadius: "10px", objectFit: "cover" }}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </CustomTabPanel>

          <CustomTabPanel value={tabValue} index={1}>
            <Stack
              py={1}
              direction="column"
              alignItems="center"
              justifyContent="center"
              px="16px"
              sx={{
                height: "66px",
                backgroundColor: "#F9FAFB",
                borderBottom: "1px solid #E5E7EB",
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <StyledPrimaryDefaultTypography>
                {LanguageData?.Mobile_Preview || "Mobile App Preview"}{" "}
              </StyledPrimaryDefaultTypography>
              <StyledSecondaryTypography>
                {LanguageData?.Scroll_mobile ||
                  "Scroll on the mobile phone to review details"}
              </StyledSecondaryTypography>
            </Stack>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                my={6.5}
                sx={{
                  width: "375px",
                  height: "750px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "8px",
                  overflowY: "scroll",
                  "::-webkit-scrollbar": { display: "none" },
                }}
              >
                <Stack sx={{ padding: "32px 16px 32px 16px" }}>
                  <BackArrowMobileIcon />
                  <Typography mt={5} sx={{ fontSize: "24px !important" }}>
                    {title}
                  </Typography>
                </Stack>
                {image?.path ? (
                  <img
                    key={image?.id}
                    src={
                      image?.path
                        ? `${process.env.REACT_APP_FILE_PATH}${image.path}`
                        : image?.src
                    }
                    alt={image?.name}
                    width="120px"
                    height="120px"
                    style={{ borderRadius: "10px", objectFit: "cover" }}
                  />
                ) : (
                  <img
                    src={
                      changedImage[0]?.path
                        ? `${process.env.REACT_APP_FILE_PATH}${changedImage[0]?.path}`
                        : changedImage[0]?.src
                    }
                    width="100%"
                    height="211px"
                    style={{ objectFit: "cover" }}
                  />
                )}

                {/* {image.length > 0 &&
                  image.map((images, index) => (
                    <img
                      key={images?.id ? images?.id : index}
                      src={
                        images?.path
                          ? `${process.env.REACT_APP_FILE_PATH}${images.path}`
                          : images?.src
                      }
                      alt={images?.name}
                      width="120px"
                      height="120px"
                      style={{ borderRadius: "10px", objectFit: "cover" }}
                    />
                  ))} */}
                {datetimerangedata[0] != null && (
                  <>
                    <Box padding="24px 16px 24px 16px">
                      {/* <ImageCourosel images={FacilityImages} /> */}
                      {/* <img src="https://images.pexels.com/photos/380768/pexels-photo-380768.jpeg?auto=compress&cs=tinysrgb&w=600" width="100%" /> */}
                      <SecondaryTypography>
                        {moment(datetimerangedata[0]?.$d).format("DD MMM YYYY")}{" "}
                        -{" "}
                        {moment(datetimerangedata[1]?.$d).format("DD MMM YYYY")}{" "}
                      </SecondaryTypography>
                      <StyledSecondaryDefaultTypography>
                        {time?.start} - {time?.end}
                      </StyledSecondaryDefaultTypography>
                    </Box>
                    <Box height="10px" backgroundColor="#F9FAFB">
                      {" "}
                    </Box>
                  </>
                )}
                <Stack gap={2.5} sx={{ padding: "32px 16px 32px 16px" }}>
                  <Stack gap="4px">
                    <SecondaryTypography>
                      {LanguageData?.Announcement_Switchtab_Details ||
                        "Details"}{" "}
                    </SecondaryTypography>
                    <TypographyDefault
                      sx={{
                        "&>p": {
                          margin: "0px !important",
                          padding: "0px !important",
                        },
                        "& li": { padding: "5px !important" },
                      }}
                    >
                      {parse(description)}{" "}
                    </TypographyDefault>
                    <StyledSecondaryTypography
                      mt={5}
                      fontSize="12px !important"
                    >
                      {LanguageData?.Published_on || "Published on"}{" "}
                      {moment(date?.startDate?.$d).format("DD MMM YYYY")}
                    </StyledSecondaryTypography>
                  </Stack>
                </Stack>
              </Box>
            </Box>
          </CustomTabPanel>
        </Box>

        <Box
          bgcolor="#FFFFFF"
          sx={{
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="space-between"
            alignItems="center"
            height="60px"
          >
            <Stack direction="row">
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                {LanguageData?.Common_last_Update || "Last Updated at"}{" "}
                {moment.utc(editData?.updated_at).local().format("HH:mm A")}
              </StyledSecondaryTypography>
            </Stack>
            <Stack direction="column" justifyContent="end" alignItems="end">
              <Button
                onClick={() => {
                  handleEditAnnouncement();
                  SetPostLoader(true);
                }}
                variant="Standard"
              >
                {LanguageData?.Common_Confirm || "Confirm"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}

      <Box p={5} sx={{ backgroundColor: "#F3F4F6" }}>
        <Stack direction="row" justifyContent="space-between">
          <BackArrow
            onClick={() => {
              navigate("/Announcement");
            }}
            style={{ cursor: "pointer" }}
          />
          {/* <BorderAlertCloseIcon width='40' height='40' /> */}
        </Stack>
        <Stack
          my={5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <PrimaryTypography mb={1}>
              {LanguageData?.Edit_Announcement || "Edit Announcement"}{" "}
            </PrimaryTypography>
          </Box>
        </Stack>
        <Grid container gap="1.5%">
          {blocks.map((itm) => {
            return (
              <Grid
                xs={5.91}
                onClick={() => Continue(itm.tle, "")}
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                <Box
                  component="div"
                  sx={{
                    borderTop: itm.status
                      ? "3px solid #0E4965"
                      : "3px solid #D2D6DB",
                    width: "100%",
                    color: itm.status ? "#0E4965" : "#9DA4AE",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "12px",
                      padding: "10px 0 0 0",
                    }}
                  >
                    {itm.tle}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        {addblocks.Announcement_Details && Announcement_Details()}
        {addblocks.Review_Information && Review_Information()}
      </Box>

      {/* <Stack onClick={() => setopenDateRangePicker(true)} sx={{ height: '47px', padding: '2px 12px 2px 12px', borderRadius: "8px", backgroundColor: "#F9FAFB" }} direction='row' height='40px' justifyContent='space-between' alignItems='center'>
            {Boolean(datetimerangedata[0]) ? <StyledSecondaryDefaultTypography>{moment(datetimerangedata[0]?.$d).format('MMM D YYYY')} - {moment(datetimerangedata[1]?.$d).format('MMM D YYYY')} </StyledSecondaryDefaultTypography> :
                <StyledSecondaryDefaultTypography sx={{ color: '#9DA4AE !important' }}>Please select a inactive date</StyledSecondaryDefaultTypography>
            }
            <DatePickerIcon />
        </Stack> */}
    </>
  );
}
