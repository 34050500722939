import React from "react";
import {
  Box,
  Select,
  MenuItem,
  SvgIcon,
  Typography,
  CircularProgress,
} from "@mui/material";
import { ReactComponent as DropArrowIcon } from "../../Icons/SelectDropdownArrow.svg";
import {
  TypoGraph,
  StyledSecondaryServiceTypography,
  StyledSecondaryTypography,
} from "../../Theme";
import { ReactComponent as ActiveIcon } from "../../Icons/ActiveGraph.svg";
import { ReactComponent as InactiveIcon } from "../../Icons/InactiveIcon.svg";
import { ReactComponent as OffBoardIcon } from "../../Icons/OffBoardIcon.svg";
import Chart from "react-apexcharts";
import { ReactComponent as ExclamatoryIcon } from "../../Icons/GraphExclamatoryIcon.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as Indicator } from "../../Icons/tooltipIndicator.svg";

const FacilityBookingsGraph = () => {
  const graphData = [79, 9878, 886];
  const { FacilityData, OverviewStatus } = useSelector(
    (state) => state.OverView
  );
  const { LanguageData } = useSelector((state) => state.Language);

  const BookingSeries = [
    {
      name: "Bookings",
      data: [
        FacilityData?.jan?.booking_count || 0,
        FacilityData?.feb?.booking_count || 0,
        FacilityData?.mar?.booking_count || 0,
        FacilityData?.apr?.booking_count || 0,
        FacilityData?.may?.booking_count || 0,
        FacilityData?.jun?.booking_count || 0,
        FacilityData?.jul?.booking_count || 0,
        FacilityData?.aug?.booking_count || 0,
        FacilityData?.sep?.booking_count || 0,
        FacilityData?.oct?.booking_count || 0,
        FacilityData?.nov?.booking_count || 0,
        FacilityData?.dec?.booking_count || 0,
      ],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
      events: {
        mouseMove: (event, chartContext) => {
          chartContext.el.style.cursor = "pointer";
        },
        mouseLeave: (event, chartContext) => {
          chartContext.el.style.cursor = "default";
        },
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          fontSize: "12px",
          fontFamily: "Inter, sans-serif",
          color: "#384250",
          fontWeight: 400,
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value;
        },
        style: {
          fontSize: "12px",
          fontFamily: "Inter, sans-serif",
          color: "#384250",
          fontWeight: 400,
        },
      },
    },
    colors: ["#1687BD"],
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        // borderRadius: 8,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      theme: false,
      style: {
        background: "#262D33",
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function () {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const total = series[0][dataPointIndex];
        const month = w.globals.labels[dataPointIndex];

        return `
         <div class="background">
        <div class="triangle">
       
      <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 16V0L1.5 6.5L0.5 7.5L0 8L8 16Z" fill="#262D35"/>
</svg>

       
        </div>
            <div class="apexcharts-tooltip-custom">
              <div class="total-count-tip">
              <div>
              Booking
              </div> 
              <div>
              ${total} 
              </div>
              </div>
              <div class="month-change-top">${month}${" "}${2023}</div>
              </div>
             
            </div>
            </div>
          `;
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
  };

  return (
    <>
      {
        <>
          <Box
            sx={{
              borderBottom: "1px solid #E5E7EB",
              borderTop: "1px solid #E5E7EB",
              padding: "16px",
              backgroundColor: "#FCFCFD",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box>
                <StyledSecondaryServiceTypography style={{ fontWeight: 500 }}>
                  {` ${
                    LanguageData?.home_total_bookings || "Total Bookings"
                  }${" "}${FacilityData?.total_count || 0} `}
                </StyledSecondaryServiceTypography>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <ActiveIcon style={{ marginRight: "10px" }} />
                  <TypoGraph>
                    {LanguageData?.home_booking || "Booking"}
                  </TypoGraph>
                </Box>
              </Box>
            </Box>
          </Box>
          {FacilityData.total_count ? (
            <Box
              sx={{
                borderRadius: "0px 0px 8px 8px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Chart
                options={options}
                series={BookingSeries}
                type="bar"
                height={400}
              />
            </Box>
          ) : (
            <Box
              sx={{
                borderRadius: "0px, 0px, 8px, 8px",
                padding: "16px",
                backgroundColor: "#F9FAFB",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "300px",
              }}
            >
              {OverviewStatus ? (
                <CircularProgress />
              ) : (
                <>
                  {" "}
                  <ExclamatoryIcon
                    style={{ marginBottom: "5px", marginTop: "60px" }}
                  />
                  <Typography
                    sx={{
                      color: "#9DA4AE",
                      fontSize: "16px",
                      fontWeight: 600,
                      width: "470px",
                      my: 2,
                      textAlign: "center",
                      marginTop: "5px",
                    }}
                    style={{ color: "#4D5761" }}
                  >
                    No statistics available for display
                  </Typography>
                  <StyledSecondaryTypography
                    color="#9DA4AE !important"
                    textAlign="center"
                    width="400px"
                    height="88px"
                    fontSize="16px"
                    fontWeight="400px"
                    lineHeight="22.4px"
                    marginBottom="60px"
                  >
                    To get started, you can{" "}
                    <Link to="/manage" style={{ color: "#0E4965" }}>
                      <b>click here </b>
                    </Link>
                    to add the facility via the facility page.
                  </StyledSecondaryTypography>
                </>
              )}
            </Box>
          )}
        </>
      }
    </>
  );
};

export default FacilityBookingsGraph;
