import {
  Alert,
  Box,
  Button,
  Checkbox,
  Menu,
  MenuItem,
  CircularProgress,
  Modal,
  Grid,
  OutlinedInput,
  Pagination,
  PaginationItem,
  Select,
  Snackbar,
  SnackbarContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import {
  DatePickerStyle,
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledOutlinedInput,
  StyledSecondaryDefaultTypography,
  SecondaryTypographyDefault,
  StyledSecondaryBookingTypography,
  StyledSecondaryTypography,
  StyledTableCell,
  StyledTableRow,
  StyledTypography,
  TypographyDefault,
} from "../../Theme";
import { ReactComponent as SearchIcon } from "../../Icons/Search.svg";
import { ReactComponent as FilterIcon } from "../../Icons/Filter.svg";
import { ReactComponent as SortIcon } from "../../Icons/Sort.svg";
import { ReactComponent as CheckboxIcon } from "../../Icons/Checkbox.svg";
import { ReactComponent as DeleteIcon } from "../../Icons/DeleteTable.svg";
import { ReactComponent as DeleteDisable } from "../../Icons/DeleteDisable.svg";
import { ReactComponent as PreviousIcon } from "../../Icons/Previous.svg";
import { ReactComponent as NextIcon } from "../../Icons/Next.svg";
import { ReactComponent as ExclamatoryIcon } from "../../Icons/Exclamatory.svg";
import { ReactComponent as MoreIcon } from "../../Icons/ThreeDot.svg";
import { ReactComponent as NumberInputIcon } from "../../Icons/NumberInputIcon.svg";
import { ReactComponent as Close } from "../../Icons/BorderAlertClose.svg";
import { ReactComponent as SnackbarClose } from "../../Icons/AlertClose.svg";
import { ReactComponent as SnackbarSuccess } from "../../Icons/SnackbarSuccess.svg";
import { ReactComponent as DatePickerIcon } from "../../Icons/Datepicker.svg";
import { ReactComponent as DownloadIcon } from "../../Icons/DownloadIcon.svg";
import { ReactComponent as CheckBoxMinusIcon } from "../../Icons/CheckBoxMinus.svg";
import { ReactComponent as CsvIcon } from "../../Icons/CsvIcon.svg";
import { ReactComponent as PdfIcon } from "../../Icons/PdfIcon.svg";
import { MarketingTabColumn, UserMarketingTabColumn } from "../../Data";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../Redux/AxiosInstance";
import moment from "moment";
import { getChip } from "../../Utils";
import TypesInformationModal from "../../CommonComponents/TypeInformationModal";
import { DatePicker } from "@mui/x-date-pickers";
import AlertModal, { PostLoaderModal } from "../../CommonComponents/modal";
import CustomDateRangePicker from "../../CommonComponents/CustomDateRange";
import { ReactComponent as DisabledDownloadIcon } from "../../Icons/DisableDownload.svg";
import { showToast } from "../../CommonComponents/Toaster";

export default function Marketing() {
  const { LanguageData } = useSelector((state) => state.Language);
  const [initialRender, setInitialRender] = useState(true);
  const initialState = { offboard: false, inActive: false, active: false };
  const tabList = [
    LanguageData?.Pending || "Pending",
    LanguageData?.Active || "Active",
    LanguageData?.Inactive || "Inactive",
    LanguageData?.Completed || "Completed",
    LanguageData?.Draft || "Draft",
  ];
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Pending");
  const [TabId, setTabId] = useState(3);
  const [maketingDataList, setmaketingDataList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [filterData, setFilterData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [selectedMultipleData, setselectedMultipleData] = useState([]);
  const [changeStatusMultiple, setChangeStatusMultiple] = useState(false);
  const [mainCheck, setMainCheck] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [modalCheckboxStatus, setModalCheckboxStatus] = useState({
    offboard: false,
    inActive: false,
    active: false,
  });
  const [offboardDate, setOffboardDate] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const sortOpen = Boolean(sortAnchorEl);
  const [sortData, setSortData] = useState("");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const filterOpen = Boolean(filterAnchorEl);
  const [SearchData, setSearchData] = useState("");
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);
  const MoreOpen = Boolean(moreAnchorEl);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const downloadOpen = Boolean(downloadAnchorEl);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { UserManage } = useSelector((state) => state.Login);
  const [SelectTypeModal, setSelectTypeModal] = useState(false);
  const [selectedTypeData, setSelectedTypeData] = useState("");
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [showMultipleDeleteModal, setshowMultipleDeleteModal] = useState(false);
  const [Tableloading, setTableloading] = useState(true);
  const [postLoader, SetPostLoader] = useState(false);

  const [multipleDel, setMultipleDel] = useState([]);

  const [id, setId] = useState("");

  const [openDateRangePicker, setopenDateRangePicker] = useState(false);
  const [datetimerangedata, setdatetimerangedata] = useState([null, null]);
  const [dateSubmit, setdateSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState({ start: "", end: "" });
  const [showAnchorEl, setshowAnchorEl] = useState(null);
  const showOpen = Boolean(showAnchorEl);

  console.log(selectedTab);

  let deleteModalDatas = {
    label:
      LanguageData?.Marketing?.Delete_Title ||
      "Confirm to delete the Promotion/ Event",
    descriptionOne:
      LanguageData?.Marketing?.Delete_Subtitle ||
      "Please confirm that you wish to delete the event/promotion. Upon deletion event/promotion will disappear from both dashboard and mobile app.",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: LanguageData?.Cancel || "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#B42318",
      text: LanguageData?.Confirm || "Confirm",
    },
  };

  const AllcloseModal = () => {
    setshowMultipleDeleteModal(false);
    setSelectedTypeData("");
    setSelectTypeModal(false);
    setshowMultipleDeleteModal(false);
    setMultipleDel([]);
  };

  const DateParams = () => {
    if (datetimerangedata?.length >= 1) {
      if (datetimerangedata[0] !== null) {
        if (
          moment(datetimerangedata[0]?.$d).format("yyyy-MM-DD") ==
          moment(datetimerangedata[1]?.$d).format("yyyy-MM-DD")
        ) {
          const data = {
            start_date: moment(new Date(datetimerangedata[0]?.$d)).format(
              "yyyy-MM-DD"
            ),
          };
          return data;
        } else if (datetimerangedata[1] !== null) {
          if (datetimerangedata[0]?.$d !== datetimerangedata[1]?.$d) {
            const data = {
              start_date: moment(new Date(datetimerangedata[0]?.$d)).format(
                "yyyy-MM-DD"
              ),
              end_date: moment(new Date(datetimerangedata[1]?.$d)).format(
                "yyyy-MM-DD"
              ),
            };
            return data;
          }
        }
      }
    }
  };

  const getMarketingTabData = async () => {
    const urlParams = new URLSearchParams(DateParams());
    setTableloading(true);
    setmaketingDataList([]);
    await axiosInstance
      .get(
        `${
          process.env.REACT_APP_API_URL
        }property/${propertyId}/marketing?page=${currentPage}&status_id=${TabId}&record_count=${rowsPerPage}&marketing_type_id=${filterData}&sort=${sortData}&${
          dateSubmit && TabId != 4 ? urlParams.toString() : ""
        }`
      )
      .then((data) => {
        setmaketingDataList(data.data.data.marketing);
        setPaginationData(data.data.data.pagination_details);
        setTableloading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.error == "No marketings found") {
          setmaketingDataList([]);
        }
        setTableloading(false);
      });
  };

  useEffect(() => {
    getMarketingTabData();
  }, [currentPage]);

  useEffect(() => {
    if (!initialRender && !openDateRangePicker) {
      if (currentPage != 1) {
        setCurrentPage(1);
      } else {
        getMarketingTabData();
      }
    } else {
      setInitialRender(false);
    }
  }, [TabId, rowsPerPage, filterData, sortData, openDateRangePicker]);

  const handleSearch = async (data) => {
    setTableloading(true);
    setmaketingDataList([]);
    setSearchData(data);
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/marketing?page=${currentPage}&status_id=${TabId}&record_count=${rowsPerPage}&marketing_type_id=${filterData}&sort=${sortData}&search=${data}`
      );
      setmaketingDataList(response.data.data.marketing);
      setPaginationData(response.data.data.pagination_details);
      setTableloading(false);
      // setRowsPerPage(response?.data?.data?.pagination_details?.per_page)
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.error == "No marketings found") {
        setmaketingDataList([]);
      }
      setTableloading(false);
    }
  };

  const DownloadReport = async (type) => {
    const urlParams = new URLSearchParams(DateParams());
    try {
      setLoading(true);
      setDownloadAnchorEl(null);
      const response = await axiosInstance.get(
        `${
          process.env.REACT_APP_API_URL
        }property/${propertyId}/marketing/download?download_type=${type}&page=${currentPage}&status_id=${TabId}&record_count=${rowsPerPage}&marketing_type_id=${filterData}&sort=${
          TabId == 4 ? sortData : ""
        }&${dateSubmit && TabId != 4 ? urlParams.toString() : ""}$&search=${
          TabId == 4 ? SearchData : ""
        }`,
        { responseType: "blob" }
      );
      console.log(response);
      saveAs(response.data, `Marketing_report.${type}`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTab == (LanguageData?.Draft || "Draft")) {
      setTabId(4);
    } else if (selectedTab == (LanguageData?.Active || "Active")) {
      setTabId(1);
    } else if (selectedTab == (LanguageData?.Inactive || "Inactive")) {
      setTabId(2);
    } else if (selectedTab == (LanguageData?.Pending || "Pending")) {
      setTabId(3);
    } else if (selectedTab == (LanguageData?.Completed || "Completed")) {
      setTabId(5);
    }

    if (sortData == "old") {
      setSortData("new");
    }
  }, [selectedTab]);

  const getColumnType = () => {
    switch (selectedTab) {
      case "Pending":
        return MarketingTabColumn;
      case "Active":
        return MarketingTabColumn;
      case "Inactive":
        return MarketingTabColumn;
      case "Completed":
        return MarketingTabColumn;
      case "Draft":
        return MarketingTabColumn;
      default:
        return [];
    }
  };

  const handleMoreOptions = (e, value) => {
    setMoreAnchorEl(e.currentTarget);
    setSelectedData(value);
  };

  const confirmDelete = () => {};

  const closemodal = () => {
    setSelectTypeModal(false);
    setSelectedTypeData("");
    setshowDeleteModal(false);
  };

  const MultipleDelete = (e, value) => {
    const { checked } = e.target;

    setMultipleDel((prev) => {
      const checkdata = multipleDel.find((itm) => itm?.id == value?.id);
      if (Boolean(checkdata)) {
        const filterdata = multipleDel.filter((itm) => itm?.id != value?.id);
        return filterdata;
      } else {
        return [...prev, value];
      }
    });
  };
  const handleMainCheck = (e) => {
    const { checked } = e.target;
    if (maketingDataList?.length > 1) {
      setMainCheck(checked);
    }
  };

  useEffect(() => {
    if (mainCheck) {
      setMultipleDel([]);
      maketingDataList.map((itm) => setMultipleDel((prev) => [...prev, itm]));
    }
  }, [mainCheck]);

  useEffect(() => {
    if (multipleDel.length <= 1) {
      setMainCheck(false);
    }
  }, [multipleDel]);

  // const handleShowDeleteModal = () => {
  //     setshowDeleteModal(!showDeleteModal)
  // }
  const handleShowMultipleDeleteModal = () => {
    setshowMultipleDeleteModal(!showMultipleDeleteModal);
  };

  const handleMultipleDelete = () => {
    SetPostLoader(true);
    const ids = new FormData();
    multipleDel.forEach((itm, index) => {
      ids.append(`marketing_ids[${index}]`, itm?.id);
    });

    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/marketing/multiple_delete_marketing`,
        ids
      )
      .then((data) => {
        getMarketingTabData();
        AllcloseModal();
        SetPostLoader(false);
        showToast(data?.data?.message, "delete");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getMarketingData = () => {
  //     // if (selectedTab == 'All') {

  //     axiosInstance.get(`${process.env.REACT_APP_API_URL}property/${propertyId}/marketing`)
  //         .then((data) => {
  //             setmaketingDataList(data.data.data.marketing)
  //         })
  //         .catch((err) => {
  //             console.log(err)
  //         })
  //     // }
  // }

  // useEffect(() => {

  //     getMarketingData()
  // }, [])

  const handleConfirmDelete = async () => {
    SetPostLoader(true);
    try {
      const response = await axiosInstance.delete(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/marketing/${id}`
      );
      getMarketingTabData();
      closemodal();
      SetPostLoader(false);
      showToast(response?.data?.toast, "delete");
    } catch (error) {
      console.log(error);
    }
  };

  const renderData = (value, index) => {
    // switch (selectedTab) {
    //     case 'All':
    //         return (
    //             <StyledTableRow height='72px' key={index} >
    //                 <StyledTableCell> <Checkbox sx={{ height: '40px', minWidth: '43px' }} size='small' icon={<CheckboxIcon />} /></StyledTableCell>
    //                 <StyledTableCell onClick={() => { navigate('reviewEvent') }}>
    //                     <TypographyDefault>Spring Festival Event for Resident..</TypographyDefault>
    //                 </StyledTableCell>
    //                 <StyledTableCell onClick={() => { navigate('reviewEvent') }}>
    //                     <TypographyDefault>Event</TypographyDefault>
    //                 </StyledTableCell>
    //                 <StyledTableCell onClick={() => { navigate('reviewEvent') }}>{moment(value?.join_date).format('DD MMM YYYY')} - {moment(value?.join_date).format('DD MMM YYYY')} </StyledTableCell>
    //                 <StyledTableCell onClick={() => { navigate('reviewEvent') }}>
    //                     <TypographyDefault> <TypographyDefault>Thamos</TypographyDefault></TypographyDefault>
    //                 </StyledTableCell>
    //                 <StyledTableCell onClick={() => setshowDeleteModal(true)}><DeleteIcon /></StyledTableCell>
    //             </StyledTableRow>
    //         )
    // }
    // if (selectedTab != 'Draft') {
    return (
      <StyledTableRow height="72px" key={index}>
        {UserManage?.marketing && setTabId != 5 && (
          <StyledTableCell>
            {" "}
            <Checkbox
              onClick={(e) => MultipleDelete(e, value)}
              checked={Boolean(multipleDel.find((itm) => itm?.id == value?.id))}
              sx={{ height: "40px", minWidth: "43px" }}
              size="small"
              icon={<CheckboxIcon />}
            />
          </StyledTableCell>
        )}
        <StyledTableCell
          onClick={() => {
            navigate(
              value?.marketing_type_id == 1
                ? `reviewEvent/${value?.id}`
                : `reviewPromotion/${value?.id}`
            );
          }}
        >
          <TypographyDefault>{value?.title}</TypographyDefault>
        </StyledTableCell>
        <StyledTableCell
          onClick={() => {
            navigate(
              value?.marketing_type_id == 1
                ? `reviewEvent/${value?.id}`
                : `reviewPromotion/${value?.id}`
            );
          }}
        >
          <TypographyDefault>
            {value?.marketing_type_id == 1 ? "Event" : "Promotion"}
          </TypographyDefault>
        </StyledTableCell>
        <StyledTableCell
          onClick={() => {
            navigate(
              value?.marketing_type_id == 1
                ? `reviewEvent/${value?.id}`
                : `reviewPromotion/${value?.id}`
            );
          }}
        >
          {Boolean(value?.start_date)
            ? moment.utc(value?.start_date).local().format("DD MMM YYYY") +
              " - " +
              moment.utc(value?.end_date).local().format("DD MMM YYYY")
            : "-"}{" "}
        </StyledTableCell>
        <StyledTableCell
          onClick={() => {
            navigate(
              value?.marketing_type_id == 1
                ? `reviewEvent/${value?.id}`
                : `reviewPromotion/${value?.id}`
            );
          }}
        >
          <TypographyDefault>
            {" "}
            <TypographyDefault>{value?.created_by}</TypographyDefault>
          </TypographyDefault>
        </StyledTableCell>
        {UserManage?.marketing &&
          setTabId != 5 &&
          (multipleDel.length >= 1 ? (
            <StyledTableCell>
              {" "}
              <DeleteDisable width={20} />
            </StyledTableCell>
          ) : (
            <StyledTableCell
              onClick={() => {
                setshowDeleteModal(true);
                setId(value?.id);
              }}
            >
              {" "}
              <DeleteIcon />{" "}
            </StyledTableCell>
          ))}
      </StyledTableRow>
    );
    // }
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      {showDeleteModal && (
        <AlertModal
          modalDatas={deleteModalDatas}
          confirmFunction={handleConfirmDelete}
          closeFunction={closemodal}
        />
      )}
      {openDateRangePicker && (
        <CustomDateRangePicker
          openDateRangePicker={openDateRangePicker}
          setopenDateRangePicker={setopenDateRangePicker}
          datetimerangedata={datetimerangedata}
          setdatetimerangedata={setdatetimerangedata}
          setdateSubmit={setdateSubmit}
        />
      )}

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <PrimaryTypography>
            {LanguageData?.Marketing?.Title || "Marketing"}
          </PrimaryTypography>
          <StyledSecondaryTypography>
            {LanguageData?.Marketing?.Sub_Title ||
              "All marketing displayed on the LOBY application are reflected below."}
          </StyledSecondaryTypography>
        </Box>
        {UserManage?.marketing && (
          <Box>
            <Button
              onClick={(e) => setDownloadAnchorEl(e.currentTarget)}
              sx={{
                fontWeight: "600",
                height: "40px",
                width: "fit-content",
                color: "#0E5E84",
                backgroundColor: "#E7EFF3",
                padding: "10px 16px 10px 16px",
                mr: 3,
                "&:hover": {
                  backgroundColor: "#CFDFE6",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#F9FAFB",
                  color: "#D2D6DB",
                },
              }}
              disabled={maketingDataList.length === 0 || loading}
            >
              {loading ? (
                <Box sx={{ width: "40px", marginTop: "5px" }}>
                  <CircularProgress size={15} />
                </Box>
              ) : (
                <>
                  {maketingDataList.length > 0 ? (
                    <DownloadIcon
                      style={{ marginRight: "8px", padding: "3px" }}
                    />
                  ) : (
                    <DisabledDownloadIcon
                      style={{ marginRight: "8px", padding: "3px" }}
                    />
                  )}
                </>
              )}
              {LanguageData?.home_download_report || "Download Report"}
            </Button>
            <Button
              onClick={(e) => setSelectTypeModal(true)}
              sx={{
                padding: "10px 16px",
                borderRadius: "8px",
                backgroundColor: "#0E5E84",
                color: "#FFF",
                fontSize: "14px",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#0E4965",
                }
              }}
            >
              {LanguageData?.Create || "Create"}
            </Button>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #E5E7EB",
          p: 3,
          mt: 4,
          borderRadius: " 8px 8px 0px 0px",
          display: "flex",
          justifyContent: "space-between",
          overflowX: "scroll",
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        {multipleDel.length >= 1 ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            height="40px"
          >
            <Box display="flex" alignItems="center">
              <SecondaryTypography mr={2}>
                {multipleDel.length} Selected
              </SecondaryTypography>
              <StyledSecondaryTypography
                onClick={() => {
                  setMultipleDel([]);
                }}
                sx={{ borderBottomWidth: "2px", borderBottomStyle: "solid" }}
              >
                Unselect All
              </StyledSecondaryTypography>
            </Box>
            <Box
              sx={{
                display: "flex",
                height: "40px",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #E5E7EB",
                borderRadius: "6px",
                px: 2,
                ml: 2,
              }}
            >
              <SecondaryTypographyDefault
                onClick={() =>
                  setshowMultipleDeleteModal(!showMultipleDeleteModal)
                }
              >
                Delete Selected
              </SecondaryTypographyDefault>
            </Box>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                border: "1px solid #D2D6DB",
                display: "flex",
                borderRadius: "10px",
              }}
            >
              {tabList.map((value, index) => (
                <Box
                  key={index}
                  onClick={() => setSelectedTab(value)}
                  sx={{
                    borderRadius:
                      index == 0
                        ? "8px 0 0 8px"
                        : index == value?.length - 1
                        ? " 0 8px 8px 0 "
                        : "",
                    borderLeft: "1px solid #E5E7EB",
                    borderRight: "1px solid #E5E7EB",
                    backgroundColor:
                      value == selectedTab ? "#F3F4F6" : "initial",
                    padding: "10px 16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  <Typography
                    sx={{
                      color: value === selectedTab ? "#111927" : "#4D5761",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                    textAlign={"center"}
                  >
                    {value}
                  </Typography>
                </Box>
              ))}
            </Box>
            {selectedTab === (LanguageData?.Draft || "Draft") ? (
              <Box sx={{ display: "flex", height: "40px" }}>
                <StyledOutlinedInput
                  placeholder="Search"
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                  startAdornment={
                    <SearchIcon style={{ paddingRight: "5px" }} />
                  }
                />
                <Box
                  onClick={(e) => setSortAnchorEl(e.currentTarget)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #D2D6DB",
                    borderRadius: "8px",
                    px: 2,
                    ml: 2,
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "#F9FAFB",
                    },
                  }}
                >
                  <SortIcon style={{ marginRight: "10px" }} />
                  <SecondaryTypographyDefault>
                    {LanguageData?.Sort || "Sort"}
                  </SecondaryTypographyDefault>
                </Box>
              </Box>
            ) : (
              <Box sx={{ display: "flex", height: "40px" }}>
                <Box
                  onClick={() => {
                    setopenDateRangePicker(true);
                  }}
                  sx={{
                    minWidth: "237px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #D2D6DB",
                    borderRadius: "8px",
                    px: 2,
                    ml: 2,
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "#F9FAFB",
                    },
                  }}
                >
                  <DatePickerIcon style={{ marginRight: "10px" }} />
                  <StyledSecondaryBookingTypography>
                    {Boolean(datetimerangedata[0])
                      ? moment(datetimerangedata[0]?.$d).format("MMM D YYYY")
                      : "MMM DD YYY"}{" "}
                    -{" "}
                    {Boolean(datetimerangedata[1])
                      ? moment(datetimerangedata[1]?.$d).format("MMM D YYYY")
                      : "MMM DD YYY"}
                  </StyledSecondaryBookingTypography>
                </Box>
                <Box
                  onClick={(e) => setFilterAnchorEl(e.currentTarget)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #D2D6DB",
                    borderRadius: "8px",
                    px: 2,
                    ml: 2,
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "#F9FAFB",
                    },
                  }}
                >
                  <FilterIcon style={{ marginRight: "10px" }} />
                  <SecondaryTypographyDefault>
                    {LanguageData?.Filters || "Filters"}
                  </SecondaryTypographyDefault>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>

      <Box
        sx={{ border: "1px solid #E5E7EB", borderRadius: "0px 0px 8px 8px" }}
      >
        {maketingDataList.length > 0 ? (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <StyledTableRow>
                    {(UserManage?.marketing && setTabId != 5
                      ? MarketingTabColumn
                      : UserMarketingTabColumn
                    ).map((value, index) => {
                      return (
                        <StyledTableCell key={index}>
                          {value === "Checkbox" ? (
                            multipleDel.length >= 1 ? (
                              <Checkbox
                                sx={{ height: "40px", minWidth: "43px" }}
                                onClick={() => {
                                  setMultipleDel([]);
                                }}
                                size="small"
                                icon={<CheckBoxMinusIcon />}
                              />
                            ) : (
                              <Checkbox
                                sx={{ height: "40px", minWidth: "43px" }}
                                size="small"
                                onChange={handleMainCheck}
                                checked={mainCheck}
                                icon={<CheckboxIcon />}
                              />
                            )
                          ) : value === "More" ? (
                            ""
                          ) : (
                            value
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {maketingDataList?.map((value, index) => {
                    return renderData(value, index);
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderTopStyle: "none !important",
                borderRadius: "0px 0px 8px 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2.5,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ color: "#4D5761", fontSize: "14px", fontWeight: 500 }}
                >
                  Show :
                </Typography>
                {/* <OutlinedInput inputProps={{ min: 5, max: 30, step: 5 }} value={rowsPerPage} onChange={(e) => setRowsPerPage(e.target.value)} type='number' sx={{ height: '40px', width: '100px', ml: 2, borderRadius: '8px' }} /> */}
                <Box
                  onClick={(e) => setshowAnchorEl(e.currentTarget)}
                  sx={{
                    width: "95px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #E5E7EB",
                    borderRadius: "6px",
                    ml: 2,
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "15px" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {rowsPerPage} rows{" "}
                    <NumberInputIcon style={{ paddingLeft: "5px" }} />
                  </Typography>
                </Box>
              </Box>
              <Pagination
                size="medium"
                page={currentPage}
                count={paginationData?.last_page}
                onChange={(e, value) => setCurrentPage(value)}
                shape="rounded"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: PreviousIcon, next: NextIcon }}
                    {...item}
                  />
                )}
              />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              backgroundColor: "#F9FAFB",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "50vh",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            {Tableloading ? (
              <CircularProgress />
            ) : (
              <>
                <ExclamatoryIcon />
                <Typography
                  sx={{
                    color: "#9DA4AE",
                    fontSize: "16px",
                    fontWeight: 600,
                    my: 2,
                  }}
                >
                  No Marketing Posts Here
                </Typography>
                <StyledSecondaryDefaultTypography color="#9DA4AE !important">
                  There are no active marketing posts to display at the moment.
                </StyledSecondaryDefaultTypography>
                <StyledSecondaryDefaultTypography color="#9DA4AE !important">
                  You may create marketing posts by clicking the top right{" "}
                  <span style={{ fontWeight: 600 }}> Create </span>
                  <br /> button.{" "}
                </StyledSecondaryDefaultTypography>
              </>
            )}
          </Box>
        )}
      </Box>
      <Menu
        sx={{ m: 0.5 }}
        open={sortOpen}
        anchorEl={sortAnchorEl}
        onClose={() => setSortAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setSortAnchorEl(null);
            setSortData("recent");
          }}
        >
          <Typography>{LanguageData?.Most_Recent || "Most Recent"}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSortAnchorEl(null);
            setSortData("old");
          }}
        >
          <Typography>{LanguageData?.Oldest || "Oldest"}</Typography>
        </MenuItem>
      </Menu>
      <Menu
        sx={{ m: 0.5 }}
        open={filterOpen}
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
      >
        <MenuItem
          sx={{ backgroundColor: filterData == "" && "#f6f7f8" }}
          onClick={() => {
            setFilterData("");
            setFilterAnchorEl(null);
          }}
        >
          <Typography>{LanguageData?.Marketing?.All || "All"}</Typography>
        </MenuItem>
        <MenuItem
          sx={{ backgroundColor: filterData == 1 && "#f6f7f8" }}
          onClick={() => {
            setFilterData(1);
            setFilterAnchorEl(null);
          }}
        >
          <Typography>{LanguageData?.Marketing?.Event || "Event"}</Typography>
        </MenuItem>
        <MenuItem
          sx={{ backgroundColor: filterData == 2 && "#f6f7f8" }}
          onClick={() => {
            setFilterData(2);
            setFilterAnchorEl(null);
          }}
        >
          <Typography>
            {LanguageData?.Marketing?.Promotion || "Promotion"}
          </Typography>
        </MenuItem>
      </Menu>

      <Menu
        sx={{ m: 0.5 }}
        open={downloadOpen}
        anchorEl={downloadAnchorEl}
        onClose={() => setDownloadAnchorEl(null)}
      >
        <MenuItem>
          <TypographyDefault
            onClick={() => DownloadReport("csv")}
            py={0.5}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
          >
            <CsvIcon width={40} />{" "}
            {LanguageData?.Download_csv || "Download as CSV file"}
          </TypographyDefault>
        </MenuItem>
        <MenuItem>
          <TypographyDefault
            onClick={() => DownloadReport("pdf")}
            py={0.5}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
          >
            <PdfIcon width={40} />
            {LanguageData?.Download_pdf || "Download as PDF file"}
          </TypographyDefault>
        </MenuItem>
      </Menu>

      <Menu
        sx={{ m: 0.5 }}
        open={showOpen}
        anchorEl={showAnchorEl}
        onClose={() => setshowAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            setRowsPerPage(10);
            setshowAnchorEl(null);
          }}
        >
          <Typography>10 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(20);
            setshowAnchorEl(null);
          }}
        >
          <Typography>20 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(30);
            setshowAnchorEl(null);
          }}
        >
          <Typography>30 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(40);
            setshowAnchorEl(null);
          }}
        >
          <Typography>40 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(50);
            setshowAnchorEl(null);
          }}
        >
          <Typography>50 rows</Typography>
        </MenuItem>
      </Menu>

      <Modal open={SelectTypeModal}>
        <Box
          sx={{
            position: "absolute",
            border: "none",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: { xs: "70%", sm: "50", md: "40%", lg: "35%" },
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: "2px solid #F3F4F6",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PrimaryTypography>
                {LanguageData?.Marketing?.Types_Title ||
                  "Select Type of Marketing"}
              </PrimaryTypography>
              <Close onClick={() => closemodal()} />
            </Box>
            <Box sx={{ px: 2, borderBottom: "2px solid #F3F4F6" }}>
              <Stack
                p={2}
                my={2}
                direction="row"
                onClick={() => setSelectedTypeData(1)}
                alignItems="center"
                justifyContent="space-between"
                minHeight="70px"
                border={
                  selectedTypeData == "1"
                    ? "1px solid #0E4965"
                    : "1px solid white"
                }
                backgroundColor={
                  selectedTypeData == "1" ? "#EDF6FA" : "#F9FAFB"
                }
                borderRadius="8px"
              >
                <Stack direction="column">
                  <StyledTypography color="#0E4965 !important">
                    {LanguageData?.Marketing?.Event || "Event"}
                  </StyledTypography>
                  <StyledSecondaryTypography mt={1}>
                    {LanguageData?.Marketing?.Event_Description ||
                      "Building event that are happening within the condo complex"}
                  </StyledSecondaryTypography>
                </Stack>
                <Stack>
                  <Checkbox
                    name="single"
                    checked={selectedTypeData == "1"}
                    icon={<CheckboxIcon />}
                  />
                </Stack>
              </Stack>
              <Stack
                p={2}
                my={2}
                direction="row"
                onClick={() => setSelectedTypeData(2)}
                alignItems="center"
                justifyContent="space-between"
                minHeight="70px"
                border={
                  selectedTypeData == "2"
                    ? "1px solid #0E4965"
                    : "1px solid white"
                }
                sx={{
                  backgroundColor:
                    selectedTypeData == "2" ? "#EDF6FA" : "#F9FAFB",
                  borderRadius: "8px",
                }}
              >
                <Stack direction="column">
                  <StyledTypography color="#0E4965 !important">
                    {LanguageData?.Marketing?.Promotion || "Promotion"}
                  </StyledTypography>
                  <StyledSecondaryTypography mt={1}>
                    {LanguageData?.Marketing?.Promotion_Description ||
                      "Promotion like discounts and offers provided to condo residents"}
                  </StyledSecondaryTypography>
                </Stack>
                <Stack>
                  <Checkbox
                    name="multiple"
                    checked={selectedTypeData == "2"}
                    icon={<CheckboxIcon />}
                  />
                </Stack>
              </Stack>
            </Box>

            <Box
              sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={() => closemodal()}
                sx={{ background: "#FFFFFF", color: "#0E5E84" }}
                variant="outlined"
              >
                {LanguageData?.Cancel || "Cancel"}
              </Button>
              <Button
                disabled={Boolean(!selectedTypeData)}
                variant="contained"
                onClick={() => {
                  selectedTypeData == 1
                    ? navigate("createEvent")
                    : navigate("createPromotion");
                }}
                sx={{
                  "&:hover": {
                    background: "#0E5E84",
                  },
                }}
                style={{
                  padding: "10px 16px",
                  background: Boolean(!selectedTypeData)
                    ? "#E5E7EB"
                    : "#0E5E84",
                  borderRadius: "8px",
                  color: "#FFF",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                {LanguageData?.Confirm || "Confirm"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal open={showMultipleDeleteModal}>
        <Box
          sx={{
            position: "absolute",
            border: "none",
            width: "100%",
            height: "90%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: "40%",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: "2px solid #F3F4F6",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PrimaryTypography>
                {LanguageData?.Marketing?.Delete_Title ||
                  "Confirm to delete the Promotion/ Event"}
              </PrimaryTypography>
              <Close onClick={() => handleShowMultipleDeleteModal()} />
            </Box>
            <Box sx={{ px: 3, py: 3, borderBottom: "2px solid #F3F4F6" }}>
              <Typography mb={1.3}>
                {" "}
                {LanguageData?.Marketing?.Multi_Delete_Subtitle ||
                  "Please confirm that you wish to delete the event/promotion. Upon deletion event/promotion will disappear from both dashboard and mobile app."}
              </Typography>
              {multipleDel.map((itm, index) => {
                return (
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: 400, color: "#1F2A37" }}
                  >
                    {index + 1}. {itm?.title}
                  </Typography>
                );
              })}
            </Box>
            <Box
              sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={() => handleShowMultipleDeleteModal()}
                sx={{ background: "#FFFFFF", color: "#0E5E84" }}
                variant="outlined"
              >
                {LanguageData?.Cancel || "Cancel"}
              </Button>
              <Button
                onClick={() => handleMultipleDelete()}
                sx={{
                  background: "#B42318",
                  color: "#FFFFFF",
                  "&:hover": { background: "#B42318" },
                }}
                variant="contained"
              >
                {LanguageData?.Delete_now || "Delete now"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
