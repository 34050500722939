import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Avatar,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Badge,
  MenuItem,
  Toolbar,
  Tooltip,
  Modal,
  Popover,
  Button,
  Stack,
  Typography,
  Snackbar,
  SnackbarContent,
  IconButton,
  Slide,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../Icons/Logo.svg";
import { ReactComponent as Close } from "../Icons/BorderAlertClose.svg";
import { ReactComponent as Notification } from "../Icons/Notification.svg";
import { ReactComponent as LobyTextLogo } from "../Icons/LobyTextLogo.svg";
import { ReactComponent as Background } from "../Icons/ProfileBackground.svg";
import { ReactComponent as Hamberger } from "../Icons/MenuIcon.svg";
import { ReactComponent as NotificationGreenDot } from "../Icons/NotificationGreenDot.svg";
import { ReactComponent as ProfileSettingIcon } from "../Icons/ProfileSetting.svg";
import { ReactComponent as LogoutIcon } from "../Icons/Logout.svg";
import { ReactComponent as SaveAsIcon } from "../Icons/SaveAsDraftIcon.svg";
import { ReactComponent as AlertTosterClose } from "../Icons/AlertTosterClose.svg";
import {
  logoutModalData,
  saveAsDraftPropertySetupData,
  menus,
  logoutModalDraftData,
} from "../Data/index";
import parse from "html-react-parser";
import MenuBar from "./MenuBar";
import PropertyDetails from "../Pages/PropertyDetails";
import NetworkModal from "../CommonComponents/NetworkModal";
import {
  TypographyDefault,
  StyledPrimaryTypography,
  TypoGraph,
  SecondaryTypography,
  StyledDefaultTypography,
} from "../Theme";
import {
  savePropertyDetailsAsDraft,
  setUserDetails,
  loadLocalStorageData,
  setManageData,
  setAccessData,
  setPostLoader,
} from "../Redux/Feature/Login/loginSlice";
import { getLanguageDatas } from "../Redux/Feature/Language/LanguageSlice";
import { setpropertySetupLoader } from "../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { useDispatch } from "react-redux";
import AlertModal, {
  PropertySetupLoaderModal,
  PostLoaderModal,
} from "../CommonComponents/modal";
import { json, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  userData,
  timeAgo,
  getLocalStorageData,
  getPropertyStatus,
} from "../Utils";
import stringAvatar from "../CommonComponents/avatar";
import axiosInstance from "../Redux/AxiosInstance";
import { onMessage } from "firebase/messaging";
import { messaging } from "../firebase";
import TranslateIcon from "@mui/icons-material/Translate";
import DoneIcon from "@mui/icons-material/Done";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import "../CommonComponents/styles/Toaster.css"
import Toaster from "../CommonComponents/Toaster";
import { max } from "moment";

// export const showToast = (message, type) => {
//   if (type == "delete") {
//     return toast(message);
//   } else {
//     return toast.success(message);
//   }
// };

const Header = () => {
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [value, setValue] = useState([]);
  const dispatch = useDispatch();
  const [logoutModal, setLogoutModal] = useState(false);
  const [saveDraftModal, setSaveDraftModal] = useState(false);
  const [logoutDraftModal, setLogOutDraftModal] = useState(false);
  const location = useLocation();
  const { userDetails } = useSelector((state) => state.Login);
  const { propertyId, userRole, PropertyStatusDetails, propertySetupLoader } =
    useSelector((state) => state.PropertyDetails);

  const [allowedAccess, setAllowedAccess] = useState({
    access: {},
    manage: {},
  });
  const isDraft = JSON.parse(localStorage.getItem("propertyStatus"));
  const [anchorElNotifyModal, setAnchorElNotifyModal] = React.useState(null);
  const [notifySts, setNotifySts] = useState(false);
  const [NotifySnack, setNotifySnack] = useState(false);
  const [NotifySingleData, setNotifySingleData] = useState({
    title: "",
    body: "",
  });
  const openNotifyModal = Boolean(anchorElNotifyModal);
  const navigate = useNavigate();
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [NotificationAllData, setNotificationAllData] = useState([]);
  const [NotifyTab, setNotifyTab] = useState(1);
  const [UnreadNotify, setUnreadNotify] = useState([]);
  const [postLoader, SetPostLoader] = useState(false);
  const [languageMenuTrigger, setLanguageMenuTrigger] = useState(null);
  const [languageId, setLanguageId] = useState(3);
  const languageOpen = Boolean(languageMenuTrigger);
  const [languageArray, setLanguageArray] = useState([]);
  // const [notficationHide, setNotificationHide] = useState(true);
  // console.log(PropertyStatusDetails);
  // console.log(notficationHide);
  useEffect(() => {
    dispatch(getLanguageDatas({ languageId }));
  }, [languageId]);

  const GetAllNotification = async () => {
    console.log("im Notification");
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}notification/property/${propertyId}`
      );
      setNotificationAllData(response?.data?.data?.notification);
      const data = response?.data?.data?.notification.filter(
        (itm) => itm?.is_seen == 0
      );
      setUnreadNotify(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetAllNotification();
  }, []);

  const handleNotifySeen = async (id) => {
    const data = { notification_id: id };
    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}notification/property/${propertyId}/seen`,
        data
      );
      GetAllNotification();
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotifyNavigation = (key, ref_id, type_id) => {
    if (Boolean(ref_id)) {
      switch (key) {
        case "facility":
          navigate(`manage/reviewFacilityManage/${ref_id}`);
          handlePopoverClose();
          break;
        case "fault-report":
          navigate(`/fault-reports/reviewFaultReport/${ref_id}`);
          handlePopoverClose();
          break;
        case "service-request":
          navigate(`/service-request-list/${ref_id}`);
          handlePopoverClose();
          break;
        case "parcel":
          navigate(`parcel-collection/${ref_id}`);
          handlePopoverClose();
          break;
        case "marketing":
          if (type_id == 1) {
            navigate(`/marketing/reviewEvent/${ref_id}`);
          } else if (type_id == 2) {
            navigate(`/marketing/reviewPromotion/${ref_id}`);
          }
          handlePopoverClose();
          break;
        case "announcement":
          navigate(`/announcement/ReviewAnnouncement/${ref_id}`);
          handlePopoverClose();
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}language`)
      .then((res) => {
        setLanguageArray(res.data?.data?.language_type);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    const data = NotificationAllData?.find((itm) => itm?.is_seen == 0);
    if (data) {
      setNotifySts(true);
    } else {
      setNotifySts(false);
    }
  }, [NotificationAllData]);

  onMessage(messaging, (payload) => {
    setNotifySnack(true);
    setNotifySingleData({
      title: payload?.notification?.title,
      body: payload?.notification?.body,
    });
    console.log(payload, "im onMessage");
    GetAllNotification();
  });

  const handleCloseNotifySnack = () => {
    setNotifySnack(false);
  };

  const handlePopoverOpen = (event) => {
    setAnchorElNotifyModal(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElNotifyModal(null);
  };

  //user_Access

  useEffect(() => {
    localStorage.setItem("Allowed_access", JSON.stringify(allowedAccess));
    dispatch(setAccessData(allowedAccess?.access));
    dispatch(setManageData(allowedAccess?.manage));
  }, [allowedAccess]);

  useEffect(() => {
    const handleWindowResize = () => {
      setInnerHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  // useEffect(() => {
  //   if (PropertyStatusDetails?.status === "draft") {
  //     setNotificationHide(false);
  //   } else {
  //     setNotificationHide(true);
  //   }
  // }, [PropertyStatusDetails]);

  useEffect(() => {
    dispatch(loadLocalStorageData());

    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}user_menu_web/property/${propertyId}`
      )
      .then((response) => {
        setValue(response.data.data.menu);
        const Data = response.data.data.menu;
        const userDetails = getLocalStorageData();

        if (userDetails?.user_role == 2) {
          setAllowedAccess({
            access: {
              announcement: true,
              booking: true,
              "contact-book": true,
              "fault-reports": true,
              manage: true,
              marketing: true,
              "parcel-collection": true,
              residents: true,
              "service-request": true,
              settings: true,
              staffs: true,
              visitors: true,
            },
            manage: {
              announcement: true,
              booking: true,
              "contact-book": true,
              "fault-reports": true,
              manage: true,
              marketing: true,
              "parcel-collection": true,
              residents: true,
              "service-request": true,
              settings: true,
              staffs: true,
              visitors: true,
            },
          });
        } else if (userDetails?.user_role == 3) {
          setAllowedAccess((prev) => {
            const allAccessobj = {};
            const allManageobj = {};

            const menu = Data?.map((itm) => {
              if (itm?.sub_menu.length > 0) {
                const accessobj = {};
                const manageobj = {};
                const subMenu = itm?.sub_menu?.map((sitm) => {
                  if (sitm?.allowed_access.length !== 0) {
                    Object.assign(accessobj, {
                      [sitm.key]: sitm?.allowed_access?.access,
                    });
                    Object.assign(manageobj, {
                      [sitm.key]: sitm?.allowed_access?.manage,
                    });
                  }
                });
                Object.assign(allAccessobj, accessobj);
                Object.assign(allManageobj, manageobj);
              } else if (itm?.allowed_access.length !== 0) {
                Object.assign(allAccessobj, {
                  [itm?.key]: itm?.allowed_access?.access,
                });
                Object.assign(allManageobj, {
                  [itm?.key]: itm?.allowed_access?.manage,
                });
              }
            });

            return {
              access: { ...allAccessobj },
              manage: { ...allManageobj },
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleProfileSettings = () => {
    setAnchorEl(null);
    navigate("/profile-settings");
  };

  const handleLogout = () => {
    setLogoutModal(true);
    setAnchorEl(null);
  };

  const handleLogoutConfirm = () => {
    SetPostLoader(true);
    const token = localStorage.getItem("accessToken");
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const validate = res.data?.success;
        if (validate) {
          setLogoutModal(false);
          setAnchorEl(null);
          const email = localStorage.getItem("email");
          const emailcheck = localStorage.getItem("emailcheck");
          const password = localStorage.getItem("password");
          const passwordcheck = localStorage.getItem("passwordcheck");
          localStorage.clear();
          if (email !== null) {
            localStorage.setItem("email", email);
            localStorage.setItem("emailcheck", emailcheck);
          }
          if (password !== null) {
            localStorage.setItem("password", password);
            localStorage.setItem("passwordcheck", passwordcheck);
          }
          navigate("/");
          SetPostLoader(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSaveAs = () => {
    console.log("asd");
  };

  const handleLanguageChange = (id) => {
    setLanguageId(id);
    setLanguageMenuTrigger(null);
  };

  return (
    <>
      <NetworkModal />
      <Toaster />
      <PropertySetupLoaderModal open={propertySetupLoader} />
      {postLoader && <PostLoaderModal />}

      {logoutModal && (
        <AlertModal
          closeFunction={() => setLogoutModal(false)}
          modalDatas={logoutModalData}
          confirmFunction={handleLogoutConfirm}
        />
      )}

      {saveDraftModal && (
        <AlertModal
          closeFunction={() => setSaveDraftModal(false)}
          modalDatas={saveAsDraftPropertySetupData}
          confirmFunction={handleSaveAs}
          isDraft={true}
        />
      )}

      {logoutDraftModal && (
        <AlertModal
          closeFunction={() => setLogOutDraftModal(false)}
          modalDatas={logoutModalDraftData}
          confirmFunction={handleLogoutConfirm}
        />
      )}

      {/* {postLoader && */}

      {/* } */}
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "-ms-flexbox",
          boxSizing: "border-box",
        }}
      >
        <AppBar
          sx={{
            boxShadow: "none",
            borderBottom: "1px solid #D2D6DB",
            zIndex: 4,
            position: "fixed",
            backgroundColor: "#FFFFFF !important",
            borderRadius: "1px !important",
          }}
        >
          <Toolbar
            sx={{
              backgroundColor: "#FFFFFF !important",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  marginRight: "15px",
                  display: { xs: "-moz-initial", sm: "none" },
                }}
              >
                <Hamberger onClick={() => setMobileDrawer(true)} />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LobyTextLogo />
              </Box>
              {/* <Typography sx={{ color: "#0E4965", fontSize: "20px", ml: 1 }}>
                <LobyTextLogo />
              </Typography> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "187px",
                justifyContent: "center",
              }}
            >
              {/* <TranslateIcon
                style={{
                  color: "#4D5761",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                onClick={(e) => setLanguageMenuTrigger(e.currentTarget)}
              /> */}
              {isDraft?.status != "draft" && (
                <Badge
                  sx={{
                    "&>.css-1v67vep-MuiBadge-badge": {
                      height: "9px ",
                      width: "9px ",
                    },
                  }}
                  color="notify"
                  variant="dot"
                  overlap="circular"
                  invisible={!notifySts}
                >
                  <Notification
                    style={{ cursor: "pointer" }}
                    onClick={handlePopoverOpen}
                  />
                </Badge>
              )}
              <Box
                ml={2}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "4px 10px",
                  borderRadius: "8px",
                  "&:hover": { backgroundColor: "#F3F4F6" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <Background /> */}
                  {/* <Tooltip> */}
                  {/* <Typography
                      sx={{
                        color: "#111927",
                        position: "absolute",
                        fontSize: "12px",
                        fontWeight: 500,
                        textTransform: "uppercase",
                      }}
                    > */}
                  <Avatar
                    style={{ width: "32px", height: "32px", fontSize: "16px" }}
                    {...stringAvatar(userDetails?.name)}
                  />
                  {/* </Typography> */}
                  {/* </Tooltip> */}
                </Box>
                <Typography
                  sx={{
                    color: "#111927",
                    fontSize: "14px",
                    ml: 1,
                    fontWeight: 500,
                    maxWidth: "100px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {userDetails?.name}
                </Typography>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>

        {location.pathname === "/propertyDetailsSetup" &&
        userRole == 2 &&
        userData("status") === "draft" ? (
          <PropertyDetails />
        ) : (
          <Box
            sx={{
              display: "flex",
              backgroundColor: "#F0F0F0",
              pt: "65px",
              height: "calc(100% - 65px)",
              backgroundColor: "#F3F4F6",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "310px",
                display: { sm: "inherit", xs: "none" },
                borderRight: "1px solid #E5E7EB",
                minHeight: `${innerHeight - 66}px`,
              }}
            >
              <Box
                sx={{
                  background: "#F3F4F6",
                  height: "100%",
                  overflowY: "auto",
                  overflowX: "hidden",
                  "&::-webkit-scrollbar": { width: "1.5px" },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: " inset 0 0 1.5px #D3D3D3",
                  },
                }}
              >
                <MenuBar items={value} />
              </Box>
              <TypographyDefault sx={{ textAlign: "center", pb: 1 }}>
                Version : 0.1.2
              </TypographyDefault>
            </Box>
            <Box
              sx={{
                padding: "35px",
                overflow: "auto",
                width: "100%",
                background: "#F3F4F6",
                "::-webkit-scrollbar": { display: "none" },
              }}
            >
              <Outlet />
            </Box>
          </Box>
        )}
      </Box>

      <Drawer
        sx={{ display: { xs: "-moz-initial", sm: "none" } }}
        variant="temporary"
        anchor="left"
        open={mobileDrawer}
        onClose={() => setMobileDrawer(false)}
      >
        <Box sx={{ width: "200px" }}>
          <MenuBar items={value} />
        </Box>
      </Drawer>
      <Menu
        onClose={() => setAnchorEl(null)}
        open={open}
        anchorEl={anchorEl}
        sx={{ mt: 2 }}
      >
        {isDraft?.status === "draft" ? (
          <>
            <MenuItem onClick={() => setSaveDraftModal(true)}>
              <Box sx={{ display: "flex" }}>
                <SaveAsIcon />
                <Typography
                  sx={{
                    ml: 1.5,
                    color: "#1F2A37",
                  }}
                >
                  Save as Draft
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem sx={{ mt: 2 }} onClick={() => setLogOutDraftModal(true)}>
              <Box sx={{ display: "flex" }}>
                <LogoutIcon />
                <Typography
                  sx={{
                    ml: 1.5,
                    color: "#B42318",
                  }}
                >
                  Logout
                </Typography>
              </Box>
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem onClick={handleProfileSettings}>
              <Box sx={{ display: "flex" }}>
                <ProfileSettingIcon />
                <Typography
                  sx={{
                    ml: 1.5,
                    color: "#1F2A37",
                  }}
                >
                  Profile Settings
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem onClick={handleLogout} sx={{ mt: 2 }}>
              <Box sx={{ display: "flex" }}>
                <LogoutIcon />
                <Typography
                  sx={{
                    ml: 1.5,
                    color: "#B42318",
                  }}
                >
                  Logout
                </Typography>
              </Box>
            </MenuItem>
          </>
        )}
      </Menu>

      {/* <Modal open={true} >
        <Box sx={{ position: 'absolute', border: 'none', width: '100%', height: '90%', display: "flex", alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ backgroundColor: '#FFFFFF', width: '40%', borderRadius: '8px', }}>


            <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button sx={{ background: '#FFFFFF', color: '#0E5E84' }} variant='outlined'>cancel</Button>
              <Button sx={{ background: '#B42318', color: "#FFFFFF", "&:hover": { background: "#B42318" } }} variant='contained'>Delete now</Button>
            </Box>
          </Box>
        </Box>
      </Modal> */}
      <Popover
        id="mouse-over-popover"
        sx={{
          top: "25px",
        }}
        open={openNotifyModal}
        anchorEl={anchorElNotifyModal}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => handlePopoverClose()}
        disableRestoreFocus
      >
        <Box
          sx={{
            width: "330px",
            maxHeight: "550px",
            padding: "20px 12px",
            backgroundColor: "#FCFCFD",
            overflowY: "scroll",
            "::-webkit-scrollbar": { display: "none" },
          }}
        >
          <StyledPrimaryTypography>Notification</StyledPrimaryTypography>
          {NotificationAllData?.length !== 0 ? (
            <Stack
              my={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" columnGap="8px" sx={{ cursor: "pointer" }}>
                <TypoGraph
                  onClick={() => setNotifyTab(1)}
                  sx={{
                    color: "#111927 !important",
                    fontWeight: "500 !important",
                    backgroundColor: NotifyTab == 1 ? "#E5E7EB" : "none",
                    padding: "9px 12px",
                    border: "1px solid #E5E7EB",
                    borderRadius: "24px",
                  }}
                >
                  All
                </TypoGraph>
                <TypoGraph
                  onClick={() => setNotifyTab(2)}
                  sx={{
                    color: "#111927 !important",
                    fontWeight: "500 !important",
                    backgroundColor: NotifyTab == 2 ? "#E5E7EB" : "none",
                    padding: "9px 12px",
                    border: "1px solid #E5E7EB",
                    borderRadius: "24px",
                  }}
                >
                  Unread
                </TypoGraph>
              </Box>
              <Box sx={{ cursor: "pointer" }}>
                <TypoGraph
                  mr={2}
                  sx={{
                    color: "#0E4965 !important",
                    fontWeight: "600 !important",
                  }}
                  onClick={() => {
                    handlePopoverClose();
                    navigate("/ViewNotification");
                  }}
                >
                  See All
                </TypoGraph>
              </Box>
            </Stack>
          ) : (
            <Typography sx={{ textAlign: "center", py: 4, color: "#b6bac3" }}>
              No Notification yet
            </Typography>
          )}
          <Stack direction="column" sx={{ backgroundColor: "#FFFFFF" }}>
            {(NotifyTab === 1 ? NotificationAllData : UnreadNotify)?.map(
              (itm, index) => {
                return (
                  <Stack
                    key={index}
                    onClick={() => {
                      handleNotifyNavigation(
                        itm?.notification_type?.key,
                        itm?.ref_id,
                        itm?.notification_type?.sub_type
                      );
                      handleNotifySeen(itm?.id);
                    }}
                    px={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ cursor: "pointer" }}
                  >
                    <Box mt={index == 0 ? 0.5 : 1} p={1.2}>
                      <TypoGraph
                        sx={{
                          color: "#0E5E84 !important",
                          fontWeight: "500 !important",
                        }}
                      >
                        {timeAgo(itm?.created_at)}
                      </TypoGraph>
                      <SecondaryTypography
                        sx={{ fontWeight: "500 !important" }}
                        mt="10px"
                      >
                        {itm?.title}
                      </SecondaryTypography>
                      <TypoGraph
                        sx={{
                          "&>*": { margin: 0 },
                          fontWeight: "400 !important",
                        }}
                        mt="4px"
                      >
                        {parse(itm?.description)}
                      </TypoGraph>
                    </Box>
                    <IconButton>
                      {itm?.is_seen == 0 && <NotificationGreenDot />}
                    </IconButton>
                  </Stack>
                );
              }
            )}
          </Stack>
        </Box>
      </Popover>

      <Snackbar
        open={NotifySnack}
        onClose={() => handleCloseNotifySnack()}
        TransitionComponent={(props) => <Slide {...props} direction="left" />}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "right", horizontal: "right" }}
      >
        <SnackbarContent
          style={{
            backgroundColor: "white",
            paddingTop: "0",
            paddingBottom: "0",
            color: "#384250",
            fontWeight: "500",
            borderRadius: "8px",
            height: "55px",
          }}
          message={
            <Stack direction="row" alignItems="center">
              <styledTyphographyDefault>
                {NotifySingleData?.title}
              </styledTyphographyDefault>
            </Stack>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => {
                handleCloseNotifySnack();
              }}
            >
              <AlertTosterClose />
            </IconButton>,
          ]}
        />
      </Snackbar>

      <Menu
        sx={{ width: "312px", height: "459px" }}
        open={languageOpen}
        anchorEl={languageMenuTrigger}
        onClose={() => setLanguageMenuTrigger(null)}
      >
        {languageArray?.map((value) => (
          <MenuItem
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => handleLanguageChange(value?.id)}
            key={value?.id}
          >
            <Typography>{value?.name}</Typography>
            {languageId === value.id && (
              <DoneIcon
                style={{
                  fontSize: "1.4rem",
                  marginLeft: "20px",
                  color: "#4D5761",
                }}
              />
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Header;
