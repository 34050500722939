import React, { useEffect, useState } from "react";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as MoreInfoIcon } from "../../../Icons/MoreInfo.svg";
import { ReactComponent as CommonAreaIcon } from "../../../Icons/CommonArea.svg";
import { ReactComponent as CheckboxIcon } from "../../../Icons/Checkbox.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import { ReactComponent as Arrow } from "../../../Icons/AccordionArrow.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  TypoGraph,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../../Theme";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AlertModal from "../../../CommonComponents/modal";
import {
  addCommonArea,
  getAllCommonAreaByProperty,
  editCommonAreaApi,
  setPropertySliceToNull,
  getAllBuildingByProperty,
  setNestedStep,
  deleteOneCommonArea,
  setActiveStep,
} from "../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import SystemDetailsModal from "../../../CommonComponents/SystemDetailsModal";
import moment from "moment";
import { PostLoaderModal } from "../../../CommonComponents/modal";

const AddCommonArea = ({ setDisplayScreen, commonArea, setCommonArea }) => {
  console.log(commonArea);
  let modalDatas = {
    label: "Are you sure you wish to delete the common area?",
    descriptionOne:
      "Please confirm that you wish to delete the common area input.",
    descriptionTwo: "",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#B42318",
      text: "Delete Now",
    },
  };
  const dispatch = useDispatch();

  // const [commonArea, setCommonArea] = useState([])
  const [editCommonArea, setEditCommonArea] = useState([]);
  const [EditDataErrorText, setEditDataErrorText] = useState({});
  const {
    addCommonAreaStatus,
    commonAreaList,
    TabValue,
    deleteOneCommonAreaStatus,
    editCommonAreaStatus,
    propertyId,
    buildingList,
  } = useSelector((state) => state.PropertyDetails);
  const [addmore, setAddMore] = useState(false);
  const [data, setData] = useState({
    name: "",
    description: "",
    isPartOfBuilding: false,
    building: "",
    level: "",
    fromApi: false,
  });
  const [dataErrorText, setDataErrorText] = useState({
    name: "",
    description: "",
    isPartOfBuilding: false,
    building: "",
    level: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [deletingIndex, setDeletingIndex] = useState();
  const [commonAreaError, setCommonAreaError] = useState("");
  const [DDbuildings, setDDbuildings] = useState([]);
  const [DDlevel, setDDlevel] = useState([]);
  const [addblocks, setaddblocks] = useState({
    Details: true,
    Review_Information: false,
  });
  const blocks = [
    { status: addblocks.Details, tle: "Details" },
    { status: addblocks.Review_Information, tle: "Review Information" },
  ];
  const [editIndex, setEditIndex] = useState("");
  const [updatedAt, setUpdatedAt] = useState(null);
  const [postLoader, SetPostLoader] = useState(false);

  useEffect(() => {
    // setEditDataErrorText(editCommonArea[editIndex])
    setEditDataErrorText({
      name: "",
      description: "",
      isPartOfBuilding: false,
      building: "",
      building_name: "",
      level: "",
      level_name: "",
      fromApi: false,
      id: editCommonArea[editIndex]?.id,
    });
  }, [editCommonArea]);

  useEffect(() => {
    if (buildingList?.success) {
      setDDbuildings(buildingList?.data?.property_buildings);
    }
  }, [buildingList]);

  console.log(addCommonAreaStatus);

  useEffect(() => {
    if (addCommonAreaStatus != null) {
      if (addCommonAreaStatus?.success) {
        setData({
          name: "",
          description: "",
          isPartOfBuilding: false,
          building: "",
          level: "",
        });
        setAddMore(false);
        dispatch(getAllCommonAreaByProperty(propertyId));
        dispatch(setPropertySliceToNull());
        SetPostLoader(false);
      }
    }
    if (editCommonAreaStatus != null) {
      if (editCommonAreaStatus?.success) {
        setDataErrorText({
          name: "",
          description: "",
          isPartOfBuilding: false,
          building: "",
          level: "",
        });
        dispatch(getAllCommonAreaByProperty(propertyId));
        dispatch(setPropertySliceToNull());
        SetPostLoader(false);
      }
    }
  }, [addCommonAreaStatus, editCommonAreaStatus]);

  useEffect(() => {
    if (deleteOneCommonAreaStatus != null) {
      if (deleteOneCommonAreaStatus?.success) {
        dispatch(getAllCommonAreaByProperty(propertyId));
        setShowModal(false);
        setEditCommonArea((prev) => {
          const data = prev.map((itm, ind) => {
            return { ...itm, ["showInfo"]: false };
          });
          return data;
        });
        SetPostLoader(false);
      }
    }
  }, [deleteOneCommonAreaStatus]);

  useEffect(() => {
    if (commonAreaList?.success) {
      if (commonAreaList?.data?.common_areas?.length > 0) {
        let dateArray = commonAreaList?.data?.common_areas?.map((value) => {
          return value?.updated_at;
        });
        const mostRecentDate = moment.max(
          dateArray.map((date) => moment(date))
        );

        setUpdatedAt(mostRecentDate);
      }
      console.log(commonAreaList.data.common_areas);
      if (commonAreaList.data.common_areas.length > 0) {
        let newCommonArea = commonAreaList.data.common_areas.map((value) => {
          return {
            // fromApi: true,
            // name: value.common_area_name,
            // description: value.description,
            // isPartOfBuilding: value.is_part_of_building,
            // building: value.building_id,
            // building_name: value?.building?.building_name,
            // level: value.level_id,
            // level_name: value?.building_level?.level,
            // commonAreaId: value.id
            id: value?.id,
            fromApi: true,
            name: value.common_area_name,
            description: value.description,
            isPartOfBuilding: value.is_part_of_building,
            building: value.building_id,
            building_name: value?.buildings?.building_name
              ? value?.buildings?.building_name
              : null,
            level: value.level_id,
            level_name: value?.building_level?.level
              ? value?.building_level?.level
              : null,
            commonAreaId: value.id,
            showInfo: false,
            updated_at: updatedAt,
          };
        });
        setCommonArea([...newCommonArea]);
        setEditCommonArea([...newCommonArea]);
        // setEditCommonArea([])
      }
    } else {
      setCommonArea([]);
      setEditCommonArea([]);
    }
  }, [commonAreaList]);

  const handleData = (e) => {
    let { name, value } = e.target;
    setCommonAreaError("");
    if (name === "building") {
      setData((prev) => {
        return {
          ...data,
          level: "",
        };
      });
      const leveldata = buildingList?.data?.property_buildings.find(
        (itm) => itm.id === value
      );
      setDDlevel(leveldata.level);
    }

    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    setDataErrorText({
      ...dataErrorText,
      [name]: "",
    });
  };

  const validate = () => {
    let isError = [];
    for (let key in data) {
      if (key === "isPartOfBuilding" && data[key] === false) {
        break;
      } else {
        if (data[key] === "") {
          setDataErrorText((prev) => {
            return {
              ...prev,
              [key]: "Error! No Inputs detected",
            };
          });
          isError.push(true);
        }
      }
    }
    return isError.includes(true);
  };

  const addSingleCommonArea = () => {
    let isError = validate();
    if (!isError) {
      SetPostLoader(true);
      dispatch(
        addCommonArea(
          data.isPartOfBuilding
            ? {
                common_area_name: data.name,
                description: data.description,
                is_part_of_building: data.isPartOfBuilding ? 1 : 0,
                propertyId,
                building_id: data.building,
                level_id: data.level,
              }
            : {
                common_area_name: data.name,
                description: data.description,
                is_part_of_building: data.isPartOfBuilding ? 1 : 0,
                propertyId,
              }
        )
      );
    }
    // let existingCommonArea = commonArea
    // existingCommonArea.push(data)
    // if(!isError){
    //     setCommonArea([...existingCommonArea])
    //     setData({name:'',description:'',isPartOfBuilding:false,building:'',level:''})
    //     setAddMore(false)
    // }
  };

  const deleteExistingCommonArea = () => {
    // let newCommonArea = [...commonArea.slice(0,deletingIndex),...commonArea.slice(deletingIndex+1)]
    // setCommonArea([...newCommonArea])
    SetPostLoader(true);
    dispatch(deleteOneCommonArea({ propertyId, id: deletingIndex }));
  };

  const deleteCommonArea = () => {
    setAddMore(!addmore);
    setData({
      name: "",
      description: "",
      isPartOfBuilding: false,
      building: "",
      level: "",
    });
    setDataErrorText({
      name: "",
      description: "",
      isPartOfBuilding: false,
      building: "",
      level: "",
    });
  };

  const handleContinue = () => {
    if (commonArea.length > 0) {
      setDisplayScreen("reviewCommonArea");
      dispatch(setNestedStep(1));
    } else {
      setCommonAreaError("Add atleast one common area");
    }
  };

  const commonmodal = {
    commonarea: {
      label: "Common Area Explanation",
      description:
        "Property Common Area refers to areas within the condo complex that are not housing any residents and will be listed in common area, and selectable when residents submit fault reports. ",
    },
    buildingcommonarea: {
      label: "This common area is a part of a building",
      description:
        "All the units in this floor are running numbers will cause all numbers to represent one unit. When adding the next level, the unit count will start from the end of the last unit of this level. ",
    },
  };

  const backStep = () => {
    dispatch(setActiveStep(1));
  };

  const Continue = (nxtval) => {
    if (nxtval == "Review Information") {
      setaddblocks({ Details: false, Review_Information: true });
    } else if (nxtval == "Details") {
      setaddblocks({ Details: true, Review_Information: false });
    }
  };

  const handleExistingData = (e, index, val) => {
    setCommonAreaError("");
    setEditIndex(index);
    const { name, value, checked } = e.target;

    if (val != undefined) {
      if (!val) {
        setEditCommonArea(commonArea);
      }
      setEditCommonArea((prev) => {
        const data = prev.map((itm, ind) => {
          if (ind == index) {
            return { ...itm, ["showInfo"]: val };
          }
          return itm;
        });
        return data;
      });
    } else if (name == "building") {
      const building_name = DDbuildings.find(
        (itm) => itm.id == value
      )?.building_name;

      setEditCommonArea((prev) => {
        const data = prev.map((itm, ind) => {
          if (ind == index) {
            return {
              ...itm,
              [name]: value,
              building_name: building_name,
              level: "",
              level_name: "",
            };
          }
          return itm;
        });
        return data;
      });
    } else if (name == "level") {
      const level = DDbuildings.find(
        (itm) => itm?.id == editCommonArea[index]?.building
      )?.level;

      const level_name = level.find((litm) => litm?.id == value)?.level;

      setEditCommonArea((prev) => {
        const data = prev.map((itm, ind) => {
          if (ind == index) {
            return { ...itm, [name]: value, level_name: level_name };
          }
          return itm;
        });
        return data;
      });
    } else if (name == "isPartOfBuilding") {
      setEditCommonArea((prev) => {
        const data = prev.map((itm, ind) => {
          if (ind == index) {
            return { ...itm, [name]: value == "on" ? checked : value };
          }
          return itm;
        });
        return data;
      });
    } else {
      setEditCommonArea((prev) => {
        const data = prev.map((itm, ind) => {
          if (ind == index) {
            return { ...itm, [name]: value == "on" ? checked : value };
          }
          return itm;
        });
        return data;
      });
    }
  };

  const editValidate = (val) => {
    let isError = false;
    const data = editCommonArea.find((itm) => itm?.id == val);

    for (const key in data) {
      if (
        key === "isPartOfBuilding" &&
        (data[key] === false || data[key] === 0)
      ) {
        break;
      } else {
        if (data[key] === "" || data[key] === null) {
          console.log(key);
          setEditDataErrorText((prev) => {
            return {
              ...prev,
              [key]: true,
            };
          });
          isError = true;
        }
      }
    }
    return isError;
  };

  const ConfirmEditCommonArea = (id) => {
    console.log(editValidate(id));

    if (!editValidate(id)) {
      SetPostLoader(true);
      const data = editCommonArea.find((itm) => itm?.id === id);

      dispatch(
        editCommonAreaApi(
          data.isPartOfBuilding
            ? {
                id: id,
                common_area_name: data.name,
                description: data.description,
                is_part_of_building: data.isPartOfBuilding ? 1 : 0,
                propertyId,
                building_id: data.building,
                level_id: data.level,
              }
            : {
                id: id,
                common_area_name: data.name,
                description: data.description,
                is_part_of_building: data.isPartOfBuilding ? 1 : 0,
                propertyId,
              }
        )
      );
    }
  };

  const handleConfirm = () => {
    // navigate('/settings')
    // dispatch(setTabValue(2))
  };

  return (
    <>
      {/* {showModal && <AlertModal modalDatas={modalDatas} confirmFunction={deleteExistingCommonArea} closeFunction={handleShowModal} />}
            <BackArrow onClick={backStep} style={{ marginBottom: '25px' }} />
            <PrimaryTypography mb='4px'>Common Area</PrimaryTypography>
            <StyledSecondaryTypography mb='30px' sx={{ width: '90%' }}>Setup of building common areas, such as gyms, pools and etc.</StyledSecondaryTypography>
            <Box sx={{ backgroundColor: '#FFFFFF', border: '1px solid #E5E7EB', mb: 2, }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, borderRadius: '8px 8px 0 0 ', borderBottom: '1px solid #E5E7EB' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <SecondaryTypography>Property Common Area</SecondaryTypography>
                        {commonArea.length != 0 && <StyledSecondaryTypography sx={{ ml: 2 }}>{commonArea.length} Common Areas</StyledSecondaryTypography>}
                    </Box>
                    <SystemDetailsModal commonmodal={commonmodal.commonarea} />
                </Box>
                {(commonArea.length === 0 && !addmore) &&
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '8px', p: 2, m: 3, borderRadius: '8px', border: '1px solid #E5E7EB' }}>
                        <CommonAreaIcon />
                        <StyledTypography>Add Common Area in Building</StyledTypography>
                        <TypographyDefault>Lorem xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</TypographyDefault>
                        <Button variant="outlined" onClick={() => setAddMore(true)}>Add</Button>
                    </Box>
                }
                {commonArea.map((value, index) => {
                    return (
                        <Box sx={{ border: '1px solid #E5E7EB', m: 2 }}>
                            <Accordion>
                                <AccordionSummary expandIcon={<Arrow />}>
                                    <SecondaryTypography>{value.name}</SecondaryTypography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container rowSpacing={2} columnSpacing={2}>
                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <StyledTypography>Common Area Name*</StyledTypography>
                                                <StyledSecondaryTypography>{value.name.length || 0}/30 </StyledSecondaryTypography>
                                            </Box>
                                            <TextField disabled value={value.name} placeholder="Cargo Lift" InputProps={{ disableUnderline: true }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <StyledTypography>Description</StyledTypography>
                                                <StyledSecondaryTypography>{value.description.length || 0}/100 </StyledSecondaryTypography>
                                            </Box>
                                            <TextField disabled value={value.description} placeholder="Description" sx={{ height: '92px' }} InputProps={{ disableUnderline: true }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#F3F4F6', borderRadius: '8px', my: 2, p: 0.5, paddingRight: '20px' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Checkbox checked={value.isPartOfBuilding} icon={<CheckboxIcon />} />
                                                    <StyledTypography>This common area is a part of a building</StyledTypography>
                                                </Box>
                                                <SystemDetailsModal commonmodal={commonmodal.buildingcommonarea} />
                                            </Box>
                                        </Grid>
                                        {value.isPartOfBuilding === 1 &&
                                            <>
                                                <Grid item xs={12} sm={6}>
                                                    <StyledTypography>Building*</StyledTypography>
                                                    <Select disabled value={value?.building}>
                                                        <MenuItem key={index} value={value?.building}>
                                                            {value?.building_name}
                                                        </MenuItem>
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <StyledTypography>Level</StyledTypography>
                                                    <Select disabled value={value?.level}>
                                                        <MenuItem key={index} value={value?.level}>
                                                            {value?.level_name}
                                                        </MenuItem>
                                                    </Select>
                                                </Grid>
                                            </>
                                        }
                                        <Grid item xs={12} >
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
                                                <Button onClick={() => {
                                                    setDeletingIndex(value.commonAreaId)
                                                    handleShowModal()
                                                }} variant="outlined">Delete</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    )
                })}
                {addmore &&
                    <Box sx={{ border: '1px solid #E5E7EB', m: 2 }}>
                        <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<Arrow />}>
                                <SecondaryTypography>Common Area Descriptions</SecondaryTypography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <StyledTypography>Common Area Name*</StyledTypography>
                                            <StyledSecondaryTypography>{data.name.length || 0}/30 </StyledSecondaryTypography>
                                        </Box>
                                        <TextField inputProps={{ maxLength: 30 }} name="name" value={data.name} onChange={handleData} placeholder="Cargo Lift" InputProps={{ disableUnderline: true }} />
                                        <ErrorTypography>{dataErrorText.name}</ErrorTypography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <StyledTypography>Description</StyledTypography>
                                            <StyledSecondaryTypography>{data.description.length || 0}/100 </StyledSecondaryTypography>
                                        </Box>
                                        <TextField inputProps={{ maxLength: 100 }} name="description" value={data.description} onChange={handleData} placeholder="Description" sx={{ height: '92px' }} InputProps={{ disableUnderline: true }} />
                                        <ErrorTypography>{dataErrorText.description}</ErrorTypography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#F3F4F6', borderRadius: '8px', my: 2, p: 0.5, paddingRight: '20px' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Checkbox onClick={() => setData({
                                                    ...data,
                                                    isPartOfBuilding: !data.isPartOfBuilding
                                                })} checked={data.isPartOfBuilding} icon={<CheckboxIcon />} />
                                                <StyledTypography>This common area is a part of a building</StyledTypography>
                                            </Box>
                                            <SystemDetailsModal commonmodal={commonmodal.buildingcommonarea} />
                                        </Box>
                                    </Grid>
                                    {data.isPartOfBuilding &&
                                        <>
                                            <Grid item xs={12} sm={6}>
                                                <StyledTypography>Building*</StyledTypography>
                                                <Select name="building" value={data.building} onChange={handleData}>

                                                    {DDbuildings?.map((itm, index) => {
                                                        return (
                                                            <MenuItem key={index} value={itm.id}>
                                                                {itm.building_name}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                                <ErrorTypography>{dataErrorText.building}</ErrorTypography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <StyledTypography>Level</StyledTypography>
                                                <Select name="level" value={data.level} onChange={handleData}>
                                                  
                                                    {DDlevel?.map((itm, index) => {
                                                        return (
                                                            <MenuItem key={index} value={itm.id}>
                                                                {itm.level}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                                <ErrorTypography>{dataErrorText.level}</ErrorTypography>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
                                            <Button onClick={deleteCommonArea} variant="outlined">Delete</Button>
                                            <Button onClick={addSingleCommonArea} variant="contained" sx={{ ml: 2 }}>Add Common Area</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                }
                <ErrorTypography>{commonAreaError}</ErrorTypography>
                {(!addmore && commonArea.length != 0) &&
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="outlined" onClick={() => setAddMore(true)}>Add More</Button>
                    </Box>
                }
                <Box sx={{ display: "flex", justifyContent: 'space-between', p: 2, }}>
                    <Box sx={{ display: 'flex', alignItems: "center" }}>
                        <RefreshIcon style={{ marginRight: '5px' }} />
                        <StyledSecondaryTypography>Last Updated at 10:35AM</StyledSecondaryTypography>
                    </Box>
                    <Button onClick={handleContinue} variant="contained">Continue</Button>
                </Box>
            </Box> */}
      {postLoader && <PostLoaderModal />}

      {showModal && (
        <AlertModal
          modalDatas={modalDatas}
          confirmFunction={deleteExistingCommonArea}
          closeFunction={() => setShowModal(false)}
        />
      )}

      <BackArrow
        onClick={backStep}
        style={{ marginBottom: "25px", cursor: "pointer" }}
      />
      <PrimaryTypography mb="4px">Common Area</PrimaryTypography>
      <StyledSecondaryTypography mb="30px" sx={{ width: "90%" }}>
        Setup of building common areas, such as gyms, pools and etc.
      </StyledSecondaryTypography>

      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #E5E7EB",
          my: 2,
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 2,
            borderRadius: "8px 8px 0 0 ",
            borderBottom: "1px solid #E5E7EB",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SecondaryTypography>
              Common Area{" "}
              <span style={{ color: "#9DA4AE", paddingLeft: "7px" }}> |</span>{" "}
            </SecondaryTypography>
            {commonArea.length != 0 && (
              <StyledSecondaryTypography sx={{ ml: 1.2 }}>
                {commonArea.length} Common Areas
              </StyledSecondaryTypography>
            )}
          </Box>
          <SystemDetailsModal commonmodal={commonmodal.commonarea} />
        </Box>
        {commonArea.length === 0 && !addmore && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              rowGap: "8px",
              p: 2,
              m: 3,
              borderRadius: "8px",
              border: "1px solid #E5E7EB",
            }}
          >
            <CommonAreaIcon />
            <StyledTypography>Add Common Area</StyledTypography>
            <TypographyDefault>
              Please add common area by clicking the button below.
            </TypographyDefault>
            <Button
              variant="outlined"
              onClick={() => setAddMore(true)}
              sx={{ border: "1px solid #D2D6DB" }}
            >
              Add Common Area
            </Button>
          </Box>
        )}
        {editCommonArea.map((value, index) => {
          return (
            <Box
              sx={{
                borderRadius: "12px !important",
                "&>*": { borderRadius: "12px !important" },
                m: 2,
              }}
            >
              <Accordion
                expanded={value?.showInfo}
                onChange={(e, expanded) =>
                  handleExistingData(e, index, expanded)
                }
              >
                <AccordionSummary
                  sx={{
                    "&>*": {
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    },
                  }}
                  expandIcon={<Arrow />}
                >
                  <SecondaryTypography>{value?.name}</SecondaryTypography>
                  {!value?.showInfo && value?.isPartOfBuilding == 1 && (
                    <SecondaryTypography
                      pr={3}
                      sx={{ fontWeight: "400 !important" }}
                    >
                      {value?.building_name} • {value?.level_name}
                    </SecondaryTypography>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <StyledTypography>Common Area Name</StyledTypography>
                        <TypoGraph>{value.name.length || 0}/30 </TypoGraph>
                      </Box>
                      <TextField
                        name="name"
                        value={value.name}
                        sx={{
                          "& .MuiInputBase-root": {
                            color: EditDataErrorText?.name
                              ? "#912018"
                              : "#111927",
                          },
                          backgroundColor: EditDataErrorText?.name
                            ? "#FEE4E2"
                            : "#F9FAFB",
                        }}
                        onChange={(e) => handleExistingData(e, index)}
                        placeholder="Enter the common area name"
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ maxLength: 30 }}
                      />
                      {/* <ErrorTypography>{EditDataErrorText?.name == true && 'Error! No Inputs detected'}</ErrorTypography> */}
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <StyledTypography>Description</StyledTypography>
                        <TypoGraph>
                          {value.description.length || 0}/1000{" "}
                        </TypoGraph>
                      </Box>
                      <TextField
                        name="description"
                        sx={{
                          minHeight: "92px",
                          "& .MuiInputBase-root": {
                            color: EditDataErrorText?.description
                              ? "#912018"
                              : "#111927",
                          },
                          backgroundColor: EditDataErrorText?.description
                            ? "#FEE4E2"
                            : "#F9FAFB",
                        }}
                        value={value.description}
                        onChange={(e) => handleExistingData(e, index)}
                        placeholder="Description"
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ maxLength: 1000 }}
                      />
                      {/* <ErrorTypography>{EditDataErrorText?.description == true && 'Error! No Inputs detected'}</ErrorTypography> */}
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#F3F4F6",
                          borderRadius: "8px",
                          my: 2,
                          p: 0.5,
                          paddingRight: "20px",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Checkbox
                            name="isPartOfBuilding"
                            onChange={(e) => handleExistingData(e, index)}
                            checked={value.isPartOfBuilding}
                            icon={<CheckboxIcon />}
                          />
                          <StyledTypography>
                            This common area is a part of a building
                          </StyledTypography>
                        </Box>
                        <SystemDetailsModal
                          commonmodal={commonmodal.buildingcommonarea}
                        />
                      </Box>
                    </Grid>
                    {value.isPartOfBuilding == 1 && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <StyledTypography>Building</StyledTypography>
                          <Select
                            name="building"
                            onChange={(e) => handleExistingData(e, index)}
                            value={value?.building}
                            sx={{
                              "& .MuiInputBase-root": {
                                color: EditDataErrorText?.building_name
                                  ? "#912018"
                                  : "#111927",
                              },
                              backgroundColor: EditDataErrorText?.building_name
                                ? "#FEE4E2"
                                : "#F9FAFB",
                            }}
                            IconComponent={KeyboardArrowDownIcon}
                          >
                            {DDbuildings.map((bitm, bind) => (
                              <MenuItem key={bind} value={bitm?.id}>
                                {bitm?.building_name}
                              </MenuItem>
                            ))}
                          </Select>
                          {/* <ErrorTypography>{EditDataErrorText?.building_name == true && 'Error! No Inputs detected'}</ErrorTypography> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <StyledTypography>Level</StyledTypography>
                          <Select
                            name="level"
                            onChange={(e) => handleExistingData(e, index)}
                            value={value?.level}
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{
                              "& .MuiInputBase-root": {
                                color: EditDataErrorText?.level_name
                                  ? "#912018"
                                  : "#111927",
                              },
                              backgroundColor: EditDataErrorText?.level_name
                                ? "#FEE4E2"
                                : "#F9FAFB",
                            }}
                          >
                            {DDbuildings.map((sitm, sind) => {
                              if (sitm?.id == value?.building) {
                                const data = sitm?.level.map((litm, lind) => {
                                  return (
                                    <MenuItem key={lind} value={litm?.id}>
                                      {litm?.level}
                                    </MenuItem>
                                  );
                                });
                                return data;
                              }
                            })}
                          </Select>
                          {/* <ErrorTypography>{EditDataErrorText?.level_name == true && 'Error! No Inputs detected'}</ErrorTypography> */}
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          my: 2,
                        }}
                      >
                        <Button
                          style={{
                            padding: "10px 18px",
                            borderRadius: "8px",
                            backgroundColor: "#0E5E84",
                            color: "#FFF",
                            fontSize: "14px",
                            fontWeight: 600,
                          }}
                          sx={{
                            mr: 2,
                            "&:hover": {
                              backgroundColor: "#0E4965",
                            },
                          }}
                          onClick={() => {
                            ConfirmEditCommonArea(value?.id);
                          }}
                        >
                          Save
                        </Button>

                        <Button
                          onClick={() => {
                            setDeletingIndex(value.commonAreaId);
                            setShowModal(true);
                          }}
                          variant="outlined"
                        >
                          Delete
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })}
        {addmore && (
          <Box
            sx={{
              borderRadius: "12px !important",
              "&>*": { borderRadius: "12px !important" },
              m: 2,
            }}
          >
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<Arrow />}>
                <SecondaryTypography>
                  Common Area Descriptions
                </SecondaryTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <StyledTypography>Common Area Name</StyledTypography>
                      <StyledSecondaryTypography>
                        {data.name.length || 0}/30{" "}
                      </StyledSecondaryTypography>
                    </Box>
                    <TextField
                      inputProps={{ maxLength: 30 }}
                      sx={{
                        "& .MuiInputBase-root": {
                          color:
                            dataErrorText.name === "" ? "#111927" : "#912018",
                        },
                        backgroundColor:
                          dataErrorText.name == "" ? "#F9FAFB" : "#FEE4E2",
                      }}
                      name="name"
                      value={data.name}
                      onChange={handleData}
                      placeholder="Enter the common area name"
                      InputProps={{ disableUnderline: true }}
                    />
                    {/* <ErrorTypography>{dataErrorText.name}</ErrorTypography> */}
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <StyledTypography>Description</StyledTypography>
                      <StyledSecondaryTypography>
                        {data.description.length || 0}/1000{" "}
                      </StyledSecondaryTypography>
                    </Box>
                    <TextField
                      inputProps={{ maxLength: 1000 }}
                      sx={{
                        minHeight: "92px",
                        "& .MuiInputBase-root": {
                          color:
                            dataErrorText.description === ""
                              ? "#111927"
                              : "#912018",
                        },
                        backgroundColor:
                          dataErrorText.description == ""
                            ? "#F9FAFB"
                            : "#FEE4E2",
                      }}
                      name="description"
                      value={data.description}
                      onChange={handleData}
                      placeholder="Description"
                      InputProps={{ disableUnderline: true }}
                    />
                    {/* <ErrorTypography>{dataErrorText.description}</ErrorTypography> */}
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#F3F4F6",
                        borderRadius: "8px",
                        my: 2,
                        p: 0.5,
                        paddingRight: "20px",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          onClick={() =>
                            setData({
                              ...data,
                              isPartOfBuilding: !data.isPartOfBuilding,
                            })
                          }
                          checked={data.isPartOfBuilding}
                          icon={<CheckboxIcon />}
                        />
                        <StyledTypography>
                          This common area is a part of a building
                        </StyledTypography>
                      </Box>
                      <SystemDetailsModal
                        commonmodal={commonmodal.buildingcommonarea}
                      />
                    </Box>
                  </Grid>
                  {data.isPartOfBuilding && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <StyledTypography>Building</StyledTypography>
                        <Select
                          name="building"
                          value={data.building}
                          onChange={handleData}
                          IconComponent={KeyboardArrowDownIcon}
                          sx={{
                            "& .MuiInputBase-root": {
                              color:
                                dataErrorText.building === ""
                                  ? "#111927"
                                  : "#912018",
                            },
                            backgroundColor:
                              dataErrorText.building == ""
                                ? "#F9FAFB"
                                : "#FEE4E2",
                          }}
                        >
                          {/* <MenuItem value="building">Building</MenuItem> */}
                          {/* {Boolean(DDbuildings) && DDbuildings} */}
                          {DDbuildings?.map((itm, index) => {
                            return (
                              <MenuItem key={index} value={itm.id}>
                                {itm.building_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {/* <ErrorTypography>{dataErrorText.building}</ErrorTypography> */}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <StyledTypography>Level</StyledTypography>
                        <Select
                          name="level"
                          value={data.level}
                          onChange={handleData}
                          IconComponent={KeyboardArrowDownIcon}
                          sx={{
                            "& .MuiInputBase-root": {
                              color:
                                dataErrorText.level === ""
                                  ? "#111927"
                                  : "#912018",
                            },
                            backgroundColor:
                              dataErrorText.level == "" ? "#F9FAFB" : "#FEE4E2",
                          }}
                        >
                          {/* <MenuItem value="level">Level</MenuItem> */}
                          {/* {Boolean(DDlevel) && DDlevel} */}
                          {DDlevel?.map((itm, index) => {
                            return (
                              <MenuItem key={index} value={itm.id}>
                                {itm.level}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {/* <ErrorTypography>{dataErrorText.level}</ErrorTypography> */}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        my: 2,
                      }}
                    >
                      <Button
                        onClick={() => deleteCommonArea()}
                        variant="outlined"
                      >
                        Delete
                      </Button>
                      <Button
                        onClick={() => addSingleCommonArea()}
                        style={{
                          padding: "10px 18px",
                          borderRadius: "8px",
                          backgroundColor: "#0E5E84",
                          color: "#FFF",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                        sx={{
                          ml: 2,
                          "&:hover": {
                            backgroundColor: "#0E4965",
                          },
                        }}
                      >
                        Add Common Area
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
        <Box sx={{ marginLeft: "21px" }}>
          <ErrorTypography>{commonAreaError}</ErrorTypography>
        </Box>
        {!addmore && commonArea.length != 0 && (
          <Box mt={1} sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="outlined" onClick={() => setAddMore(true)}>
              Add Common Area
            </Button>
          </Box>
        )}
        <Box
          borderTop="1px solid #F3F4F6"
          mt={5}
          sx={{ display: "flex", justifyContent: "space-between", p: 2 }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <RefreshIcon style={{ marginRight: "5px" }} />
            <StyledSecondaryTypography>
              Last Updated at{" "}
              {moment
                .utc(updatedAt ? updatedAt : new Date())
                .local()
                .format("HH:mm A")}
            </StyledSecondaryTypography>
          </Box>
          <Button
            onClick={() => handleContinue("Review Information")}
            sx={{
              backgroundColor: "#E7EFF3",
              "&:hover": {
                backgroundColor: "#CFDFE6",
              },
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AddCommonArea;
