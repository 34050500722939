import {
  Button,
  TextField,
  Stack,
  InputAdornment,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as PasswordVisible } from "../Icons/PasswordVisible.svg";
import { ReactComponent as PasswordInvisible } from "../Icons/PasswordInvisible.svg";
import { ReactComponent as AlertIcon } from "../Icons/ErrorAlert.svg";
import { ReactComponent as CompanyLogo } from "../Icons/CompanyLogo.svg";
import { ReactComponent as CheckboxIcon } from "../Icons/Checkbox.svg";
import { ReactComponent as BackArrow } from "../Icons/BackArrow.svg";
import { emailRegex } from "../Utils";
import {
  ErrorTypography,
  StyledPrimaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
} from "../Theme";
import {
  login,
  setLoginSliceToNull,
  emailExist,
} from "../Redux/Feature/Login/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setActiveStep,
  setPropertyId,
  setUserRole,
} from "../Redux/Feature/PropertyDetails/propertyDetailsSlice";

const Login = ({ setDisplayScreen, setback, propertyDeatilsProp, back }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginStatus, emailExistStatus } = useSelector((state) => state.Login);
  const [data, setData] = useState({ email: "", password: "" });
  const [errorText, setErrorText] = useState({ email: "", password: "" });
  const [responseError, setResponseError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [block, setblock] = useState({ email: true, password: false });
  const [check, setcheck] = useState({
    emailcheck: false,
    passwordcheck: false,
  });
  const [loadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    if (loginStatus != null) {
      setLoadingBtn(false);
      if (loginStatus.success) {
        if (loginStatus.data.user_details.user_role == 2) {
          if (loginStatus.data.property_status.status === "draft") {
            const expiresIn = loginStatus.data.token?.expires_in;
            const expiresAt = Date.now() + expiresIn * 1000; // Calculate expiry timestamp
            localStorage.clear();
            localStorage.setItem(
              "accessToken",
              loginStatus.data.token.access_token
            );
            localStorage.setItem(
              "refreshToken",
              loginStatus.data.token.refresh_token
            );
            localStorage.setItem("expireTime", expiresAt);

            localStorage.setItem(
              "propertyStatus",
              JSON.stringify(loginStatus.data.property_status)
            );
            localStorage.setItem(
              "userDetails",
              JSON.stringify(loginStatus.data.user_details)
            );
            // dispatch(setActiveStep(loginStatus.data.property_status.completed_step))
            // dispatch(setPropertyId(loginStatus.data.property_status.id))
            navigate("/propertyDetailsSetup");
            dispatch(setLoginSliceToNull());
            dispatch(setPropertyId(loginStatus.data.property_status.id));
            dispatch(setUserRole(loginStatus.data.user_details.user_role));
          } else if (loginStatus.data.property_status.status != "draft") {
            const expiresIn = loginStatus.data.token?.expires_in;
            const expiresAt = Date.now() + expiresIn * 1000; // Calculate expiry timestamp
            localStorage.clear();
            localStorage.setItem(
              "propertyStatus",
              JSON.stringify(loginStatus.data.property_status)
            );
            localStorage.setItem(
              "accessToken",
              loginStatus.data.token.access_token
            );
            localStorage.setItem(
              "refreshToken",
              loginStatus.data.token.refresh_token
            );
            localStorage.setItem(
              "expireTime",
              expiresAt
            );

            localStorage.setItem(
              "userDetails",
              JSON.stringify(loginStatus.data.user_details)
            );
            navigate("overview");
            dispatch(setLoginSliceToNull());
            dispatch(setPropertyId(loginStatus.data.property_status.id));
            dispatch(setUserRole(loginStatus.data.user_details.user_role));
          }
        } else if (loginStatus.data.user_details.user_role == 3) {
          if (loginStatus.data.user_property[0].status === "published") {
            const expiresIn = loginStatus.data.token?.expires_in;
            const expiresAt = Date.now() + expiresIn * 1000; // Calculate expiry timestamp
            localStorage.clear();
            localStorage.setItem(
              "propertyStatus",
              JSON.stringify(loginStatus.data.user_property[0])
            );
            localStorage.setItem(
              "accessToken",
              loginStatus.data.token.access_token
            );
            localStorage.setItem(
              "refreshToken",
              loginStatus.data.token.refresh_token
            );
            localStorage.setItem(
              "expireTime",
              expiresAt
            );

            localStorage.setItem(
              "userDetails",
              JSON.stringify(loginStatus.data.user_details)
            );
            navigate("overview");
            dispatch(setLoginSliceToNull());
            dispatch(setPropertyId(loginStatus.data.user_property[0].id));
            dispatch(setUserRole(loginStatus.data.user_details.user_role));
          }
          // setResponseError('No Access on Web')
        }
        setempass("password", "passwordcheck");
        setempass("email", "emailcheck");
      } else {
        setResponseError(
          loginStatus.error || "Something went wrong!.Please try again"
        );
      }
    }
  }, [loginStatus]);

  useEffect(() => {
    if (emailExistStatus !== null) {
      setLoadingBtn(false);
      if (emailExistStatus.success === true) {
        setempass("email", "emailcheck");
        setblock({ email: false, password: true });
        dispatch(setLoginSliceToNull());
      } else {
        setErrorText({
          email:
            emailExistStatus?.error || "Something went wrong!.Please try again",
          password: "",
        });
      }
    }
  }, [emailExistStatus]);

  const handleData = (e) => {
    setResponseError("");
    let { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    setErrorText({
      ...errorText,
      [name]: "",
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      console.log("im enter");
      if (block.email) {
        handleemail();
      } else if (block.password) {
        handleLogin();
      }
    }
  };

  const validate = (key) => {
    let isError = [];
    if (key === "email" && data[key] != "") {
      if (!emailRegex.test(data[key])) {
        setErrorText((prev) => {
          return {
            ...prev,
            [key]: "Enter valid email",
          };
        });
        isError.push(true);
      }
    }
    if (data[key] === "") {
      setErrorText((prev) => {
        return {
          ...prev,
          [key]: "Error! No Inputs detected",
        };
      });
      isError.push(true);
    }

    return isError.includes(true);
  };
  const handleemail = () => {
    let isError = validate("email");
    if (!isError) {
      setErrorText({
        email: "",
        password: "",
      });
      setLoadingBtn(true);
      dispatch(emailExist({ email: data.email }));
    }
  };

  const handleLogin = () => {
    let isError = validate("password");
    if (!isError) {
      setLoadingBtn(true);
      setResponseError("");
      dispatch(setLoginSliceToNull());
      dispatch(login({ email: data.email, password: data.password }));
    }
  };

  const handlecheck = (e) => {
    const { checked, name } = e.target;
    setcheck((prev) => {
      return { ...prev, [name]: checked };
    });
  };

  const setempass = (eml, emlchk) => {
    if (check[emlchk] === true) {
      localStorage.setItem(emlchk, JSON.stringify(check[emlchk]));
      localStorage.setItem(eml, JSON.stringify(data[eml]));
    } else {
      localStorage.removeItem(emlchk);
      localStorage.removeItem(eml);
    }
  };
  // const setpassword = () => {
  //     if (check.passwordcheck === true) {
  //         localStorage.setItem('passwordcheck', JSON.stringify(check.passwordcheck))
  //         localStorage.setItem('password', JSON.stringify(data.password))
  //     }
  //     else {
  //         localStorage.removeItem('passwordcheck')
  //         localStorage.removeItem('password')
  //     }
  // }

  useEffect(() => {
    setResponseError("");

    const emailcheck = JSON.parse(localStorage.getItem("emailcheck"));
    const email = JSON.parse(localStorage.getItem("email"));
    if (emailcheck) {
      setcheck((prev) => {
        return { ...prev, emailcheck: true };
      });
      if (email) {
        console.log(email);
        setData((prev) => {
          return { ...prev, email: email };
        });
      }
    }

    const passwordcheck = JSON.parse(localStorage.getItem("passwordcheck"));
    const password = JSON.parse(localStorage.getItem("password"));
    if (passwordcheck) {
      setcheck((prev) => {
        return { ...prev, passwordcheck: true };
      });
      if (password) {
        setData((prev) => {
          return { ...prev, password: password };
        });
      }
    }
  }, []);

  return (
    <>
      {block.email &&
        propertyDeatilsProp.map((value) => (
          <>
            <BackArrow
              onClick={() => setDisplayScreen(back)}
              style={{ marginBottom: "30px", cursor: "pointer" }}
            />
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "100px",
                height: "100px",
                borderRadius: "12px",
                boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                backgroundImage: `url(${process.env.REACT_APP_FILE_PATH}${value?.property_logo})`,
                backgroundSize: "cover",
              }}
            ></Stack>
            <StyledPrimaryTypography
              sx={{
                paddingTop: "34px",
                lineHeight: "22px",
              }}
            >
              Log in to your account{" "}
            </StyledPrimaryTypography>
            <StyledSecondaryTypography mt={1.3}>
              The LOBY portal can only be accessed by Management and Operations
              staff.
            </StyledSecondaryTypography>
            <TextField
              name="email"
              value={data.email}
              onChange={handleData}
              placeholder="Email Address"
              inputProps={{ maxLength: 125 }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {errorText.email !== "" && <AlertIcon />}
                  </InputAdornment>
                ),
              }}
              sx={{
                mt: 3,
                "& .MuiInputBase-root": {
                  color: errorText.email === "" ? "#111927" : "#912018",
                },
                backgroundColor: errorText.email === "" ? "#F9FAFB" : "#FEE4E2",
              }}
              onKeyPress={handleKeyPress}
            />
            <ErrorTypography>{errorText.email}</ErrorTypography>
            <ErrorTypography>{responseError}</ErrorTypography>
            <Button
              onClick={handleemail}
              disabled={!data.email || loadingBtn}
              fullWidth
              variant="contained"
              sx={{ my: 2.2 }}
            >
              {loadingBtn ? <CircularProgress size={25} /> : "Login"}
            </Button>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ maxHeight: "40px !important" }}
            >
              <StyledSecondaryTypography color="#9DA4AE !important">
                {" "}
                <Checkbox
                  sx={{ padding: "0px" }}
                  icon={<CheckboxIcon />}
                  checked={check.emailcheck}
                  name="emailcheck"
                  onChange={handlecheck}
                />{" "}
                Remember for 30 days
              </StyledSecondaryTypography>
              <StyledTypography
                color="#4D5761 !important"
                onClick={() => {
                  setDisplayScreen("recoverPassword");
                }}
              >
                Forgot password?
              </StyledTypography>
            </Stack>
          </>
        ))}

      {block.password && (
        <>
          <StyledPrimaryTypography>
            Log in to your account{" "}
          </StyledPrimaryTypography>
          <StyledSecondaryTypography>
            Please enter your password below.
          </StyledSecondaryTypography>
          <TextField
            type={passwordType}
            name="password"
            value={data.password}
            onChange={handleData}
            placeholder=" Enter password"
            InputProps={{
              disableUnderline: true,
              endAdornment:
                passwordType === "password" ? (
                  <PasswordVisible onClick={() => setPasswordType("text")} />
                ) : (
                  <PasswordInvisible
                    onClick={() => setPasswordType("password")}
                  />
                ),
            }}
            sx={{
              mt: 3,
              mb: 2,
              "& .MuiInputBase-root": {
                color: responseError === "" ? "#111927" : "#912018",
              },
              backgroundColor: responseError === "" ? "#F9FAFB" : "#FEE4E2",
            }}
            onKeyPress={handleKeyPress}
          />
          <ErrorTypography>{errorText.password}</ErrorTypography>
          <ErrorTypography>{responseError}</ErrorTypography>
          <Button
            onClick={handleLogin}
            disabled={!data.password || loadingBtn}
            fullWidth
            variant="contained"
            sx={{ my: 2 }}
          >
            {loadingBtn ? <CircularProgress size={25} /> : "Login"}
          </Button>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ maxHeight: "40px !important" }}
          >
            <StyledSecondaryTypography color="#9DA4AE !important">
              {" "}
              <Checkbox
                icon={<CheckboxIcon />}
                checked={check.passwordcheck}
                name="passwordcheck"
                onChange={handlecheck}
              />{" "}
              Remember for 30 days
            </StyledSecondaryTypography>
            <StyledTypography
              color="#4D5761 !important"
              onClick={() => {
                setDisplayScreen("recoverPassword");
              }}
            >
              Forgot password?
            </StyledTypography>
          </Stack>
        </>
      )}
    </>
  );
};

export default Login;
