import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Grid,
  Select,
  MenuItem,
  Typography,
  Button,
} from "@mui/material";
import { ReactComponent as BackArrow } from "../../Icons/BorderBackArrow.svg";
import { ReactComponent as ContactDetailsIcon } from "../../Icons/ContactDetails.svg";
import { ReactComponent as ProfileSyncIcon } from "../../Icons/ProfileSync.svg";
import { useNavigate } from "react-router-dom";
import {
  PrimaryTypography,
  StyledSecondaryTypography,
  SecondaryTypography,
  StyledTypography,
  ErrorTypography,
} from "../../Theme";
import PhoneInput from "react-phone-input-2";
import axiosInstance from "../../Redux/AxiosInstance";
import moment from "moment";
import { emailRegex } from "../../Utils/index";
import { PostLoaderModal } from "../../CommonComponents/modal";
import { showToast } from "../../CommonComponents/Toaster";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const EditProfile = () => {
  const navigate = useNavigate();
  const [PersonalDetailsData, setPersonalDetailsData] = useState({
    firstname: "",
    lastname: "",
    contact: "",
    phone: "",
    countrycode: "",
    address: "",
    country: "",
    city: "",
    postalcode: "",
  });
  console.log(PersonalDetailsData);
  const [emergencyContactData, setemergencyContactData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    contact: "",
    email: "",
    relationship: "",
    countrycode: "",
  });
  console.log(emergencyContactData);
  const [errtext, seterrtext] = useState({
    PersonalDetailsData: {},
    emergencyContactData: {},
  });
  const [CountryList, setCountryList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [postLoader, SetPostLoader] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(null);

  // useEffect(() => {
  //   axiosInstance
  //     .get(`${process.env.REACT_APP_API_URL}getDropDownDatas?type=country`)
  //     .then((res) => {
  //       setCountryList(res.data.data?.drop_down);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });

  //   axiosInstance
  //     .get(`${process.env.REACT_APP_API_URL}user_details`)
  //     .then((res) => {
  //       setSelectedCountry(res.data.data.user_details?.country);
  //       setPersonalDetailsData({
  //         contact:
  //           res.data.data.user_details?.country_code +
  //           res.data.data.user_details?.phone,
  //         phone: res.data.data.user_details?.phone,
  //         countrycode: res.data.data?.user_details?.country_code,
  //         address: res.data.data.user_details?.address,
  //         country: res.data.data.user_details?.country,
  //         city: res.data.data.user_details?.city,
  //         postalcode: res.data.data.user_details?.zipcode,
  //         firstname: res.data.data.user_details?.first_name,
  //         lastname: res.data.data.user_details?.last_name,
  //       });

  //       setemergencyContactData({
  //         firstname: res.data.data.user_details.emergency_contacts?.first_name,
  //         lastname: res.data.data.user_details.emergency_contacts?.last_name,
  //         phone: res.data.data.user_details?.emergency_contacts?.phone,
  //         email: res.data.data.user_details.emergency_contacts?.email,
  //         relationship:
  //           res.data.data.user_details.emergency_contacts?.relationship,
  //         countrycode:
  //           res.data.data.user_details.emergency_contacts?.country_code,
  //         contact:
  //           res.data.data.user_details.emergency_contacts?.country_code +
  //             res.data.data.user_details?.emergency_contacts?.phone || "",
  //       });
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }, []);

  const fetchData = async () => {
    SetPostLoader(true);
    try {
      const countryResponse = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}getDropDownDatas?type=country`
      );
      setCountryList(countryResponse.data.data?.drop_down);

      const userDetailsResponse = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}user_details`
      );
      const userDetails = userDetailsResponse.data.data.user_details;
      setSelectedCountry(userDetails?.country);
      setPersonalDetailsData({
        contact: userDetails?.country_code + userDetails?.phone,
        phone: userDetails?.phone,
        countrycode: userDetails?.country_code,
        address: userDetails?.address,
        country: userDetails?.country,
        city: userDetails?.city,
        postalcode: userDetails?.zipcode,
        firstname: userDetails?.first_name,
        lastname: userDetails?.last_name,
      });
      setLastUpdate(userDetails?.last_updated);
      setemergencyContactData({
        firstname: userDetails?.emergency_contacts?.first_name,
        lastname: userDetails?.emergency_contacts?.last_name,
        phone: userDetails.emergency_contacts?.phone,
        email: userDetails?.emergency_contacts?.email,
        relationship: userDetails?.emergency_contacts?.relationship,
        countrycode: userDetails?.emergency_contacts?.country_code,
        contact:
          userDetails?.emergency_contacts?.country_code +
            userDetails?.emergency_contacts?.phone || "",
      });
    } catch (err) {
      SetPostLoader(false);
    } finally {
      SetPostLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const selectedCountryObject = CountryList.find(
        (city) => city.country === selectedCountry
      );
      if (selectedCountryObject?.city) {
        setCityList(selectedCountryObject?.city);
      }
    }
  }, [selectedCountry, CountryList]);

  const handleMobileInput = (
    value,
    country,
    functionName,
    state,
    statename
  ) => {
    functionName({
      ...state,
      countrycode: `+${country.dialCode}`,
      phone: value.slice(country.dialCode.length),
    });

    seterrtext((prev) => {
      return {
        ...prev,
        [statename]: {
          ...prev[statename],
          countrycode: "",
          phone: "",
        },
      };
    });
  };

  const handlePersonalDetails = (e, name) => {
    if (name) {
      setPersonalDetailsData((prev) => {
        return {
          ...prev,
          [name]: e,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          PersonalDetailsData: {
            ...prev.PersonalDetailsData,
            [name]: "",
          },
        };
      });
    } else {
      const { name, value } = e.target;
      if (name === "country" && value) {
        setCityList([]);
        const selectedCountry = value;
        const selectedCountryObject = CountryList.find(
          (city) => city.country === selectedCountry
        );
        setCityList(selectedCountryObject?.city);
        setPersonalDetailsData((prevData) => ({
          ...prevData,
          city: "",
        }));
      }
      setPersonalDetailsData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          PersonalDetailsData: {
            ...prev.PersonalDetailsData,
            [name]: "",
          },
        };
      });
    }
  };

  const handleEmergencyContact = (e) => {
    const { name, value } = e.target;
    setemergencyContactData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    seterrtext((prev) => {
      return {
        ...prev,
        emergencyContactData: {
          ...prev.emergencyContactData,
          [name]: "",
        },
      };
    });
  };

  const isValid = () => {
    let isError = false;
    const excludedFields = ["contact"];
    for (const key in PersonalDetailsData) {
      if (!PersonalDetailsData[key] && !excludedFields.includes(key)) {
        seterrtext((prev) => {
          return {
            ...prev,
            PersonalDetailsData: {
              ...prev.PersonalDetailsData,
              [key]: "Error! No Inputs detected",
            },
          };
        });
        isError = true;
      }
    }

    for (const key in emergencyContactData) {
      if (!emergencyContactData[key] && !excludedFields.includes(key)) {
        seterrtext((prev) => {
          return {
            ...prev,
            emergencyContactData: {
              ...prev.emergencyContactData,
              [key]: "Error! No Inputs detected",
            },
          };
        });
        isError = true;
      } else if (
        emergencyContactData["email"] &&
        emergencyContactData["email"] !== ""
      ) {
        const isValidEmail = emailRegex.test(emergencyContactData["email"]);
        if (!isValidEmail) {
          seterrtext((prev) => {
            return {
              ...prev,
              emergencyContactData: {
                ...prev.emergencyContactData,
                ["email"]: "Error! Invalid Email Format",
              },
            };
          });
          isError = true;
        }
      }
    }

    return isError;
  };

  const handleEditProfileUpload = () => {
    SetPostLoader(true);
    const formattedData = {
      phone: PersonalDetailsData.phone,
      country_code: PersonalDetailsData.countrycode,
      address: PersonalDetailsData.address,
      country: PersonalDetailsData.country,
      city: PersonalDetailsData.city,
      zipcode: PersonalDetailsData.postalcode,
      first_name: PersonalDetailsData.firstname,
      last_name: PersonalDetailsData.lastname,
      emergency_details: {
        first_name: emergencyContactData.firstname,
        last_name: emergencyContactData.lastname,
        email: emergencyContactData.email,
        phone: emergencyContactData.phone,
        country_code: emergencyContactData.countrycode,
        relationship: emergencyContactData.relationship,
      },
    };
    axiosInstance
      .post(`${process.env.REACT_APP_API_URL}my_details`, formattedData)
      .then((res) => {
        const validate = res.data?.success;
        if (validate) {
          SetPostLoader(false);
          showToast(res.data?.toast);
          navigate("/profile-settings");
        }
      })
      .catch(() => {
        SetPostLoader(false);
        showToast("Something went wrong. Please try again later", "error");
      });
  };

  const Continue = () => {
    const valid = isValid();
    if (!valid) {
      handleEditProfileUpload();
    }
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      <Box sx={{ padding: "0 20px", maxWidth: "728px", margin: "0 auto" }}>
        <BackArrow
          onClick={() => navigate("/profile-settings")}
          style={{ cursor: "pointer" }}
        />
        <Box sx={{ marginTop: "20px" }}>
          <PrimaryTypography>Edit Profile</PrimaryTypography>
          <StyledSecondaryTypography>
            Last Updated: {moment(lastUpdate).format("DD MMM YYYY,HH:mm")}
          </StyledSecondaryTypography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: "16px",
            borderRadius: "8px 8px 0 0",
            borderBottom: "1px solid #E5E7EB",
            backgroundColor: "#FCFCFD",
            marginTop: "20px",
          }}
        >
          <ContactDetailsIcon style={{ marginRight: "10px" }} />
          <SecondaryTypography>Personal Details</SecondaryTypography>
        </Box>
        <Box
          sx={{
            borderRadius: "0 0 8px 8px",
            padding: "30px 16px",
            backgroundColor: "#FFFF",
          }}
        >
          <Box sx={{ padding: "0px 0px 15px 0px" }}>
            <StyledTypography sx={{ marginBottom: "10px" }}>
              Contact Number
            </StyledTypography>
            <PhoneInput
              value={PersonalDetailsData.contact}
              onChange={(value, country, e) =>
                handleMobileInput(
                  value,
                  country,
                  setPersonalDetailsData,
                  PersonalDetailsData,
                  "PersonalDetailsData"
                )
              }
              inputProps={{ name: "phone", maxLength: 20 }}
              country={"th"}
              buttonStyle={{ border: "none" }}
              inputStyle={{
                backgroundColor: errtext.PersonalDetailsData.phone
                  ? "#FEE4E2"
                  : " #F9FAFB",
                border: "none",
                height: "46px",
                width: "100%",
                borderRadius: "8px",
                color: errtext.PersonalDetailsData.phone
                  ? "#912018"
                  : "#111927",
              }}
              countryCodeEditable={false}
            />
            <ErrorTypography>
              {errtext.PersonalDetailsData.phone}
            </ErrorTypography>
          </Box>
          <Box sx={{ padding: "15px 0px 15px 0px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <StyledTypography>Address</StyledTypography>{" "}
              <StyledTypography
                style={{ fontSize: "12px", fontWeight: 400, color: "#6C737F" }}
              >
                {PersonalDetailsData.address?.length}/50
              </StyledTypography>
            </Box>
            <TextField
              sx={{
                "& .MuiInputBase-root": {
                  color: errtext.PersonalDetailsData.address
                    ? "#912018"
                    : "#111927",
                },
                backgroundColor: errtext.PersonalDetailsData.address
                  ? "#FEE4E2"
                  : " #F9FAFB",
                height: "88px",
              }}
              name="address"
              placeholder="Enter the full address here"
              inputProps={{ maxLength: 50 }}
              InputProps={{ disableUnderline: true }}
              fullWidth
              value={PersonalDetailsData.address}
              onChange={handlePersonalDetails}
            />
            <ErrorTypography>
              {errtext.PersonalDetailsData.address}
            </ErrorTypography>
          </Box>
          <Grid container spacing={2} style={{ padding: "10px 0px 10px 0px" }}>
            <Grid item xs={12} sm={4} mt={2}>
              <StyledTypography>Country</StyledTypography>
              <Select
                name="country"
                value={PersonalDetailsData.country}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.country
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.country
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
                IconComponent={KeyboardArrowDownIcon}
              >
                {CountryList.length > 0 &&
                  CountryList.map((value) => (
                    <MenuItem key={value?.key} value={value?.country}>
                      {value?.country}
                    </MenuItem>
                  ))}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.country}
              </ErrorTypography>
            </Grid>

            <Grid item xs={12} sm={4} mt={2}>
              <StyledTypography>City</StyledTypography>
              <Select
                name="city"
                value={PersonalDetailsData.city}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.city
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.city
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
                IconComponent={KeyboardArrowDownIcon}
              >
                {CityList.length > 0 &&
                  CityList.map((city) => (
                    <MenuItem value={city}>{city}</MenuItem>
                  ))}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.city}
              </ErrorTypography>
            </Grid>

            <Grid item xs={12} sm={4} mt={2}>
              <StyledTypography>Postal Code</StyledTypography>
              <TextField
                name="postalcode"
                value={PersonalDetailsData.postalcode}
                onChange={handlePersonalDetails}
                inputProps={{ maxLength: 6 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.postalcode
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.postalcode
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.postalcode}
              </ErrorTypography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: "16px",
            borderRadius: "8px 8px 0 0",
            borderBottom: "1px solid #E5E7EB",
            backgroundColor: "#FCFCFD",
            marginTop: "20px",
          }}
        >
          <ContactDetailsIcon style={{ marginRight: "10px" }} />
          <SecondaryTypography>Emergency Contact</SecondaryTypography>
        </Box>
        <Box
          sx={{
            borderRadius: "0 0 8px 8px",
            padding: "30px 16px",
            backgroundColor: "#FFFF",
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <StyledTypography>First Name</StyledTypography>

              <TextField
                name="firstname"
                value={emergencyContactData.firstname}
                onChange={handleEmergencyContact}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.firstname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.emergencyContactData.firstname
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.firstname}
              </ErrorTypography>
            </Grid>
            <Grid item xs={6}>
              <StyledTypography>Last Name</StyledTypography>

              <TextField
                name="lastname"
                value={emergencyContactData.lastname}
                onChange={handleEmergencyContact}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.lastname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.emergencyContactData.lastname
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.lastname}
              </ErrorTypography>
            </Grid>
          </Grid>
          <Grid container spacing={4} style={{ paddingTop: "20px" }}>
            <Grid item xs={6}>
              <StyledTypography> Email Address</StyledTypography>

              <TextField
                name="email"
                value={emergencyContactData.email}
                onChange={handleEmergencyContact}
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 125 }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.email
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.emergencyContactData.email
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.email}
              </ErrorTypography>
            </Grid>
            <Grid item xs={6}>
              <StyledTypography>Contact Number</StyledTypography>
              <PhoneInput
                value={emergencyContactData.contact}
                onChange={(value, country, e) =>
                  handleMobileInput(
                    value,
                    country,
                    setemergencyContactData,
                    emergencyContactData,
                    "emergencyContactData"
                  )
                }
                inputProps={{ name: "phone", maxLength: 20 }}
                country={"th"}
                buttonStyle={{ border: "none" }}
                inputStyle={{
                  backgroundColor: errtext.emergencyContactData.phone
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  border: "none",
                  height: "46px",
                  width: "100%",
                  borderRadius: "8px",
                  color: errtext.emergencyContactData.phone
                    ? "#912018"
                    : "#111927",
                }}
                countryCodeEditable={false}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.phone}
              </ErrorTypography>
            </Grid>
          </Grid>
          <Grid container spacing={4} style={{ paddingTop: "20px" }}>
            <Grid item xs={6}>
              <StyledTypography> Relationship</StyledTypography>
              <TextField
                name="relationship"
                value={emergencyContactData.relationship}
                onChange={handleEmergencyContact}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.relationship
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.emergencyContactData.relationship
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.relationship}
              </ErrorTypography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "8px",
            backgroundColor: "#FFFFFF",
            padding: "20px 16px 20px 16px",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <ProfileSyncIcon />
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: "#9DA4AE",
                marginLeft: "3px",
              }}
            >
              Last Updated at {moment(lastUpdate).format("HH:mm")}
            </Typography>
          </Box>
          <Box>
            <Button
              sx={{
                borderRadius: "8px",
                backgroundColor: "#E7EFF3",
                color: "#0E5E84",
                fontSize: "16px",
                fontWeight: "600",
                "&:hover": {
                  backgroundColor: "#CFDFE6",
                },
              }}
              onClick={Continue}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EditProfile;
