import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { useLocation, useNavigate } from "react-router-dom";
import {
  TypographyDefault,
  StyledPrimaryTypography,
  TypoGraph,
  SecondaryTypography,
} from "../Theme";
import { Box, Stack, Divider, Skeleton, Typography } from "@mui/material";
import { ReactComponent as NotificationGreenDot } from "../Icons/NotificationGreenDot.svg";
import { ReactComponent as Facilities } from "../Icons/Facilities.svg";
import { ReactComponent as ExclamatoryIcon } from "../Icons/GraphExclamatoryIcon.svg";
import axiosInstance from "../Redux/AxiosInstance";
import { timeAgo } from "../Utils";

export default function Notification() {
  const navigate = useNavigate();
  const [notifyData, setNotifyData] = useState([1, 1, 1]);
  const [status, setStatus] = useState(1);
  const { propertyId, userRole } = useSelector(
    (state) => state.PropertyDetails
  );
  const { LanguageData } = useSelector((state) => state.Language);
  const [NotificationAllData, setNotificationAllData] = useState([]);
  const [UnreadNotify, setUnreadNotify] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  const GetAllNotification = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}notification/property/${propertyId}`
      );
      setNotificationAllData(response?.data?.data?.notification);
      const data = response?.data?.data?.notification.filter(
        (itm) => itm?.is_seen == 0
      );
      setUnreadNotify(data);
      setPageLoading(false);
    } catch (error) {
      console.log(error);
      setPageLoading(false);
      setNotificationAllData([]);
    }
  };

  useEffect(() => {
    GetAllNotification();
  }, []);

  const handleNotifySeen = async (id) => {
    const data = { notification_id: id };
    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}notification/property/${propertyId}/seen`,
        data
      );
      GetAllNotification();
    } catch (error) {
      console.log(error);
    }
  };
  const handleNotifyNavigation = (key, ref_id) => {
    if (Boolean(ref_id)) {
      switch (key) {
        case "facility":
          navigate(`/manage/reviewFacilityManage/${ref_id}`);
          break;
        case "fault-report":
          navigate(`/fault-reports/reviewFaultReport/${ref_id}`);
          break;
        case "service-request":
          navigate(`/service-request-list/${ref_id}`);
          break;
        case "parcel":
          navigate(`/parcel-collection/${ref_id}`);
          break;
        case "marketing":
          navigate(`reviewFacilityManage/${ref_id}`);
          break;
        case "announcement":
          navigate(`/announcement/ReviewAnnouncement/${ref_id}`);
          break;

        default:
          break;
      }
    }
  };

  return (
    <Box sx={{ height: "auto", padding: { md: "20px 40px", xs: "20px 10px" } }}>
      <Stack
        mb={4}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledPrimaryTypography>
          {" "}
          {pageLoading ? (
            <Skeleton variant="rounded" width={150} height="20px" />
          ) : (
            "Notification"
          )}
        </StyledPrimaryTypography>
        <Box display="flex" columnGap="8px">
          {pageLoading ? (
            <>
              <Skeleton variant="rounded" width={50} height="20px" />
              <Skeleton variant="rounded" width={50} height="20px" />
            </>
          ) : (
            <>
              {NotificationAllData?.length > 0 && (
                <>
                  <Box sx={{ cursor: "pointer" }}>
                    <TypoGraph
                      onClick={() => setStatus(1)}
                      sx={{
                        color: "#111927 !important",
                        fontWeight: "500 !important",
                        backgroundColor: status == 1 ? "#E5E7EB" : "none",
                        padding: "9px 12px",
                        border: "1px solid #E5E7EB",
                        borderRadius: "24px",
                      }}
                    >
                      {LanguageData?.All || "All"}
                    </TypoGraph>
                  </Box>
                  <Box sx={{ cursor: "pointer" }}>
                    <TypoGraph
                      onClick={() => setStatus(2)}
                      sx={{
                        color: "#111927 !important",
                        fontWeight: "500 !important",
                        backgroundColor: status == 2 ? "#E5E7EB" : "none",
                        padding: "9px 12px",
                        border: "1px solid #E5E7EB",
                        borderRadius: "24px",
                      }}
                    >
                      {LanguageData?.Unread || "Unread"}
                    </TypoGraph>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </Stack>
      {pageLoading ? (
        <Skeleton variant="rounded" width="100%" height="50vh" />
      ) : NotificationAllData?.length > 0 ? (
        <Stack
          direction="column"
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            padding: "24px 16px",
          }}
        >
          {(status === 1 ? NotificationAllData : UnreadNotify).map(
            (itm, index) => (
              <React.Fragment key={itm.id}>
                <Stack
                  onClick={() => {
                    handleNotifyNavigation(
                      itm?.notification_type?.key,
                      itm?.ref_id
                    );
                    handleNotifySeen(itm?.id);
                  }}
                  direction="row"
                  alignItems="center"
                  columnGap={2.3}
                  px={1.5}
                  style={{ cursor: "pointer" }}
                >
                  <Box
                    sx={{
                      width: "60px",
                      height: "60px",
                      backgroundColor: "#F3F4F6",
                      borderRadius: "12px",
                      position: "relative",
                    }}
                  >
                    <Facilities
                      width="25"
                      height="25"
                      style={{
                        position: "absolute",
                        top: "25%",
                        left: "28%",
                      }}
                    />
                  </Box>
                  <Box mt={index === 0 ? 0.5 : 1} p={1.2} flexGrow={1}>
                    <TypoGraph
                      sx={{
                        color: "#0E5E84 !important",
                        fontWeight: "500 !important",
                      }}
                    >
                      {timeAgo(itm?.created_at)}
                    </TypoGraph>
                    <SecondaryTypography
                      sx={{ fontWeight: "500 !important" }}
                      mt="10px"
                    >
                      {itm?.title}
                    </SecondaryTypography>
                    <TypoGraph
                      mt="4px"
                      sx={{
                        "&>*": { margin: 0 },
                        fontWeight: "400 !important",
                      }}
                    >
                      {parse(itm?.description)}
                    </TypoGraph>
                  </Box>
                  {itm?.is_seen === 0 && <NotificationGreenDot />}
                </Stack>
                {(status === 1 ? NotificationAllData : UnreadNotify)?.length -
                  1 !==
                  index && <Divider />}
              </React.Fragment>
            )
          )}
        </Stack>
      ) : (
        <Box
          sx={{
            borderRadius: "0px 0px 8px 8px",
            padding: "16px",
            backgroundColor: "#FFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ExclamatoryIcon style={{ marginBottom: "5px", marginTop: "60px" }} />
          <Typography
            sx={{
              color: "#9DA4AE",
              fontSize: "16px",
              fontWeight: 600,
              width: "470px",
              my: 2,
              textAlign: "center",
              marginTop: "5px",
              marginBottom: "100px",
            }}
            style={{ color: "#4D5761" }}
          >
            {LanguageData?.No_Notification ||
              "No Notification available for display"}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
