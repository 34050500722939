import React, { useEffect, useState } from "react";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import {
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledErrorTypography,
  StyledSecondaryTypography,
  StyledTypography,
} from "../../../Theme";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { setNestedStep } from "../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import axiosInstance from "../../../Redux/AxiosInstance";
import { PostLoaderModal } from "../../../CommonComponents/modal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const GeneralDetails = ({
  setDisplayScreen,
  setGeneralDetails,
  generalDetails,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const [errorText, setErrorText] = useState({
    title: "",
    address: "",
    country: "",
    city: "",
    zipcode: "",
    email: "",
    phone: "",
    phone: "",
    country_code: "",
    details: "",
  });
  const [CountryList, setCountryList] = useState([]);
  const [CityList, setCityList] = useState([]);

  const handleDetails = (e) => {
    let { name, value } = e.target;
    if (name === "country" && value) {
      setCityList([]);
      const selectedCountry = value;
      const selectedCountryObject = CountryList.find(
        (city) => city.country === selectedCountry
      );
      setCityList(selectedCountryObject?.city);
      setGeneralDetails((prevData) => ({
        ...prevData,
        city: "",
      }));
    }
    setGeneralDetails({
      ...generalDetails,
      [name]: value,
    });
    setErrorText({
      ...errorText,
      [name]: "",
    });
  };

  const handleMobileInput = (value, country) => {
    // console.log(value.slice(country.dialCode.length+1))
    setGeneralDetails({
      ...generalDetails,
      contact: value,
      country_code: `+${country.dialCode}`,
      phone: value.slice(country.dialCode.length),
    });

    if (generalDetails["phone"].length >= 6) {
      setErrorText((prevState) => {
        return {
          ...prevState,
          phone: "",
        };
      });
    }
  };

  useEffect(() => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}getDropDownDatas?type=country`)
      .then((res) => {
        setCountryList(res.data.data?.drop_down);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (generalDetails?.country && generalDetails?.country !== "") {
      let selectedCountry = generalDetails?.country;
      const selectedCountryObject = CountryList.find(
        (city) => city.country === selectedCountry
      );

      if (selectedCountryObject?.city) {
        setCityList(selectedCountryObject?.city);
      }
    }
  }, [CountryList]);

  const validate = () => {
    let isError = [];
    for (let key in generalDetails) {
      if (key === "phone" && generalDetails[key].length < 6) {
        setErrorText((prevState) => {
          return {
            ...prevState,
            [key]: "Error! The phone must be at least 6 characters.",
          };
        });
        isError.push(true);
      } else if (key === "zipcode" && generalDetails[key].length < 5) {
        setErrorText((prevState) => {
          return {
            ...prevState,
            [key]: "Error! Postal Code must be above 5 numbers",
          };
        });
        isError.push(true);
      } else if (key === "zipcode" && generalDetails[key].length > 6) {
        setErrorText((prevState) => {
          return {
            ...prevState,
            [key]: "Error! Postal Code must be below 7 numbers",
          };
        });
        isError.push(true);
      } else if (key === "email" && generalDetails[key] != "") {
        const emailRegex =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRegex.test(generalDetails[key]) === false) {
          setErrorText((prevState) => {
            return {
              ...prevState,
              [key]: "Enter valid email",
            };
          });
          isError.push(true);
        }
      } else {
        if (generalDetails[key] === "") {
          setErrorText((prevState) => {
            return {
              ...prevState,
              [key]: "Error! No Inputs detected",
            };
          });
          isError.push(true);
        }
      }
    }
    return isError.includes(true);
  };

  const handleContinue = () => {
    let isError = validate();
    if (!isError) {
      setDisplayScreen("coverAndLogo");
      dispatch(setNestedStep(1));
    }
  };
  return (
    <>
      {isLoading && <PostLoaderModal />}
      <BackArrow style={{ marginBottom: "30px", cursor: "pointer" }} />
      <PrimaryTypography mb={0.5}>General</PrimaryTypography>
      <StyledSecondaryTypography mb="30px">
        Information related to property development and building.
      </StyledSecondaryTypography>
      <Box borderRadius="8px" sx={{ backgroundColor: "#FFFFFF" }}>
        <Box
          sx={{
            p: 2,
            mt: 2,
            borderRadius: "8px 8px 0 0 ",
            borderBottom: "1px solid #E5E7EB",
          }}
        >
          <SecondaryTypography>Property Details</SecondaryTypography>
        </Box>
        <Grid container sx={{ p: 2 }} rowSpacing={3} columnSpacing={2}>
          <Grid item xs={12}>
            <StyledTypography>Property Title</StyledTypography>
            <TextField
              value={generalDetails.title}
              onChange={(e) => handleDetails(e)}
              name="title"
              placeholder="Add Property Title"
              sx={{ height: "46px" }}
              InputProps={{ disableUnderline: true }}
              inputProps={{ maxLength: 20 }}
            />
            <ErrorTypography>{errorText.title}</ErrorTypography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <StyledTypography>Property Address</StyledTypography>
              <StyledSecondaryTypography>
                {generalDetails.address.length}/100
              </StyledSecondaryTypography>
            </Box>
            <TextField
              value={generalDetails.address}
              onChange={(e) => handleDetails(e)}
              inputProps={{ maxLength: 100 }}
              name="address"
              placeholder="Enter The Full Property Address Here."
              sx={{ height: "92px" }}
              InputProps={{ disableUnderline: true }}
            />
            <ErrorTypography>{errorText.address}</ErrorTypography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledTypography>Country</StyledTypography>
            <Select
              value={generalDetails.country}
              onChange={(e) => handleDetails(e)}
              name="country"
              IconComponent={KeyboardArrowDownIcon}
            >
              {CountryList.length > 0 &&
                CountryList.map((value) => (
                  <MenuItem key={value?.key} value={value?.country}>
                    {value?.country}
                  </MenuItem>
                ))}
            </Select>
            <ErrorTypography>{errorText.country}</ErrorTypography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledTypography>City</StyledTypography>
            <Select
              value={generalDetails.city}
              onChange={(e) => handleDetails(e)}
              name="city"
              IconComponent={KeyboardArrowDownIcon}
            >
              {CityList.length > 0 &&
                CityList.map((city) => (
                  <MenuItem value={city}>{city}</MenuItem>
                ))}
            </Select>
            <ErrorTypography>{errorText.city}</ErrorTypography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledTypography>Postal Code</StyledTypography>
            <TextField
              type="number"
              value={generalDetails.zipcode}
              onChange={(e) => handleDetails(e)}
              name="zipcode"
              placeholder="Postal Code"
              sx={{ height: "46px" }}
              InputProps={{ disableUnderline: true }}
              inputProps={{ maxLength: 6 }}
            />
            <ErrorTypography>{errorText.zipcode}</ErrorTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTypography>Corporate Email Address</StyledTypography>
            <TextField
              disabled
              value={generalDetails.email}
              onChange={(e) => handleDetails(e)}
              name="email"
              placeholder="Enter The Main Contact Email Address For The Condo."
              sx={{ height: "46px" }}
              InputProps={{ disableUnderline: true }}
              inputProps={{ maxLength: 125 }}
            />
            <ErrorTypography>{errorText.email}</ErrorTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTypography sx={{ mb: 1 }}>
              Main Contact Number
            </StyledTypography>
            <PhoneInput
              value={generalDetails.contact}
              onChange={(value, country, e, formatValue) =>
                handleMobileInput(value, country)
              }
              inputProps={{ name: "contact" }}
              country={"th"}
              buttonStyle={{ border: "none" }}
              inputStyle={{
                backgroundColor: "#F9FAFB",
                border: "none",
                height: "46px",
                width: "100%",
                borderRadius: "8px",
              }}
              countryCodeEditable={false}
              placeholder="Add Contact Number"
            />
            <ErrorTypography>{errorText.phone}</ErrorTypography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <StyledTypography>Details of the property</StyledTypography>
              <StyledSecondaryTypography>
                {generalDetails.details.length}/100
              </StyledSecondaryTypography>
            </Box>
            <TextField
              value={generalDetails.details}
              onChange={(e) => handleDetails(e)}
              name="details"
              inputProps={{ maxLength: 100 }}
              placeholder="Enter The Description Of The Property Here."
              sx={{ height: "92px" }}
              InputProps={{ disableUnderline: true }}
            />
            <ErrorTypography>{errorText.details}</ErrorTypography>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 2,
            mb: 5,
            borderTop: "1px solid #E5E7EB",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <RefreshIcon style={{ marginRight: "5px" }} />
            <StyledSecondaryTypography>
              Last Updated at{" "}
              {moment.utc(generalDetails?.updateDate).local().format("HH:mm A")}
            </StyledSecondaryTypography>
          </Box>
          <Button
            onClick={handleContinue}
            sx={{
              backgroundColor: "#E7EFF3",
              "&:hover": {
                backgroundColor: "#CFDFE6",
              },
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default GeneralDetails;
