import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import { ReactComponent as ContactDetailsIcon } from "../../../Icons/ContactDetails.svg";
import { ReactComponent as BorderAlertCloseIcon } from "../../../Icons/BorderAlertClose.svg";
import { ReactComponent as DatePickerIcon } from "../../../Icons/Datepicker.svg";
import { ReactComponent as EditIcon } from "../../../Icons/Edit.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  DatePickerStyle,
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
  TypoGraph,
  TypographyDefault,
} from "../../../Theme";
import {
  Stack,
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import AlertModal, { PostLoaderModal } from "../../../CommonComponents/modal";
import axiosInstance from "../../../Redux/AxiosInstance";
import {
  userData,
  NameRegex,
  emailRegex,
  No_special_regex,
} from "../../../Utils";
import { showToast } from "../../../CommonComponents/Toaster";

let deleteModalDatas = {
  label: "Delete Onboarding",
  descriptionOne: "Please confirm that you wish to delete onboarding staff. ",
  buttonOne: {
    variant: "outlined",
    color: "#0E5E84",
    backgroundColor: "#FFFFFF",
    text: "Cancel",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    backgroundColor: "#B42318",
    text: "Delete Now",
  },
};

const reviewstyle = {
  minHeight: "61px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "10px 0",
};

const AddSingleStaff = () => {
  const { propertyId, userRole } = useSelector(
    (state) => state.PropertyDetails
  );
  const { userDetails } = useSelector((state) => state.Login);
  const navigate = useNavigate();
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [addblocks, setaddblocks] = useState({
    Personal_Details: true,
    WorkAccess_Details: false,
    Review_Information: false,
  });
  const [PersonalDetailsData, setPersonalDetailsData] = useState({
    firstname: "",
    lastname: "",
    middlename: "",
    dob: "",
    gender: "",
    nationality: "",
    inorpn: "",
    contact: "",
    phone: "",
    countrycode: "",
    email: "",
    address: "",
    country: "",
    city: "",
    postalcode: "",
  });

  const [emergencyContactData, setemergencyContactData] = useState({
    firstname: "",
    lastname: "",
    // gender: '',
    // nationality: '',
    countrycode: "",
    contact: "",
    phone: "",
    email: "",
    relationship: "",
  });
  const [WorkAccessData, setWorkAccessData] = useState({
    employmenttype: "",
    department: "",
    jobrole: "",
    accessstartdate: "",
    accessenddate: "",
  });
  const [nationalityData, setnationalityData] = useState("");
  const [genderData, setgenderData] = useState([]);
  const [employeeTypeData, setemployeeTypeData] = useState([]);
  const [department, setDepartment] = useState([]);
  const [departmentIndex, setDepartmentIndex] = useState("");
  const [CountryList, setCountryList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [postLoader, SetPostLoader] = useState(false);

  const date = (val) => {
    const formattedDob = moment(val).format("DD MMM YYYY ").toString();
    return formattedDob;
  };

  const [onboardedby, setonboardedby] = useState({
    name: userDetails?.name,
    email: userDetails?.email,
    department: userData("department_details")?.department_name,
    jobrole:
      userRole == 2
        ? userDetails?.user_role_details?.role_name
        : userData("role_details")?.title,
  });
  const [errtext, seterrtext] = useState({
    PersonalDetailsData: {},
    emergencyContactData: {},
    WorkAccessData: {},
  });

  //add api

  const AddStaff = async () => {
    const AccessStart = WorkAccessData.accessstartdate?.$d;
    const final = moment(AccessStart).hour(0).minute(0).second(0);
    const final1 = moment.utc(final).format("YYYY-MM-DD HH:mm:ss");
    const AccessEnd = WorkAccessData.accessenddate?.$d;
    const finalEnd = moment(AccessEnd).hour(0).minute(0).second(0);
    const final1End = moment.utc(finalEnd).format("YYYY-MM-DD HH:mm:ss");
    const staffData = {
      first_name: PersonalDetailsData.firstname, //required|max:20|min:1
      last_name: PersonalDetailsData.lastname, //required|max:20|min:1
      // middle_name: PersonalDetailsData.middlename, //max:20|min:1
      dob: moment(PersonalDetailsData.dob?.$d).format("YYYY-MM-DD"), //required|date|before_or_equal:today
      gender: PersonalDetailsData.gender, //required|int|in:1,2,3
      nationality: PersonalDetailsData.nationality, //required|max:20|min:1
      identity_number: PersonalDetailsData.inorpn, //required|max:25|min:1
      phone: PersonalDetailsData.phone, //required|min:10|max:20
      country_code: PersonalDetailsData.countrycode, //required
      address: PersonalDetailsData.address, //required|min:3|max:50
      country: PersonalDetailsData.country, //required|min:3|max:20
      city: PersonalDetailsData.city, //required|min:3|max:20
      zipcode: PersonalDetailsData.postalcode, //required|max:7|min:6
      email: PersonalDetailsData.email,
      emergency_details: {
        first_name: emergencyContactData.firstname, //required|max:20|min:1
        last_name: emergencyContactData.lastname, //max:20|min:1
        email: emergencyContactData.email, //email
        phone: emergencyContactData.phone, //required|min:10|max:20
        country_code: emergencyContactData.countrycode, //required
        gender: "1", //required|int|in:1,2,3
        nationality: "hyfg", //required|max:20|min:1
        relationship: emergencyContactData.relationship, //required|max:20|min:1
      },
      access_details: {
        residency_type_id: WorkAccessData.employmenttype, //required|exists:residency_type,id
        department_id: WorkAccessData.department, //exists:property_department,id
        role_id: WorkAccessData.jobrole, //exists:property_role,id
        join_date: moment.utc(new Date()).format("YYYY-MM-DD"), //date
        access_start: final1,
        //required|date
        // "access_end": moment(WorkAccessData.accessenddate?.$d).format('YYYY-MM-DD') //date|after:access_details.access_start
      },
    };

    {
      Boolean(PersonalDetailsData.middlename) &&
        Object.assign(staffData, {
          middle_name: PersonalDetailsData.middlename,
        });
    }
    {
      Boolean(WorkAccessData.accessenddate) &&
        WorkAccessData.employmenttype == 1 &&
        (staffData.access_details.access_end = final1End);
    }
    SetPostLoader(true);
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/staff_onboard_single`,
        staffData
      );
      // console.log(response.data?.toast);
      showToast(response.data?.toast);
      navigate("/staffs");
      SetPostLoader(false);
    } catch (error) {
      showToast(
        error?.response?.data?.error ||
          "Something went wrong! Please try again.",
        "error"
      );
      SetPostLoader(false);
    }
  };

  useEffect(() => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}getDropDownDatas?type=country`)
      .then((res) => {
        setCountryList(res.data.data?.drop_down);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const getnationality = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}getDropDownDatas?type=nationality`
        );
        const nationalityData = response.data.data.drop_down;
        var nationalitymenuItems = [];
        for (const key in nationalityData) {
          nationalitymenuItems.push(
            <MenuItem key={key} value={key}>
              {nationalityData[key]}
            </MenuItem>
          );
        }
        setnationalityData(nationalitymenuItems);
      } catch (error) {
        console.log(error.message);
      }
    };
    getnationality();

    const getGenderData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}getDropDownDatas?type=gender`
        );
        const genderData = response.data.data.drop_down;
        setgenderData(genderData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getGenderData();
    const getResidentTypeData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}getDropDownDatas?type=residency_type_employee`
        );
        const employeeTypeData = response.data.data.drop_down;
        setemployeeTypeData(employeeTypeData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getResidentTypeData();

    const getDepartment = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/role`
        );
        const departmentdata = response.data.data.property_role;
        setDepartment(departmentdata);
      } catch (error) {
        console.log(error.message);
      }
    };
    getDepartment();
  }, []);

  const DepartmentDropDown = (index) => {
    setDepartmentIndex(index);
    setWorkAccessData((prev) => {
      return { ...prev, jobrole: "" };
    });
  };

  const isValid = (curval) => {
    let isError = false;
    // Personaldetails
    if (curval === "Personal Details") {
      for (const key in PersonalDetailsData) {
        if (key === "email" && PersonalDetailsData[key] !== "") {
          if (!emailRegex.test(PersonalDetailsData[key])) {
            seterrtext((prev) => {
              return {
                ...prev,
                PersonalDetailsData: {
                  ...prev.PersonalDetailsData,
                  [key]: "Error! Invalid Email Format",
                },
              };
            });
            isError = true;
          }
        } else if (key === "dob" && PersonalDetailsData[key].$d > new Date()) {
          seterrtext((prev) => {
            return {
              ...prev,
              PersonalDetailsData: {
                ...prev.PersonalDetailsData,
                [key]: "Error! Date of Birth should before today",
              },
            };
          });
          isError = true;
        }
        // else if (key === 'phone' && PersonalDetailsData[key].length < 10) {
        //     seterrtext((prev) => {
        //         return ({
        //             ...prev, PersonalDetailsData: {
        //                 ...prev.PersonalDetailsData,
        //                 [key]: 'Error! Contact number must be above 10 number ',
        //             }
        //         })
        //     })
        //     isError = true
        // }
        else if (key === "phone") {
          if (
            PersonalDetailsData[key] === "" ||
            PersonalDetailsData[key] === null ||
            PersonalDetailsData[key].length < 6
          ) {
            seterrtext((prev) => {
              return {
                ...prev,
                PersonalDetailsData: {
                  ...prev.PersonalDetailsData,
                  [key]:
                    PersonalDetailsData[key] === "" ||
                    PersonalDetailsData[key] === null
                      ? "Error! No Inputs detected"
                      : "Error! Phone should not below 6 numbers ",
                },
              };
            });
            isError = true;
          }
        } else if (key === "postalcode") {
          if (
            PersonalDetailsData[key] === "" ||
            PersonalDetailsData[key] === null
          ) {
            seterrtext((prev) => {
              return {
                ...prev,
                PersonalDetailsData: {
                  ...prev.PersonalDetailsData,
                  [key]: "Error! No Inputs detected",
                },
              };
            });
            isError = true;
          } else if (PersonalDetailsData[key].length < 5) {
            seterrtext((prev) => {
              return {
                ...prev,
                PersonalDetailsData: {
                  ...prev.PersonalDetailsData,
                  [key]: "Error! Postal Code must be above 5 numbers ",
                },
              };
            });
            isError = true;
          }
        } else if (
          (PersonalDetailsData[key] === "" ||
            PersonalDetailsData[key] === null) &&
          key !== "middlename"
        ) {
          seterrtext((prev) => {
            return {
              ...prev,
              PersonalDetailsData: {
                ...prev.PersonalDetailsData,
                [key]: "Error! No Inputs detected",
              },
            };
          });
          isError = true;
        }
      }
      //emergencycontact
      for (const key in emergencyContactData) {
        if (key === "email" && emergencyContactData[key] !== "") {
          if (!emailRegex.test(emergencyContactData[key])) {
            seterrtext((prev) => {
              return {
                ...prev,
                emergencyContactData: {
                  ...prev.emergencyContactData,
                  [key]: "Error! Invalid Email Format",
                },
              };
            });
            isError = true;
          }
        }
        // else if (key === 'phone' && emergencyContactData[key].length < 10) {
        //     seterrtext((prev) => {
        //         return ({
        //             ...prev, emergencyContactData: {
        //                 ...prev.emergencyContactData,
        //                 [key]: 'Error! Contact number must be above 10 number ',
        //             }
        //         })
        //     })
        //     isError = true
        // }
        else if (key === "phone") {
          if (
            emergencyContactData[key] === "" ||
            emergencyContactData[key] === null ||
            emergencyContactData[key].length < 6
          ) {
            seterrtext((prev) => {
              return {
                ...prev,
                emergencyContactData: {
                  ...prev.emergencyContactData,
                  [key]:
                    emergencyContactData[key] === "" ||
                    emergencyContactData[key] === null
                      ? "Error! No Inputs detected"
                      : "Error! Phone should not below 6 numbers ",
                },
              };
            });
            isError = true;
          }
        } else if (emergencyContactData[key] === "" && key !== "lastname") {
          seterrtext((prev) => {
            return {
              ...prev,
              emergencyContactData: {
                ...prev.emergencyContactData,
                [key]: "Error! No Inputs detected",
              },
            };
          });
          isError = true;
        }
      }
    } else if (curval === "Work & Access Details") {
      for (const key in WorkAccessData) {
        if (key === "accessenddate" && WorkAccessData.employmenttype != "2") {
          if (WorkAccessData[key] === "" || WorkAccessData[key] === null) {
            seterrtext((prev) => {
              return {
                ...prev,
                WorkAccessData: {
                  ...prev.WorkAccessData,
                  [key]: "Error! No Inputs detected",
                },
              };
            });
            isError = true;
          } else if (
            WorkAccessData.accessstartdate.$d >= WorkAccessData.accessenddate.$d
          ) {
            seterrtext((prev) => {
              return {
                ...prev,
                WorkAccessData: {
                  ...prev.WorkAccessData,
                  [key]: "Error! End date should be greater than start date",
                },
              };
            });
            isError = true;
          }
        } else if (
          (WorkAccessData[key] === "" || WorkAccessData[key] === null) &&
          key !== "accessenddate"
        ) {
          seterrtext((prev) => {
            return {
              ...prev,
              WorkAccessData: {
                ...prev.WorkAccessData,
                [key]: "Error! No Inputs detected",
              },
            };
          });
          isError = true;
        }
      }
    }
    return isError;
  };
  //personalDetails
  const Continue = (nxtval, curval) => {
    if (nxtval === "Work & Access Details") {
      curval = "Personal Details";
    } else if (nxtval === "Review Information") {
      curval = "Work & Access Details";
    }
    const Valid = isValid(curval);
    if (!Valid) {
      if (nxtval === "Personal Details") {
        setaddblocks({
          Personal_Details: true,
          WorkAccess_Details: false,
          Review_Information: false,
        });
      } else if (nxtval === "Work & Access Details") {
        setaddblocks({
          Personal_Details: false,
          WorkAccess_Details: true,
          Review_Information: false,
        });
      } else if (nxtval === "Review Information") {
        setaddblocks({
          Personal_Details: false,
          WorkAccess_Details: false,
          Review_Information: true,
        });
      }
    }
  };

  const handlePersonalDetails = (e, name) => {
    if (name) {
      setPersonalDetailsData((prev) => {
        return {
          ...prev,
          [name]: e,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          PersonalDetailsData: {
            ...prev.PersonalDetailsData,
            [name]: "",
          },
        };
      });
    } else {
      const { name, value } = e.target;
      if (name === "country" && value) {
        setCityList([]);
        const selectedCountry = value;
        const selectedCountryObject = CountryList.find(
          (city) => city.country === selectedCountry
        );
        setCityList(selectedCountryObject?.city);
        setPersonalDetailsData((prevData) => ({
          ...prevData,
          city: "",
        }));
      }
      setPersonalDetailsData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          PersonalDetailsData: {
            ...prev.PersonalDetailsData,
            [name]: "",
          },
        };
      });
    }
  };
  const handleMobileInput = (
    value,
    country,
    functionName,
    state,
    statename
  ) => {
    functionName({
      ...state,
      contact: value,
      countrycode: `+ ${country.dialCode}`,
      phone: value.slice(country.dialCode.length),
    });
    seterrtext((prev) => {
      return {
        ...prev,
        [statename]: {
          ...prev[statename],
          countrycode: "",
          phone: "",
        },
      };
    });
  };

  const handleEmergencyContact = (e) => {
    const { name, value } = e.target;
    setemergencyContactData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    seterrtext((prev) => {
      return {
        ...prev,
        emergencyContactData: {
          ...prev.emergencyContactData,
          [name]: "",
        },
      };
    });
  };

  const handleWorkAccess = (e, name) => {
    if (name) {
      setWorkAccessData((prev) => {
        return {
          ...prev,
          [name]: e,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          WorkAccessData: {
            ...prev.WorkAccessData,
            [name]: "",
          },
        };
      });
    } else {
      const { name, value } = e.target;
      setWorkAccessData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          WorkAccessData: {
            ...prev.WorkAccessData,
            [name]: "",
          },
        };
      });
      if (name === "employmenttype" && value == "2") {
        setWorkAccessData((prev) => {
          return {
            ...prev,
            accessenddate: "",
          };
        });
        seterrtext((prev) => {
          return {
            ...prev,
            WorkAccessData: {
              ...prev.WorkAccessData,
              accessenddate: "",
            },
          };
        });
      }
    }
  };

  const blocks = [
    { status: addblocks.Personal_Details, tle: "Personal Details" },
    { status: addblocks.WorkAccess_Details, tle: "Work & Access Details" },
    { status: addblocks.Review_Information, tle: "Review Information" },
  ];

  const PersonalDetails = () => {
    return (
      <>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>Personal Details</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px  ",
            }}
          >
            <Grid xs={12} sm={5.8}>
              <StyledTypography>First Name*</StyledTypography>
              <TextField
                name="firstname"
                value={PersonalDetailsData.firstname}
                onChange={handlePersonalDetails}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.firstname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.firstname
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
                fullWidth
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.firstname}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8}>
              <StyledTypography>Last Name*</StyledTypography>
              <TextField
                name="lastname"
                value={PersonalDetailsData.lastname}
                onChange={handlePersonalDetails}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.lastname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.lastname
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
                fullWidth
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.lastname}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Middle Name</StyledTypography>
              <TextField
                name="middlename"
                value={PersonalDetailsData.middlename}
                onChange={handlePersonalDetails}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.middlename}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Date of Birth*</StyledTypography>
              <DatePicker
                disableFuture
                format="DD/MM/YYYY"
                slots={{ openPickerIcon: () => <DatePickerIcon /> }}
                name="dob"
                value={PersonalDetailsData.dob || null}
                onChange={(value) => handlePersonalDetails(value, "dob")}
                sx={{
                  ...DatePickerStyle,
                  backgroundColor: Boolean(errtext.PersonalDetailsData.dob)
                    ? "#FEE4E2"
                    : "",

                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.dob
                      ? "#912018"
                      : "#111927",
                  },
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.dob}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Gender*</StyledTypography>
              <Select
                name="gender"
                value={PersonalDetailsData.gender}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.gender
                      ? "#912018"
                      : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                  backgroundColor: errtext.PersonalDetailsData.gender
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  width: "100%",
                }}
                IconComponent={KeyboardArrowDownIcon}
              >
                {genderData.map((itm) => {
                  return <MenuItem value={itm.id}>{itm.name}</MenuItem>;
                })}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.gender}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Nationality*</StyledTypography>
              <Select
                name="nationality"
                value={PersonalDetailsData.nationality}
                IconComponent={KeyboardArrowDownIcon}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.nationality
                      ? "#912018"
                      : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                  backgroundColor: errtext.PersonalDetailsData.nationality
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {Boolean(nationalityData) && nationalityData}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.nationality}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>
                Identity Number / Passport Number*
              </StyledTypography>
              <TextField
                name="inorpn"
                value={PersonalDetailsData.inorpn}
                onChange={handlePersonalDetails}
                onKeyDown={(evt) => {
                  !No_special_regex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 25 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.inorpn
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.inorpn
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.inorpn}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography sx={{ marginBottom: "10px" }}>
                Contact Number*
              </StyledTypography>
              <PhoneInput
                value={PersonalDetailsData.contact}
                onChange={(value, country, e) =>
                  handleMobileInput(
                    value,
                    country,
                    setPersonalDetailsData,
                    PersonalDetailsData,
                    "PersonalDetailsData"
                  )
                }
                inputProps={{ name: "phone", maxLength: 20 }}
                country={"th"}
                buttonStyle={{ border: "none" }}
                inputStyle={{
                  backgroundColor: errtext.PersonalDetailsData.phone
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  border: "none",
                  height: "46px",
                  width: "100%",
                  borderRadius: "8px",
                  color: errtext.PersonalDetailsData.phone
                    ? "#912018"
                    : "#111927",
                }}
                countryCodeEditable={false}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.phone}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={12} mt={2}>
              <StyledTypography>Email Address*</StyledTypography>
              <TextField
                name="email"
                value={PersonalDetailsData.email}
                onChange={handlePersonalDetails}
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 125 }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.email
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.email
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.email}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={12} mt={2}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <StyledTypography>Address*</StyledTypography>
                <TypoGraph>{PersonalDetailsData.address.length}/50</TypoGraph>
              </Box>
              <TextField
                name="address"
                placeholder="Enter the full address here"
                value={PersonalDetailsData.address}
                onChange={handlePersonalDetails}
                inputProps={{ maxLength: 50 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.address
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.address
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  height: "88px",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.address}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={3.77} mt={2}>
              <StyledTypography>Country*</StyledTypography>
              <Select
                name="country"
                value={PersonalDetailsData.country}
                IconComponent={KeyboardArrowDownIcon}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.country
                      ? "#912018"
                      : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                  backgroundColor: errtext.PersonalDetailsData.country
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {CountryList.length > 0 &&
                  CountryList.map((value) => (
                    <MenuItem key={value?.key} value={value?.country}>
                      {value?.country}
                    </MenuItem>
                  ))}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.country}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={3.77} mt={2}>
              <StyledTypography>City*</StyledTypography>
              <Select
                name="city"
                value={PersonalDetailsData.city}
                IconComponent={KeyboardArrowDownIcon}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.city
                      ? "#912018"
                      : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                  backgroundColor: errtext.PersonalDetailsData.city
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {CityList.length > 0 &&
                  CityList.map((city) => (
                    <MenuItem value={city}>{city}</MenuItem>
                  ))}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.city}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={3.77} mt={2}>
              <StyledTypography>Postal Code*</StyledTypography>
              <TextField
                type="number"
                name="postalcode"
                value={PersonalDetailsData.postalcode}
                onChange={(e) => {
                  e.target.value.length <= 6 && handlePersonalDetails(e);
                }}
                // inputProps={{ maxLength: 6 }}
                onKeyDown={(evt) => {
                  ["e", "E", "-"].includes(evt.key) && evt.preventDefault();
                }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.postalcode
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.postalcode
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.postalcode}
              </ErrorTypography>
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>Emergency Contact</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px",
            }}
          >
            <Grid xs={12} sm={5.8}>
              <StyledTypography>First Name*</StyledTypography>
              <TextField
                name="firstname"
                value={emergencyContactData.firstname}
                onChange={handleEmergencyContact}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.firstname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.emergencyContactData.firstname
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.firstname}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8}>
              <StyledTypography>Last Name</StyledTypography>
              <TextField
                name="lastname"
                value={emergencyContactData.lastname}
                onChange={handleEmergencyContact}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.lastname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.emergencyContactData.lastname
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.lastname}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Email Address*</StyledTypography>
              <TextField
                name="email"
                value={emergencyContactData.email}
                onChange={handleEmergencyContact}
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 125 }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.email
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.emergencyContactData.email
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.email}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Contact Number*</StyledTypography>
              <PhoneInput
                placeholder="Enter contact number"
                value={emergencyContactData.contact}
                onChange={(value, country, e) =>
                  handleMobileInput(
                    value,
                    country,
                    setemergencyContactData,
                    emergencyContactData,
                    "emergencyContactData"
                  )
                }
                inputProps={{ name: "phone", maxLength: 20 }}
                country={"th"}
                buttonStyle={{ border: "none" }}
                inputStyle={{
                  backgroundColor: errtext.emergencyContactData.phone
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  border: "none",
                  height: "46px",
                  width: "100%",
                  borderRadius: "8px",
                  color: errtext.emergencyContactData.phone
                    ? "#912018"
                    : "#111927",
                }}
                countryCodeEditable={false}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.phone}
              </ErrorTypography>
            </Grid>
            {/* <Grid xs={12} sm={3.77} mt={2}>
                            <StyledTypography>Nationality*</StyledTypography>
                            <Select name="nationality" value={emergencyContactData.nationality} onChange={(e) => handleEmergencyContact(e)}>
                                {Boolean(nationalityData) && nationalityData}
                            </Select>
                            <ErrorTypography>{errtext.emergencyContactData.nationality}</ErrorTypography>
                        </Grid>
                        <Grid xs={12} sm={3.77} mt={2}>
                            <StyledTypography>Gender*</StyledTypography>
                            <Select type='number' name="gender" value={emergencyContactData.gender} onChange={(e) => handleEmergencyContact(e)}>
                                {genderData.map((itm) => {
                                    return <MenuItem value={itm.id}>{itm.name}</MenuItem>
                                })}
                            </Select>
                            <ErrorTypography>{errtext.emergencyContactData.gender}</ErrorTypography>
                        </Grid> */}
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Relationship*</StyledTypography>
              <TextField
                name="relationship"
                value={emergencyContactData.relationship}
                onChange={handleEmergencyContact}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.relationship
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.emergencyContactData.relationship
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.relationship}
              </ErrorTypography>
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={3}
          bgcolor="#FFFFFF"
          sx={{
            border: "1px solid  #E5E7EB",
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "8px 8px 8px 8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="end"
            alignItems="center"
            height="60px"
          >
            {/* <Stack direction='row'>
                            <RefreshIcon style={{ marginRight: '5px' }} />
                            <StyledSecondaryTypography>Last Updated at  {moment(new Date()).format('HH:mm A')}</StyledSecondaryTypography>
                        </Stack> */}
            <Stack direction="column" justifyContent="end" alignItems="end">
              <Button
                type="button"
                onClick={() =>
                  Continue("Work & Access Details", "Personal Details")
                }
                variant="Standard"
              >
                Continue
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  };
  const WorkAccessDetails = () => {
    return (
      <>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>Work & Access Details</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 0px 0px  ",
            }}
          >
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Employment Type*</StyledTypography>
              <Select
                name="employmenttype"
                value={WorkAccessData.employmenttype}
                IconComponent={KeyboardArrowDownIcon}
                onChange={(e) => handleWorkAccess(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.employmenttype
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.WorkAccessData.employmenttype
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              >
                {employeeTypeData.map((itm) => {
                  return <MenuItem value={itm.id}>{itm.name}</MenuItem>;
                })}
              </Select>
              <ErrorTypography>
                {errtext.WorkAccessData.employmenttype}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8}></Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Department*</StyledTypography>
              <Select
                name="department"
                value={WorkAccessData.department}
                IconComponent={KeyboardArrowDownIcon}
                onChange={(e) => handleWorkAccess(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.department
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.WorkAccessData.department
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              >
                {department.map((itm, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        DepartmentDropDown(index);
                      }}
                      value={itm.id}
                    >
                      {itm.department_name}
                    </MenuItem>
                  );
                })}
              </Select>
              <ErrorTypography>
                {errtext.WorkAccessData.department}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Job Role*</StyledTypography>
              <Select
                name="jobrole"
                value={WorkAccessData.jobrole}
                IconComponent={KeyboardArrowDownIcon}
                onChange={(e) => handleWorkAccess(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.jobrole
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.WorkAccessData.jobrole
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              >
                {department[departmentIndex]?.role.map((itm) => {
                  return <MenuItem value={itm.id}>{itm.title}</MenuItem>;
                })}
              </Select>
              <ErrorTypography>
                {errtext.WorkAccessData.jobrole}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Access Start Date*</StyledTypography>
              <DatePicker
                format="DD/MM/YYYY"
                slots={{
                  openPickerIcon: () => <DatePickerIcon />,
                }}
                name="accessstartdate"
                value={WorkAccessData.accessstartdate || null}
                onChange={(value) => handleWorkAccess(value, "accessstartdate")}
                sx={{
                  ...DatePickerStyle,
                  backgroundColor: Boolean(
                    errtext.WorkAccessData.accessstartdate
                  )
                    ? "#FEE4E2"
                    : "",

                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.accessstartdate
                      ? "#912018"
                      : "#111927",
                  },
                }}
              />
              <ErrorTypography>
                {errtext.WorkAccessData.accessstartdate}
              </ErrorTypography>
            </Grid>
            {(WorkAccessData?.employmenttype == "" ||
              WorkAccessData?.employmenttype === 1) && (
              <Grid xs={12} sm={5.8} mt={2}>
                <StyledTypography>Access End Date*</StyledTypography>
                <DatePicker
                  format="DD/MM/YYYY"
                  slots={{ openPickerIcon: () => <DatePickerIcon /> }}
                  name="accessenddate"
                  value={WorkAccessData.accessenddate || null}
                  onChange={(value) => handleWorkAccess(value, "accessenddate")}
                  sx={{
                    ...DatePickerStyle,
                    backgroundColor: Boolean(
                      errtext.WorkAccessData.accessenddate
                    )
                      ? "#FEE4E2"
                      : "",

                    "& .MuiInputBase-root": {
                      color: errtext.WorkAccessData.accessenddate
                        ? "#912018"
                        : "#111927",
                    },
                  }}
                  disablePast
                />
                <ErrorTypography>
                  {errtext.WorkAccessData.accessenddate}
                </ErrorTypography>
              </Grid>
            )}
          </Grid>
        </Box>

        <Box
          mt={0}
          bgcolor="#FFFFFF"
          sx={{
            border: "1px solid  #E5E7EB",
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="end"
            alignItems="center"
            height="60px"
          >
            {/* <Stack direction='row'>
                            <RefreshIcon style={{ marginRight: '5px' }} />
                            <StyledSecondaryTypography>Last Updated at  {moment(new Date()).format('HH:mm A')}</StyledSecondaryTypography>
                        </Stack> */}
            <Stack direction="column" justifyContent="end" alignItems="end">
              <Button
                type="button"
                onClick={() =>
                  Continue("Review Information", "Work & Access Details")
                }
                variant="Standard"
              >
                Continue
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  };
  const ReviewInformation = () => {
    return (
      <>
        {/* Personal Details */}
        <Box
          my={5}
          minHeight="100px"
          padding="20px 16px"
          backgroundColor="#FFFAEB"
          border="1px solid #FEE6A1"
        >
          <StyledTypography mb="10px" sx={{ color: "#B54708 !important" }}>
            Onboarding Alert
          </StyledTypography>
          <TypographyDefault sx={{ color: "#B54708 !important" }}>
            Please ensure that all data provided are accurate to prevent any
            delays in user access. Once staff is onboarded, they will receive an
            onboarding email to download and install the LOBY App. Staff will be
            able to use the app once the selected access start date has been
            reached.
          </TypographyDefault>
        </Box>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <Stack direction="row" alignItems="center" gap="10px">
              <ContactDetailsIcon />
              <SecondaryTypography>Personal Details</SecondaryTypography>
            </Stack>
            <Button
              onClick={() => Continue("Personal Details", "")}
              sx={{
                width: "83px",
                height: "36px",
                padding: "8px 6px 8px 14px",
              }}
              variant="outlined"
            >
              {" "}
              Edit{" "}
              <IconButton sx={{ paddingLeft: "10px" }}>
                {" "}
                <EditIcon width="14px" />
              </IconButton>
            </Button>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px  ",
            }}
          >
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>First Name</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.firstname}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Last Name</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.lastname}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Middle Name</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.middlename}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Date of Birth
              </StyledSecondaryTypography>
              <TypographyDefault>
                {date(PersonalDetailsData.dob.$d)}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Gender</StyledSecondaryTypography>
              {genderData.map((itm) => {
                if (PersonalDetailsData.gender === itm.id) {
                  return <TypographyDefault>{itm.name}</TypographyDefault>;
                }
              })}
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Nationality</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.nationality}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Identity Number / Passport Number
              </StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.inorpn}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography sx={{ marginBottom: "10px" }}>
                Contact Number
              </StyledSecondaryTypography>
              <TypographyDefault>{PersonalDetailsData.phone}</TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Email Address
              </StyledSecondaryTypography>
              <TypographyDefault>{PersonalDetailsData.email}</TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Address</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.address}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Country</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.country}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>City</StyledSecondaryTypography>
              <TypographyDefault>{PersonalDetailsData.city}</TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Postal Code</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.postalcode}
              </TypographyDefault>
            </Grid>
          </Grid>
        </Box>

        {/* Emergency Contact */}

        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <Stack direction="row" alignItems="center" gap="10px">
              <ContactDetailsIcon />
              <SecondaryTypography>Emergency Contact</SecondaryTypography>
            </Stack>
            <Button
              onClick={() => Continue("Personal Details", "")}
              sx={{
                width: "83px",
                height: "36px",
                padding: "8px 6px 8px 14px",
              }}
              variant="outlined"
            >
              {" "}
              Edit{" "}
              <IconButton sx={{ paddingLeft: "10px" }}>
                {" "}
                <EditIcon width="14px" />
              </IconButton>
            </Button>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px  ",
            }}
          >
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>First Name</StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.firstname}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Last Name</StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.lastname}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Email Address
              </StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.email}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Contact Number
              </StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.phone}
              </TypographyDefault>
            </Grid>
            {/* <Divider sx={{ borderTop: '1px solid #F3F4F6', width: '100%', marginTop: '10px' }} /> */}
            {/* <Grid xs={12} sm={5.8} sx={reviewstyle}>
                            <StyledSecondaryTypography>Nationality</StyledSecondaryTypography>
                            <TypographyDefault>{emergencyContactData.nationality}</TypographyDefault>
                        </Grid>
                        <Grid xs={12} sm={5.8} sx={reviewstyle}>
                            <StyledSecondaryTypography>Gender</StyledSecondaryTypography>
                            {genderData.map((itm) => {
                                if (emergencyContactData.gender === itm.id) {
                                    return <TypographyDefault>{itm.name}</TypographyDefault>
                                }
                            })}
                        </Grid> */}
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Relationship
              </StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.relationship}
              </TypographyDefault>
            </Grid>
          </Grid>
        </Box>

        {/* Work & Access Details */}

        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <Stack direction="row" alignItems="center" gap="10px">
              <ContactDetailsIcon />
              <SecondaryTypography>Work & Access Details</SecondaryTypography>
            </Stack>
            <Button
              onClick={() => Continue("Work & Access Details", "")}
              sx={{
                width: "83px",
                height: "36px",
                padding: "8px 6px 8px 14px",
              }}
              variant="outlined"
            >
              {" "}
              Edit{" "}
              <IconButton sx={{ paddingLeft: "10px" }}>
                {" "}
                <EditIcon width="14px" />
              </IconButton>
            </Button>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px  ",
            }}
          >
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Employment Type
              </StyledSecondaryTypography>
              <TypographyDefault>
                {WorkAccessData.employmenttype == 1 ? "Temporary" : "Permanent"}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Department</StyledSecondaryTypography>
              {department.map((itm, index) => {
                if (WorkAccessData.department === itm.id) {
                  return (
                    <TypographyDefault>{itm.department_name}</TypographyDefault>
                  );
                }
              })}
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Job Role</StyledSecondaryTypography>
              {department[departmentIndex]?.role.map((itm) => {
                if (WorkAccessData.jobrole === itm.id) {
                  return <TypographyDefault>{itm.title}</TypographyDefault>;
                }
              })}
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Access Start Date
              </StyledSecondaryTypography>
              <TypographyDefault>
                {date(WorkAccessData.accessstartdate.$d)}
              </TypographyDefault>
            </Grid>
            {WorkAccessData?.employmenttype === 1 && (
              <Grid xs={12} sm={5.8} sx={reviewstyle}>
                <StyledSecondaryTypography>
                  Access End Date
                </StyledSecondaryTypography>
                <TypographyDefault>
                  {WorkAccessData.accessenddate
                    ? date(WorkAccessData.accessenddate.$d)
                    : "-"}
                </TypographyDefault>
              </Grid>
            )}
          </Grid>
        </Box>

        {/* Onboarded by */}

        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <SecondaryTypography>Onboarded by</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px  ",
            }}
          >
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Name</StyledSecondaryTypography>
              <TypographyDefault>{onboardedby.name}</TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Email</StyledSecondaryTypography>
              <TypographyDefault>{onboardedby.email}</TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Department</StyledSecondaryTypography>
              <TypographyDefault>
                {onboardedby.department || "-"}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Job Role</StyledSecondaryTypography>
              <TypographyDefault>
                {onboardedby.jobrole || "-"}
              </TypographyDefault>
            </Grid>
          </Grid>
        </Box>

        <Box
          mt={3}
          bgcolor="#FFFFFF"
          sx={{
            border: "1px solid  #E5E7EB",
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "8px 8px 8px 8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="end"
            alignItems="center"
            height="60px"
          >
            {/* <Stack direction='row'>
                            <RefreshIcon style={{ marginRight: '5px' }} />
                            <StyledSecondaryTypography>Last Updated at  {moment(new Date()).format('HH:mm A')}</StyledSecondaryTypography>
                        </Stack> */}
            <Stack direction="column" justifyContent="end" alignItems="end">
              <Button
                type="button"
                onClick={AddStaff}
                sx={{ width: "fit-content" }}
                variant="Standard"
              >
                Continue
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  };

  const deleteContactDetail = () => {
    navigate("/staffs");
  };
  const handleShowDeleteModal = () => {
    setshowDeleteModal(!showDeleteModal);
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      {showDeleteModal && (
        <AlertModal
          modalDatas={deleteModalDatas}
          confirmFunction={deleteContactDetail}
          closeFunction={handleShowDeleteModal}
        />
      )}
      <Box p={5} sx={{ backgroundColor: "#F3F4F6" }}>
        <Stack mb={3} direction="row" justifyContent="space-between">
          <BackArrow
            onClick={() => navigate("/staffs")}
            style={{ marginBottom: "16px", cursor: "pointer" }}
          />
          <BorderAlertCloseIcon
            width="40"
            height="40"
            cursor="pointer"
            onClick={() => setshowDeleteModal(true)}
          />
        </Stack>
        <Box mb={5}>
          <PrimaryTypography mb={1}>Staff Onboarding</PrimaryTypography>
          <StyledSecondaryTypography>
            Fill in details below to onboard new staff. For onboarding of
            multiple staff, select batch onboarding instead.
          </StyledSecondaryTypography>
        </Box>
        <Grid container gap="1.5%">
          {blocks.map((itm) => {
            return (
              <Grid
                xs={3.87}
                onClick={() => Continue(itm.tle, "")}
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                <Box
                  component="div"
                  sx={{
                    borderTop: itm.status
                      ? "3px solid #0E4965"
                      : "3px solid #D2D6DB",
                    width: "100%",
                    color: itm.status ? "#0E4965" : "#9DA4AE",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "12px",
                      padding: "10px 0 0 0",
                    }}
                  >
                    {itm.tle}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        {addblocks.Personal_Details && PersonalDetails()}
        {addblocks.WorkAccess_Details && WorkAccessDetails()}
        {addblocks.Review_Information && ReviewInformation()}
      </Box>
    </>
  );
};

export default AddSingleStaff;
