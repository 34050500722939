import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  PrimaryTypography,
  StyledSwitch,
  StyledSecondaryTypography,
  TypographyDefault,
} from "../../../Theme";
import { Stack, Box, Skeleton } from "@mui/material";
import axiosInstance from "../../../Redux/AxiosInstance";
import AlertModal, { PostLoaderModal } from "../../../CommonComponents/modal";

const Notification = () => {
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { LanguageData } = useSelector((state) => state.Language);
  const { UserManage } = useSelector((state) => state.Login);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationId, setNotificationId] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const [loading, setloading] = useState(true);
  const [postLoader, SetPostLoader] = useState(false);

  const handleChange = (e, id, name) => {
    const { checked } = e.target;
    const updatedNotificationData = notificationData.map((notification) => {
      if (notification.key === name) {
        return {
          ...notification,
          is_enabled: checked,
        };
      }
      return notification;
    });

    setNotificationData(updatedNotificationData);
    setNotificationId(id);
    setTrigger(!trigger);
  };

  useEffect(() => {
    if (notificationId !== 0) {
      SetPostLoader(true);
      axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}notification/property/${propertyId}/type/${notificationId}`
        )
        .then((res) => {
          console.log(res);
          SetPostLoader(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [trigger]);

  useEffect(() => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}notification/property/${propertyId}/settings`
      )
      .then((res) => {
        setNotificationData(res.data.data?.notification_settings);
        setloading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <>
      {postLoader && <PostLoaderModal />}
      <PrimaryTypography mb={4}>
        {" "}
        {LanguageData?.Notification || "Notification"}{" "}
      </PrimaryTypography>
      {loading ? (
        <Skeleton variant="rounded" width="100%" height="50vh" />
      ) : (
        notificationData.map((notificationValue, index) => (
          <Box
            sx={{
              borderRadius:
                index === 0
                  ? "8px 8px 0px 0px"
                  : index === notificationData.length - 1
                  ? "0px 0px 8px 8px"
                  : "initial",
            }}
            backgroundColor="#FFFFFF"
            key={notificationValue?.id}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              padding="24px 20px 24px 20px"
              borderBottom="1px solid #F3F4F6"
            >
              <Stack direction="column">
                <TypographyDefault fontWeight="500 !important">
                  {notificationValue?.name}
                </TypographyDefault>
                <StyledSecondaryTypography>
                  {notificationValue?.description}
                </StyledSecondaryTypography>
              </Stack>
              <StyledSwitch
                checked={notificationValue?.is_enabled}
                onChange={(e) =>
                  handleChange(e, notificationValue?.id, notificationValue?.key)
                }
                disabled={!UserManage?.settings}
              />
            </Stack>
          </Box>
        ))
      )}
    </>
  );
};

export default Notification;
